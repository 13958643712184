import { Box, Card, Table, TableBody, TableContainer } from '@mui/material';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ActivityTableRow, { StatusType, UpdateTime } from './ActivityTableRow';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Activity,
  ActivitySubmitRequest,
  archiveAnAppointment,
  submitWeeklyActivitySheet,
  updateAppointment,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { EmployeeType, ResponseType } from '../../utils/type';
import { getHeaderValues } from './activityUtils';
import ModalPopup from '../../components/formlib/ModalPopup';
import { debounce } from 'lodash';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { GetServiceAndSettingsCode } from '../../services/progressNotes/GetServiceAndSettingsCode';
import { CodeListType } from '../ProgressNote/ProgressNote';
import { LookUpContext } from '../../context/LookUpContextProvider';
import {
  GroupBilling,
  UpdateGroupBillig,
} from '../../services/progressNotes/ProgressNotesApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { convertTimeDuration } from '../../utils/dateUtil';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface HourlyNonExemptEmployeeHeader {
  day: string;
  billableHrs: string;
  approvedNonBillingHrs: string;
  otherAssocWorkHrs: string;
  noShowTravelWaitDoc: string;
  otherNonBillableHrs: string;
  hrsOut?: string;
  totalHrs: string;
  status: string;
  actions: string;
}

export interface SalariedNonExemptEmployeeHeader {
  day: string;
  billableHrs: string;
  approvedNonBillingHrs: string;
  otherAssocWorkHrs: string;
  hrsOut?: string;
  totalHrs: string;
  status: string;
  actions: string;
}

export interface SalariedEmployeeHeader {
  day: string;
  billableHrs: string;
  approvedNonBillingHrs: string;
  otherAssocWorkHrs: string;
  hrsOut?: string;
  totalHrs: string;
  status: string;
  actions: string;
}

interface TableViewProps {
  selectedDate?: Date;
  activities: Activity[];
  employeeType: EmployeeType | null;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  isEmployee: boolean;
  updateActivities?: (
    activitySheetId: string | null,
    status: StatusType,
  ) => void;
  submitApprovedActivities?: () => void;
  screenName: string;
}

type ActivitySheetHeaderType =
  | HeadCell<HourlyNonExemptEmployeeHeader>[]
  | HeadCell<SalariedNonExemptEmployeeHeader>[]
  | HeadCell<SalariedEmployeeHeader>[];

const ActivityListTableView: FC<TableViewProps> = ({
  selectedDate,
  employeeType,
  activities,
  setReload,
  reload,
  isEmployee,
  updateActivities,
  submitApprovedActivities,
  screenName,
}) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [showArchiveModel, setArchiveModel] = useState<boolean>(false);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [checkBoxEnabled, setCheckBoxEnabled] = useState<boolean>(
    isEmployee ? false : activities.length !== 0,
  );
  const [headerCells, setHeaderCells] = useState<ActivitySheetHeaderType>([]);
  const { updateServiceAndSettingCodes } = useContext(LookUpContext);
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);

  useEffect(() => {
    if (employeeType === null) {
      return;
    }
    const cells = getHeaderValues(employeeType);
    if (
      checkPermissionForFeature(
        'backend.review_activity_sheet',
        'editPermission',
      ) &&
      screenName === 'ApprovalActivity'
    ) {
      cells.push({
        id: 'status',
        labelId: 'Dashboard.Status',
        defaultLabelId: 'Status',
        numeric: false,
        requiredSorting: false,
      });
      cells.push({
        id: 'actions',
        labelId: 'actions',
        defaultLabelId: 'Actions',
        numeric: false,
        requiredSorting: false,
      });
    }
    if (screenName === 'EmployeeActivity') {
      cells.push({
        id: 'status',
        labelId: 'Dashboard.Status',
        defaultLabelId: 'Status',
        numeric: false,
        requiredSorting: false,
      });
      cells.push({
        id: 'actions',
        labelId: 'actions',
        defaultLabelId: 'Actions',
        numeric: false,
        requiredSorting: false,
      });
    }
    setHeaderCells(cells);
  }, []);

  useEffect(() => {
    GetServiceAndSettingsCode()
      .then((coderesponse: CodeListType) => {
        const serviceCodes = coderesponse.serviceCode.map((code) => ({
          id: code.id,
          label: code.code,
        }));
        const settingsCodes = coderesponse.settingCode.map((code) => ({
          id: code.id,
          label: code.code,
        }));
        updateServiceAndSettingCodes(serviceCodes, settingsCodes);
      })
      .catch(() => {});
  }, []);

  const employeeSubmit = useCallback(
    debounce((activityArray: Activity[]) => {
      prepareActivityRequestAndSubmit(activityArray);
    }, 300),
    [],
  );

  const prepareActivityRequestAndSubmit = (activityArray: Activity[]) => {
    let progressNotesCompleted = true;
    for (const element of activityArray) {
      if (!element.archived && element.archivalStatus !== 'Submitted') {
        const hasPendingOrInProgress = element.groupBilling.some((group) =>
          group.appointments.some((appointment) => {
            if (appointment.interpreter) {
              return false;
            }
            return (
              appointment.progressNotesStatus === 'Pending' ||
              appointment.progressNotesStatus === 'In Progress'
            );
          }),
        );
        if (hasPendingOrInProgress) {
          progressNotesCompleted = false;
          break;
        }
      }
    }
    if (!progressNotesCompleted) {
      setSuccessOrError('error');
      setToastrId('pendingProgressNoteValidationMessage');
      setToastrDefaultMessage(
        'One or more Progress Notes are not completed. Please complete all Progress Notes to submit your Activity Sheet.',
      );
      return;
    }

    let archived = false;
    for (const element of activityArray) {
      if (
        element.archived &&
        (element.archivalStatus === null ||
          element.archivalStatus === 'Pending')
      ) {
        archived = true;
        break;
      } else {
        archived = element.groupBilling.some((group) => {
          group.appointments.some(
            (appt) =>
              appt.archived &&
              (appt.archivalStatus === null ||
                appt.archivalStatus === 'Pending'),
          );
        });
        if (archived) {
          break;
        }
      }
    }

    if (archived) {
      setArchiveModel(true);
    } else if (activityArray.length > 0) {
      submitActivitySheet(activityArray);
    }
  };

  const checkAlreadySubmitted = () => {
    let alreadySubmitted = true;
    activities.forEach((activity) => {
      if (
        activity.status !== 'Submitted' &&
        activity.status !== 'Approved' &&
        activity.archivalStatus !== 'Submitted'
      ) {
        alreadySubmitted = false;
      }
    });
    return alreadySubmitted;
  };

  const submitActivitySheet = (activityArray: Activity[]) => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    const submitRequest: ActivitySubmitRequest = {
      employeeId: userId,
      activities: [],
      startDate: '',
      endDate: '',
    };
    activityArray.forEach((activity) => {
      activity.groupBilling.forEach((group) => {
        group.groupBillingDuration =
          group.groupBillingDuration !== null
            ? convertTimeDuration(group.groupBillingDuration, false)
            : null;
      });
      if (
        (activity.archivalStatus === null ||
          activity.archivalStatus === 'Pending') &&
        (activity.status === 'Not Submitted' || activity.status === 'Rejected')
      ) {
        const subIds: string[] = [];
        activity.groupBilling.forEach((group) => {
          group.appointments.forEach((appt) => {
            subIds.push(appt.progressNotesId);
          });
        });
        submitRequest.activities.push({
          progressNotesIds: subIds,
          groupBilling: activity.archived ? [] : activity.groupBilling,
          activitySheetId: activity.activitySheetId,
          status: activity.status,
        });
      }
    });

    if (submitRequest.activities.length === 0) {
      return;
    }
    const currentDate = dayjs(selectedDate);
    const newStartDate = currentDate.date(
      currentDate.date() - currentDate.day(),
    );

    const newEndDate = currentDate.date(
      currentDate.date() + (6 - currentDate.day()),
    );
    const startDateInUTC =
      moment(newStartDate.startOf('day').format(), 'YYYY-MM-DDTHH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    const endDateDateInUTC =
      moment(newEndDate.endOf('day').format(), 'YYYY-MM-DDTHH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

    submitRequest.startDate = startDateInUTC;
    submitRequest.endDate = endDateDateInUTC;

    toggleLoader(true);
    setArchiveModel(false);
    submitWeeklyActivitySheet(submitRequest)
      .then(() => {
        setSuccessOrError('success');
        setToastrId('activitySubmitSuccessMessage');
        setToastrDefaultMessage('Activity sheet submitted successfully');
        toggleLoader(false);
        setReload(true);
        setCheckBoxEnabled(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 13001) {
            setToastrId('progressNoteNotSignedMessage');
            setToastrDefaultMessage(response.message);
          } else if (response.code === 13002) {
            setToastrId('timeOffNotApprovedMessage');
            setToastrDefaultMessage(response.message);
          } else if (response.code === 1057) {
            setToastrId('activitySheetGroupBillingErrorMessage');
            setToastrDefaultMessage(response.message);
          } else {
            setToastrId('activitySubmitFailureMessage');
            setToastrDefaultMessage('Failed to submit Activity sheet');
          }
        }
      });
  };

  const archiveItem = (
    activitySheetId: string,
    progressNotesId: string,
    archive: boolean,
  ) => {
    toggleLoader(true);
    archiveAnAppointment(activitySheetId, progressNotesId, archive)
      .then(() => {
        setSuccessOrError('success');
        if (archive) {
          setToastrId('archiveSuccessMessage');
          setToastrDefaultMessage('Archived successfully');
        } else {
          setToastrId('unArchiveSuccessMessage');
          setToastrDefaultMessage('Unarchived successfully');
        }
        toggleLoader(false);
        setReload(true);
        setCheckBoxEnabled(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('archiveFailureMessage');
          setToastrDefaultMessage('Archive Failed');
        }
      });
  };

  const updateGroupBillingFromActivitySheet = (data: GroupBilling) => {
    toggleLoader(true);
    UpdateGroupBillig(data)
      .then(() => {
        setSuccessOrError('success');
        setToastrId('GroupBilling.UpdatedSuccess');
        setToastrDefaultMessage('Group Billing Updated Successfully');
        toggleLoader(false);
        setReload(true);
      })
      .catch(() => {
        setSuccessOrError('error');
        setToastrId('GroupBilling.UpdatedError');
        setToastrDefaultMessage('Failed to Update Group Billing');
        toggleLoader(false);
      });
  };

  const updateTimeOrNote = (
    activitySheetId: string,
    data: UpdateTime,
    updateType: 'NOTE' | 'TIME',
  ) => {
    toggleLoader(true);
    updateAppointment({
      activitySheetId: activitySheetId,
      description: data.note,
      approvedNonBillableHours: data.approvedNonBillableHours,
      otherAssocWorkHrs: data.otherAssocWorkHrs,
      otherNonBillableHrs: data.otherNonBillableHrs,
      totalHours: data.totalHours,
      noShowTravelDoc: data.noShowTravelDoc,
    })
      .then(() => {
        setSuccessOrError('success');
        if (updateType === 'NOTE') {
          setToastrId('noteUpdateSuccessMessage');
          setToastrDefaultMessage('Note updated successfully');
        } else {
          setToastrId('dataUpdatedTextMessage');
          setToastrDefaultMessage('Data Updated Successfully');
        }
        toggleLoader(false);
        setReload(true);
        setCheckBoxEnabled(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          if (updateType === 'NOTE') {
            setToastrId('noteUpdateFailureMessage');
            setToastrDefaultMessage('Failed to update note');
          } else {
            setToastrId('dataUpdateFailMessage');
            setToastrDefaultMessage('Data Failed to Update');
          }
        }
      });
  };

  return (
    <Box
      component={'section'}
      sx={{
        marginTop: '12px',
      }}
    >
      {toastrId && !reload && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}

      {confirmationPopup && (
        <ModalPopup
          open={confirmationPopup}
          maxWidth={'xs'}
          description="activitySheetConfirmationDesc"
          onCancel={() => {
            setCheckBoxEnabled(false);
            setConfirmationPopup(false);
          }}
          onClose={() => {
            setCheckBoxEnabled(false);
            setConfirmationPopup(false);
          }}
          onOk={() => {
            setConfirmationPopup(false);
            employeeSubmit(activities);
          }}
          labelId1="cancelText"
          negativeActionLabel="Cancel"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="Yes"
        />
      )}

      {showArchiveModel === true && (
        <ModalPopup
          open={showArchiveModel}
          maxWidth={'sm'}
          description="archivalWarningDesc"
          onCancel={() => {
            setCheckBoxEnabled(false);
            setArchiveModel(false);
          }}
          onClose={() => {
            setCheckBoxEnabled(false);
            setArchiveModel(false);
          }}
          onOk={() => {
            setCheckBoxEnabled(false);
            submitActivitySheet(activities);
          }}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="No"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="Yes"
        />
      )}

      <Card sx={{ margin: '8px 0px' }}>
        <TableContainer>
          <Table>
            <TableHeader
              className="activityHeader listDataTableHead"
              labelSxProp={{ whiteSpace: 'normal' }}
              headerNames={headerCells}
              checkBoxRequired={false}
            />
            <TableBody className="tableRowcss">
              {activities?.map((item) => {
                return (
                  <ActivityTableRow
                    key={item.activitySheetId}
                    props={item}
                    headerCount={headerCells.length}
                    employeeType={employeeType}
                    isEmployee={isEmployee}
                    onStatusUpdate={(activitySheetId, status) => {
                      if (updateActivities) {
                        updateActivities(activitySheetId, status);
                      }
                    }}
                    updateTimeOrNote={(
                      activitySheetId: string,
                      data: UpdateTime,
                      updateType: 'NOTE' | 'TIME',
                    ) => {
                      updateTimeOrNote(activitySheetId, data, updateType);
                    }}
                    archiveItem={(
                      activitySheetId: string,
                      progressNotesId: string,
                      archive: boolean,
                    ) => {
                      archiveItem(activitySheetId, progressNotesId, archive);
                    }}
                    updateGroupBilling={(data) => {
                      updateGroupBillingFromActivitySheet(data);
                    }}
                    screenName={screenName}
                    selectedDate={selectedDate}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {isEmployee && selectedDate && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckBoxComponent
            ischecked={checkBoxEnabled}
            CheckHandleChange={(e) => {
              const currentWeekEndDate = dayjs().date(
                dayjs().date() + (6 - dayjs().day()),
              );
              if (
                selectedDate &&
                currentWeekEndDate.isAfter(selectedDate, 'days')
              ) {
                setCheckBoxEnabled(e.target.checked);
              }
            }}
            disabled={
              (activities && activities.length === 0) || checkAlreadySubmitted()
            }
            labelid={'activityAgreeDesc'}
            defaultlabelid="By clicking on this checkbox, you are attesting to its accuracy."
          />
        </Box>
      )}
      {screenName === 'EmployeeActivity' ? (
        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'end'}
          paddingTop={isEmployee ? '0px' : '8px'}
        >
          {!isEmployee && (
            <ButtonComponent
              className="btn-primary btn-cancel"
              variantType="contained"
              type="submit"
              labelId={'cancelText'}
              onClick={() => {
                if (updateActivities) {
                  updateActivities(null, 'Submitted');
                }
              }}
            />
          )}
          {selectedDate && (
            <ButtonComponent
              className="btn-primary btn-submit ml-md"
              variantType="contained"
              type="submit"
              labelId={'submitText'}
              disabled={isEmployee ? !checkBoxEnabled : activities.length === 0}
              onClick={() => {
                if (isEmployee) {
                  const alreadySubmitted = checkAlreadySubmitted();
                  if (!alreadySubmitted) {
                    setConfirmationPopup(true);
                  }
                } else {
                  if (submitApprovedActivities) {
                    submitApprovedActivities();
                  }
                }
              }}
            />
          )}
        </Box>
      ) : checkPermissionForFeature(
          'backend.review_activity_sheet',
          'editPermission',
        ) && screenName === 'ApprovalActivity' ? (
        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'end'}
          paddingTop={isEmployee ? '0px' : '8px'}
          paddingBottom={'8px'}
        >
          {!isEmployee && (
            <ButtonComponent
              className="btn-primary btn-cancel"
              variantType="contained"
              type="submit"
              labelId={'cancelText'}
              onClick={() => {
                if (updateActivities) {
                  updateActivities(null, 'Submitted');
                }
              }}
            />
          )}
          <ButtonComponent
            className="btn-primary btn-submit ml-md"
            variantType="contained"
            type="submit"
            labelId={'submitText'}
            disabled={isEmployee ? !checkBoxEnabled : activities.length === 0}
            onClick={() => {
              if (isEmployee) {
                employeeSubmit(activities);
              } else {
                if (submitApprovedActivities) {
                  submitApprovedActivities();
                }
              }
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ActivityListTableView;

import { FC } from 'react';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Badge,
  styled,
  Tooltip,
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import TitleText from '../../components/formlib/TitleText';
import SearchBox from '../../components/formlib/SearchBox';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { Order, ResponseType } from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { PAGE_SIZE } from '../../services/Constant';
import { useNavigate } from 'react-router';
import { ADD_EDIT_INSURANCE_ROUTE_NAME } from '../../routes/Routing';
import {
  InsuranceType,
  getMasterInsuranceList,
} from '../../services/configApi/insurance/insuranceProvider';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import dayjs from 'dayjs';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const CustomBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#37D183',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

const CustomBadges = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#CF273C',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

interface Data {
  insuranceCompanyName: string;
  payerId: string;
  phoneNumber: string;
  address: string;
  cityState: string;
  status: boolean;
  actions: string;
}

type HeadCell = {
  id: keyof Data;
  label: string;
  numeric: boolean;
  requiredSorting: boolean;
};

interface EnhancedTableProps {
  headCells: HeadCell[];
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const initialHeadCells: HeadCell[] = [
  {
    id: 'insuranceCompanyName',
    label: 'Third Part Payer',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'payerId',
    label: 'Payer Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'address',
    label: 'Address',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'cityState',
    label: 'City/State',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    label: 'Status',
    numeric: false,
    requiredSorting: false,
  },
];

const TableHeader: FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof Data, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className="listDataTableHead">
      <TableRow>
        <TableCell padding="checkbox">
          <CheckBoxComponent ischecked={false} />
        </TableCell>
        {props.headCells.map((cell: HeadCell) => (
          <TableCell
            key={cell.id}
            align={cell.numeric ? 'right' : 'left'}
            sx={{ height: '49px', padding: '12px 14px' }}
          >
            <TableSortLabel
              className="Tableheaderstyle"
              sx={{
                fontFamily: 'Lato-Bold',
                color: '#97A6A5',
                textWrap: 'nowrap',
              }}
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={createSortHandler(cell.id, cell.requiredSorting)}
              hideSortIcon={!cell.requiredSorting}
            >
              {cell.label}
              {orderBy === cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const InsuranceList = () => {
  const navigate = useNavigate();
  const [insurances, setInsurances] = useState<InsuranceType[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();
  const [pageNumber, setPageNumber] = useState(0);
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('insuranceCompanyName');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const handleButtonClick = (insurance: InsuranceType | null) => {
    navigate(ADD_EDIT_INSURANCE_ROUTE_NAME, {
      replace: false,
      state: insurance,
    });
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(0);
        setSearchQuery(value);
      } else {
        setSuccessOrError('error');
        setToastrId('Dashboard.ClientList.searchError');
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (
      headCells.length === 6 &&
      checkPermissionForFeature('backend.insurance_master', 'editPermission')
    ) {
      headCells.push({
        id: 'actions',
        label: 'Actions',
        numeric: false,
        requiredSorting: false,
      });
    }
    toggleLoader(true);
    getMasterInsuranceList(searchQuery, pageNumber, PAGE_SIZE, orderBy, order)
      .then((response) => {
        setPaginationCount(
          response.insuranceCount === null ? 0 : response.insuranceCount,
        );
        if (response.insurances !== null) {
          setInsurances(response.insurances);
        } else {
          setInsurances([]);
        }

        toggleLoader(false);
      })
      .catch((error) => {
        setSuccessOrError('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, [searchQuery, pageNumber, order]);

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={successOrError}
          labelId={toastrId}
          defaultMessageId={defaultMessage}
        />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Box
          sx={{
            padding: '20px 0px',
            display: 'flex',
            flexDirection: 'row',
          }}
          justifyContent={'space-between'}
        >
          <TitleText labelid="insuranceText" defaultlabel="Insurance" />
          <Box>
            <SearchBox
              labelId="searchInsuranceText"
              defaultlabel="Search Insurance"
              onChange={handleSearchChange}
            />
            {checkPermissionForFeature(
              'backend.insurance_master',
              'addPermission',
            ) && (
              <ButtonComponent
                className="btn-primary btn-submit ml-md"
                labelId="addNewText"
                variantType="contained"
                defaultLabelId="Add New"
                onClick={() => handleButtonClick(null)}
              />
            )}
          </Box>
        </Box>

        <Box>
          {searchQuery !== '' && insurances.length === 0 && <SearchNotFound />}
          {insurances.length !== 0 && (
            <Box>
              <Card sx={{ width: '100%' }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750, maxWidth: '100%' }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                  >
                    <TableHeader
                      headCells={headCells}
                      numSelected={0}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={insurances.length}
                    />
                    <TableBody className="tableRowcss">
                      {insurances.map((insurance, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {}}
                            key={index.toString()}
                            sx={{
                              cursor: 'pointer',
                              backgroundColor:
                                index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                            }}
                          >
                            <TableCell padding="checkbox">
                              <CheckBoxComponent ischecked={false} />
                            </TableCell>
                            <TooltipTableCell
                              className="cursorPointer"
                              textSxProps={{
                                maxWidth: '400px',
                              }}
                              value={insurance.insuranceCompanyName}
                              visible={
                                insurance.insuranceCompanyName.length > 40
                              }
                            />
                            <TooltipTableCell
                              value={
                                insurance.payerId ? insurance.payerId : '-'
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              textSxProps={{ maxWidth: '150px' }}
                              value={
                                insurance.phoneNumber
                                  ? insurance.phoneNumber
                                  : '-'
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              textSxProps={{ maxWidth: '200px' }}
                              value={
                                insurance.address1 && insurance.address2
                                  ? `${insurance.address1}, ${insurance.address2}`
                                  : insurance.address1
                                  ? insurance.address1
                                  : '-'
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              textSxProps={{ maxWidth: '150px' }}
                              value={
                                insurance.city && insurance.state
                                  ? `${insurance.city}, ${insurance.state}`
                                  : '-'
                              }
                              visible={true}
                            />
                            <TableCell>
                              <div>
                                <>
                                  {insurance.endDate &&
                                  insurance.startDate &&
                                  dayjs(insurance.endDate).isBefore(
                                    dayjs().format('MM/DD/YYYY'),
                                  ) ? (
                                    <CustomBadges variant="dot" />
                                  ) : (
                                    <CustomBadge variant="dot" />
                                  )}
                                  {insurance.endDate &&
                                  insurance.startDate &&
                                  dayjs(insurance.endDate).isBefore(
                                    dayjs().format('MM/DD/YYYY'),
                                  )
                                    ? 'In-Active'
                                    : 'Active'}
                                </>
                              </div>
                            </TableCell>
                            {checkPermissionForFeature(
                              'backend.insurance_master',
                              'editPermission',
                            ) && (
                              <TableCell>
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Box
                                    onClick={() => {
                                      handleButtonClick(insurance);
                                    }}
                                    sx={{ marginLeft: '16px' }}
                                  >
                                    <Tooltip title="Edit">
                                      <EditIcon className="cursorPointer" />
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>

              {paginationCount > 10 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AppPagination
                    pageNumber={pageNumber}
                    paginationCount={paginationCount}
                    handleChangePage={(_event, newPage) => {
                      if (newPage - 1 !== pageNumber) {
                        setPageNumber(newPage - 1);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InsuranceList;

import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from './InformationModal';
import ChildandFamilyIFSPOutcomesData from './IFSP/ChildandFamilyIFSPOutcomes.json';
import { ReactComponent as Delete } from '../../assets/images/deleteAlertIcon.svg';
import { ReactComponent as HideIcon } from '../../assets/images/hideIcon.svg';
import { ReactComponent as PrinterIcon } from '../../assets/images/printerIcon.svg';
import { ReactComponent as FilledAddIcon } from '../../assets/images/filledAddIcon.svg';
import { ReactComponent as AddIcon } from '../../assets/images/CircleAdd.svg';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import dayjs, { Dayjs } from 'dayjs';
import { Grid, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ProcessReviewTableRow from './ProcessReviewTableRow';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import IfspContext from './ifspContextApi/IfspContex';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import _ from 'lodash';
import OutcomesProgressInfoTabelRow from './OutcomesProgressInfoTabelRow';

interface ChildandFamilyIFSPOutcomesProps {
  info: boolean;
  open: boolean;
  isIfspScreenLocked: boolean;
  onClose: (open: boolean) => void;
  closeInfo: (info: boolean) => void;
  onOpen: (open: boolean) => void;
  openInfo: (info: boolean) => void;
  reviewInfo: boolean;
  openReviewInfo: (reviewInfo: boolean) => void;
  closeReviewInfo: (reviewInfo: boolean) => void;
  selectedLanguage: string;
}

type MainDataType = {
  outcomesProgressReview: RowDataType;
  outcomesProgressInformation: OutComesInfoType[];
  isChecked: boolean;
};

export type OutComesInfoType = {
  date: string | null | Dayjs;
  progressDesc: string;
  progressRating: string;
};

export type RowDataType = {
  outcomeId: string;
  startDate: string | null | Dayjs;
  endDate: string | null | Dayjs;
  concern: string;
  outcome: string;
  measurableProcedure: string;
  targetDate: string;
  isRecordSubmitted: boolean;
  tbrUpdateRequired: boolean;
  tbrOutcomeId: number;
};

type TableDataType = {
  startDate: string;
  endDate: string;
  concern: string;
  outcome: string;
  measurableProcedure: string;
  targetDate: string;
  action: string;
};

export type OutcomesProgressReviewDataType = {
  date: string | null | Dayjs;
  progressDesc: string;
  progressRating: string;
};

const headCells: HeadCell<TableDataType>[] = [
  {
    id: 'concern',
    labelId: 'Concern',
    defaultLabelId: 'Concern',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'outcome',
    labelId: 'Outcome',
    defaultLabelId: 'Outcome',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'measurableProcedure',
    labelId: 'Measurable Procedure',
    defaultLabelId: 'Measurable Procedure',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'targetDate',
    labelId: 'Time Frame',
    defaultLabelId: 'Time Frame',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startDate',
    labelId: 'Start Date',
    defaultLabelId: 'Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'endDate',
    labelId: 'End Date',
    defaultLabelId: 'End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'action',
    labelId: 'Action',
    defaultLabelId: 'Action',
    numeric: false,
    requiredSorting: false,
  },
];

const outComesProgressInfoheadCells: HeadCell<OutcomesProgressReviewDataType>[] =
  [
    {
      id: 'date',
      labelId: 'Date',
      defaultLabelId: 'Date',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'progressDesc',
      labelId: 'Progress Description',
      defaultLabelId: 'Progress Description',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'progressRating',
      labelId: 'Progress Rating',
      defaultLabelId: 'Progress Rating',
      numeric: false,
      requiredSorting: false,
    },
  ];

export const progressRatingsList = [
  {
    id: 'progress',
    label: "We're Making Progress",
  },
  {
    id: 'adjust',
    label: "Let's Make Adjustments",
  },
  {
    id: 'noLonger',
    label: 'No Longer a priority at this time',
  },
  {
    id: 'done',
    label: 'Outcome Met - We Did It',
  },
];

const validationSchema = yup.object().shape({
  startDate: yup.string().required('IFSP.startDateRequired'),
  endDate: yup.date().nullable().typeError('IFSP.endDateRequired'),
  concern: yup.string().required('IFSP.ConcernRequired'),
  outcome: yup.string().required('IFSP.OutcomeRequired'),
  measurableProcedure: yup
    .string()
    .required('IFSP.MeasurableProcedureRequired'),
  targetDate: yup.string().required('IFSP.TimeFrameRequired'),
});

const validateForm = (value: {
  startDate: string | null | Dayjs;
  endDate: string | null | Dayjs;
}) => {
  const errors: Partial<{
    startDate: string | null | Dayjs;
    endDate: string | null | Dayjs;
  }> = {};

  if (value.startDate && value.endDate) {
    const startDate = dayjs(value.startDate, 'MM/DD/YYYY');
    const endDate = dayjs(value.endDate, 'MM/DD/YYYY');

    if (startDate.isAfter(endDate)) {
      errors.endDate = 'IFSP.endDateValidationError';
    }
  }

  if (datePickerMinDateMaxDateValidate(value.startDate)) {
    errors.startDate = 'datePickerMinDateMaxDateValidate';
  }
  if (datePickerMinDateMaxDateValidate(value.endDate)) {
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }

  return errors;
};
const ChildandFamilyIFSPOutcomes: FC<ChildandFamilyIFSPOutcomesProps> = ({
  open,
  onOpen,
  onClose,
  isIfspScreenLocked,
  info,
  openInfo,
  closeInfo,
  openReviewInfo,
  closeReviewInfo,
  reviewInfo,
}) => {
  const { ifsp, updateIfspContext, initialIfsp } = useContext(IfspContext);
  const [startDate, setStartDate] = useState<string | null | Dayjs>(null);
  const [endDate, setEndDate] = useState<string | null | Dayjs>(null);
  const [progressDesc, setProgressDescription] = useState<string>('');
  const [progressRating, setProgressRating] = useState<string>('');
  const [progressInfoDate, setProgressInfoDate] = useState<
    string | null | Dayjs
  >(null);
  const [isValidProgressDesc, setIsValidProgressDesc] = useState<boolean>(true);
  const [isValidProgressRating, setIsValidProgressRating] =
    useState<boolean>(true);
  const [isValidProgressInfoDate, setIsValidProgressInfoDate] =
    useState<boolean>(true);
  const [concern, setConcern] = useState<string>('');
  const [outcome, setOutcome] = useState<string>('');
  const [measurableProcedure, setMeasurableProcedure] = useState<string>('');
  const [targetDate, setTargetDate] = useState<string>('');
  const [isRecordSubmitted, setIsRecordSubmitted] = useState<boolean>(false);
  const [tbrUpdateRequired, setTbrUpdateRequired] = useState<boolean>(false);
  const [tbrOutcomeId, setTbrOutcomeId] = useState<number>(0);
  const [mainData, setMainData] = useState<MainDataType[]>(
    ifsp.ifspTeam.forms.childIfspOutcomes,
  );
  const [showSubSection, setShowSubSection] = useState<boolean>(true);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [openedSubSectionIndex, setOpenedSubSectionIndex] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    formikRef.current?.setTouched({});
  }, [open]);

  const formikRef = useRef<
    FormikProps<{
      outcomeId: string;
      startDate: string | null | Dayjs;
      endDate: string | null | Dayjs;
      concern: string;
      outcome: string;
      measurableProcedure: string;
      targetDate: string;
      isRecordSubmitted: boolean;
      tbrOutcomeId: number;
      tbrUpdateRequired: boolean;
    }>
  >(null);

  const addMoreProgressReview = (
    index: number,
    values: {
      startDate: string | null | Dayjs;
      endDate: string | null | Dayjs;
      concern: string;
      outcome: string;
      measurableProcedure: string;
      targetDate: string;
      isRecordSubmitted: boolean;
      tbrUpdateRequired: boolean;
      tbrOutcomeId: number;
    },
  ) => {
    if (isEdit) {
      const updatedProgressReviewData = (mainData[
        index
      ].outcomesProgressReview = {
        ...mainData[index].outcomesProgressReview,
        startDate: values.startDate
          ? (dayjs(values.startDate) as Dayjs).format('MM/DD/YYYY')
          : null,
        endDate: values.endDate
          ? (dayjs(values.endDate) as Dayjs).format('MM/DD/YYYY')
          : null,
        concern: values.concern,
        measurableProcedure: values.measurableProcedure,
        outcome: values.outcome,
        targetDate: values.targetDate,
        tbrOutcomeId: values.tbrOutcomeId,
        tbrUpdateRequired: values.tbrUpdateRequired
          ? true
          : _.isEqual(
              initialIfsp.ifspTeam.forms.childIfspOutcomes[index]
                .outcomesProgressReview,
              mainData[index].outcomesProgressReview,
            ),
      });
      setMainData((prevData) => {
        const tempData = [...prevData];
        tempData[index].outcomesProgressReview = updatedProgressReviewData;
        return tempData;
      });
      setIsEdit(false);
      setStartDate(null);
      setEndDate(null);
      setProgressDescription('');
      setProgressRating('');
      setConcern('');
      setOutcome('');
      setMeasurableProcedure('');
      setTargetDate('');
      setIsRecordSubmitted(false);
      setTbrOutcomeId(0);
      setTbrUpdateRequired(false);
    } else {
      setMainData((prevData) => {
        const tempData = [...prevData];

        tempData[index].outcomesProgressReview = {
          outcomeId: '',
          startDate: values.startDate
            ? (dayjs(values.startDate) as Dayjs).format('MM/DD/YYYY')
            : null,
          endDate: values.endDate
            ? (dayjs(values.endDate) as Dayjs).format('MM/DD/YYYY')
            : null,
          concern: values.concern,
          measurableProcedure: values.measurableProcedure,
          outcome: values.outcome,
          targetDate: values.targetDate,
          isRecordSubmitted: values.isRecordSubmitted,
          tbrOutcomeId: values.tbrOutcomeId,
          tbrUpdateRequired: true,
        };

        return tempData;
      });

      setStartDate(null);
      setProgressDescription('');
      setProgressRating('');
      setConcern('');
      setOutcome('');
      setMeasurableProcedure('');
      setTargetDate('');
      setIsRecordSubmitted(false);
      setTbrOutcomeId(0);
      setTbrUpdateRequired(false);
      formikRef.current?.resetForm();
    }
  };

  const onDeleteRow = (data: RowDataType, subSectionIndex: number) => {
    setMainData((prevData) => {
      const tempData = [...prevData];
      tempData[subSectionIndex].outcomesProgressReview = {
        outcomeId: '',
        startDate: null,
        endDate: null,
        concern: '',
        outcome: '',
        measurableProcedure: '',
        targetDate: '',
        isRecordSubmitted: false,
        tbrOutcomeId: 0,
        tbrUpdateRequired: true,
      };
      return tempData;
    });
  };

  const onEditRow = (subSectionIndex: number) => {
    const editableData = mainData[subSectionIndex].outcomesProgressReview;
    formikRef.current?.setValues({
      outcomeId: editableData.outcomeId,
      startDate: editableData.startDate,
      endDate: editableData.endDate,
      concern: editableData.concern,
      outcome: editableData.outcome,
      measurableProcedure: editableData.measurableProcedure,
      targetDate: editableData.targetDate,
      isRecordSubmitted: editableData.isRecordSubmitted,
      tbrOutcomeId: editableData.tbrOutcomeId,
      tbrUpdateRequired: editableData.tbrUpdateRequired,
    });
    setStartDate(editableData.startDate);
    setEndDate(editableData.endDate);
    setConcern(editableData.concern);
    setOutcome(editableData.outcome);
    setMeasurableProcedure(editableData.measurableProcedure);
    setTargetDate(editableData.targetDate);
    setIsRecordSubmitted(editableData.isRecordSubmitted);
    setTbrOutcomeId(editableData.tbrOutcomeId);
    setTbrUpdateRequired(editableData.tbrUpdateRequired);
    setIsEdit(true);
  };
  const addSubSection = () => {
    setMainData([
      ...mainData,
      {
        outcomesProgressReview: {
          outcomeId: '',
          startDate: null,
          endDate: null,
          concern: '',
          outcome: '',
          measurableProcedure: '',
          targetDate: '',
          isRecordSubmitted: false,
          tbrOutcomeId: 0,
          tbrUpdateRequired: true,
        },
        outcomesProgressInformation: [],
        isChecked: true,
      },
    ]);
    setOpenIndex(mainData.length - 1);
  };

  const deleteSubSection = (index: number) => {
    const newSdpData = mainData.filter((__, i) => i !== index);
    setMainData(newSdpData);
    if (openIndex === index) {
      setShowSubSection(false);
    } else if (openIndex && openIndex > index) {
      setOpenIndex(openIndex - 1);
    }
  };

  useEffect(() => {
    setMainData(ifsp.ifspTeam.forms.childIfspOutcomes);
  }, [ifsp]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          childIfspOutcomes: mainData,
        },
      },
    });
    setOpenIndex(mainData.length - 1);
  }, [mainData]);
  return (
    <Formik
      initialValues={{
        outcomeId: '',
        startDate,
        endDate,
        concern,
        measurableProcedure,
        outcome,
        targetDate,
        isRecordSubmitted,
        tbrUpdateRequired,
        tbrOutcomeId,
      }}
      validationSchema={validationSchema}
      validate={validateForm}
      innerRef={formikRef}
      onSubmit={(values, { resetForm }) => {
        addMoreProgressReview(openedSubSectionIndex, values);
        resetForm();
      }}
    >
      {({ values, setFieldValue, errors, touched, handleSubmit }) => (
        <Box component="main">
          {info && (
            <InformationModal
              open={info}
              titleLabel="IFSP.Information"
              titleDefaultLabel="Information"
              buttonLabel=" IFSP.InfoOkButton"
              buttonDefaultLabel="Ok"
              onClose={() => {
                closeInfo(info);
              }}
              info={ChildandFamilyIFSPOutcomesData.info}
            />
          )}
          {reviewInfo && (
            <InformationModal
              open={reviewInfo}
              titleLabel="IFSP.Information"
              titleDefaultLabel="Information"
              buttonLabel=" IFSP.InfoOkButton"
              buttonDefaultLabel="Ok"
              onClose={() => {
                closeReviewInfo(reviewInfo);
              }}
              info={ChildandFamilyIFSPOutcomesData.reviewInfo}
            />
          )}
          <Box
            component="section"
            className="p-lg gray3 borderRadius4"
            sx={{
              border: '1px solid #00C6B8',
            }}
          >
            <Box component="div" className="flex__justify__space-between">
              {open ? (
                <Box component="div" className="flex__justify__space-between">
                  <MediumTypography
                    labelid="IFSP.session3"
                    defaultlabel="Child And Family IFSP Outcomes"
                    fontSize="18px"
                    fontweight={600}
                    textColor="#2A4241"
                    className="pr-md"
                  />
                  <InfoIcon
                    className="cursorPointer"
                    onClick={() => {
                      openInfo(info);
                    }}
                  />
                </Box>
              ) : (
                <MediumTypography
                  labelid="IFSP.session3"
                  defaultlabel="Child And Family IFSP Outcomes"
                  fontSize="18px"
                  fontweight={600}
                  textColor="#2A4241"
                />
              )}
              {!open ? (
                <DownArrow
                  className="cursorPointer"
                  style={{ color: '#008C82' }}
                  onClick={() => {
                    onOpen(open);
                  }}
                />
              ) : (
                <UpArrow
                  className="cursorPointer"
                  onClick={() => {
                    onClose(open);
                  }}
                />
              )}
            </Box>
            {open && (
              <Box component="div">
                {mainData.map((__, subSectionIndex) => (
                  <Box component="div" key={subSectionIndex}>
                    <Box
                      component="div"
                      className="flex__justify__space-between mt-lg mb-lg"
                      sx={{
                        borderTop:
                          subSectionIndex === 0 ? '1px solid #00C6B8' : 'none',
                        paddingTop: subSectionIndex === 0 ? '24px' : '0px',
                        paddingBottom:
                          showSubSection && openIndex === subSectionIndex
                            ? '0px'
                            : '24px',
                        borderBottom:
                          showSubSection && openIndex === subSectionIndex
                            ? 'none'
                            : '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__ align__items__center"
                      >
                        {showSubSection && openIndex === subSectionIndex ? (
                          <HideIcon
                            onClick={() => {
                              setShowSubSection(false);
                              setIsEdit(false);
                              setOpenIndex(subSectionIndex);
                            }}
                            className="cursorPointer"
                          />
                        ) : (
                          <FilledAddIcon
                            onClick={() => {
                              formikRef.current?.resetForm();
                              setShowSubSection(true);
                              setIsEdit(false);
                              setOpenIndex(subSectionIndex);
                            }}
                            className="cursorPointer"
                          />
                        )}
                        <MediumTypography
                          labelid="IFSP.outComesInformation"
                          defaultlabel="Outcomes Information"
                          textColor="#2A4241"
                          fontSize="16px"
                          fontweight={600}
                          className="ml-sm mr-sm"
                        />
                        <InfoIcon
                          className="cursorPointer"
                          onClick={() => {
                            openReviewInfo(reviewInfo);
                          }}
                        />
                      </Box>
                      <Box component="div" className=" flex__ ">
                        <Box
                          component="div"
                          className="cursorPointer flex__ align__items__center visibility__hidden"
                        >
                          <PrinterIcon />
                          <MediumTypography
                            labelid="r"
                            defaultlabel="Print"
                            textColor="#2A4241"
                            fontweight={700}
                            fontSize="16px"
                            className="ml-xs"
                          />
                        </Box>
                        {mainData.length > 1 &&
                          !isIfspScreenLocked &&
                          mainData[subSectionIndex].outcomesProgressReview
                            .isRecordSubmitted === false &&
                          mainData[subSectionIndex].outcomesProgressReview
                            .tbrOutcomeId < 1 && (
                            <Box
                              component="div"
                              className="cursorPointer flex__ align__items__center ml-lg"
                              onClick={() => {
                                if (!isIfspScreenLocked) {
                                  deleteSubSection(subSectionIndex);
                                }
                              }}
                            >
                              <Delete />
                              <MediumTypography
                                labelid="IFSP.Delete"
                                defaultlabel="Delete"
                                textColor="#EB4C60"
                                fontweight={700}
                                fontSize="16px"
                                className="ml-xs"
                              />
                            </Box>
                          )}
                      </Box>
                    </Box>
                    {showSubSection && openIndex === subSectionIndex && (
                      <Box
                        component="div"
                        className="pb-lg"
                        sx={{
                          borderBottom: '1px solid #00C6B8',
                        }}
                      >
                        {(!mainData[subSectionIndex].outcomesProgressReview
                          .startDate ||
                          isEdit) && (
                          <Box component="div">
                            <Grid container rowSpacing={'16px'}>
                              <Grid item xs={5}>
                                <Textinput
                                  disabled={isIfspScreenLocked}
                                  labelid="IFSP.consernPriority"
                                  defaultlabelid="Concern Priority"
                                  Value={values.concern}
                                  inputProps={{
                                    className: 'translate',
                                    maxLength: 500,
                                  }}
                                  handlechange={(text) =>
                                    setFieldValue('concern', text)
                                  }
                                  className="bg__white"
                                  Required
                                />
                                {errors.concern && touched.concern && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.concern}
                                    defaultlabel="Concern Priority is Required"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={0.5}></Grid>

                              <Grid item xs={5}>
                                <Textinput
                                  disabled={
                                    isIfspScreenLocked ||
                                    values.isRecordSubmitted ||
                                    values.tbrOutcomeId > 0
                                  }
                                  labelid="IFSP.outcome"
                                  defaultlabelid="Outcome"
                                  Value={values.outcome}
                                  inputProps={{
                                    className: 'translate',
                                    maxLength: 500,
                                  }}
                                  handlechange={(text) =>
                                    setFieldValue('outcome', text)
                                  }
                                  Required
                                  className="bg__white"
                                />
                                {errors.outcome && touched.outcome && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.outcome}
                                    defaultlabel="Outcome is Required"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={5}>
                                <Textinput
                                  disabled={isIfspScreenLocked}
                                  labelid="IFSP.MeasurableCriteria"
                                  defaultlabelid="Measurable Criteria & Procedures"
                                  Value={values.measurableProcedure}
                                  inputProps={{
                                    className: 'translate',
                                    maxLength: 500,
                                  }}
                                  handlechange={(text) =>
                                    setFieldValue('measurableProcedure', text)
                                  }
                                  Required
                                  className="bg__white"
                                />
                                {errors.measurableProcedure &&
                                  touched.measurableProcedure && (
                                    <MediumTypography
                                      className="errorText-md"
                                      labelid={errors.measurableProcedure}
                                      defaultlabel="Measurable Criteria & Procedures is Required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={0.5}></Grid>

                              <Grid item xs={5}>
                                <Textinput
                                  disabled={isIfspScreenLocked}
                                  labelid="IFSP.TimeFrame"
                                  defaultlabelid="Time Frame"
                                  Value={values.targetDate}
                                  inputProps={{
                                    className: 'translate',
                                    maxLength: 500,
                                  }}
                                  handlechange={(text) =>
                                    setFieldValue('targetDate', text)
                                  }
                                  Required
                                  className="bg__white"
                                />
                                {errors.targetDate && touched.targetDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.targetDate}
                                    defaultlabel="Time Frame is Required"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={5}>
                                <DatePickerComponent
                                  disabledDate={
                                    isIfspScreenLocked ||
                                    values.isRecordSubmitted ||
                                    values.tbrOutcomeId > 0
                                  }
                                  className="bg__white"
                                  required={true}
                                  labelid={'IFSP.startDate'}
                                  defaultlabelid={'Start Date*'}
                                  disableFuture={false}
                                  value={
                                    values.startDate
                                      ? (dayjs(values.startDate) as Dayjs)
                                      : null
                                  }
                                  handlechange={(dateValue: Dayjs | null) => {
                                    if (dateValue !== null) {
                                      const formattedDate =
                                        dayjs(dateValue).format('MM/DD/YYYY');
                                      setFieldValue('startDate', formattedDate);
                                    } else {
                                      setFieldValue('startDate', null);
                                      setStartDate(null);
                                    }
                                  }}
                                />
                                {errors.startDate && touched.startDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.startDate}
                                    defaultlabel="Start Date is Required"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={0.5}></Grid>
                              <Grid item xs={5}>
                                <DatePickerComponent
                                  disabledDate={isIfspScreenLocked}
                                  className="bg__white"
                                  labelid={'IFSP.endDate'}
                                  defaultlabelid={'End Date'}
                                  disableFuture={false}
                                  value={
                                    values.endDate
                                      ? (dayjs(values.endDate) as Dayjs)
                                      : null
                                  }
                                  handlechange={(dateValue: Dayjs | null) => {
                                    if (dateValue !== null) {
                                      const formattedDate =
                                        dayjs(dateValue).format('MM/DD/YYYY');
                                      setFieldValue('endDate', formattedDate);
                                    } else {
                                      setFieldValue('endDate', null);
                                      setEndDate(null);
                                    }
                                  }}
                                />
                                {errors.endDate && touched.endDate && (
                                  <MediumTypography
                                    className="errorText-md"
                                    labelid={errors.endDate}
                                    defaultlabel="End Date is Required"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={1} className="cursorPointer mt-xs">
                                <Box
                                  className="cursorPointer flex__ align__items__center"
                                  onClick={() => {
                                    if (!isIfspScreenLocked) {
                                      setOpenedSubSectionIndex(subSectionIndex);
                                      handleSubmit();
                                    }
                                  }}
                                >
                                  <Box className="flex__ align__items__center">
                                    <AddIcon className="cursorPointer mr-xs ml-lg" />
                                  </Box>
                                  <MediumTypography
                                    labelid={
                                      isEdit ? 'IFSP.Update' : 'IFSP.Add'
                                    }
                                    defaultlabel={isEdit ? 'Update' : 'Add'}
                                    fontweight={400}
                                    textColor="#2A4241"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                        <Box component="div" className="respoScrollTable mt-md">
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHeader
                                headerNames={headCells}
                                checkBoxRequired={false}
                                className="listDataTableHead"
                              />
                              <TableBody className="tableRowcss ">
                                {openIndex === subSectionIndex &&
                                  mainData[subSectionIndex]
                                    .outcomesProgressReview.startDate && (
                                    <ProcessReviewTableRow
                                      isIfspScreenLocked={isIfspScreenLocked}
                                      data={
                                        mainData[subSectionIndex]
                                          .outcomesProgressReview
                                      }
                                      onEdit={() => {
                                        if (!isIfspScreenLocked) {
                                          onEditRow(subSectionIndex);
                                        }
                                      }}
                                      onDelete={() => {
                                        if (!isIfspScreenLocked) {
                                          onDeleteRow(
                                            mainData[subSectionIndex]
                                              .outcomesProgressReview,
                                            subSectionIndex,
                                          );
                                        }
                                      }}
                                    />
                                  )}
                              </TableBody>
                            </Table>
                            {!mainData[subSectionIndex].outcomesProgressReview
                              .startDate && (
                              <Box component="div" className="width__100">
                                <MediumTypography
                                  textstyle={'center'}
                                  labelid="IFSP.emptyMessage"
                                  defaultlabel="No data added yet"
                                  className="p-sm"
                                />
                              </Box>
                            )}
                          </TableContainer>
                        </Box>
                        <MediumTypography
                          labelid="IFSP.outcomesProgressInformation"
                          defaultlabel="Outcomes Progress Information"
                          textColor="#2A4241"
                          fontSize="16px"
                          fontweight={600}
                          className="mt-md"
                        />
                        <Grid container rowSpacing={'16px'} className="mt-sm">
                          <Grid item xs={5}>
                            <DatePickerComponent
                              disabledDate={isIfspScreenLocked}
                              labelid="IFSP.Date"
                              defaultlabelid="Date*"
                              required={true}
                              value={
                                progressInfoDate
                                  ? (dayjs(progressInfoDate) as Dayjs)
                                  : null
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  setProgressInfoDate(formattedDate);
                                  setIsValidProgressInfoDate(true);
                                } else {
                                  setProgressInfoDate(null);
                                  setIsValidProgressInfoDate(false);
                                }
                              }}
                              className="bg__white"
                            />
                            {!isValidProgressInfoDate && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={'IFSP.DateRequired'}
                                defaultlabel="Date is Required"
                              />
                            )}
                          </Grid>
                          <Grid item xs={0.5}></Grid>
                          <Grid item xs={5}>
                            <Textinput
                              disabled={isIfspScreenLocked}
                              labelid="IFSP.progressDesc"
                              defaultlabelid="Progress Description*"
                              Value={progressDesc}
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              handlechange={(text) => {
                                setProgressDescription(text);
                                setIsValidProgressDesc(true);
                              }}
                              className="bg__white"
                            />
                            {!isValidProgressDesc && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={'IFSP.progressDescRequired'}
                                defaultlabel="Progress Description is Required"
                              />
                            )}
                          </Grid>
                          <Grid item xs={5}>
                            <DropdownComponent
                              disabled={isIfspScreenLocked}
                              names={progressRatingsList}
                              defaultlabelid="Progress Rating"
                              labelid="IFSP.progressRating"
                              value={progressRating}
                              handleChange={(value: string) => {
                                setProgressRating(value);
                                setIsValidProgressRating(true);
                              }}
                              Required
                            />
                            {!isValidProgressRating && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={'IFSP.progressRatingRequired'}
                                defaultlabel="Progress Rating is Required"
                              />
                            )}
                          </Grid>
                          <Grid item xs={0.5}></Grid>
                          <Grid item xs={5}>
                            <Box
                              className="cursorPointer flex__ align__items__center pt-xs"
                              onClick={() => {
                                if (
                                  !isIfspScreenLocked &&
                                  progressDesc &&
                                  progressRating &&
                                  progressInfoDate
                                ) {
                                  setMainData((prev) => {
                                    const tempData = [...prev];
                                    tempData[
                                      subSectionIndex
                                    ].outcomesProgressInformation.push({
                                      progressDesc: progressDesc,
                                      progressRating: progressRating,
                                      date: progressInfoDate,
                                    });
                                    return tempData; // Return the updated array
                                  });
                                  setProgressDescription('');
                                  setProgressRating('');
                                  setProgressInfoDate(null);
                                } else {
                                  const missingFields = [
                                    !progressDesc,
                                    !progressRating,
                                    !progressInfoDate,
                                  ];

                                  const missingFieldHandlers = [
                                    () => setIsValidProgressDesc(false),
                                    () => setIsValidProgressRating(false),
                                    () => setIsValidProgressInfoDate(false),
                                  ];
                                  missingFields.forEach((missing, index) => {
                                    if (missing) missingFieldHandlers[index]();
                                  });
                                }
                              }}
                            >
                              <Box className="flex__ align__items__center pr-sm">
                                <AddIcon className="cursorPointer" />
                              </Box>
                              <MediumTypography
                                labelid={'IFSP.Add'}
                                defaultlabel={'Add'}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box component="div" className="respoScrollTable mt-md">
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHeader
                                headerNames={outComesProgressInfoheadCells}
                                checkBoxRequired={false}
                                className="listDataTableHead"
                              />
                              <TableBody className="tableRowcss ">
                                {openIndex === subSectionIndex &&
                                  mainData[
                                    subSectionIndex
                                  ].outcomesProgressInformation.map(
                                    (
                                      listData: OutcomesProgressReviewDataType,
                                      index,
                                    ) => (
                                      <OutcomesProgressInfoTabelRow
                                        key={index}
                                        data={listData}
                                      />
                                    ),
                                  )}
                              </TableBody>
                            </Table>
                            {mainData[subSectionIndex]
                              .outcomesProgressInformation.length === 0 && (
                              <Box component="div" className="width__100">
                                <MediumTypography
                                  textstyle={'center'}
                                  labelid="IFSP.emptyMessage"
                                  defaultlabel="No data added yet"
                                  className="p-sm"
                                />
                              </Box>
                            )}
                          </TableContainer>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
                <Box component="div" className="mt-md">
                  <ButtonComponent
                    disabled={isIfspScreenLocked}
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="Add Additional Outcome"
                    defaultLabelId="Add Additional Outcome"
                    onClick={addSubSection}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default ChildandFamilyIFSPOutcomes;

import React from 'react';
import { Box, styled } from '@mui/system';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import { Badge, Grid, Tooltip } from '@mui/material';
import SmallTypography from '../../components/formlib/SmallTypography';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { FaceSheetType, OptionType } from '../../utils/type';
import { CLIENTS_ROUTE_NAME } from '../../routes/Routing';
import { useNavigate } from 'react-router';
import { calculateMonths, convertTimeDuration } from '../../utils/dateUtil';
import { ReactComponent as History } from '../../assets/images/history.svg';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { eicsConcentValues } from './FaceSheetScreen';
import { formatName } from '../../utils/nameUtils';
import { Codes } from '../../services/configApi/codelist/diagnosticCodes';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { getPhoneNumber } from '../../utils/utilities';

interface ClientInfoType {
  data: FaceSheetType;
  handleServiceDailog: () => void;
  handleIntakeDailog: () => void;
  handleFormDailog: () => void;
  handleSSPProviders: () => void;
  serviceCoordinatorsList: OptionType[];
  intakeCoordinatorsList: OptionType[];
}

const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color ?? '#37D183',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
    marginRight: '10px',
  },
}));

const badgePicker = (status: string) => {
  switch (status) {
    case 'Inactive':
      return (
        <CustomBadge
          variant="dot"
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: 'red',
            },
          }}
        />
      );
    case 'Pending':
      return (
        <CustomBadge
          variant="dot"
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#FFA500',
            },
          }}
        />
      );
    default:
      return <CustomBadge variant="dot" />;
  }
};

const getClientStatusColor = (status: string) => {
  if (status === 'Inactive') return 'red';
  if (status === 'Pending') return '#FFA500';
  return '#37D183';
};

const getClientInfo = (data: FaceSheetType) => {
  const clientId = localStorage.getItem('ClientId');
  const statusColor = getClientStatusColor(data.demographics.status);
  const apartmentText =
    data.parents.length > 0 && data.parents[0].apartment
      ? `${data.parents[0].apartment}, `
      : '';

  return { clientId, statusColor, apartmentText };
};

const getParentName = (parentData: FaceSheetType) => {
  if (parentData.parents.length > 0) {
    const parentName = [
      parentData.parents[0].firstName ?? '',
      parentData.parents[0].middleName ?? '',
      parentData.parents[0].lastName ?? '',
    ]
      .filter(Boolean)
      .join(' ');
    return parentName || '-';
  }
  return '-';
};

const getConsentValues = (value: number) => {
  const consentValue = eicsConcentValues.find(
    (item) => parseInt(item.value) === value,
  );
  if (consentValue) {
    return consentValue.defaultLabel;
  }
  return '-';
};

const getDiagnosisForView = (viewData: Codes) => {
  if (viewData !== null) {
    if (viewData.isUnderlined) {
      return `${viewData.code} - * ${viewData.description}`;
    } else {
      return `${viewData.code} - ${viewData.description}`;
    }
  } else {
    return '-';
  }
};

const ClientInfoFaceSheet: React.FC<ClientInfoType> = ({
  data,
  handleServiceDailog,
  handleIntakeDailog,
  handleFormDailog,
  handleSSPProviders,
  serviceCoordinatorsList,
  intakeCoordinatorsList,
}) => {
  const navigation = useNavigate();
  const { clientId, statusColor, apartmentText } = getClientInfo(data);
  const { lookups } = React.useContext(LookUpContext) as LookUpContextType;

  return (
    <>
      <Box>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            paddingRight: 1,
            cursor: 'pointer',
          }}
        >
          <ArrowRight
            onClick={() => {
              sessionStorage.setItem('tabName', '0');
              navigation(CLIENTS_ROUTE_NAME, {
                state: { clientId: clientId, isCreate: true },
              });
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}
              className="mt-xs"
            >
              <Box>
                <Tooltip
                  title={formatName(
                    data.demographics.firstName,
                    data.demographics.middleName,
                    data.demographics.lastName,
                    data.demographics.suffix,
                  )}
                  arrow={true}
                  sx={{ maxWidth: 'max-content' }}
                >
                  <Box>
                    <MediumTypography
                      label={formatName(
                        data.demographics.firstName,
                        data.demographics.middleName,
                        data.demographics.lastName,
                        data.demographics.suffix,
                      )}
                      textColor="#2A4241"
                      fontSize="20px"
                      fontweight={700}
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box>
                {data.demographics.isSecondaryProgramServices && (
                  <SmallTypography
                    labelId="(secondaryProgramServiceText)"
                    defaultLabelId="(Secondary Program Service)"
                    sxProps={{
                      fontSize: '12px',
                      fontWeight: '400',
                      alignSelf: 'flex-end',
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box display={'flex'}>
              <Grid item xs={12}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                  className="mt-xs"
                >
                  <SmallTypography
                    text={
                      data.demographics.sex
                        ? convertGenderToFrontend(data.demographics.sex)
                        : '-'
                    }
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <SmallTypography
                    text="|"
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      paddingX: 1,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <SmallTypography
                    text={data.demographics.dateOfBirth}
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <SmallTypography
                    text="|"
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      paddingX: 1,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <SmallTypography
                    text={calculateMonths(
                      data.demographics.dateOfBirth,
                    ).replace(/[()]/g, '')}
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <SmallTypography
                    text="|"
                    sxProps={{
                      color: '#97A6A5',
                      margin: 0,
                      paddingX: 1,
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Box sx={{ marginLeft: '16px', marginTop: '-4px' }}>
                      {badgePicker(data.demographics.status)}
                    </Box>
                    <SmallTypography
                      text={data.demographics.status}
                      sxProps={{
                        color: statusColor,
                        margin: 0,
                        fontSize: '12px',
                        fontWeight: 500,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={3}>
                {data.enrollmentStatus && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <MediumTypography
                      labelid={'Enrollment Status:'}
                      defaultlabel={'Enrollment Status:'}
                      textColor="#97A6A5"
                      sxProps={{
                        fontSize: '12px',
                        fontWeight: '700',
                        alignSelf: 'flex-end',
                        textWrap: 'nowrap',
                        wordBreak: 'break-word',
                      }}
                    />
                    <Tooltip
                      id="tool-tip"
                      title={data.enrollmentStatus}
                      placement="top"
                    >
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontWeight: '400',
                          alignSelf: 'flex-end',
                          marginLeft: '5px',
                          overflow: 'hidden',
                          textWrap: 'nowrap',
                          wordBreak: 'break-word',
                          width: '100%',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <MediumTypography
                          labelid={data.enrollmentStatus}
                          defaultlabel={data.enrollmentStatus}
                          textColor="#2A4241"
                          sxProps={{
                            fontSize: '12px',
                            fontWeight: '400',
                            alignSelf: 'flex-end',
                            marginLeft: '5px',
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            wordBreak: 'break-word',
                            width: 'auto',
                            maxWidth: '200px',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <MediumTypography
            labelid={'ConsentForms.contactAddress'}
            defaultlabel={'Address:'}
            sxProps={{ color: '#00C6B8', fontSize: '12px' }}
          />
          <Box sx={{ width: '650px' }}>
            <Tooltip
              title={
                [
                  data.demographics.address.street,
                  data.demographics.address.address2,
                  data.demographics.address.city,
                  data.demographics.address.state,
                  data.demographics.address.zipCode,
                ]
                  .filter((part) => part !== null && part !== '')
                  .join(', ') || '-'
              }
              arrow={true}
              sx={{ maxWidth: 'max-content' }}
            >
              <Box>
                <MediumTypography
                  label={
                    [
                      data.demographics.address.street,
                      data.demographics.address.address2,
                      data.demographics.address.city,
                      data.demographics.address.state,
                      data.demographics.address.zipCode,
                    ]
                      .filter((part) => part !== null && part !== '')
                      .join(', ') || '-'
                  }
                  textColor="#2A4241"
                  sxProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'space-between',
          }}
        >
          <Box sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'Facesheet.beginDate'}
              defaultlabel={'Assessment Begin Date'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.beginDate
                  ? data.demographics.beginDate.toString()
                  : '-'
              }
              textColor="#2A4241"
            />
          </Box>
          <Box sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'Facesheet.rolloverDate'}
              defaultlabel={'Roll-over Date'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.rollOverDate
                  ? data.demographics.rollOverDate
                  : '-'
              }
              textColor="#2A4241"
            />
          </Box>
          <Box sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'Facesheet.noOfAssessmentHoursText'}
              defaultlabel={'Assessment Hrs'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.assessmentHours
                  ? convertTimeDuration(
                      data.demographics.assessmentHours,
                      true,
                    ).concat(' hrs')
                  : '-'
              }
              textColor="#2A4241"
            />
          </Box>
          <Box sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'Facesheet.usedAssessmentHoursText'}
              defaultlabel={'Used Assessment Hrs'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.noOfAssessmentHoursUsed
                  ? convertTimeDuration(
                      data.demographics.noOfAssessmentHoursUsed,
                      true,
                    ).concat(' hrs')
                  : '-'
              }
              textColor="#2A4241"
            />
          </Box>
          <Box sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'Facesheet.remainingAssessmentHoursText'}
              defaultlabel={'Remaining Assessment Hrs'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.remainingAssessmentHours
                  ? convertTimeDuration(
                      data.demographics.remainingAssessmentHours,
                      true,
                    ).concat(' hrs')
                  : '-'
              }
              textColor="#2A4241"
            />
          </Box>
        </Box>
        {data.demographics.waivers && (
          <Box
            sx={{
              paddingTop: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'space-between',
            }}
          >
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <MediumTypography
                labelid={'Waivers.waiverbegindate'}
                defaultlabel={'Waiver Begin Date'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <MediumTypography
                label={
                  data.demographics.waivers.startDate
                    ? data.demographics.waivers.startDate.toString() ?? ''
                    : '-'
                }
                textColor="#2A4241"
              />
            </Box>
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <MediumTypography
                labelid={'Waivers.waiverenddate'}
                defaultlabel={'Waiver End Date'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <MediumTypography
                label={
                  data.demographics.waivers.endDate
                    ? data.demographics.waivers.endDate.toString() ?? ''
                    : '-'
                }
                textColor="#2A4241"
              />
            </Box>
            {data.demographics.waivers.countType === 'H' && (
              <>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.noOfWaiverHoursText'}
                    defaultlabel={'Waiver Hrs'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.hoursStartingBalance
                        ? convertTimeDuration(
                            data.demographics.waivers.hoursStartingBalance,
                            true,
                          ).concat(' hrs')
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.usedWaiverHoursText'}
                    defaultlabel={'Used Waiver Hrs'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.hoursUsedBalance
                        ? convertTimeDuration(
                            data.demographics.waivers.hoursUsedBalance,
                            true,
                          ).concat(' hrs')
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.remainingWaiverHoursText'}
                    defaultlabel={'Remaining Waiver Hrs'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.hoursRemainingBalance
                        ? convertTimeDuration(
                            data.demographics.waivers.hoursRemainingBalance,
                            true,
                          ).concat(' hrs')
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
              </>
            )}
            {data.demographics.waivers.countType === 'V' && (
              <>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.noOfWaiverCountsText'}
                    defaultlabel={'Waiver Count'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.serviceStartingBalance !==
                        null &&
                      data.demographics.waivers.serviceStartingBalance >= 0
                        ? data.demographics.waivers.serviceStartingBalance.toString() ??
                          '-'
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.usedWaiverCountsText'}
                    defaultlabel={'Used Waiver Count'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.serviceUsedBalance !== null &&
                      data.demographics.waivers.serviceUsedBalance >= 0
                        ? data.demographics.waivers.serviceUsedBalance.toString() ??
                          '-'
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                  <MediumTypography
                    labelid={'Facesheet.remainingWaiverCountsText'}
                    defaultlabel={'Remaining Waiver Count'}
                    sxProps={{
                      color: '#00C6B8',
                      fontSize: '12px',
                    }}
                  />
                  <MediumTypography
                    label={
                      data.demographics.waivers.serviceRemainingBalance !==
                        null &&
                      data.demographics.waivers.serviceRemainingBalance >= 0
                        ? data.demographics.waivers.serviceRemainingBalance.toString() ??
                          '-'
                        : '-'
                    }
                    textColor="#2A4241"
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box className="horizontalLine" sx={{ marginY: '12px' }} />
      <Box>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            paddingRight: 1.5,
            cursor: 'pointer',
          }}
        >
          <ArrowRight
            onClick={() => {
              sessionStorage.setItem('tabName', '1');
              navigation(CLIENTS_ROUTE_NAME, {
                state: { clientId: clientId, isCreate: true },
              });
            }}
          />
        </Box>
        <Box>
          <MediumTypography
            labelid={'Facesheet.parents'}
            defaultlabel={'Guardian:'}
            sxProps={{ color: '#00C6B8', fontSize: '12px' }}
          />
          <Box sx={{ width: '500px' }}>
            <Tooltip
              title={getParentName(data)}
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={getParentName(data)}
                  textColor="#2A4241"
                  sxProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Grid container rowSpacing={'12px'} columnSpacing={'40px'}>
        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
          <MediumTypography
            labelid={'Facesheet.relationship'}
            defaultlabel={'Guardian Type:'}
            sxProps={{ color: '#97A6A5', fontSize: '12px' }}
          />
          <Box sx={{ width: '100px' }}>
            <Tooltip
              title={
                data.parents.length > 0 &&
                `${
                  data.parents.length > 0 &&
                  data.parents[0].relationShipToClient
                }`.length > 4
                  ? lookups?.guardianType?.find(
                      (g) =>
                        g.code.toUpperCase() ===
                        `${data.parents[0].relationShipToClient}`.toUpperCase(),
                    )?.description || ''
                  : ''
              }
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={
                    data.parents.length > 0 &&
                    data.parents[0].relationShipToClient
                      ? lookups?.guardianType?.find(
                          (g) =>
                            g.code.toUpperCase() ===
                            `${data.parents[0].relationShipToClient}`.toUpperCase(),
                        )?.description || '-'
                      : '-'
                  }
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft: 0.5,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
          <MediumTypography
            labelid={'ConsentForms.contactPhone'}
            defaultlabel={'Phone:'}
            sxProps={{ color: '#97A6A5', fontSize: '12px' }}
          />
          <Box sx={{ width: '100px' }}>
            <Tooltip
              title={
                data.parents.length > 0 &&
                `${data.parents[0].homePhoneNumber}`.length > 5
                  ? `${data.parents[0].homePhoneNumber}`
                  : ''
              }
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={
                    data.parents.length > 0 &&
                    (data.parents[0].mobilePhoneNumber ||
                      data.parents[0].homePhoneNumber)
                      ? getPhoneNumber(
                          data.parents[0].mobilePhoneNumber,
                          data.parents[0].homePhoneNumber,
                        )
                      : '-'
                  }
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft: 0.5,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
          <MediumTypography
            labelid={'ConsentForms.contactAddress'}
            defaultlabel={'Address:'}
            sxProps={{ color: '#97A6A5', fontSize: '12px' }}
          />
          <Box
          // sx={{ width: '350px' }}
          >
            <Tooltip
              title={
                data.parents.length > 0 &&
                `${data.parents[0].street}, ${apartmentText} ${data.parents[0].city}, ${data.parents[0].state}, ${data.parents[0].zipCode}`
                  .length > 50
                  ? `${data.parents[0].street}, ${apartmentText} ${data.parents[0].city}, ${data.parents[0].state}, ${data.parents[0].zipCode}`
                  : ''
              }
              arrow={true}
              // sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={
                    data.parents.length > 0 &&
                    `${data.parents[0].street}, ${apartmentText} ${data.parents[0].city}, ${data.parents[0].state}, ${data.parents[0].zipCode}`
                      ? `${data.parents[0].street}, ${apartmentText} ${data.parents[0].city}, ${data.parents[0].state}, ${data.parents[0].zipCode}`
                      : '-'
                  }
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft: 0.5,
                  }}
                  className="ellipsis_txt"
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', gap: 2 }} className="mt-sm">
        <ButtonComponent
          sxProps={{ display: 'none' }}
          className="btn-primary btn-cancel"
          variantType="contained"
          labelId="ssp.providers"
          defaultLabelId="SSP Providers"
          onClick={() => {
            handleSSPProviders();
          }}
        />
      </Box>
      <Box className="horizontalLine" sx={{ marginTop: '12px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid container rowSpacing={'12px'} columnSpacing={'40px'}>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <MediumTypography
              labelid={'Facesheet.referralDate'}
              defaultlabel={'Referral Begin Date:'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.referralDate
                  ? data.demographics.referralDate
                  : '-'
              }
              textColor="#2A4241"
            />
          </Grid>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <MediumTypography
              labelid={'Facesheet.ifspDueDate'}
              defaultlabel={'IFSP Due Date:'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.ifspDueDate
                  ? data.demographics.ifspDueDate
                  : '-'
              }
              textColor="#2A4241"
            />
          </Grid>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: 1.8 }}>
              <MediumTypography
                labelid={'Facesheet.intakeFacilitator'}
                defaultlabel={'Intake Facilitator'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <History
                style={{
                  width: '12px',
                  height: '12px',
                  marginTop: 5,
                  cursor: 'pointer',
                }}
                onClick={handleIntakeDailog}
              />
            </Box>
            <MediumTypography
              label={
                data.demographics.intakeCoordinator
                  ? intakeCoordinatorsList.find(
                      (co) => co.id === data.demographics.intakeCoordinator,
                    )?.label
                  : '-'
              }
              textColor="#2A4241"
            />
          </Grid>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: 1.8 }}>
              <MediumTypography
                labelid={'Facesheet.serviceCoordinator'}
                defaultlabel={'Service Coordinator:'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <History
                style={{
                  width: '12px',
                  height: '12px',
                  marginTop: 5,
                  cursor: 'pointer',
                }}
                onClick={handleServiceDailog}
              />
            </Box>
            <MediumTypography
              label={
                data.demographics.serviceCoordinator
                  ? serviceCoordinatorsList.find(
                      (co) => co.id === data.demographics.serviceCoordinator,
                    )?.label
                  : '-'
              }
              textColor="#2A4241"
            />
          </Grid>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <MediumTypography
              labelid={'Facesheet.eicsConsentToAccessInsuranceText'}
              defaultlabel={'Consent To Access Insurance'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            {data.demographics?.consentToAccessStartDate !== null && (
              <MediumTypography
                label={`${getConsentValues(
                  data.demographics.eicsConsentToAccessInsurance,
                )} (${data.demographics.consentToAccessStartDate})`}
                textColor="#2A4241"
              />
            )}
          </Grid>

          <Grid item sx={{ flexDirection: 'column', display: 'flex' }}>
            <MediumTypography
              labelid={'referralDiagnosisCodeText'}
              defaultlabel="Referral Diagnosis Code"
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            {data.demographics.diagnosis !== null ? (
              <Box sx={{ width: '125px' }}>
                <Tooltip
                  title={
                    data.demographics.diagnosis &&
                    data.demographics.diagnosis !== null &&
                    getDiagnosisForView(data.demographics.diagnosis)
                  }
                  arrow={true}
                  sx={{
                    maxWidth: 'max-content',
                    textDecoration: data.demographics.diagnosis.isUnderlined
                      ? 'underline'
                      : 'none',
                  }}
                >
                  <Box>
                    <MediumTypography
                      label={
                        data.demographics.diagnosis &&
                        data.demographics.diagnosis !== null
                          ? getDiagnosisForView(data.demographics.diagnosis)
                          : '-'
                      }
                      sxProps={{
                        textDecoration: data.demographics.diagnosis.isUnderlined
                          ? 'underline'
                          : 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                      textColor="#2A4241"
                    />
                  </Box>
                </Tooltip>
              </Box>
            ) : (
              <MediumTypography label={'-'} textColor="#2A4241" />
            )}
          </Grid>
          <Grid
            item
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <MediumTypography
              labelid={'AddNewReferralDetails.referralEndDate'}
              defaultlabel={'Referral End Date'}
              sxProps={{
                color: '#00C6B8',
                fontSize: '12px',
              }}
            />
            <MediumTypography
              label={
                data.demographics.referralEndDate
                  ? data.demographics.referralEndDate
                  : '-'
              }
              textColor="#2A4241"
            />
          </Grid>
        </Grid>

        {checkPermissionForFeature('backend.clients', 'editPermission') && (
          <Box
            sx={{
              float: 'right',
              position: 'relative',
              cursor: 'pointer',
              paddingRight: 1,
            }}
          >
            <EditIcon
              onClick={() => {
                handleFormDailog();
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ClientInfoFaceSheet;

import React, { useCallback, useContext, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { OptionType, SSPContactTypes } from '../../utils/type';
import * as yup from 'yup';
import { Box, Grid, Typography, AlertColor, Card } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  ContactsTypes,
  addContacts,
  getContactsBaedOnType,
  updateContacts,
} from '../../services/configApi/User';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SmallTypography from '../../components/formlib/SmallTypography';
import ModalPopup from '../../components/formlib/ModalPopup';
import { SSPService, initialCheckboxData } from './../Dropdown';
import AddressForm from '../../components/formlib/AddressForm';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DeleteIcon from '../../assets/images/deleteAlertIcon.svg';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import { useIntl } from 'react-intl';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import {
  formatPhoneNumber,
  nameValidationWithNumbers,
} from '../../utils/formValidationUtil';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

const contactValues: SSPContactTypes = {
  contactType: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  street: '',
  apartment: '',
  zipCode: { id: '', label: '' },
  city: '',
  state: '',
  startDate: dayjs(new Date()),
  endDate: null,
  isActive: true,
  links: [''],
  homeNumber: '',
  sspContactType: [''],
  sspService: '',
  otherSSPService: '',
  contactId: '',
  isEdit: false,
  companyOrMedicalPracticeName: '',
  isHouseHoldMember: false,
  notes: '',
  fax: '',
};

const urlValidatePattern =
  /^(https?:\/\/)?((localhost:\d{1,5})|([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}))(\/[-a-zA-Z0-9@:%._+~#?&//=]*)?$/;

const initialValidationSchema = yup.object().shape({
  contactType: yup.string(),
  firstName: yup.string().required('family.firstnamemessage'),
  lastName: yup.string().required('family.lastnameMessage'),
  phoneNumber: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  email: yup.string().email('SignIn.validation.inValidEmail'),
  apartment: yup.string().nullable(),
  street: yup.string().required('addressLine1RequiredMessage'),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('stateRequiredMessage'),
  zipCode: yup.object().shape({
    label: yup.string().required('zipCodeRequiredMessage'),
  }),
  areaServed: yup.string(),
  links: yup
    .array()
    .of(yup.string().matches(urlValidatePattern, 'Invalid link format'))
    .nullable(),
  sspService: yup.string(),
  otherSSPService: yup.string().when('sspService', {
    is: 'other',
    then: yup.string().required('specifyFieldRequireMessage'),
    otherwise: yup.string().nullable(),
  }),
  startDate: yup.date().required('contact.startDateRequiredMessage').nullable(),
  endDate: yup.date().nullable(),
  isActive: yup.string().nullable(),
  homeNumber: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('parent.primarynumberRequired')
    .nullable(),
  contactId: yup.string(),
  sspContactType: yup.array().nullable(),
});

const validateForm = (value: SSPContactTypes) => {
  const errors: Partial<SSPContactTypes> = {};

  if (value.startDate) {
    if (dayjs(value.startDate).isAfter(dayjs())) {
      errors.startDate = 'validDate';
    }
  }

  if (value.endDate) {
    if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
      errors.endDate = 'effectiveEndDateValidationMessage';
    }
  }

  if (
    datePickerMinDateMaxDateValidate(value.startDate) ||
    datePickerMinDateMaxDateValidate(value.endDate)
  ) {
    errors.startDate = 'datePickerMinDateMaxDateValidate';
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const SSPContact = (props: {
  contacttype: string;
  onSuccess: (successerror: AlertColor, id: string, message: string) => void;
  sspValues: SSPContactTypes;
  editable: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [hideBtn, setHideBtn] = React.useState(false);
  const [sspVals, setSSPVals] = React.useState<SSPContactTypes>(contactValues);
  const [openModal, setOpenModal] = React.useState(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [checkboxData] = React.useState(initialCheckboxData);
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<string[]>(
    [],
  );
  const [numSets, setNumSets] = React.useState(1);
  const formRef = React.useRef<FormikProps<SSPContactTypes>>(null);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const rctl = useIntl();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!props.editable && props.sspValues.contactId !== '') {
      const clientId: string = localStorage.getItem('ClientId') ?? '';

      getContactsBaedOnType(clientId, props.sspValues.contactId)
        .then(async (response: ContactsTypes[]) => {
          if (response.length > 0) {
            const res = response[0] as SSPContactTypes;
            if (res.contactType === 'Specialty Service Provider') {
              if (res.links !== null) {
                setNumSets(res.links.length);
              }
              formRef.current?.setValues((values) => ({
                ...values,
                links: res.links,
                sspContactType: res.sspContactType,
              }));
              setSelectedCheckboxes(res.sspContactType);
              setSSPVals({
                contactId: res.contactId,
                firstName: res.firstName,
                lastName: res.lastName,
                phoneNumber: res.phoneNumber,
                homeNumber: res.homeNumber,
                email: res.email,
                street: res.street,
                apartment: res.apartment,
                zipCode: { id: 'aa', label: res.zipCode } as OptionType,
                city: res.city,
                state: res.state,
                links: res.links,
                startDate:
                  res.startDate === null ? dayjs(new Date()) : res.startDate,
                endDate: res.endDate,
                isActive: res.isActive,
                sspService: res.sspService,
                otherSSPService: res.otherSSPService,
                sspContactType: res.sspContactType,
                contactType: props.contacttype,
                isEdit: true,
                companyOrMedicalPracticeName: res.companyOrMedicalPracticeName,
                isHouseHoldMember: res.isHouseHoldMember,
                notes: res.notes,
                fax: res.fax,
              });
              setHideBtn(true);
            }
          }
        })
        .catch((error) => {
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Family.gettoastrError');
            setToastrDefaultMessage('Failed to get contact details');
          }
        });
    }
  }, [props.sspValues, props.editable]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((item) => item !== value),
      );
    }
  };

  const alphaNumericValidation = (value: string) => {
    let trimmedValue = value.replace(/^\s+/, '');
    const maxLength = 50;

    if (trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.slice(0, maxLength);
    }

    const regex = /^[A-Za-z.,'“!#_ -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.resetForm();
    setSSPVals(contactValues);
  };

  const handleAddLink = () => {
    if (numSets < 3) {
      setNumSets((prevNumSets) => prevNumSets + 1);
    }
  };

  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  const handleDeleteLinks = (index: number) => {
    setNumSets((prevNumSets) => prevNumSets - 1);
    formRef.current?.setValues((values) => ({
      ...values,
      links: values.links.filter((__, i) => i !== index),
    }));
  };

  const addOrUpdateSspContact = useCallback(
    debounce((sspFormVals: SSPContactTypes) => {
      toggleLoader(true);
      sspFormVals.endDate =
        sspFormVals.endDate === null
          ? null
          : dayjs(sspFormVals.endDate).format('MM/DD/YYYY');
      sspFormVals.zipCode = sspFormVals.zipCode
        ? (sspFormVals.zipCode as OptionType).label
        : '';

      sspFormVals.contactType = props.contacttype;
      sspFormVals.startDate =
        sspFormVals.startDate === null
          ? null
          : dayjs(sspFormVals.startDate).format('MM/DD/YYYY');
      if (formRef.current?.values.links) {
        sspFormVals.links = formRef.current?.values.links;
      }

      if (sspFormVals.isEdit) {
        updateContacts(sspFormVals.contactId, sspFormVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.updateMessage');
              setToastrDefaultMessage('Contact Updated Successfully');
              props.onSuccess(
                'success',
                'Contact.updateMessage',
                'Contact Updated Successfully',
              );
            }
          })

          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateFailureMessage',
                'Failed to update contact details',
              );
              setToastrId('Contact.updateFailureMessage');
              setToastrDefaultMessage('Failed to update contact details');
            }
          });
      } else {
        const clientId: string = localStorage.getItem('ClientId') ?? '';

        addContacts(clientId, sspFormVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.createdMessage');
              setToastrDefaultMessage(response.message);
              props.onSuccess(
                'success',
                'Contact.createdMessage',
                'Contact Added Successfully',
              );
            }
          })

          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateCreateMessage',
                'Failed to create contact details',
              );
              setToastrId('Contact.updateCreateMessage');
              setToastrDefaultMessage('Failed to create contact details');
            }
          });
      }
    }, 1000),
    [],
  );

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Formik
        initialValues={sspVals}
        innerRef={formRef}
        enableReinitialize={true}
        validateOnChange={true}
        validate={validateForm}
        validationSchema={initialValidationSchema}
        onSubmit={(values) => {
          const finalValues = JSON.parse(JSON.stringify(values));
          if (finalValues.endDate) {
            const endDate = dayjs(finalValues.endDate, 'MM/DD/YYYY'); // Parse endDate
            const currentDate = dayjs(); // Current date

            if (endDate.isSame(currentDate, 'day')) {
              finalValues.isActive = true;
            } else if (endDate.isBefore(currentDate, 'day')) {
              finalValues.isActive = false;
            } else {
              finalValues.isActive = true; // Optional: For future dates
            }
          }
          finalValues.sspContactType = selectedCheckboxes;
          addOrUpdateSspContact(finalValues);
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          values,
          initialValues,
        }) => {
          useEffect(() => {
            if (_.isEqual(initialValues, values)) {
              handleUpdateUnsavedChanges(false);
            } else {
              handleUpdateUnsavedChanges(true);
            }
          }, [values]);
          return (
            <>
              <Card
                sx={{
                  padding: '0px 24px 24px 24px',
                  my: '0px',
                  ml: '0px',
                  height: '100%',
                  overflow: 'hidden',
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                  boxShadow:
                    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)',
                }}
              >
                <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <FormGroup>
                      {checkboxData.map((option) => (
                        <FormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              onChange={handleChange}
                              sx={{ fontFamily: 'Lato-Regular' }}
                            />
                          }
                          value={option}
                          id="checkbox"
                          label={
                            <span
                              style={{
                                fontFamily: 'Lato-Regular',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '17px',
                              }}
                            >
                              {option}
                            </span>
                          }
                          labelPlacement="end"
                          checked={selectedCheckboxes.includes(option)}
                          sx={{ fontFamily: 'Lato-Regular' }}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`firstName`}
                      labelid="Family.firstName"
                      defaultlabelid="First Name"
                      handlechange={(value: string) => {
                        const inputValue = value;
                        const validatedValue =
                          alphaNumericValidation(inputValue);
                        if (validatedValue !== undefined) {
                          setFieldValue('firstName', validatedValue);
                        }
                      }}
                      Value={values.firstName}
                      Required={true}
                      inputProps={{
                        maxLength: 50,
                        pattern: '[A-Za-z .,!]+',
                      }}
                    />
                    {errors.firstName && touched.firstName && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.firstName}
                        defaultLabelId="First Name is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`lastName`}
                      labelid="Family.lastName"
                      defaultlabelid="Last Name"
                      handlechange={(value: string) => {
                        const inputValue = value;
                        const validatedValue =
                          alphaNumericValidation(inputValue);
                        if (validatedValue !== undefined) {
                          setFieldValue('lastName', validatedValue);
                        }
                      }}
                      Value={values.lastName}
                      Required={true}
                      inputProps={{
                        maxLength: 50,
                        pattern: '[A-Za-z .,!]+',
                      }}
                    />
                    {errors.lastName && touched.lastName && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.lastName}
                        defaultLabelId="Last Name is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`home`}
                      labelid="Family.home"
                      defaultlabelid="Primary Phone Number"
                      handlechange={(value: string) => {
                        setFieldValue('homeNumber', NumValidation(value));
                      }}
                      Required={true}
                      inputProps={{
                        pattren: ['0-9'],
                      }}
                      Value={values.homeNumber}
                    />
                    {errors.homeNumber && touched.homeNumber && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.homeNumber}
                        defaultLabelId="Primary Phone Number is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`phoneNumber`}
                      labelid="Family.phoneNumber"
                      defaultlabelid="Mobile Number"
                      handlechange={(value: string) => {
                        setFieldValue('phoneNumber', NumValidation(value));
                      }}
                      inputProps={{
                        pattren: ['0-9'],
                      }}
                      Value={values.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId="PhoneNumber.Validation"
                        defaultLabelId="must have a minimum of 10 digits"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`email`}
                      labelid="Family.email"
                      defaultlabelid="Email Address"
                      handlechange={(value: string) => {
                        setFieldValue('email', value);
                      }}
                      Value={values.email}
                      inputProps={{
                        maxLength: 320,
                      }}
                    />
                    {errors.email && touched.email && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.email}
                        defaultLabelId="Email is required"
                      />
                    )}
                  </Grid>

                  <Grid item xs={4.5} sx={{ marginTop: '3px' }}>
                    <Textinput
                      name="referralContactFax"
                      Value={values.fax}
                      labelid="AddNewReferralDetails.Fax"
                      defaultlabelid="Fax"
                      Required={false}
                      inputProps={{
                        maxLength: 50,
                      }}
                      handlechange={(value: string) => {
                        setFieldValue('fax', formatPhoneNumber(value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <DropdownComponent
                      names={SSPService}
                      value={values.sspService}
                      labelid="Family.sspService"
                      defaultlabelid="SSP Service"
                      handleChange={(value: string) => {
                        setFieldValue(`sspService`, value);
                        if (value !== 'other') {
                          setFieldValue('otherSSPService', '');
                        }
                      }}
                    />
                    {errors.sspService && touched.sspService && (
                      <MediumTypography
                        label={errors.sspService}
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  {values.sspService !== 'other' && (
                    <Grid item xs={4.5} lg={4.5}></Grid>
                  )}
                  {values.sspService === 'other' && (
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name="otherSSPService"
                        Value={values.otherSSPService}
                        labelid="sspReferral.Specify"
                        defaultlabelid="Specify"
                        Required={true}
                        inputProps={{
                          maxLength: 50,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue('otherSSPService', value);
                        }}
                      />
                      {errors.otherSSPService && touched.otherSSPService && (
                        <MediumTypography
                          labelid={errors.otherSSPService}
                          defaultlabel="Specify field is required"
                          textColor="red"
                          marginTop="8px"
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={9}>
                    <AddressForm
                      address1PlaceholderId="address1Text"
                      address2PlaceholderId="address2Text"
                      zipCodePlaceholderId="Clientpage.Zip"
                      cityPlaceholderId="Clientpage.City"
                      statePlaceholderId="Clientpage.State"
                      disableForm={false}
                      formRequired={true}
                      value={{
                        address1: values.street,
                        address2: values.apartment,
                        zipCode: values.zipCode,
                        city: values.city,
                        state: values.state,
                      }}
                      formikErrors={{
                        address1: errors.street,
                        address2: errors.apartment,
                        zipCode: errors.zipCode,
                        city: errors.city,
                        state: errors.state,
                      }}
                      formikTouched={{
                        address1: touched.street,
                        address2: touched.apartment,
                        zipCode: touched.zipCode,
                        city: touched.city,
                        state: touched.state,
                      }}
                      isClient={false}
                      handleAddressUpdate={(newAddress) => {
                        setFieldValue('street', newAddress.address1);
                        setFieldValue('apartment', newAddress.address2);
                        setFieldValue('zipCode', newAddress.zipCode);
                        setFieldValue('city', newAddress.city);
                        setFieldValue('state', newAddress.state);
                        formRef.current?.setValues({
                          ...values,
                          street: newAddress.address1,
                          apartment: newAddress.address2,
                          zipCode: newAddress.zipCode,
                          city: newAddress.city,
                          state: newAddress.state,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={4.5}>
                    <DatePickerComponent
                      labelid="Family.startDate"
                      defaultlabelid="Start Date"
                      disableFuture={true}
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('startDate', null);
                        } else {
                          formRef.current?.setValues({
                            ...values,
                            startDate: formattedDate,
                            endDate: null,
                          });
                          // setFieldValue('startDate', formattedDate);
                          // setFieldValue('endDate', null);
                        }
                      }}
                      value={dayjs(values.startDate) as Dayjs}
                    />
                    {errors.startDate && touched.startDate && (
                      <MediumTypography
                        labelid={errors.startDate}
                        defaultlabel="Start Date is required"
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5}>
                    {values.endDate && (
                      <DatePickerComponent
                        labelid="Family.endDate"
                        defaultlabelid="End Date"
                        minDate={
                          values.startDate !== null
                            ? dayjs(values.startDate).add(1, 'day')
                            : undefined
                        }
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('endDate', null);
                          } else {
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                        value={dayjs(values.endDate) as Dayjs}
                      />
                    )}
                    {!values.endDate && (
                      <DatePickerComponent
                        labelid="Family.endDate"
                        defaultlabelid="End Date"
                        minDate={
                          values.startDate !== null
                            ? dayjs(values.startDate).add(1, 'day')
                            : undefined
                        }
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('endDate', null);
                          } else {
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                        value={null}
                      />
                    )}
                    {errors.endDate && touched.endDate && (
                      <MediumTypography
                        labelid={errors.endDate}
                        defaultlabel="End Date is required"
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5}>
                    <Textinput
                      name={`companyOrMedicalPracticeName`}
                      labelid="contacts.medicalOrFamilyPracticeName"
                      defaultlabelid="Company/Medical Practice Name"
                      handlechange={(value: string) => {
                        setFieldValue('companyOrMedicalPracticeName', value);
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      Value={values.companyOrMedicalPracticeName}
                    />
                  </Grid>
                  <Grid item xs={4.5}></Grid>
                  <Grid
                    xs={4.5}
                    item
                    sx={{ marginTop: '-1%', marginBottom: '-1%' }}
                  >
                    <CheckBoxComponent
                      name="isHouseHoldMember"
                      labelid="Contacts.HouseHold"
                      defaultlabelid="Household Member"
                      ischecked={values.isHouseHoldMember}
                      CheckHandleChange={(e) => {
                        setFieldValue('isHouseHoldMember', e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid xs={4.5} item></Grid>
                  <Grid item xs={9} lg={9}>
                    <CommentTextbox
                      name="notes"
                      placeholder={rctl.formatMessage({
                        id: 'AddNewReferralDetails.notes',
                        defaultMessage: 'Notes',
                      })}
                      Value={values.notes}
                      maxLength={2000}
                      handlechange={(value: string) => {
                        const validatedValue = nameValidationWithNumbers(value);
                        if (validatedValue !== undefined) {
                          setFieldValue('notes', validatedValue);
                        }
                      }}
                    />
                  </Grid>
                  {Array.from({ length: numSets }).map((__, index) => (
                    <Grid item xs={12} lg={12} sx={{ display: 'flex' }}>
                      <Grid item xs={9} lg={9}>
                        <Textinput
                          name={`links-${index}`}
                          labelid={`Family.links-${index}`}
                          defaultlabelid="Enter web page link"
                          Value={values.links[index]}
                          handlechange={(value: string) => {
                            setFieldValue(`links.${index}`, value); // Update state with the valid link
                          }}
                          inputProps={{ maxLength: 150 }}
                        />
                        {errors.links &&
                          errors.links[index] &&
                          touched.links && (
                            <MediumTypography
                              label={errors.links[index]}
                              textColor="red"
                              marginTop="8px"
                            />
                          )}
                      </Grid>
                      <Grid item xs={1} lg={1}>
                        {index >= 1 && (
                          <img
                            src={DeleteIcon}
                            alt="backarrow"
                            style={{
                              position: 'relative',
                              cursor: 'pointer',
                              width: '22px',
                              height: '22px',
                              top: '5px',
                              left: '10px',
                            }}
                            onClick={() => handleDeleteLinks(index)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={4.5}>
                    <Typography
                      sx={{
                        textDecoration: 'underline',
                        paddingBottom: '4px',
                        textUnderlineOffset: '5px',
                        position: 'relative',
                        fontFamily: 'Lato-Regular',
                        fontWeight: '700',
                        ...(numSets >= 3
                          ? {
                              cursor: 'not-allowed',
                              pointerEvents: 'none',
                              opacity: '0.5',
                              color: 'rgba(0, 0, 0, 0.38);',
                            }
                          : { cursor: 'pointer', color: '#00C6B8' }),
                      }}
                      onClick={handleAddLink}
                    >
                      {`+ Add another link`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4.5}></Grid>
                </Grid>
              </Card>
              <Box sx={{ marginTop: '20px' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="right"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item sx={{ marginRight: '16px' }}>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId="Contacts.cancelbtn"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (initialValues.contactId !== '') {
                          if (_.isEqual(initialValues, values)) {
                            formRef.current?.resetForm();
                            props.onSuccess('info', '', '');
                          } else {
                            setOpenModal(true);
                          }
                        } else {
                          if (!_.isEqual(initialValues, values)) {
                            setOpenModal(true);
                          } else {
                            setOpenModal(false);
                            formRef.current?.resetForm();
                            setSSPVals(contactValues);
                            props.onSuccess('info', '', '');
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    {!hideBtn && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="Contacts.save"
                        defaultLabelId="Add Contact"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    )}
                    {hideBtn && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="Contacts.update"
                        defaultLabelId="Update"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        }}
      </Formik>
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          if (sspVals.contactId !== '') {
            setOpenModal(false);
            formRef.current?.setValues(formRef.current?.initialValues);
          } else {
            clearForm();
          }
        }}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
    </>
  );
};

export default SSPContact;

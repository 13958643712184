import React, { ReactNode, useContext, useEffect } from 'react';
import { Alert, AlertColor, Box, Snackbar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

interface ToastrProps {
  open?: boolean;
  successOrError?: AlertColor;
  handleClose?: () => void;
  toastrMessage?: string;
  labelId?: string;
  defaultMessageId?: string;
  icon?: ReactNode;
}

const SnackBarComponent: React.FC<ToastrProps> = (props) => {
  const {
    open,
    successOrError,
    handleClose,
    toastrMessage,
    labelId,
    defaultMessageId,
    icon,
  } = props;

  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);

  useEffect(() => {
    if (successOrError === 'success') {
      handleUpdateUnsavedChanges(false);
    }
  }, [successOrError]);

  return (
    <Box component="div">
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          width: '100% !important',
          top: '14px !important',
          zIndex: 99999,
        }}
      >
        <Alert
          id="toastr"
          onClose={handleClose}
          severity={successOrError}
          variant="filled"
          icon={icon}
          sx={{
            alignItems: 'center',
            height: '36px',
            padding: '0px 10px',
            borderRadius: '60px',
            color: 'white !important',
            ...(successOrError === 'success'
              ? { backgroundColor: '#008C82' }
              : successOrError === 'error'
              ? { backgroundColor: '#EB4C60' }
              : ''),
          }}
        >
          {toastrMessage ?? (
            <FormattedMessage id={labelId} defaultMessage={defaultMessageId} />
          )}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SnackBarComponent;

import { AlertColor, Card, Grid, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import DurationPicker from '../../components/formlib/DurationPicker';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  createWaiver,
  getAllWaivers,
  updateWaiver,
} from '../../services/waiversApi/WaiversApis';
import { LookUpContext } from '../../context/LookUpContextProvider';
import { OptionType, WaiversType } from '../../utils/type';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import {
  convertTimeDuration,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../utils/dateUtil';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  isCustomError,
  ApiError,
  ApiMessage,
} from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { Context } from '../../LanguageWrapper';
import _ from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const emptyWaiver: WaiversType = {
  waiverIndex: 0,
  waiverNumber: '',
  startDate: null,
  endDate: null,
  services: [],
  disciplines: [],
  coTx: false,
  countType: '',
  hoursStartingBalance: null,
  hoursUsedBalance: null,
  hoursRemainingBalance: null,
  serviceStartingBalance: null,
  serviceUsedBalance: null,
  serviceRemainingBalance: null,
  status: 'Pending',
};

const WaiversScreen = () => {
  const [openAccordian, setOpenAccordian] = useState<boolean>(true);
  const [openAccordianIndex, setSetOpenAccordianIndex] = useState<number>(0);
  const [initialWaiver, setInitialWaiver] = useState<WaiversType[] | null>(
    null,
  );
  const [initialBackupWaiver, setInitialBackupWaiver] = useState<
    WaiversType[] | null
  >(null);
  const [services, setServices] = useState<OptionType[]>([]);
  const [disciplineList, setDisciplineList] = useState<OptionType[]>([]);
  const [waiversType, setWaiversType] = useState<OptionType[]>([]);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [showUnsavedAlertDialog, setShowUnsavedAlertDialog] =
    useState<boolean>(false);
  const [toastrId, setToastrId] = React.useState('');
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const formRef = useRef<FormikProps<WaiversType[]>>(null);
  const { lookups } = useContext(LookUpContext);
  const { programs } = useContext(Context);
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const parentRef = useRef<HTMLDivElement>(null);
  const clientId = localStorage.getItem('ClientId');

  const getWaivers = (cId: string) => {
    getAllWaivers(cId)
      .then((res) => {
        if (res.length > 0) {
          toggleLoader(false);

          // Check if any waiver is active
          const isAnyActiveWaiver = res.some((e) => e.status === 'Active');

          if (isAnyActiveWaiver) {
            // Find the index of the first active waiver
            const activeIndex = res.findIndex((e) => e.status === 'Active');
            setSetOpenAccordianIndex(activeIndex); // Set the active waiver index
            setOpenAccordian(true); // Open the accordion
          } else {
            // If no active waiver, find the matching waiver number
            const openIndex = res.findIndex(
              (e) =>
                e.waiverNumber ===
                formRef.current?.values[openAccordianIndex]?.waiverNumber,
            );

            if (openIndex !== -1) {
              setSetOpenAccordianIndex(openIndex); // Set the found waiver index
              setOpenAccordian(true); // Open the accordion
            }
          }

          formRef.current?.setValues(
            res.map((e, index) => ({
              ...e,
              waiverIndex: index,
              hoursStartingBalance: convertTimeDuration(
                e.hoursStartingBalance,
                true,
              ),
              hoursUsedBalance: convertTimeDuration(e.hoursUsedBalance, true),
              hoursRemainingBalance: convertTimeDuration(
                e.hoursRemainingBalance,
                true,
              ),
            })),
          );
          setInitialWaiver(
            res.map((e, index) => ({
              ...e,
              waiverIndex: index,
              hoursStartingBalance: convertTimeDuration(
                e.hoursStartingBalance,
                true,
              ),
              hoursUsedBalance: convertTimeDuration(e.hoursUsedBalance, true),
              hoursRemainingBalance: convertTimeDuration(
                e.hoursRemainingBalance,
                true,
              ),
            })),
          );
          setInitialBackupWaiver(
            res.map((e, index) => ({
              ...e,
              waiverIndex: index,
              hoursStartingBalance: convertTimeDuration(
                e.hoursStartingBalance,
                true,
              ),
              hoursUsedBalance: convertTimeDuration(e.hoursUsedBalance, true),
              hoursRemainingBalance: convertTimeDuration(
                e.hoursRemainingBalance,
                true,
              ),
            })),
          );
        } else {
          toggleLoader(false);
          setShowEmptyScreen(true);
        }
      })
      .catch((err) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId('Waiver.Get.errormsg');
        setToastrDefaultMessage(err);
      });
  };
  useEffect(() => {
    if (clientId) {
      toggleLoader(true);
      getWaivers(clientId);
    }
  }, []);
  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setDisciplineList(() => {
      return lookups.discipline
        ?.filter((e) => e.code !== 'NA')
        .map((e) => {
          return {
            label: e.description,
            id: e.code,
          };
        });
    });
    setServices(() => {
      return (
        lookups.serviceCode
          ?.filter((e) => e.active)
          .map((e) => ({
            label: `${e.code}` + ' - ' + `${e.description}`,
            id: e.code,
          })) || []
      );
    });

    setWaiversType(() => {
      return lookups.waiverCountType.map((e) => ({
        label: e.description,
        id: e.code,
      }));
    });
  }, [lookups]);

  useEffect(() => {
    handleUpdateUnsavedChanges(!_.isEqual(initialBackupWaiver, initialWaiver));
  }, [initialWaiver, initialBackupWaiver]);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      checkPermissionForFeature('backend.waiver_information', 'editPermission')
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const validationSchema = yup.array().of(
    yup.object().shape({
      waiverIndex: yup.number(),
      waiverNumber: yup.string().when('waiverIndex', {
        is: (waiverIndex: number) => waiverIndex === openAccordianIndex,
        then: yup.string().required('Waivers.waiverNumberIsRequired'),
        otherwise: yup.string().notRequired(),
      }),
      startDate: yup.date().when('waiverIndex', {
        is: (waiverIndex: number) => waiverIndex === openAccordianIndex,
        then: yup.date().required('Waivers.waiverStartDateIsRequired'),
        otherwise: yup.date().notRequired().nullable(),
      }),
      endDate: yup.date().when('waiverIndex', {
        is: (waiverIndex: number) => waiverIndex === openAccordianIndex,
        then: yup
          .date()
          .required('Waivers.waiverEndDateIsRequired')
          .test({
            name: 'endDate',
            message: 'Waivers.waiverEndDateShouldBeGreaterThanStartDate',
            test: function (value) {
              const startDate = this.resolve(yup.ref('startDate')); // Resolve startDate value
              if (value && startDate) {
                const isBefore = dayjs(value).isBefore(
                  dayjs(startDate as Date),
                );
                if (isBefore) {
                  return this.createError({
                    path: this.path,
                    message:
                      'Waivers.waiverEndDateShouldBeGreaterThanStartDate',
                  });
                }
              }
              return true; // Valid if endDate is not before startDate
            },
          }),
        otherwise: yup.date().notRequired().nullable(),
      }),
      services: yup
        .array()
        .of(yup.string())
        .when('waiverIndex', {
          is: (waiverIndex: number) => waiverIndex === openAccordianIndex,
          then: yup
            .array()
            .of(yup.string().required('Waivers.waiverServiceIsRequired'))
            .min(1, 'Waivers.waiverAtLeastOneServiceRequired'),
          otherwise: yup.array().notRequired(),
        }),
      countType: yup.string().when('waiverIndex', {
        is: (waiverIndex: number) => waiverIndex === openAccordianIndex,
        then: yup.string().required('Waivers.waiverCountTypeIsRequired'),
        otherwise: yup.string().notRequired(),
      }),
      hoursStartingBalance: yup.string().when(['waiverIndex', 'countType'], {
        is: (waiverIndex: number, countType: string) =>
          waiverIndex === openAccordianIndex && countType === 'H',
        then: yup
          .string()
          .required('Waivers.waiverStartingBalanceIsRequired')
          .notOneOf(
            ['00.00', '00:00', '0.00'],
            'Waivers.waiverStartingBalanceCannotbe00:00',
          ),
        otherwise: yup.string().notRequired().nullable(),
      }),
      serviceStartingBalance: yup.number().when(['waiverIndex', 'countType'], {
        is: (waiverIndex: number, countType: string) =>
          waiverIndex === openAccordianIndex && countType === 'V',
        then: yup
          .number()
          .required('Waivers.waiverserviceStartingBalanceIsRequired')
          .min(1, 'Waivers.waiverServiceStartingRangeValidation')
          .max(10, 'Waivers.waiverServiceStartingRangeValidation'),
        otherwise: yup.number().notRequired().nullable(),
      }),
    }),
  );

  const handleAddNewWaiver = () => {
    if (initialWaiver) {
      setSetOpenAccordianIndex(initialWaiver.length);
      setOpenAccordian(true);
    }
    const selectedProgram = localStorage.getItem('selectedProgram');

    if (selectedProgram) {
      const selectedProgramId = JSON.parse(selectedProgram).id;
      setInitialWaiver((prev) => {
        return [
          ...(prev || []),
          {
            ...emptyWaiver,
            waiverIndex: prev?.length,
            programId: selectedProgramId ?? '',
          },
        ];
      });
      setInitialBackupWaiver((prev) => {
        return [
          ...(prev || []),
          {
            ...emptyWaiver,
            waiverIndex: prev?.length,
            programId: selectedProgramId ?? '',
          },
        ];
      });
    }
  };

  const handleCreateWaiver = (props: WaiversType) => {
    if (clientId) {
      createWaiver(props, clientId)
        .then((res: ApiMessage) => {
          getWaivers(clientId);
          setOpen(true);
          setToastrVariable('success');
          setToastrDefaultMessage(res.message);
          setToastrId('Waiver.Add.successmsg');
        })
        .catch((error) => {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Waiver.Add.errormsg');
            setToastrDefaultMessage(error.message);
          }
        });
    }
  };

  const handleUpdateWaiver = (props: WaiversType) => {
    if (clientId) {
      updateWaiver(props, clientId, props.id ? props.id : '')
        .then((res: ApiMessage) => {
          getWaivers(clientId);
          setOpen(true);
          setToastrVariable('success');
          setToastrDefaultMessage(res.message);
          setToastrId('Waiver.Add.successmsg');
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            setToastrId('Waiver.Add.errormsg');
            setToastrDefaultMessage(response.message);
          }
        });
    }
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <ModalPopup
        open={showUnsavedAlertDialog}
        onCancel={() => {
          setShowUnsavedAlertDialog(false);
        }}
        description="formUnsavedChangesMessage"
        onOk={() => {
          if (clientId) {
            toggleLoader(true);
            handleUpdateUnsavedChanges(false);
            getWaivers(clientId);
            setShowUnsavedAlertDialog(false);
          }
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      {showEmptyScreen ? (
        <EmptyScreen
          titleLabelId="Waivers.emptyScreenTitle"
          defaultTitleText="No Waivers added yet."
          buttonLabelId="Waivers.emptyScreenButton"
          defaultButtonTitleText="Add New Waiver"
          showButton={
            checkPermissionForFeature(
              'backend.waiver_information',
              'addPermission',
            ) && checkPermissionForFeature('backend.clients', 'editPermission')
          }
          onButtonClick={() => {
            const selectedProgram = localStorage.getItem('selectedProgram');
            if (selectedProgram) {
              const selectedProgramId = JSON.parse(selectedProgram).id;
              setShowEmptyScreen(false);
              setInitialWaiver([
                {
                  ...emptyWaiver,
                  waiverIndex: 0,
                  programId: selectedProgramId ?? '',
                },
              ]);
              setInitialBackupWaiver([
                {
                  ...emptyWaiver,
                  waiverIndex: 0,
                  programId: selectedProgramId ?? '',
                },
              ]);
            }
          }}
        />
      ) : (
        <Card className="formCardview">
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
          {initialWaiver &&
            initialWaiver.map((item, sectionIndex) => (
              <Box
                key={sectionIndex}
                className={
                  sectionIndex === initialWaiver.length - 1
                    ? 'borderRadius4 p-lg'
                    : 'borderRadius4 p-lg mb-md'
                }
                sx={{ backgroundColor: '#ECF9F8', border: '1px solid #00C6B8' }}
              >
                <Box
                  className="flex__ align__items__center justifyContentSpaceBtw"
                  sx={{
                    marginBottom:
                      openAccordian && sectionIndex === openAccordianIndex
                        ? '20px'
                        : '0px',
                  }}
                >
                  <Box className="flex__" sx={{ width: '100%' }}>
                    <MediumTypography
                      labelid="Waivers.programName"
                      defaultlabel="Program Name:"
                      fontSize="16px"
                      textColor="#97A6A5"
                      className="mr-xs"
                    />
                    <Tooltip
                      title={
                        programs.find(
                          (programName) => programName.id === item.programId,
                        )?.label || '-'
                      }
                      arrow
                      followCursor
                      className="minWidthContent"
                    >
                      <Box>
                        <MediumTypography
                          sxProps={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            textOverflow: 'ellipsis',
                            maxWidth: '150px',
                            fontWeight: 500,
                          }}
                          label={
                            programs.find(
                              (programName) =>
                                programName.id === item.programId,
                            )?.label || '-'
                          }
                          fontSize="16px"
                          textColor="#2A4241"
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  {openAccordian &&
                  sectionIndex === openAccordianIndex ? null : (
                    <Box
                      className="flex__ justifyContent-Center"
                      sx={{ width: '100%' }}
                    >
                      <MediumTypography
                        labelid="Waivers.waiversNumber"
                        defaultlabel="Waiver Number:"
                        fontSize="16px"
                        textColor="#97A6A5"
                        className="mr-xs"
                      />
                      <Box sx={{ width: '120px' }}>
                        <MediumTypography
                          label={item.waiverNumber || '-'}
                          fontSize="16px"
                          textColor="#2A4241"
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    className="flex__ align__items__center justifyContent-End"
                    sx={{ width: '100%' }}
                  >
                    <MediumTypography
                      labelid="Waivers.status"
                      defaultlabel="Status:"
                      fontSize="16px"
                      textColor="#97A6A5"
                      className="mr-xs"
                    />
                    <Box sx={{ width: '100px' }}>
                      <MediumTypography
                        label={item.status}
                        fontSize="16px"
                        textColor={
                          item.status === 'Active'
                            ? '#37D183'
                            : item.status === 'Not Started'
                            ? '#F9B803'
                            : item.status === 'Inactive'
                            ? '#EB4C60'
                            : '#F9B803'
                        }
                      />
                    </Box>
                    {openAccordian && sectionIndex === openAccordianIndex ? (
                      <UpArrow
                        className="cursorPointer ml-sm"
                        onClick={() => {
                          setSetOpenAccordianIndex(openAccordianIndex);
                          setOpenAccordian(false);
                        }}
                      />
                    ) : (
                      <DownArrow
                        className="cursorPointer ml-sm"
                        onClick={() => {
                          setSetOpenAccordianIndex(sectionIndex);
                          setOpenAccordian(true);
                        }}
                      />
                    )}
                  </Box>
                </Box>
                {openAccordian &&
                  sectionIndex === openAccordianIndex &&
                  initialWaiver && (
                    <Formik
                      initialValues={initialWaiver}
                      validationSchema={validationSchema}
                      innerRef={formRef}
                      onSubmit={(values) => {
                        toggleLoader(true);
                        const request = JSON.parse(
                          JSON.stringify(values),
                        ) as WaiversType[];
                        request[openAccordianIndex].hoursStartingBalance =
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursStartingBalance,
                              false,
                            ).split(':')[0]
                          }` +
                          ':' +
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursStartingBalance,
                              false,
                            ).split(':')[1]
                          }`;
                        request[openAccordianIndex].hoursUsedBalance =
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursUsedBalance,
                              false,
                            ).split(':')[0]
                          }` +
                          ':' +
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursUsedBalance,
                              false,
                            ).split(':')[1]
                          }`;
                        request[openAccordianIndex].hoursRemainingBalance =
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursRemainingBalance,
                              false,
                            ).split(':')[0]
                          }` +
                          ':' +
                          `${
                            convertTimeDuration(
                              request[openAccordianIndex].hoursRemainingBalance,
                              false,
                            ).split(':')[1]
                          }`;
                        if (request[openAccordianIndex].countType === 'V') {
                          request[openAccordianIndex].hoursStartingBalance =
                            '00:00';
                          request[openAccordianIndex].hoursUsedBalance =
                            '00:00';
                          request[openAccordianIndex].hoursRemainingBalance =
                            '00:00';
                        } else if (
                          request[openAccordianIndex].countType === 'H'
                        ) {
                          request[
                            openAccordianIndex
                          ].serviceStartingBalance = 0;
                          request[
                            openAccordianIndex
                          ].serviceRemainingBalance = 0;
                          request[openAccordianIndex].serviceUsedBalance = 0;
                        }
                        if (request[openAccordianIndex].id) {
                          handleUpdateWaiver(request[openAccordianIndex]);
                        } else {
                          handleCreateWaiver(request[openAccordianIndex]);
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                      }) => {
                        useEffect(() => {
                          setInitialWaiver(values);
                        }, [values]);
                        return (
                          <Box
                            ref={parentRef}
                            style={{
                              pointerEvents:
                                isScreenLocked && item.id ? 'none' : 'auto',
                              opacity: isScreenLocked && item.id ? '0.5' : '1 ',
                            }}
                            onKeyDownCapture={(e) => {
                              if (isScreenLocked && item.id) {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            onFocus={() => {
                              if (isScreenLocked && item.id) {
                                if (parentRef.current)
                                  parentRef.current.focus();
                              }
                            }}
                          >
                            <Grid
                              container
                              xs={12}
                              rowSpacing={'24px'}
                              columnSpacing={'50px'}
                            >
                              <Grid item xs={5}>
                                <Textinput
                                  className="bg__white"
                                  labelid="Waivers.enterWaiversNumber"
                                  defaultlabelid="Waiver Number"
                                  Value={
                                    values[openAccordianIndex].waiverNumber
                                  }
                                  handlechange={(e) => {
                                    setFieldValue(
                                      `${openAccordianIndex}.waiverNumber`,
                                      e,
                                    );
                                  }}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                  Required
                                  disabled={item.status == 'Inactive'}
                                />
                                {errors[openAccordianIndex] &&
                                  (
                                    errors[
                                      openAccordianIndex
                                    ] as FormikErrors<WaiversType>
                                  ).waiverNumber &&
                                  touched[openAccordianIndex] &&
                                  (
                                    touched[
                                      openAccordianIndex
                                    ] as FormikErrors<WaiversType>
                                  ).waiverNumber && (
                                    <MediumTypography
                                      labelid={
                                        (
                                          touched[
                                            openAccordianIndex
                                          ] as FormikErrors<WaiversType>
                                        ).waiverNumber
                                      }
                                      defaultlabel="Waiver Number is Required"
                                      sxProps={{
                                        paddingTop: '8px',
                                        color: 'var(--thom-radical-red)',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={7} />
                              <Grid item xs={5}>
                                <DatePickerComponent
                                  className="bg__white"
                                  inputFormat="MM/DD/YYYY"
                                  labelid={'Waivers.waiverbegindate'}
                                  defaultlabelid={'Waiver Begin Date'}
                                  value={dayjs(
                                    values[openAccordianIndex].startDate,
                                  )}
                                  handlechange={(e: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(e).format('MM/DD/YYYY');
                                    if (e === null) {
                                      setFieldValue(
                                        `${openAccordianIndex}.startDate`,
                                        '',
                                      );
                                    } else {
                                      setFieldValue(
                                        `${openAccordianIndex}.startDate`,
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  required
                                  disabledDate={item.status == 'Inactive'}
                                />
                                {errors[openAccordianIndex]?.startDate &&
                                  touched[openAccordianIndex]?.startDate && (
                                    <MediumTypography
                                      labelid={
                                        errors[openAccordianIndex]?.startDate
                                      }
                                      defaultlabel="Waiver Begin Date is Required"
                                      sxProps={{
                                        paddingTop: '8px',
                                        color: 'var( --thom-radical-red)',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5}>
                                <DatePickerComponent
                                  className="bg__white"
                                  labelid={'Waivers.waiverenddate'}
                                  inputFormat="MM/DD/YYYY"
                                  defaultlabelid={'Waiver End Date'}
                                  minDate={dayjs(
                                    values[openAccordianIndex].startDate,
                                  )}
                                  value={dayjs(
                                    values[openAccordianIndex].endDate,
                                  )}
                                  handlechange={(e: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(e).format('MM/DD/YYYY');
                                    if (e === null) {
                                      setFieldValue(
                                        `${openAccordianIndex}.endDate`,
                                        '',
                                      );
                                    } else {
                                      setFieldValue(
                                        `${openAccordianIndex}.endDate`,
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  required
                                  disabledDate={item.status == 'Inactive'}
                                />
                                {errors[openAccordianIndex]?.endDate &&
                                  touched[openAccordianIndex]?.endDate && (
                                    <MediumTypography
                                      labelid={
                                        errors[openAccordianIndex]?.endDate
                                      }
                                      defaultlabel="Waiver End Date is Required"
                                      sxProps={{
                                        paddingTop: '8px',
                                        color: 'var( --thom-radical-red)',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={6}>
                                <MediumTypography
                                  labelid="Waivers.limitServices"
                                  defaultlabel="Limit Services"
                                />
                              </Grid>
                              <Grid item xs={6} />
                              <Grid item xs={5}>
                                <MultipleAutocompleteSearch
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  labelId={'Waivers.selectSpecificService'}
                                  defaultId={'Specific Services'}
                                  value={values[
                                    openAccordianIndex
                                  ].services.map((service) => {
                                    return {
                                      id: service,
                                      label:
                                        services.find(
                                          (servicee) => servicee.id === service,
                                        )?.label || '',
                                    };
                                  })}
                                  data={services}
                                  onSelectItem={(e) => {
                                    setFieldValue(
                                      `${openAccordianIndex}.services`,
                                      e.map((itemm) => itemm.id),
                                    );
                                  }}
                                  disableLabel={false}
                                  optionHintId={'No contacts found'}
                                  required
                                  disabled={item.status == 'Inactive'}
                                />
                                {errors[openAccordianIndex]?.services &&
                                  touched[openAccordianIndex]?.services && (
                                    <MediumTypography
                                      labelid={
                                        errors[openAccordianIndex]
                                          ?.services as string
                                      }
                                      defaultlabel="Waiver Services is Required"
                                      sxProps={{
                                        paddingTop: '8px',
                                        color: 'var( --thom-radical-red)',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5}>
                                <MultipleAutocompleteSearch
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  labelId={'Waivers.specificDisciplines'}
                                  defaultId={'Specific Disciplines'}
                                  value={values[
                                    openAccordianIndex
                                  ].disciplines.map((discipline) => {
                                    return {
                                      id: discipline,
                                      label:
                                        disciplineList.find(
                                          (disciplinee) =>
                                            disciplinee.id === discipline,
                                        )?.label || '',
                                    };
                                  })}
                                  data={disciplineList}
                                  onSelectItem={(e) => {
                                    setFieldValue(
                                      `${openAccordianIndex}.disciplines`,
                                      e.map(
                                        (disciplineItem) => disciplineItem.id,
                                      ),
                                    );
                                  }}
                                  disableLabel={false}
                                  disabled={item.status == 'Inactive'}
                                  optionHintId={'No Disciplines found'}
                                />
                              </Grid>
                              <Grid item xs={2} />
                              <Grid
                                item
                                xs={4}
                                className="flex__ align__items__center"
                              >
                                <CheckBoxComponent
                                  ischecked={values[openAccordianIndex].coTx}
                                  CheckHandleChange={(e) => {
                                    setFieldValue(
                                      `${openAccordianIndex}.coTx`,
                                      e.target.checked,
                                    );
                                  }}
                                  disabled={item.status == 'Inactive'}
                                  labelid="Waivers.coTreatment"
                                  defaultlabelid="Co-Treatment"
                                />
                              </Grid>
                              <Grid xs={8} />
                              <Grid item xs={6}>
                                <MediumTypography
                                  labelid="Waivers.doCount"
                                  defaultlabel="Do Count"
                                />
                              </Grid>
                              <Grid item xs={6} />
                              <Grid item xs={5}>
                                <AutocompleteSearch
                                  className="bg__white"
                                  labelId={'Waivers.selectWaiverCountType'}
                                  defaultId={'Select Waiver Count Type'}
                                  selectedValue={{
                                    id:
                                      waiversType.find(
                                        (waiverType) =>
                                          waiverType.id ===
                                          values[openAccordianIndex].countType,
                                      )?.id || '',
                                    label:
                                      waiversType.find(
                                        (waiverType) =>
                                          waiverType.id ===
                                          values[openAccordianIndex].countType,
                                      )?.label || '',
                                  }}
                                  data={waiversType}
                                  onSelectItem={(e) => {
                                    if (e?.id) {
                                      setFieldValue(
                                        `${openAccordianIndex}.countType`,
                                        e?.id,
                                      );
                                    } else {
                                      setFieldValue(
                                        `${openAccordianIndex}.countType`,
                                        '',
                                      );
                                    }
                                  }}
                                  disableLabel={false}
                                  Required
                                  disabled={item.status == 'Inactive'}
                                />
                                {errors[openAccordianIndex]?.countType &&
                                  touched[openAccordianIndex]?.countType && (
                                    <MediumTypography
                                      labelid={
                                        errors[openAccordianIndex]?.countType
                                      }
                                      defaultlabel="Count Type is Required"
                                      sxProps={{
                                        paddingTop: '8px',
                                        color: 'var( --thom-radical-red)',
                                      }}
                                    />
                                  )}
                              </Grid>
                              {values[openAccordianIndex].countType === 'H' && (
                                <>
                                  <Grid item xs={5}>
                                    <DurationPicker
                                      backgroundColor="#ffffff"
                                      labelId={'Waivers.startingWaiverBalance'}
                                      defaultLabel={'Starting Waiver Balance'}
                                      interval={25}
                                      maxDuration={{ hour: 12, minutes: 0 }}
                                      duration={formatStringDateInDecimals(
                                        values[openAccordianIndex]
                                          .hoursStartingBalance,
                                      )}
                                      required={true}
                                      setDuration={(duration) => {
                                        setFieldValue(
                                          `${openAccordianIndex}.hoursStartingBalance`,
                                          formatTimeDurationInDecimals(
                                            duration,
                                          ),
                                        );
                                      }}
                                      disabled={item.status == 'Inactive'}
                                    />
                                    {errors[openAccordianIndex]
                                      ?.hoursStartingBalance &&
                                      touched[openAccordianIndex]
                                        ?.hoursStartingBalance && (
                                        <MediumTypography
                                          labelid={
                                            errors[openAccordianIndex]
                                              ?.hoursStartingBalance
                                          }
                                          defaultlabel="Sarting Balance cannot be empty"
                                          sxProps={{
                                            paddingTop: '8px',
                                            color: 'var( --thom-radical-red)',
                                          }}
                                        />
                                      )}
                                  </Grid>
                                  <Grid item xs={2}></Grid>
                                  <Grid item xs={5}>
                                    <DurationPicker
                                      backgroundColor="#ffffff"
                                      labelId={'Waivers.waiverCountsUsed'}
                                      defaultLabel={'Waiver Counts Used'}
                                      interval={25}
                                      maxDuration={{ hour: 12, minutes: 0 }}
                                      duration={formatStringDateInDecimals(
                                        values[openAccordianIndex]
                                          .hoursUsedBalance,
                                      )}
                                      required={false}
                                      setDuration={() => {}}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <DurationPicker
                                      backgroundColor="#ffffff"
                                      labelId={'Waivers.waiverCountsBalance'}
                                      defaultLabel={'Waiver Counts Balance'}
                                      interval={25}
                                      maxDuration={{ hour: 12, minutes: 0 }}
                                      duration={formatStringDateInDecimals(
                                        values[openAccordianIndex]
                                          .hoursRemainingBalance,
                                      )}
                                      required={false}
                                      setDuration={() => {}}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid item xs={2} />
                                </>
                              )}
                              {values[openAccordianIndex].countType === 'V' && (
                                <>
                                  <Grid item xs={5}>
                                    <Textinput
                                      className="bg__white"
                                      labelid="Waivers.serviceStartingBalance"
                                      defaultlabelid="Service Starting Balance"
                                      // type="number"
                                      Value={
                                        values[openAccordianIndex]
                                          .serviceStartingBalance
                                          ? String(
                                              values[openAccordianIndex]
                                                .serviceStartingBalance,
                                            )
                                          : ''
                                      }
                                      handlechange={(e) => {
                                        const filteredValue = e.replace(
                                          /[^0-9]/g,
                                          '',
                                        );
                                        if (filteredValue) {
                                          setFieldValue(
                                            `${openAccordianIndex}.serviceStartingBalance`,
                                            Number(filteredValue),
                                          );
                                        } else {
                                          setFieldValue(
                                            `${openAccordianIndex}.serviceStartingBalance`,
                                            '',
                                          );
                                        }
                                      }}
                                      Required
                                      disabled={item.status == 'Inactive'}
                                    />
                                    {errors[openAccordianIndex]
                                      ?.serviceStartingBalance &&
                                      touched[openAccordianIndex]
                                        ?.serviceStartingBalance && (
                                        <MediumTypography
                                          labelid={String(
                                            errors[openAccordianIndex]
                                              ?.serviceStartingBalance,
                                          )}
                                          defaultlabel="Starting Balance cannot be empty"
                                          sxProps={{
                                            paddingTop: '8px',
                                            color: 'var( --thom-radical-red)',
                                          }}
                                        />
                                      )}
                                  </Grid>
                                  <Grid item xs={2}></Grid>
                                  <Grid item xs={5}>
                                    <Textinput
                                      className="bg__white"
                                      labelid="Waivers.serviceCountsUsed"
                                      defaultlabelid="Service Counts Used"
                                      type="number"
                                      Value={String(
                                        values[openAccordianIndex]
                                          .serviceUsedBalance === null
                                          ? ''
                                          : values[openAccordianIndex]
                                              .serviceUsedBalance,
                                      )}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Textinput
                                      className="bg__white"
                                      labelid="Waivers.serviceCountsBalance"
                                      defaultlabelid="Service Counts Balance"
                                      type="number"
                                      Value={String(
                                        values[openAccordianIndex]
                                          .serviceRemainingBalance === null
                                          ? ''
                                          : values[openAccordianIndex]
                                              .serviceRemainingBalance,
                                      )}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid item xs={2} />
                                </>
                              )}
                            </Grid>
                            <Box className="flex__ justifyContent-End mt-md">
                              <ButtonComponent
                                className="btn-primary btn-cancel mr-sm"
                                variantType="contained"
                                defaultLabelId="Cancel"
                                labelId="Waivers.cancel"
                                onClick={() => {
                                  if (
                                    !_.isEqual(
                                      initialBackupWaiver &&
                                        initialBackupWaiver[openAccordianIndex],
                                      initialWaiver[openAccordianIndex],
                                    )
                                  ) {
                                    setShowUnsavedAlertDialog(true);
                                  } else {
                                    setShowUnsavedAlertDialog(false);
                                  }
                                }}
                                disabled={item.status == 'Inactive'}
                              />
                              <ButtonComponent
                                className="btn-primary btn-submit"
                                variantType="contained"
                                type="submit"
                                labelId="Waivers.submit"
                                defaultLabelId="Submit"
                                onClick={() => {
                                  handleSubmit();
                                }}
                                disabled={item.status == 'Inactive'}
                              />
                            </Box>
                          </Box>
                        );
                      }}
                    </Formik>
                  )}
              </Box>
            ))}
          {initialWaiver && initialWaiver?.length > 0 && (
            <MediumTypography
              labelid="Waivers.addAnotherWaiver"
              defaultlabel="+ Add Another Waiver"
              fontSize="16px"
              fontweight={700}
              textColor={
                checkPermissionForFeature(
                  'backend.waiver_information',
                  'addPermission',
                ) &&
                checkPermissionForFeature('backend.clients', 'editPermission')
                  ? '#00C6B8'
                  : '#97A6A5'
              }
              sxProps={{
                cursor:
                  checkPermissionForFeature(
                    'backend.waiver_information',
                    'addPermission',
                  ) &&
                  checkPermissionForFeature('backend.clients', 'editPermission')
                    ? 'pointer'
                    : 'not-allowed',
              }}
              className="cursorPointer mt-md"
              onClick={() => {
                if (
                  checkPermissionForFeature(
                    'backend.waiver_information',
                    'addPermission',
                  ) &&
                  checkPermissionForFeature('backend.clients', 'editPermission')
                ) {
                  handleAddNewWaiver();
                }
              }}
            />
          )}
        </Card>
      )}
    </Box>
  );
};

export default WaiversScreen;

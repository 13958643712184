import {
  TableCell,
  TableRow,
  Box,
  Tooltip,
  Chip,
  Popover,
  Grid,
} from '@mui/material';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import { FC, useEffect, useState } from 'react';
import {
  Activity,
  Appointment,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { ReactComponent as StatusIcon } from '../../assets/images/statusIcon.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/acceptIcon.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/rejectIcon.svg';
import { ReactComponent as UndoIcon } from '../../assets/images/undoIcon.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/archiveIcon.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/chevronUp.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/chevronDown.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import ActivityEditModal from '../../components/formlib/modal/ActivityEditModal';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import { convertTimeDuration, getTimeString } from '../../utils/dateUtil';
import { EmployeeType } from '../../utils/type';
import AppointmentTableRow from './AppointmentTableRow';
import MediumTypography from '../../components/formlib/MediumTypography';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { useIntl } from 'react-intl';
import { getStatusColor } from '../../utils/utilities';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ActivityGroupBillingModal from '../../components/formlib/modal/ActivityGroupBillingModal';
import { GroupBilling } from '../../services/progressNotes/ProgressNotesApi';
import moment from 'moment';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../services/Constant';

export interface UpdateTime {
  totalHours?: string | null;
  approvedNonBillableHours?: string | null;
  otherAssocWorkHrs?: string | null;
  otherNonBillableHrs?: string | null;
  noShowTravelDoc?: string | null;
  note?: string;
}

interface ActivityProps {
  props: Activity;
  employeeType: EmployeeType | null;
  isEmployee: boolean;
  headerCount: number;
  onStatusUpdate?: (activitySheetId: string | null, status: StatusType) => void;
  updateTimeOrNote?: (
    activitySheetId: string,
    data: UpdateTime,
    updateType: 'NOTE' | 'TIME',
  ) => void;
  updateGroupBilling: (data: GroupBilling) => void;
  archiveItem?: (
    activitySheetId: string,
    progressNotesId: string,
    archive: boolean,
  ) => void;
  screenName: string;
  selectedDate?: Date;
}

export type StatusType =
  | 'Submitted'
  | 'In Progress'
  | 'Approved'
  | 'Rejected'
  | 'Pending'
  | 'Open'
  | 'Completed'
  | 'Inactive'
  | 'Active'
  | 'Canceled'
  | 'Not Submitted';

const ActivityTableRow: FC<ActivityProps> = ({
  props,
  isEmployee,
  headerCount,
  employeeType,
  onStatusUpdate,
  updateTimeOrNote,
  updateGroupBilling,
  archiveItem,
  screenName,
  selectedDate,
}) => {
  const [state, setState] = useState<{
    status: StatusType;
    openEditModel: boolean;
    openNoteModel: boolean;
    openGroupBillingModel: boolean;
  }>({
    status: 'Submitted',
    openEditModel: false,
    openNoteModel: false,
    openGroupBillingModel: false,
  });
  const [expand, setExpand] = useState<boolean>(
    !props.archived &&
      (props.appointments.length > 0 ||
        (props.groupBilling && props.groupBilling.length > 0)),
  );
  const rctl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getAppStartTime = (
    time: string | null,
    format: string,
    utc: boolean,
  ) => {
    if (time === null) {
      return '';
    }
    return getTimeString(time, format, utc);
  };

  const displayPopup = (activity: Activity) => {
    return activity.hoursOut !== null && activity.hoursOut !== '00:00';
  };

  const renderHoursOutTooltip = () => {
    return (
      <Grid container padding={'8px'} display={'flex'} flexDirection={'column'}>
        {employeeType !== 'Hourly Non-Exempt' &&
          props.vacationHours !== null &&
          props.vacationHours !== '00:00' && (
            <Grid item>
              <Box display={'flex'}>
                <MediumTypography
                  label={`${rctl.formatMessage({
                    id: 'vacationHoursText',
                    defaultMessage: 'Vacation',
                  })}:`}
                />
                <MediumTypography
                  className="pl-sm"
                  label={convertTimeDuration(props.vacationHours, true)}
                />
              </Box>
            </Grid>
          )}
        {employeeType !== 'Hourly Non-Exempt' &&
          props.etHours !== null &&
          props.etHours !== '00:00' && (
            <Grid item>
              <Box display={'flex'}>
                <MediumTypography
                  label={`${rctl.formatMessage({
                    id: 'etHoursText',
                    defaultMessage: 'Earned Time',
                  })}:`}
                />
                <MediumTypography
                  className="pl-sm"
                  label={convertTimeDuration(props.etHours, true)}
                />
              </Box>
            </Grid>
          )}

        {employeeType !== 'Hourly Non-Exempt' &&
          props.flexHours !== null &&
          props.flexHours !== '00:00' && (
            <Grid item>
              <Box display={'flex'}>
                <MediumTypography
                  label={`${rctl.formatMessage({
                    id: 'flexHoursText',
                    defaultMessage: 'Flex Time',
                  })}:`}
                />
                <MediumTypography
                  className="pl-sm"
                  label={convertTimeDuration(props.flexHours, true)}
                />
              </Box>
            </Grid>
          )}

        {employeeType !== 'Hourly Non-Exempt' &&
          props.holidayTimeHours !== null &&
          props.holidayTimeHours !== '00:00' && (
            <Grid item>
              <Box display={'flex'}>
                <MediumTypography
                  label={`${rctl.formatMessage({
                    id: 'holidayTimeText',
                    defaultMessage: 'Holiday Time',
                  })}:`}
                />
                <MediumTypography
                  className="pl-sm"
                  label={convertTimeDuration(props.holidayTimeHours, true)}
                />
              </Box>
            </Grid>
          )}

        {props.sickHours !== null && props.sickHours !== '00:00' && (
          <Grid item>
            <Box display={'flex'}>
              <MediumTypography
                label={`${rctl.formatMessage({
                  id: 'sickHrsText',
                  defaultMessage: 'Sick',
                })}:`}
              />
              <MediumTypography
                className="pl-sm"
                label={convertTimeDuration(props.sickHours, true)}
              />
            </Box>
          </Grid>
        )}

        {props.ltiHours !== null && props.ltiHours !== '00:00' && (
          <Grid item>
            <Box display={'flex'}>
              <MediumTypography
                label={`${rctl.formatMessage({
                  id: 'ltiHoursText',
                  defaultMessage: 'Sick bank (LTI)',
                })}:`}
              />
              <MediumTypography
                className="pl-sm"
                label={convertTimeDuration(props.ltiHours, true)}
              />
            </Box>
          </Grid>
        )}

        {employeeType !== 'Hourly Non-Exempt' &&
          props.profHours !== null &&
          props.profHours !== '00:00' && (
            <Grid item>
              <Box display={'flex'}>
                <MediumTypography
                  label={`${rctl.formatMessage({
                    id: 'profHoursText',
                    defaultMessage: 'Professional',
                  })}:`}
                />
                <MediumTypography
                  className="pl-sm"
                  label={convertTimeDuration(props.profHours, true)}
                />
              </Box>
            </Grid>
          )}

        {props.juryDutyHours !== null && props.juryDutyHours !== '00:00' && (
          <Grid item>
            <Box display={'flex'}>
              <MediumTypography
                label={`${rctl.formatMessage({
                  id: 'juryDutyText',
                  defaultMessage: 'Jury Duty',
                })}:`}
              />
              <MediumTypography
                className="pl-sm"
                label={convertTimeDuration(props.juryDutyHours, true)}
              />
            </Box>
          </Grid>
        )}

        {props.pfmlHours !== null && props.pfmlHours !== '00:00' && (
          <Grid item>
            <Box display={'flex'}>
              <MediumTypography
                label={`${rctl.formatMessage({
                  id: 'pfmlShortText',
                  defaultMessage: 'PFML',
                })}:`}
              />
              <MediumTypography
                className="pl-sm"
                label={convertTimeDuration(props.pfmlHours, true)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      status: props.status ?? 'Submitted',
    }));
    return () => {
      setExpand(false);
    };
  }, [props.status]);

  const updateNoteState = () => {
    setState((previousState) => ({
      ...previousState,
      openNoteModel: !previousState.openNoteModel,
    }));
  };
  const updateEditState = () => {
    setState((previousState) => ({
      ...previousState,
      openEditModel: !previousState.openEditModel,
    }));
  };
  const openGroupBillingModal = (id: string | null, index: number) => {
    if (!state.openGroupBillingModel) {
      if (props.groupBilling.length > 0 && id) {
        const groupBilling = props.groupBilling[index];
        const appt = groupBilling.appointments.find(
          (a) => a.appointmentId === id,
        );
        if (appt) {
          appt.groupBillingStartTime = groupBilling.groupBillingStartTime;
          appt.groupBillingDuration = groupBilling.groupBillingDuration;
          if (appt.groupBillingStartTime) {
            const newAppt = JSON.parse(JSON.stringify(appt)) as Appointment;
            newAppt.groupBillingStartTime = moment(
              newAppt.groupBillingStartTime,
              'YYYY-MM-DDTHH:mm',
            )
              .utc(true)
              .local()
              .format();
            setSelectedAppointment(newAppt);
          } else {
            setSelectedAppointment(appt);
          }
        }
      } else {
        setSelectedAppointment(null);
      }
    } else {
      setSelectedAppointment(null);
    }
    setState((previousState) => ({
      ...previousState,
      openGroupBillingModel: !previousState.openGroupBillingModel,
    }));
  };

  const renderAccept = () => {
    return (
      <Box sx={{ display: 'flex', marginLeft: '8px' }}>
        <Box
          sx={{ cursor: 'pointer' }}
          display={'flex'}
          alignItems={'center'}
          onClick={() => {
            setState({ ...state, status: 'Approved' });
            if (onStatusUpdate) {
              onStatusUpdate(props.activitySheetId, 'Approved');
            }
          }}
        >
          <AcceptIcon />
        </Box>
        <Box
          sx={{ cursor: 'pointer', marginLeft: '24px' }}
          display={'flex'}
          alignItems={'center'}
          onClick={() => {
            setState({ ...state, status: 'Rejected' });
            if (onStatusUpdate) {
              onStatusUpdate(props.activitySheetId, 'Rejected');
            }
          }}
        >
          <RejectIcon />
        </Box>
      </Box>
    );
  };
  const renderUndo = () => {
    return (
      <Box sx={{ display: 'flex' }} alignItems={'center'}>
        <TextWithIcon
          LeftIcon={<StatusIcon fill={getStatusColor(state.status)} />}
          label={state.status}
          className="paddingZero archivedIconMinusRemoveSm"
          defaultLabel="Status"
          sxProps={{
            color: getStatusColor(state.status),
            paddingRight: '4px',
            textTransform: 'none',
          }}
        />
        {props.actionRequired !== 'NO' && (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setState({ ...state, status: 'Submitted' });
              if (onStatusUpdate) {
                onStatusUpdate(props.activitySheetId, 'Submitted');
              }
            }}
          >
            <UndoIcon />
          </Box>
        )}
      </Box>
    );
  };

  const getBackgroundColor = () => {
    if (props.isHoliday) {
      return '#FFEBDD';
    } else if (props.archived) {
      return '#97A6A5';
    } else if (props.rowId === 0) {
      return '#ffffff';
    } else {
      return '#ECF9F8';
    }
  };

  const checkEditingAllowed = () => {
    if (!isEmployee) {
      return false;
    }
    console.log(props.status);
    return (
      props.status === null ||
      props.status === 'Not Submitted' ||
      props.status === 'Rejected'
    );
  };

  return (
    <>
      {state.openGroupBillingModel && (
        <ActivityGroupBillingModal
          editingAllowed={checkEditingAllowed()}
          open={state.openGroupBillingModel}
          handleClose={() => {
            openGroupBillingModal(null, -1);
          }}
          onGroupBillingEdit={(values) => {
            if (selectedAppointment === null) {
              return;
            }
            const date = moment(
              selectedAppointment.startTime,
              'YYYY-MM-DDTHH:mm',
            )
              .utc(true)
              .local()
              .format(DATE_FORMAT);
            const data: GroupBilling = {
              appointmentId: selectedAppointment.appointmentId,
              attendeeId: selectedAppointment.attendeeId,
              groupBillingDuration:
                values.duration !== null
                  ? convertTimeDuration(values.duration, false)
                  : null,
              groupBillingStartTime:
                values.startTime === null
                  ? null
                  : moment(
                      `${date} ${dayjs(
                        values.startTime,
                        'YYYY-MM-DDTHH:mm',
                      ).format('HH:mm')}`,
                      'MM/DD/YYYY HH:mm',
                    )
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            };
            openGroupBillingModal(null, -1);
            updateGroupBilling(data);
          }}
          startTime={
            selectedAppointment !== null
              ? selectedAppointment.groupBillingStartTime
              : null
          }
          duration={
            selectedAppointment !== null
              ? selectedAppointment.groupBillingDuration
              : null
          }
        />
      )}
      {state.openEditModel && (
        <ActivityEditModal
          isEmployee={isEmployee}
          employeeType={employeeType}
          open={state.openEditModel}
          totalHours={convertTimeDuration(props.totalHours, true)}
          approvedNonBillableHours={convertTimeDuration(
            props.approvedNonBillableHours,
            true,
          )}
          otherAssocWorkHrs={convertTimeDuration(props.otherAssocWorkHrs, true)}
          otherNonBillableHrs={convertTimeDuration(
            props.otherNonBillableHrs,
            true,
          )}
          noShowTravelDoc={convertTimeDuration(props.noShowTravelDoc, true)}
          handleClose={updateEditState}
          onTimeEdit={(values) => {
            if (updateTimeOrNote && props.activitySheetId !== null) {
              updateEditState();
              updateTimeOrNote(
                props.activitySheetId,
                {
                  totalHours: values.totalHours,
                  approvedNonBillableHours: values.approvedNonBillableHours,
                  otherAssocWorkHrs: values.otherAssocWorkHrs,
                  otherNonBillableHrs: values.otherNonBillableHrs,
                  noShowTravelDoc: values.noShowTravelDoc,
                  note: props.description ?? '',
                },
                'TIME',
              );
            }
          }}
        />
      )}

      {state.openNoteModel && (
        <ActivityNoteModal
          open={state.openNoteModel}
          note={props.description ?? ''}
          handleClose={updateNoteState}
          closeOnBackdropClick={true}
          maxLength={2000}
          updateNote={(note: string) => {
            if (updateTimeOrNote && props.activitySheetId !== null) {
              updateNoteState();
              updateTimeOrNote(
                props.activitySheetId,
                {
                  totalHours: props.totalHours,
                  approvedNonBillableHours: props.approvedNonBillableHours,
                  note: note,
                },
                'NOTE',
              );
            }
          }}
        />
      )}

      <TableRow
        key={props.appointmentId}
        sx={{
          backgroundColor: getBackgroundColor(),
          opacity: props.archived ? '0.7' : '1',
        }}
      >
        <TableCell sx={{ borderBottom: expand ? 'unset' : '' }}>
          <Box display={'flex'} alignItems={'center'}>
            {(props.appointments.length > 0 ||
              (props.groupBilling && props.groupBilling.length > 0)) &&
            !props.archived ? (
              <Box
                sx={{ paddingLeft: '8px', cursor: 'pointer' }}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand && <ChevronUp />}
                {!expand && <ChevronDown />}
              </Box>
            ) : (
              <Box sx={{ paddingLeft: '22px' }} />
            )}
            <Box>
              {props.holidayTitle !== null && (
                <Box>
                  <Tooltip title={props.holidayTitle}>
                    <Chip
                      size="small"
                      label={props.holidayTitle}
                      sx={{
                        position: 'relative',
                        fontFamily: 'Lato-Regular',
                        fontSize: '10px',
                        color: 'white',
                        marginLeft: '8px',
                        backgroundColor: '#F96300',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '150px',
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
              <MediumTypography
                sxProps={{
                  paddingLeft: '8px',
                  color:
                    props.isHoliday !== null && props.isHoliday
                      ? '#F96300'
                      : '#2A4241',
                }}
                className="singleLine"
                label={getAppStartTime(props.day, 'dddd, MMM DD', true)}
              />
            </Box>
          </Box>
        </TableCell>
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.billableHours !== null
              ? convertTimeDuration(props.billableHours, true)
              : '-'
          }
          visible={false}
        />
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.approvedNonBillableHours !== null
              ? convertTimeDuration(props.approvedNonBillableHours, true)
              : '-'
          }
          visible={false}
        />
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.otherAssocWorkHrs !== null
              ? convertTimeDuration(props.otherAssocWorkHrs, true)
              : '-'
          }
          visible={false}
        />

        {employeeType === 'Hourly Non-Exempt' && (
          <TooltipTableCell
            tooltipSxProps={{
              borderBottom: expand ? 'unset' : '',
            }}
            value={
              props.noShowTravelDoc !== null
                ? convertTimeDuration(props.noShowTravelDoc, true)
                : '-'
            }
            visible={false}
          />
        )}
        {employeeType === 'Hourly Non-Exempt' && (
          <TooltipTableCell
            tooltipSxProps={{
              borderBottom: expand ? 'unset' : '',
            }}
            value={
              props.otherNonBillableHrs !== null
                ? convertTimeDuration(props.otherNonBillableHrs, true)
                : '-'
            }
            visible={false}
          />
        )}
        <TableCell sx={{ borderBottom: expand ? 'unset' : '' }}>
          <Box display={'flex'} alignItems={'center'}>
            <MediumTypography
              label={
                props.hoursOut !== null
                  ? convertTimeDuration(props.hoursOut, true)
                  : '-'
              }
            />
            {displayPopup(props) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px',
                  cursor: 'pointer',
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon stroke="var(--thom-mono-gray)" />
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  {renderHoursOutTooltip()}
                </Popover>
              </Box>
            )}
          </Box>
        </TableCell>
        <TooltipTableCell
          tooltipSxProps={{
            borderBottom: expand ? 'unset' : '',
          }}
          value={
            props.totalHours !== null
              ? convertTimeDuration(props.totalHours, true)
              : '-'
          }
          visible={false}
        />
        {isEmployee ? (
          <TableCell
            sx={{
              borderBottom: expand ? 'unset' : '',
              display:
                screenName === 'EmployeeActivity' ||
                (checkPermissionForFeature(
                  'backend.review_activity_sheet',
                  'editPermission',
                ) &&
                  screenName === 'ApprovalActivity')
                  ? null
                  : 'none',
            }}
          >
            <Box sx={{ display: 'flex' }} alignItems={'center'}>
              <StatusIcon fill={getStatusColor(state.status)} />
              <MediumTypography
                className="singleLine"
                sxProps={{ paddingLeft: '8px' }}
                label={props.status ?? '-'}
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            padding={'none'}
            sx={{
              borderBottom: expand ? 'unset' : '',
              display:
                screenName === 'EmployeeActivity' ||
                (checkPermissionForFeature(
                  'backend.review_activity_sheet',
                  'editPermission',
                ) &&
                  screenName === 'ApprovalActivity')
                  ? null
                  : 'none',
            }}
          >
            {state.status === 'Submitted' && renderAccept()}
            {state.status !== 'Submitted' && renderUndo()}
          </TableCell>
        )}

        <TableCell
          sx={{
            borderBottom: expand ? 'unset' : '',
            display:
              screenName === 'EmployeeActivity' ||
              (checkPermissionForFeature(
                'backend.review_activity_sheet',
                'editPermission',
              ) &&
                screenName === 'ApprovalActivity')
                ? null
                : 'none',
          }}
        >
          {props.archived ? (
            <Box
              display={'inline-block'}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                cursor: 'pointer',
                paddingLeft: '8px',
                paddingRight: '4px',
              }}
              onClick={() => {
                if (
                  props.archivalStatus !== null &&
                  props.archivalStatus !== 'Submitted' &&
                  archiveItem &&
                  props.activitySheetId !== null
                ) {
                  archiveItem(props.activitySheetId, '', !props.archived);
                }
              }}
            >
              <TextWithIcon
                LeftIcon={
                  props.archivalStatus !== null &&
                  props.archivalStatus !== 'Submitted' && <UndoIcon />
                }
                className="paddingZero archivedIconMinusRemoveSm"
                labelId={'archivedText'}
                defaultLabel="Archived"
                sxProps={{
                  color: '#97A6A5',
                  textTransform: 'none',
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                pointerEvents: props.status === 'Approved' ? 'none' : 'auto',
                opacity: props.status === 'Approved' ? 0.5 : 1,
              }}
            >
              <ActionToolTip labelId="BlockTime.note" defaultLabel="Note">
                <Box
                  sx={{ padding: '4px', display: 'flex', cursor: 'pointer' }}
                  onClick={updateNoteState}
                >
                  <MessageIcon
                    stroke={props.description ? '#00C6B8' : '#97A6A5'}
                  />
                </Box>
              </ActionToolTip>
              {isEmployee &&
                props.status !== 'Approved' &&
                props.status !== 'Submitted' && (
                  <>
                    <ActionToolTip labelId="editText" defaultLabel="Edit">
                      <Box
                        sx={{
                          padding: '4px',
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                        }}
                        onClick={updateEditState}
                      >
                        <EditIcon />
                      </Box>
                    </ActionToolTip>
                    <ActionToolTip labelId="archiveText" defaultLabel="Archive">
                      <Box
                        sx={{
                          padding: '4px',
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                          visibility: 'hidden',
                        }}
                        onClick={() => {
                          if (
                            props.archivalStatus === null ||
                            props.archivalStatus === 'Pending'
                          ) {
                            if (archiveItem && props.activitySheetId !== null) {
                              if (expand) {
                                setExpand(!expand);
                              }
                              archiveItem(
                                props.activitySheetId,
                                '',
                                !props.archived,
                              );
                            }
                          }
                        }}
                      >
                        <ArchiveIcon />
                      </Box>
                    </ActionToolTip>
                  </>
                )}
              {!isEmployee && state.status !== 'Approved' && (
                <ActionToolTip labelId="editText" defaultLabel="Edit">
                  <Box
                    sx={{
                      padding: '4px',
                      display: 'flex',
                      cursor: 'pointer',
                      marginLeft: '8px',
                    }}
                    onClick={updateEditState}
                  >
                    <EditIcon />
                  </Box>
                </ActionToolTip>
              )}
            </Box>
          )}
        </TableCell>
      </TableRow>
      {expand === true && (
        <TableRow
          sx={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          <TableCell
            colSpan={headerCount}
            sx={{ paddingLeft: '24px !important' }}
          >
            <AppointmentTableRow
              rowId={props.rowId}
              groupAppointments={props.groupBilling}
              parentArchived={props.archived}
              isHoliday={props.isHoliday ?? false}
              parentStatus={props.status}
              isEmployee={isEmployee}
              archiveAppointment={(sheetId, archived) => {
                if (archiveItem) {
                  archiveItem(props.activitySheetId, sheetId, archived);
                }
              }}
              screenName={screenName}
              selectedDate={selectedDate}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ActivityTableRow;

import React, { useContext, useEffect, useRef, useState } from 'react';
import InformationModal from './InformationModal';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import dayjs, { Dayjs } from 'dayjs';
import IfspContext from './ifspContextApi/IfspContex';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { OptionType } from '../../utils/type';

import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { Grid } from '@mui/material';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

interface TransitionPlanProps {
  info: boolean;
  open: boolean;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
  onClose: (open: boolean) => void;
  closeInfo: (info: boolean) => void;
  onOpen: (open: boolean) => void;
  openInfo: (info: boolean) => void;
}

interface TransitionPlanDataType {
  isTransitionPlanDone: string;
  transitionPlanDate: string | null | Dayjs;
  noTransitionPlanReason: string;
  needs: string;
  leaEligible: string;
  notify: string;
  consentShare: boolean;
  consentDate: string | null | Dayjs;
  outsideTPCReason: string;
  meetingDate: string | null | Dayjs;
  noTPCReason: string;
  sectionDetermine: boolean;
  specify: string;
  makeReferral: boolean;
  date: string | null | Dayjs;
  tpc: boolean;
  leaInvited: string;
  repParticipate: string;
  participatedNames: string;
  noReferralMade: boolean;
  leaOptOutDate: string | null | Dayjs;
  seaStatus: string;
  isChecked: boolean;
}

const LEAData = [
  {
    id: 1,
    labelId: 'IFSP.LEA.1',
    defaultLabelId: 'Sign consent to share information with LEA',
  },
  {
    id: 2,
    labelId: 'IFSP.LEA.2',
    defaultLabelId: 'Determine which sections of EI record to send to LEA',
  },
  {
    id: 3,
    labelId: 'IFSP.LEA.3',
    defaultLabelId:
      'Make referral to LEA/school district (between 2.3 and 2.9)',
  },
  {
    id: 4,
    labelId: 'IFSP.LEA.4',
    defaultLabelId: 'Arrange Transition Planning Conference (TPC)',
  },
  {
    id: 5,
    labelId: 'IFSP.LEA.5',
    defaultLabelId: 'No Referral Made',
  },
];
const TransitionPlan = ({
  closeInfo,
  info,
  isIfspScreenLocked,
  openInfo,
  open,
  onOpen,
  onClose,
  selectedLanguage,
}: TransitionPlanProps) => {
  const {
    ifsp,
    updateIfspContext,
    updateTransitionOutOfRange,
    UpdateLeaReferralDateOutOfRange,
    updateIsTransitionDateReqired,
    updateIsOptOutNotificationDateRequired,
    isOptOutNotificationDateRequired,
    isTransitionDateReqired,
    transitionDateOutOfRange,
    leaReferralDateOutOfRange,
  } = useContext(IfspContext);
  const [transitionPlan, setTransitionPlan] = useState<TransitionPlanDataType>(
    ifsp.ifspTeam.forms.transitionPlan,
  );

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  const [transitionReasondata, setTransitionReasondata] = useState<
    OptionType[]
  >([]);
  const [leaReferralReasonData, setLeaReferralReasonData] = useState<
    OptionType[]
  >([]);
  const [tpcReasonData, setTpcReasonData] = useState<OptionType[]>([]);
  const [seaStatus, setSeaStatus] = useState<OptionType[]>([]);

  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setTransitionReasondata(() => {
      return lookups.transitionReason.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setLeaReferralReasonData(() => {
      return lookups.leaReferralReason.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setTpcReasonData(() => {
      return lookups.tpcReason.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setSeaStatus(() => {
      return lookups.seaStatus.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
  }, [lookups]);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          transitionPlan: transitionPlan,
        },
      },
    });
  }, [transitionPlan]);

  useEffect(() => {
    const newTransitionPlan = ifsp.ifspTeam.forms
      .transitionPlan as TransitionPlanDataType;
    if (JSON.stringify(newTransitionPlan) !== JSON.stringify(transitionPlan)) {
      setTransitionPlan(newTransitionPlan);
    }
  }, [ifsp]);

  return (
    <Box component="main">
      {info && (
        <InformationModal
          open={info}
          titleLabel="IFSP.Information"
          titleDefaultLabel="Information"
          buttonLabel="IFSP.InfoOkButton"
          buttonDefaultLabel="Ok"
          onClose={() => {
            closeInfo(info);
          }}
          info={
            "Early Intervention services may occur for less than the specified period based on the child's age at the time of the eligibility evaluation. All EI services end one day prior to the child's third birthday. Transition planning occurs for all infants and toddlers who are moving on from an EI program. As part of the IFSP, a transition plan is developed to consider and identify the steps and services to support a smooth transition. This process includes activities and tasks discussed by the IFSP Team."
          }
        />
      )}
      <Box
        component="section"
        className="p-lg gray3 borderRadius4 "
        sx={{
          border: '1px solid #00C6B8',
        }}
      >
        <Box component="div" className="flex__justify__space-between">
          {open ? (
            <Box component="div" className="flex__justify__space-between">
              <MediumTypography
                labelid="IFSP.session7"
                defaultlabel="Transition Plan"
                fontSize="16px"
                fontweight={600}
                textColor="#2A4241"
                className="pr-md"
              />
              <InfoIcon
                className="cursorPointer"
                onClick={() => {
                  openInfo(info);
                }}
              />
            </Box>
          ) : (
            <MediumTypography
              labelid="IFSP.session7"
              defaultlabel="Transition Plan"
              fontSize="18px"
              fontweight={600}
              textColor="#2A4241"
            />
          )}
          {!open ? (
            <DownArrow
              className="cursorPointer"
              style={{ color: '#008C82' }}
              onClick={() => {
                onOpen(open);
              }}
            />
          ) : (
            <UpArrow
              className="cursorPointer"
              onClick={() => {
                onClose(open);
              }}
            />
          )}
        </Box>
        {open && (
          <Box component="div" className="mt-lg">
            <div
              ref={parentRef}
              style={{
                pointerEvents: isScreenLocked ? 'none' : 'auto',
                opacity: isScreenLocked ? '0.5' : '1 ',
              }}
              onKeyDownCapture={(e) => {
                if (isScreenLocked) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onFocus={() => {
                if (isScreenLocked) {
                  if (parentRef.current) parentRef.current.focus();
                }
              }}
            >
              <Grid
                container
                rowSpacing={'24px'}
                className="align__items__center"
              >
                <Grid item>
                  <MediumTypography
                    labelid="EICS.TransitionPlan"
                    defaultlabel={`Transition Plan `}
                    fontSize="14px"
                  />
                </Grid>
                <Grid item className="ml-lg">
                  <CustomRadioButton
                    disable={isIfspScreenLocked}
                    data={[
                      {
                        value: 'Yes',
                        labelId: 'Yes',
                        defaultLabel: 'Yes',
                      },
                      {
                        value: 'No',
                        labelId: 'No',
                        defaultLabel: 'No',
                      },
                    ]}
                    value={transitionPlan.isTransitionPlanDone}
                    onSelectItem={(value) => {
                      if (value === 'No') {
                        updateIsTransitionDateReqired(false);
                        if (!transitionPlan.noTransitionPlanReason) {
                          updateTransitionOutOfRange(true);
                        }
                        updateTransitionOutOfRange(true);
                        setTransitionPlan({
                          ...transitionPlan,
                          isTransitionPlanDone: value ?? '',
                          transitionPlanDate: null,
                        });
                      } else {
                        updateIsTransitionDateReqired(true);
                        updateTransitionOutOfRange(false);
                        setTransitionPlan({
                          ...transitionPlan,
                          isTransitionPlanDone: value ?? '',
                        });
                      }
                    }}
                    row={true}
                    className="pt-xs"
                  />
                </Grid>
                {transitionPlan.isTransitionPlanDone === 'Yes' ? (
                  <Grid item xs={5.6}>
                    <DatePickerComponent
                      disabledDate={isIfspScreenLocked}
                      className="bg__white"
                      labelid="EICS.TransitionPlandate"
                      disableFuture={false}
                      required={true}
                      defaultlabelid="Date of Transition Plan*"
                      value={
                        transitionPlan.transitionPlanDate === '' || null
                          ? null
                          : (dayjs(transitionPlan.transitionPlanDate) as Dayjs)
                      }
                      handlechange={(dateValue: Dayjs | null) => {
                        const formattedDate =
                          dayjs(dateValue).format('MM/DD/YYYY');
                        if (dateValue !== null) {
                          updateIsTransitionDateReqired(false);
                          setTransitionPlan({
                            ...transitionPlan,
                            transitionPlanDate: formattedDate,
                          });
                        } else {
                          if (transitionPlan.isTransitionPlanDone === 'Yes') {
                            updateIsTransitionDateReqired(true);
                          }
                          setTransitionPlan({
                            ...transitionPlan,
                            transitionPlanDate: null,
                          });
                        }
                        const dateOfBirth = ifsp.client.dob;
                        if (dateValue) {
                          if (!dateOfBirth) {
                            throw new Error('Date of birth is undefined');
                          }

                          const dob = new Date(dateOfBirth);
                          const transitionDate = new Date(dateValue.toDate());

                          const dobYear = dob.getFullYear();
                          const dobMonth = dob.getMonth();
                          const transitionYear = transitionDate.getFullYear();
                          const transitionMonth = transitionDate.getMonth();

                          const yearDifferenceInMonths =
                            (transitionYear - dobYear) * 12;
                          const monthDifference = transitionMonth - dobMonth;
                          const totalMonthDifference =
                            yearDifferenceInMonths + monthDifference;
                          const isWithinRange =
                            totalMonthDifference >= 27 &&
                            totalMonthDifference <= 33;
                          updateTransitionOutOfRange(!isWithinRange);
                        }
                      }}
                    />
                    {isTransitionDateReqired && (
                      <Box className="loginError">
                        <MediumTypography
                          labelid={'IFSP.DateOfTransitionPlanIsRequired'}
                          defaultlabel={'Date of Transition Plan is required'}
                          className="errorText-md"
                        />
                      </Box>
                    )}
                  </Grid>
                ) : null}
              </Grid>
              <Box>
                <Grid
                  container
                  rowSpacing={'24px'}
                  className="pt-md pb-sm align__items__center"
                >
                  <Grid item xs={8.3}>
                    <DropdownComponent
                      disabled={isIfspScreenLocked}
                      name="noTransitionPlanReason"
                      names={transitionReasondata}
                      value={transitionPlan.noTransitionPlanReason}
                      defaultlabelid="Reason for “No transition plan” or outside “expected” timeframe"
                      labelid="EICS.TransitionPlanDropdown"
                      handleChange={(value: string) => {
                        updateTransitionOutOfRange(value ? false : true);
                        setTransitionPlan({
                          ...transitionPlan,
                          noTransitionPlanReason: value,
                        });
                      }}
                      placeholderOnfloatBackgroundColor="#E7F0F0"
                    />
                    <MediumTypography
                      labelid="EICS.TransitionPlanDropDownLabel"
                      sxProps={{ fontStyle: 'italic' }}
                      defaultlabel={
                        'The reason is required as the child is below 27 months and less than 90 days from their 3rd birthday'
                      }
                      fontSize="14px"
                    />
                    {transitionDateOutOfRange &&
                      !transitionPlan.noTransitionPlanReason && (
                        <Box className="loginError">
                          <MediumTypography
                            labelid={'IFSP.NoTransitionPlanReasonRequired'}
                            defaultlabel={
                              'Reason for no transition plan/outside expected timeframe is required'
                            }
                            className="errorText-md"
                          />
                        </Box>
                      )}
                  </Grid>
                </Grid>
              </Box>
              <MediumTypography
                labelid="IFSP.responsible"
                defaultlabel="What do we need to do? Who is responsible?"
                fontweight={400}
                textColor="#2A4241"
              />
              <Textinput
                fieldheight="100px"
                disabled={isIfspScreenLocked}
                placeHolder="Enter your comment"
                labelid="IFSP.enterYourComment"
                defaultlabelid="Enter your comment"
                className="mt-sm bg__white"
                multiline={true}
                sxProps={{
                  width: '795px',
                }}
                inputProps={{
                  style: {
                    width: '795px',
                    height: '75px',
                    paddingBottom: '8px',
                    padding: '0px 0px 0px 0px',
                    textAlign: 'justify',
                  },
                  className: 'translate',
                  maxLength: 500,
                }}
                Value={transitionPlan.needs}
                handlechange={(text) =>
                  setTransitionPlan({ ...transitionPlan, needs: text })
                }
              />
              <Box
                component="div"
                className="flex__ align__items__center pt-md"
              >
                <MediumTypography
                  labelid="IFSP.referralToLocalEducationAgency"
                  defaultlabel="Referral to Local Education Agency (LEA/School District)"
                  fontSize="16px"
                  fontweight={600}
                  textColor="#2A4241"
                  className="pr-md"
                />
              </Box>
              <MediumTypography
                labelid="IFSP.steps"
                defaultlabel="Steps that we need to take before age 2.9:"
                fontSize="16px"
                fontweight={600}
                textColor="#2A4241"
                className="pt-sm pb-sm"
              />
              <Grid
                container
                rowSpacing={'24px'}
                className=" align__items__center pb-sm"
              >
                <Grid item>
                  <MediumTypography
                    labelid="EICS.PotentiallyLEAEligible"
                    defaultlabel={`Potentially LEA eligible `}
                    fontSize="14px"
                  />
                </Grid>
                <Grid item className="ml-lg">
                  <CustomRadioButton
                    disable={isIfspScreenLocked}
                    data={[
                      {
                        value: 'Yes',
                        labelId: 'Yes',
                        defaultLabel: 'Yes',
                      },
                      {
                        value: 'No',
                        labelId: 'No',
                        defaultLabel: 'No',
                      },
                    ]}
                    value={transitionPlan.leaEligible}
                    onSelectItem={(value) => {
                      if (value === 'No') {
                        setTransitionPlan({
                          ...transitionPlan,
                          leaEligible: value ?? '',
                          notify: '',
                        });
                      } else {
                        setTransitionPlan({
                          ...transitionPlan,
                          leaEligible: value ?? '',
                        });
                      }
                    }}
                    row={true}
                    className="pt-xs"
                  />
                </Grid>
              </Grid>
              {transitionPlan.leaEligible === 'Yes' ? (
                <Box component="div">
                  <CustomRadioButton
                    disable={isIfspScreenLocked}
                    data={[
                      {
                        value: 'true',
                        labelId: 'IFSP.notify',
                        defaultLabel: `Notify the LEA/SEA of my child's potential eligibility`,
                      },
                      {
                        value: 'false',
                        labelId: 'IFSP.noNotify',
                        defaultLabel:
                          'Guardian choose to opt out of notification',
                      },
                    ]}
                    value={transitionPlan.notify}
                    onSelectItem={(value) => {
                      if (value) {
                        setTransitionPlan({ ...transitionPlan, notify: value });
                      }
                    }}
                    row={false}
                    paddingY="4px"
                  />
                </Box>
              ) : null}
              {LEAData.map((qus) => {
                return (
                  <Box component="div" key={qus.id}>
                    <Box
                      component="div"
                      className="flex__ pt-sm align__items__center"
                    >
                      <CheckBoxComponent
                        disabled={isIfspScreenLocked}
                        ischecked={
                          qus.id === 1
                            ? transitionPlan.consentShare
                            : qus.id === 2
                            ? transitionPlan.sectionDetermine
                            : qus.id === 3
                            ? transitionPlan.makeReferral
                            : qus.id === 4
                            ? transitionPlan.tpc
                            : qus.id === 5
                            ? transitionPlan.noReferralMade
                            : false
                        }
                        CheckHandleChange={(value) => {
                          setTransitionPlan((prevTransitionPlan) => ({
                            ...prevTransitionPlan,
                            consentShare:
                              qus.id === 1
                                ? value.target.checked
                                : prevTransitionPlan.consentShare,
                            sectionDetermine:
                              qus.id === 2
                                ? value.target.checked
                                : prevTransitionPlan.sectionDetermine,
                            makeReferral:
                              qus.id === 3
                                ? value.target.checked
                                : prevTransitionPlan.makeReferral,
                            tpc:
                              qus.id === 4
                                ? value.target.checked
                                : prevTransitionPlan.tpc,
                            noReferralMade:
                              qus.id === 5
                                ? value.target.checked
                                : prevTransitionPlan.noReferralMade,
                          }));
                        }}
                        labelid={qus.labelId}
                        defaultlabelid={qus.defaultLabelId}
                        className="pl-sm"
                      />
                    </Box>
                    {qus.id === 1 && (
                      <>
                        <Box
                          component="div"
                          className="pt-sm pl-xlg flex__ align__items__center"
                        >
                          <MediumTypography
                            labelid={'IFSP.dateOfConsent'}
                            defaultlabel={'Date of Consent:'}
                            fontweight={400}
                            textColor="#2A4241"
                            className="pr-md"
                          />
                          <Box component="div" sx={{ width: '346px' }}>
                            <DatePickerComponent
                              disabledDate={isIfspScreenLocked}
                              className="bg__white"
                              labelid={'IFSP.dateOfConsent'}
                              defaultlabelid={'Date of Consent'}
                              value={
                                transitionPlan.consentDate === '' || null
                                  ? null
                                  : (dayjs(transitionPlan.consentDate) as Dayjs)
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    consentDate: formattedDate,
                                  });
                                } else {
                                  UpdateLeaReferralDateOutOfRange(false);
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    consentDate: null,
                                  });
                                }
                                const dateOfBirth = ifsp.client.dob;
                                if (dateValue) {
                                  if (!dateOfBirth) {
                                    throw new Error(
                                      'Date of birth is undefined',
                                    );
                                  }

                                  const dob = new Date(dateOfBirth);
                                  const transitionDate = new Date(
                                    dateValue.toDate(),
                                  );

                                  const dobYear = dob.getFullYear();
                                  const dobMonth = dob.getMonth();
                                  const transitionYear =
                                    transitionDate.getFullYear();
                                  const transitionMonth =
                                    transitionDate.getMonth();

                                  const yearDifferenceInMonths =
                                    (transitionYear - dobYear) * 12;
                                  const monthDifference =
                                    transitionMonth - dobMonth;
                                  const totalMonthDifference =
                                    yearDifferenceInMonths + monthDifference;
                                  const isWithinRange =
                                    totalMonthDifference >= 27 &&
                                    totalMonthDifference <= 33;
                                  UpdateLeaReferralDateOutOfRange(
                                    !isWithinRange,
                                  );
                                }
                              }}
                            />
                          </Box>
                        </Box>
                        <Box className="pl-xlg">
                          <Grid
                            container
                            rowSpacing={'24px'}
                            className="pt-xlg align__items__center"
                          >
                            <Grid item xs={5}>
                              <DropdownComponent
                                disabled={isIfspScreenLocked}
                                name="leaReferralReason"
                                names={leaReferralReasonData}
                                value={transitionPlan.outsideTPCReason}
                                defaultlabelid="Reason if LEA referral is outside of standard timeframe (27-33 mo)"
                                labelid="EICS.LEADropDown"
                                handleChange={(value: string) => {
                                  UpdateLeaReferralDateOutOfRange(
                                    value ? false : true,
                                  );
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    outsideTPCReason: value,
                                  });
                                }}
                                placeholderOnfloatBackgroundColor="#E7F0F0"
                              />
                              {leaReferralDateOutOfRange &&
                                !transitionPlan.outsideTPCReason && (
                                  <Box className="loginError">
                                    <MediumTypography
                                      labelid={'IFSP.LEAReferralReasonRequired'}
                                      defaultlabel={
                                        'LEA Referral reason is required'
                                      }
                                      className="errorText-md"
                                    />
                                  </Box>
                                )}
                            </Grid>
                          </Grid>
                        </Box>
                        <MediumTypography
                          labelid="IFSP.tPC"
                          defaultlabel="Transition Planning Conference (TPC):"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                          className="pt-md pl-xlg"
                        />
                        <Box
                          component="div"
                          className="flex__ align__items__center pt-lg pl-xlg"
                        >
                          <MediumTypography
                            labelid="IFSP.meetingDate"
                            defaultlabel="Meeting Date:"
                            fontweight={400}
                            textColor="#2A4241"
                          />
                          <Box
                            component="div"
                            className="ml-md"
                            sx={{ width: '364px' }}
                          >
                            <DatePickerComponent
                              disabledDate={isIfspScreenLocked}
                              className="bg__white"
                              labelid={'IFSP.meetingDate'}
                              defaultlabelid={'Meeting Date'}
                              value={
                                transitionPlan.meetingDate === '' || null
                                  ? null
                                  : (dayjs(transitionPlan.meetingDate) as Dayjs)
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                if (dateValue !== null) {
                                  const formattedDate =
                                    dayjs(dateValue).format('MM/DD/YYYY');
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    meetingDate: formattedDate,
                                  });
                                } else {
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    meetingDate: null,
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Box>
                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md pl-xlg align__items__center"
                        >
                          <Grid item xs={5}>
                            <DropdownComponent
                              disabled={isIfspScreenLocked}
                              name="noTPCReason"
                              names={tpcReasonData}
                              value={transitionPlan.noTPCReason}
                              defaultlabelid="Reason No TPC occurred"
                              labelid="EICS.ReasonNoTPCOccoured"
                              handleChange={(value: string) => {
                                setTransitionPlan({
                                  ...transitionPlan,
                                  noTPCReason: value,
                                });
                              }}
                              placeholderOnfloatBackgroundColor="#E7F0F0"
                            />
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                        </Grid>
                      </>
                    )}
                    {qus.id === 2 && (
                      <Box
                        component="div"
                        className="pt-sm pl-xlg flex__ align__items__center"
                      >
                        <MediumTypography
                          labelid={'IFSP.specify'}
                          defaultlabel={'Specify:'}
                          fontweight={400}
                          textColor="#2A4241"
                          className="pr-md"
                        />
                        <Textinput
                          disabled={isIfspScreenLocked}
                          placeHolder="Specify here.."
                          sxProps={{
                            width: '405px',
                            backgroundColor: '#ffffff',
                          }}
                          Value={transitionPlan.specify}
                          inputProps={{
                            className: 'translate',
                            maxLength: 500,
                          }}
                          handlechange={(text) =>
                            setTransitionPlan({
                              ...transitionPlan,
                              specify: text,
                            })
                          }
                        />
                      </Box>
                    )}
                    {qus.id === 3 && (
                      <Box
                        component="div"
                        className="pt-sm pl-xlg flex__ align__items__center"
                      >
                        <MediumTypography
                          labelid={'IFSP.dateOfReferralLea'}
                          defaultlabel={'Date of Referral to LEA:'}
                          fontweight={400}
                          textColor="#2A4241"
                          className="pr-md"
                        />
                        <Box component="div" sx={{ width: '300px' }}>
                          <DatePickerComponent
                            disabledDate={isIfspScreenLocked}
                            className="bg__white"
                            labelid={'IFSP.dateOfReferralLeaPicker'}
                            defaultlabelid={'Date of Referral to LEA'}
                            value={
                              transitionPlan.date === '' || null
                                ? null
                                : (dayjs(transitionPlan.date) as Dayjs)
                            }
                            handlechange={(dateValue: Dayjs | null) => {
                              if (dateValue !== null) {
                                const formattedDate =
                                  dayjs(dateValue).format('MM/DD/YYYY');
                                setTransitionPlan({
                                  ...transitionPlan,
                                  date: formattedDate,
                                });
                              } else {
                                setTransitionPlan({
                                  ...transitionPlan,
                                  date: null,
                                });
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {qus.id === 4 && (
                      <>
                        <Box
                          component="div"
                          className="pt-sm pl-xlg flex__ align__items__center"
                        >
                          <MediumTypography
                            labelid={'IFSP.representativeInvited'}
                            defaultlabel={
                              'Was the LEA/school district representative invited?'
                            }
                            fontweight={400}
                            textColor="#2A4241"
                            className="pr-md"
                          />
                          <Box component="div" className="ml-md">
                            <CustomRadioButton
                              disable={isIfspScreenLocked}
                              data={[
                                {
                                  value: 'false',
                                  labelId: 'No',
                                  defaultLabel: 'No',
                                },
                                {
                                  value: 'true',
                                  labelId: 'Yes',
                                  defaultLabel: 'Yes',
                                },
                              ]}
                              value={transitionPlan.leaInvited}
                              onSelectItem={(value) => {
                                if (value === 'false') {
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    leaInvited: value,
                                    participatedNames: '',
                                    repParticipate: '',
                                  });
                                } else {
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    leaInvited: value ?? '',
                                  });
                                }
                              }}
                            />
                          </Box>
                        </Box>
                        {transitionPlan.leaInvited === 'true' && (
                          <Box
                            component="div"
                            className="flex__ pl-xlg align__items__center pt-md"
                          >
                            <MediumTypography
                              labelid="IFSP.LEA/SchoolParticipate"
                              defaultlabel="Did the LEA/School District Representative Participate?"
                              fontweight={400}
                              textColor="#2A4241"
                              className="pr-md"
                            />
                            <CustomRadioButton
                              disable={isIfspScreenLocked}
                              data={[
                                {
                                  value: 'yes',
                                  labelId: 'Yes',
                                  defaultLabel: 'Yes',
                                },
                                {
                                  value: 'no',
                                  labelId: 'No',
                                  defaultLabel: 'No',
                                },
                              ]}
                              value={transitionPlan.repParticipate}
                              onSelectItem={(value) => {
                                if (value === 'no') {
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    repParticipate: value,
                                    participatedNames: '',
                                  });
                                } else {
                                  setTransitionPlan({
                                    ...transitionPlan,
                                    repParticipate: value ?? '',
                                  });
                                }
                              }}
                            />
                          </Box>
                        )}
                        {transitionPlan.repParticipate === 'yes' && (
                          <Box>
                            <MediumTypography
                              labelid="IFSP.whoParticipated"
                              defaultlabel="Who participated? What was there role?"
                              fontweight={400}
                              textColor="#2A4241"
                              className="pt-md pl-xlg"
                            />
                            <Textinput
                              disabled={isIfspScreenLocked}
                              className="mt-sm ml-xlg"
                              sxProps={{
                                width: '840px',
                                backgroundColor: '#ffffff',
                              }}
                              Value={transitionPlan.participatedNames}
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              handlechange={(text) =>
                                setTransitionPlan({
                                  ...transitionPlan,
                                  participatedNames: text,
                                })
                              }
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                );
              })}
              <Box className="pl-xlg">
                <Grid
                  container
                  rowSpacing={'24px'}
                  className="pt-md align__items__center"
                >
                  <Grid item xs={5}>
                    <DropdownComponent
                      disabled={isIfspScreenLocked}
                      name="leaReferralReason"
                      names={seaStatus}
                      value={transitionPlan.seaStatus}
                      defaultlabelid="SEA Status"
                      labelid="IFSP.SEAStatus"
                      handleChange={(value: string) => {
                        if (value !== 'OPTOUT') {
                          setTransitionPlan({
                            ...transitionPlan,
                            seaStatus: value,
                            leaOptOutDate: '',
                          });
                        } else {
                          setTransitionPlan({
                            ...transitionPlan,
                            seaStatus: value,
                          });
                        }
                        if (
                          value === 'OPTOUT' &&
                          (transitionPlan.leaOptOutDate === null ||
                            transitionPlan.leaOptOutDate === '' ||
                            !dayjs(transitionPlan.leaOptOutDate).isValid())
                        ) {
                          updateIsOptOutNotificationDateRequired(true);
                        } else {
                          updateIsOptOutNotificationDateRequired(false);
                        }
                      }}
                      placeholderOnfloatBackgroundColor="#E7F0F0"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                className="pt-md pl-xlg flex__ align__items__center"
              >
                <MediumTypography
                  labelid={'IFSP.LeaOptoutDateTitle'}
                  defaultlabel={'LEA Opt-out Notification date:'}
                  fontweight={400}
                  textColor="#2A4241"
                  className="pr-md"
                />
                <Box component="div" sx={{ width: '335px' }}>
                  <DatePickerComponent
                    required={transitionPlan.seaStatus === 'OPTOUT'}
                    disabledDate={isIfspScreenLocked}
                    className="bg__white"
                    labelid={'IFSP.LeaOptoutDate'}
                    defaultlabelid={'LEA Opt-out Notification date'}
                    value={
                      transitionPlan.leaOptOutDate === null ||
                      transitionPlan.leaOptOutDate === ''
                        ? null
                        : dayjs(transitionPlan.leaOptOutDate as Dayjs)
                    }
                    handlechange={(value) => {
                      if (value !== null) {
                        const formattedDate = dayjs(value).format('MM/DD/YYYY');
                        setTransitionPlan({
                          ...transitionPlan,
                          leaOptOutDate: formattedDate,
                        });
                        if (
                          !dayjs(formattedDate).isValid() &&
                          transitionPlan.seaStatus === 'OPTOUT'
                        ) {
                          updateIsOptOutNotificationDateRequired(true);
                        } else {
                          updateIsOptOutNotificationDateRequired(false);
                        }
                      } else {
                        if (transitionPlan.seaStatus === 'OPTOUT') {
                          updateIsOptOutNotificationDateRequired(true);
                        } else {
                          updateIsOptOutNotificationDateRequired(false);
                        }
                        setTransitionPlan({
                          ...transitionPlan,
                          leaOptOutDate: '',
                        });
                      }
                    }}
                  />
                  {isOptOutNotificationDateRequired && (
                    <Box className="loginError">
                      <MediumTypography
                        labelid={'IFSP.LeaOptoutDateRequired'}
                        defaultlabel={
                          'LEA Opt-out notification date is required.'
                        }
                        className="errorText-md"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TransitionPlan;

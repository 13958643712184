import {
  AlertColor,
  Card,
  CardContent,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Box } from '@mui/system';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import TitleText from '../../components/formlib/TitleText';
import React, { useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import MyTimeOffTableRow from './MyTimeOffTableRow';
import MyTimeOffModal from './MyTimeOffModal';
import moment from 'moment';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  TimeOffData,
  addEmployeeMyTimeOff,
  deleteEmployeeMyTimeOff,
  getEmployeeMyTimeOff,
  updateEmployeeMyTimeOff,
} from '../../services/myTimeoff/MyTimeoffApi';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import SelectComponent from '../../components/formlib/SelectComponent';
import { Order } from '../../utils/type';
import ModalPopup from '../../components/formlib/ModalPopup';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { convertTimeDuration } from '../../utils/dateUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';

export type TimeOffHours = {
  type: string;
  id: string;
  remainingHrs: string;
  employeeType: string;
};

export type TimeOffListType = {
  id?: string;
  typeId: string;
  durationType: string;
  startDate: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  reason: string;
  leaveName: string;
};

export type GetTimeOffListType = {
  id: string;
  typeId: string;
  status: string;
  durationType: string;
  startDate: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  reason: string;
  appliedDate: string;
  leaveName: string;
  noOfHrs: string;
  totalDays: string;
};

export type MyTimeOffType = {
  leavesInfo: TimeOffHours[];
  timeOffList: GetTimeOffListType[];
  totalTimeOffsCount: number;
  employeeType: string;
};

interface TableData {
  type: string;
  createdOn: string;
  duration: string;
  reason: string;
  status: string;
  actions: string;
}
const initialHeadCells: HeadCell<TableData>[] = [
  {
    id: 'createdOn',
    labelId: 'myTimeOffDateApplied',
    defaultLabelId: 'Date Applied',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'type',
    labelId: 'myTimeOffType',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duration',
    labelId: 'myTimeOffDuration',
    defaultLabelId: 'Duration',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'status',
    labelId: 'myTimeOffStatus',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'reason',
    labelId: 'myTimeOffReason',
    defaultLabelId: 'Reason',
    numeric: false,
    requiredSorting: false,
  },
];

const style = {
  position: 'absolute' as const,
  top: '45%',
  left: '45%',
  transform: 'translate(-40%, -40%)',
  width: '780px',
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '10px 20px 20px 20px',
  maxHeight: '500px',
  borderRadius: '6px',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    background: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  outline: 'none',
  fontFamily: 'Lato-Regular',
};
const style400 = {
  position: 'absolute' as const,
  top: '45%',
  left: '45%',
  transform: 'translate(-40%, -40%)',
  width: '100%',
  maxWidth: '300px',
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '10px 20px 20px 20px',
  maxHeight: '500px',
  borderRadius: '6px',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    background: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  outline: 'none',
  fontFamily: 'Lato-Regular',
};
const MyTimeOff = () => {
  const [myTimeOff, setMyTimeOff] = useState<MyTimeOffType>({
    leavesInfo: [{ type: '', id: '', remainingHrs: '', employeeType: '' }],
    timeOffList: [],
    totalTimeOffsCount: 0,
    employeeType: '',
  });
  const [showTimeOffModel, setShowTimeOffModel] = useState<boolean>(false);
  const [availableHrs, setAvailableHrs] = useState<boolean>(false);
  const [editRowData, setEditRowData] = useState<TimeOffListType>();
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof TableData>('duration');
  const [toastrId, setToastrId] = React.useState('');
  const { loader, toggleLoader } = useContext(
    LoaderContext,
  ) as LoaderContextType;
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [deleteTimeOff, setDeleteTimeOff] = useState<GetTimeOffListType | null>(
    null,
  );
  const currentDate = moment();
  const [fisicalYears, setFisicalYears] = useState<string[]>([]);
  const [startFisicalDateTime, setStartFisicalDateTime] = useState<string>('');
  const [endFisicalDateTime, setEndFisicalDateTime] = useState<string>('');
  const isAvailableHrs = currentDate.isBetween(
    startFisicalDateTime,
    endFisicalDateTime,
  );
  const [headCells, setHeadCells] = useState(initialHeadCells);

  useEffect(() => {
    const getFisicalYearLabel = (startYear: number): string => {
      const endYear = startYear + 1;
      return `${startYear}-${endYear.toString()}`;
    };

    const currentYear = currentDate.year();
    const fiveYearsAgo = currentYear - 5;

    const yearsArray = [];
    let initalYearValue = '';
    for (let year = fiveYearsAgo; year <= currentYear; year++) {
      if (currentDate.isAfter(`${year}-07-01`) && year >= 2022) {
        const fisicalYearLabel = getFisicalYearLabel(year);
        yearsArray.push(fisicalYearLabel);
        setStartFisicalDateTime(`07/01/${year} 00:00:00`);
        setEndFisicalDateTime(`06/30/${year + 1} 23:59:59`);
        if (currentDate.isBetween(`${year}-07-01`, `${year + 1}-06-30`)) {
          initalYearValue = fisicalYearLabel;
          setSelectedYear(initalYearValue);
        }
      }
    }
    setFisicalYears(yearsArray);
  }, []);
  const handleYearChange = (value: string) => {
    if (value) {
      const [startYear, endYear] = value.split('-').map(Number);
      setStartFisicalDateTime(`07/01/${startYear} 00:00:00`);
      setEndFisicalDateTime(`06/30/${endYear} 23:59:59`);
    }
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTimeOff = () => {
    setEditRowData(undefined);
    setShowTimeOffModel(true);
  };

  const onClose = () => {
    setShowTimeOffModel(false);
  };

  const handleAvailableHrs = () => {
    setAvailableHrs(true);
  };

  const onCloseModal = () => {
    setAvailableHrs(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (requestBody: TimeOffListType, action: string) => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);

    if (employeeId === null) {
      return;
    }

    toggleLoader(true);

    const convertStartDatetoUtc = (date: string | null | undefined) => {
      return moment(`${date} 00:00:00`, 'MM/DD/YYYY HH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    };

    const convertEndDatetoUtc = (date: string | null | undefined) => {
      return moment(`${date} 23:59:59`, 'MM/DD/YYYY HH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    };

    const convertStartDatetoUtcForHours = (
      date: string | null | undefined,
      time: string | null | undefined,
    ) => {
      return moment(`${date} ${time}`, 'MM/DD/YYYY HH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    };

    const convertEndDatetoUtcForHours = (
      date: string | null | undefined,
      time: string | null | undefined,
    ) => {
      return moment(`${date} ${time}`, 'MM/DD/YYYY HH:mm:ss')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    };
    const convertedStartDate =
      requestBody.durationType === 'days'
        ? convertStartDatetoUtc(requestBody.startDate)
        : convertStartDatetoUtcForHours(
            requestBody.startDate,
            requestBody.startTime,
          );
    const convertedEndDate =
      requestBody.durationType === 'days'
        ? convertEndDatetoUtc(requestBody.endDate)
        : convertEndDatetoUtcForHours(
            requestBody.startDate,
            requestBody.endTime,
          );

    const utcFisicalYearStartTime = moment(
      startFisicalDateTime,
      'MM/DD/YYYY HH:mm:ss',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
    const utcFisicalYearEndTime = moment(
      endFisicalDateTime,
      'MM/DD/YYYY HH:mm:ss',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');

    if (action === 'add') {
      addEmployeeMyTimeOff(
        employeeId,
        {
          ...requestBody,
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        },
        utcFisicalYearStartTime,
        utcFisicalYearEndTime,
      )
        .then(() => {
          toggleLoader(false);
          onClose();
          setOpen(true);
          setToastrVariable('success');
          setToastrId('myTimeOffAddedSuccessfully');
          setToastrDefaultMessage('Time Off added successfully');
          getTimeoffDetails();
        })
        .catch((error) => {
          toggleLoader(false);
          setToastrVariable('error');
          setOpen(true);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const errorResponse = error as ApiMessage;
            if (errorResponse.code === 1009 || errorResponse.code === 409) {
              setToastrId('TimeOffConflict');
              setToastrDefaultMessage(
                'Your Time off overlaps with an existing request. Adjust the dates or time to avoid conflict.',
              );
            } else if (errorResponse.code === 1015) {
              setToastrId('TimeOffConflictForHolidays');
              setToastrDefaultMessage(
                'No Time off is required for the chosen days or time.',
              );
            } else if (errorResponse.code === 1018) {
              setToastrId('myTimeOffExceedingMsg');
              setToastrDefaultMessage(
                `You can't submit a leave request exceeding your available balance.`,
              );
            } else if (errorResponse.code === 1029) {
              setToastrId('myTimeOffInvalidDates');
              setToastrDefaultMessage(
                `The dates selected are prior to the start date of the program or after the end date. Please choose different dates.`,
              );
            } else if (errorResponse.code === 1030) {
              setToastrId('myTimeOffActivitysheetError');
              setToastrDefaultMessage(
                `  Activity sheet has already been Submitted/Approved for the selected dates. Choose different dates.`,
              );
            } else {
              setToastrId('myTimeOffFailedToUpdate');
              setToastrDefaultMessage('Failed to update TimeOff');
            }
          }
        });
    } else {
      updateEmployeeMyTimeOff(
        employeeId,
        {
          ...requestBody,
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        },
        utcFisicalYearStartTime,
        utcFisicalYearEndTime,
      )
        .then(() => {
          onClose();
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('success');
          setToastrId('myTimeOffUpdatedSuccessfully');
          setToastrDefaultMessage('Time Off updated successfully');
          getTimeoffDetails();
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const errorResponse = error as ApiMessage;
            if (errorResponse.code === 1009 || errorResponse.code === 409) {
              setToastrId('TimeOffConflict');
              setToastrDefaultMessage(
                'Your Time off overlaps with an existing request. Adjust the dates or time to avoid conflict.',
              );
            } else if (errorResponse.code === 1015) {
              setToastrId('TimeOffConflictForHolidays');
              setToastrDefaultMessage(
                'No Time off is required for the chosen days or time.',
              );
            } else if (errorResponse.code === 1018) {
              setToastrId('myTimeOffExceedingMsg');
              setToastrDefaultMessage(
                `You can't submit a leave request exceeding your available balance.`,
              );
            } else if (errorResponse.code === 1029) {
              setToastrId('myTimeOffInvalidDates');
              setToastrDefaultMessage(
                `The dates selected are prior to the start date of the program or after the end date. Please choose different dates.`,
              );
            } else if (errorResponse.code === 1030) {
              setToastrId('myTimeOffActivitysheetError');
              setToastrDefaultMessage(
                `  Activity sheet has already been Submitted/Approved for the selected dates. Choose different dates.`,
              );
            } else {
              setToastrId('myTimeOffFailedToUpdate');
              setToastrDefaultMessage('Failed to update TimeOff');
            }
          }
        });
    }
  };

  const handleEditClick = (rowData: TimeOffData) => {
    const startDate = moment(rowData.startDate, 'YYYY-MM-DDTHH:mm:ss')
      .utc(true)
      .local()
      .format('MM/DD/YYYY');
    const endDate = moment(rowData.endDate, 'YYYY-MM-DDTHH:mm:ss')
      .utc(true)
      .local()
      .format('MM/DD/YYYY');
    const startTime = moment(rowData.startDate, 'YYYY-MM-DDTHH:mm:ss')
      .utc(true)
      .local()
      .format('HH:mm');
    const endTime = moment(rowData.endDate, 'YYYY-MM-DDTHH:mm:ss')
      .utc(true)
      .local()
      .format('HH:mm');

    setEditRowData({
      id: rowData.id,
      durationType: rowData.durationType,
      startDate: startDate,
      endDate: endDate,
      reason: rowData.reason,
      typeId: rowData.typeId,
      leaveName: rowData.leaveName,
      startTime: startTime,
      endTime: endTime,
    });
    setShowTimeOffModel(true);
  };
  const handleDeleteMyTimeOff = (id: string) => {
    toggleLoader(true);
    deleteEmployeeMyTimeOff(id)
      .then((res) => {
        if (res.code === 200) {
          setOpen(true);
          setToastrVariable('success');
          setToastrId('myTimeOffDeletedSuccessfully');
          setToastrDefaultMessage(res.message);
          getTimeoffDetails();
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId('myTimeOffFailedToDelete');
        setToastrDefaultMessage(error);
      });
  };

  const getTimeoffDetails = () => {
    toggleLoader(true);
    const utcFisicalYearStartTime = moment(
      startFisicalDateTime,
      'MM/DD/YYYY HH:mm:ss',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
    const utcFisicalYearEndTime = moment(
      endFisicalDateTime,
      'MM/DD/YYYY HH:mm:ss',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId) {
      getEmployeeMyTimeOff(
        employeeId,
        page,
        utcFisicalYearStartTime,
        utcFisicalYearEndTime,
        isAvailableHrs,
        order,
        orderBy,
      )
        .then((res) => {
          if (res) {
            const filteredLeaves = res.leavesInfo.filter((item: TimeOffHours) =>
              item.employeeType.split(',').includes(res.employeeType),
            );
            toggleLoader(false);
            setMyTimeOff({
              leavesInfo: filteredLeaves,
              timeOffList: res.timeOffList,
              totalTimeOffsCount: res.totalTimeOffsCount,
              employeeType: res.employeeType,
            });
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('failedProfileDetailsMessage');
            setToastrDefaultMessage('Failed to load profile details');
          }
        });
    }
  };

  useEffect(() => {
    if (selectedYear !== '') {
      getTimeoffDetails();
    }
  }, [page, order, selectedYear, orderBy]);

  useEffect(() => {
    if (
      (checkPermissionForFeature('backend.time_off', 'editPermission') ||
        checkPermissionForFeature('backend.time_off', 'deletePermission')) &&
      headCells.length === 5
    ) {
      setHeadCells((prev) => [
        ...prev,
        {
          id: 'actions',
          labelId: 'myTimeOffActions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, []);

  const blocks = myTimeOff.leavesInfo.map((data) => {
    if (
      (data.type === 'Sick' || myTimeOff.employeeType === 'Salaried Exempt') &&
      data.type !== 'Others'
    ) {
      return (
        <Grid item className="pb-md mr-md" sx={{ width: '195px !important' }}>
          <Box
            sx={{
              borderRadius: '6px',
              border: '1px solid #00C6B8',
            }}
            key={data.id}
          >
            <CardContent
              sx={{
                padding: '12px',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                }}
              >
                <MediumTypography
                  label={convertTimeDuration(data.remainingHrs, true)}
                  sxProps={{
                    fontSize: '40px',
                    fontWeight: 800,
                    lineHeight: '48px',
                    textAlign: 'center',
                    color: '#2A4241',
                    whiteSpace: 'nowrap',
                  }}
                />
                <MediumTypography
                  labelid="myTimeOffHours"
                  defaultlabel="Hours"
                  sxProps={{
                    fontSize: '12px',
                    color: '#97A6A5',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                />

                <Box
                  sx={{
                    paddingTop: '8px',
                  }}
                >
                  <MediumTypography
                    label={data.type}
                    textstyle="center"
                    sxProps={{
                      padding: '4px',
                      backgroundColor: '#B1FDF7',
                      fontSize: '12px',
                      borderRadius: '4px',
                      color: '#2A4241',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
          </Box>
        </Grid>
      );
    }
  });

  return (
    <>
      {confirmationModal && (
        <ModalPopup
          open={confirmationModal}
          description="TimeOff.deleteConfirmationMessage"
          onCancel={() => setConfirmationModal(false)}
          onOk={() => {
            if (deleteTimeOff && deleteTimeOff.id !== undefined) {
              handleDeleteMyTimeOff(deleteTimeOff.id);
            }
            setDeleteTimeOff(null);
            setConfirmationModal(false);
          }}
          labelId1="TimeOff.No"
          negativeActionLabel="No"
          labelId2="TimeOff.Yes"
          positiveActionLabel="Yes"
        />
      )}
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box>
        <Card
          sx={{
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
        >
          <CardContent
            sx={{
              padding: '16px 16px 0px 16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TitleText
                labelid="myTimeOffTitle"
                defaultlabel="My Time Off"
                Sxprops={{
                  fontSize: '16px',
                  fontWeight: 700,
                  color: '#2A4241',
                  textAlign: 'left',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flexGrow: 1,
                }}
              >
                <Box sx={{ width: '25%' }}>
                  <SelectComponent
                    value={selectedYear}
                    handleChange={(value) => {
                      setSelectedYear(value);
                      handleYearChange(value);
                    }}
                    names={fisicalYears}
                    height="32px"
                  />
                </Box>
                <ButtonComponent
                  className="btn-primary btn-submitBorder btn-cancel ml-md"
                  variantType="outlined"
                  type="submit"
                  labelId="myTimeOFFAvailableHours"
                  defaultLabelId="Available Hours"
                  sxProps={{
                    border: '1px solid #008C82',
                    display: 'none',
                  }}
                  disabled={!isAvailableHrs}
                  onClick={() => {
                    handleAvailableHrs();
                  }}
                />
                {checkPermissionForFeature(
                  'backend.time_off',
                  'addPermission',
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit ml-md"
                    variantType="contained"
                    type="submit"
                    labelId="myTimeOffRequestButtonTitle"
                    defaultLabelId="Request"
                    disabled={!isAvailableHrs}
                    onClick={() => handleTimeOff()}
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {showTimeOffModel && (
        <MyTimeOffModal
          open={showTimeOffModel}
          handleClose={onClose}
          rowData={editRowData}
          availableHrs={myTimeOff.leavesInfo}
          handlesubmit={(values: TimeOffListType, action: string) => {
            handleSubmit(values, action);
          }}
        />
      )}
      {availableHrs && (
        <Modal
          open={availableHrs}
          onClose={() => {
            onCloseModal();
          }}
        >
          <Box
            sx={myTimeOff.employeeType === 'Salaried Exempt' ? style : style400}
          >
            <Card
              sx={{
                width: '100%',
                borderRadius: '6px',
              }}
            >
              <CardContent className="p-xlg">
                <Box className="flex__ justifyContent-FlexEnd ">
                  <Close
                    className="cursorPointer"
                    onClick={() => onCloseModal()}
                  />
                </Box>
                <Box>
                  <MediumTypography
                    labelid="myTimeOFFAvailableHours"
                    defaultlabel="Available Hours"
                    sxProps={{
                      fontSize: '24px',
                      fontWeight: 700,
                      paddingBottom: '24px',
                    }}
                  />
                  <Grid container direction={'row'}>
                    {blocks}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      )}

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHeader
            headerNames={headCells}
            checkBoxRequired={false}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            className="listDataTableHead"
          />
          <TableBody className="tableRowcss">
            {myTimeOff.timeOffList.length !== 0 &&
              myTimeOff.timeOffList.map((timeOffData, index) => (
                <MyTimeOffTableRow
                  data={timeOffData}
                  key={index}
                  onEdit={handleEditClick}
                  onDeleteMyTimeOff={() => {
                    if (timeOffData.id !== undefined) {
                      setDeleteTimeOff(timeOffData);
                      setConfirmationModal(true);
                    }
                  }}
                />
              ))}
          </TableBody>
        </Table>
        {myTimeOff.timeOffList.length === 0 && !loader && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <MediumTypography
              textstyle={'center'}
              labelid="noTimeOffMessage"
              defaultlabel="No request added yet"
              paddingstyle="12px"
            />
          </Box>
        )}
      </TableContainer>

      {myTimeOff.totalTimeOffsCount > 5 && (
        <Card
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AppPagination
            pageNumber={page}
            perPageCount={5}
            paginationCount={myTimeOff.totalTimeOffsCount}
            handleChangePage={(_event, newPage) => {
              if (newPage - 1 !== page) {
                setPage(newPage - 1);
              }
            }}
          />
        </Card>
      )}
    </>
  );
};

export default MyTimeOff;

import { Dialog, DialogTitle, DialogContent, Grid, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MediumTypography from '../MediumTypography';
import { Formik } from 'formik';
import SmallTypography from '../SmallTypography';
import ButtonComponent from '../ButtonComponent';
import * as yup from 'yup';
import {
  checkTimeExceeding,
  convertTimeDuration,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../../utils/dateUtil';
import { STORAGE_USERNAME_KEY } from '../../../services/Constant';
import DurationPicker from '../DurationPicker';
import { EmployeeType } from '../../../utils/type';

interface ActivityNoteModelProps {
  employeeType: EmployeeType | null;
  open: boolean;
  isEmployee: boolean;
  totalHours: string | null;
  approvedNonBillableHours: string | null;
  otherAssocWorkHrs: string | null;
  otherNonBillableHrs: string | null;
  noShowTravelDoc: string | null;
  handleClose: () => void;
  onTimeEdit: (data: {
    totalHours: string | null;
    approvedNonBillableHours: string | null;
    otherAssocWorkHrs: string | null;
    otherNonBillableHrs: string | null;
    noShowTravelDoc: string | null;
  }) => void;
}

const validationSchema = yup.object().shape({
  totalHours: yup.string().nullable(),
  approvedNonBillableHours: yup.string().nullable(),
  otherAssocWorkHrs: yup.string().nullable(),
  otherNonBillableHrs: yup.string().nullable(),
  noShowTravelDoc: yup.string().nullable(),
});

const ActivityEditModal: FC<ActivityNoteModelProps> = ({
  open,
  isEmployee,
  employeeType,
  noShowTravelDoc,
  approvedNonBillableHours,
  otherAssocWorkHrs,
  otherNonBillableHrs,
  totalHours,
  handleClose,
  onTimeEdit,
}) => {
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    setEmployeeName(localStorage.getItem(STORAGE_USERNAME_KEY));
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Grid container direction={'column'} rowSpacing={4}>
          <Grid item>
            <MediumTypography
              label={employeeName ?? ''}
              sxProps={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '8px',
                lineHeight: '30px',
                wordBreak: 'break-word',
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            totalHours: totalHours,
            approvedNonBillableHours: approvedNonBillableHours,
            otherAssocWorkHrs: otherAssocWorkHrs,
            otherNonBillableHrs: otherNonBillableHrs,
            noShowTravelDoc: noShowTravelDoc,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={(values, { setFieldError }) => {
            if (
              values.approvedNonBillableHours !== null &&
              checkTimeExceeding(values.approvedNonBillableHours, '12:00')
            ) {
              setFieldError('approvedNonBillableHours', 'hrsLimitMessage');
              return;
            }
            if (
              values.otherAssocWorkHrs !== null &&
              checkTimeExceeding(values.otherAssocWorkHrs, '12:00')
            ) {
              setFieldError('otherAssocWorkHrs', 'hrsLimitMessage');
              return;
            }

            if (
              values.totalHours !== null &&
              checkTimeExceeding(values.totalHours, '12:00')
            ) {
              setFieldError('totalHours', 'hrsLimitMessage');
              return;
            }

            const ApprovedNonBillablehoursConversion = convertTimeDuration(
              values.approvedNonBillableHours,
              false,
            );

            const totalHoursConversion = convertTimeDuration(
              values.totalHours,
              false,
            );

            const noShowTravelDocConversion = convertTimeDuration(
              values.noShowTravelDoc,
              false,
            );

            const otherAssocWorkHrsConversion = convertTimeDuration(
              values.otherAssocWorkHrs,
              false,
            );

            const otherNonBillableHrsConversion = convertTimeDuration(
              values.otherNonBillableHrs,
              false,
            );

            onTimeEdit({
              approvedNonBillableHours: ApprovedNonBillablehoursConversion,
              otherAssocWorkHrs: otherAssocWorkHrsConversion,
              otherNonBillableHrs: otherNonBillableHrsConversion,
              totalHours: totalHoursConversion,
              noShowTravelDoc: noShowTravelDocConversion,
            });
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Grid sx={{ marginTop: '8px' }}>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="totalHrsText"
                    defaultLabel="Total Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(values.totalHours)}
                    setDuration={(duration) => {
                      setFieldValue(
                        'totalHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.totalHours && touched.totalHours && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.totalHours}
                      defaultLabelId="Field should not be empty"
                    />
                  )}
                </Grid>

                <Grid item xs={6} key={'approvedNonBillableHrsText'}>
                  <DurationPicker
                    key={'approvedNonBillableHrsText'}
                    interval={25}
                    required={false}
                    labelId="approvedNonBillableHrsText"
                    defaultLabel="Approved Non-Billable Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(
                      values.approvedNonBillableHours,
                    )}
                    setDuration={(duration) => {
                      setFieldValue(
                        'approvedNonBillableHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.approvedNonBillableHours &&
                    touched.approvedNonBillableHours && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.approvedNonBillableHours}
                        defaultLabelId="Field should not be empty"
                      />
                    )}
                </Grid>

                <Grid item xs={6} key={'actRltdBillHours'}>
                  <DurationPicker
                    key={'actRltdBillHours'}
                    interval={25}
                    required={false}
                    labelId="otherAssocWorkHrsText"
                    defaultLabel="Other Assoc. Work Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(
                      values.otherAssocWorkHrs,
                    )}
                    setDuration={(duration) => {
                      setFieldValue(
                        'otherAssocWorkHrs',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.otherAssocWorkHrs && touched.otherAssocWorkHrs && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.otherAssocWorkHrs}
                      defaultLabelId="Field should not be empty"
                    />
                  )}
                </Grid>

                {employeeType === 'Hourly Non-Exempt' && (
                  <Grid item xs={6} key={'otherNonBillableHrsText'}>
                    <DurationPicker
                      key={'otherNonBillableHrsText'}
                      interval={25}
                      required={false}
                      labelId="otherNonBillableHrsText"
                      defaultLabel="Other Non-Billable Hrs"
                      maxDuration={{ hour: 12, minutes: 0 }}
                      duration={formatStringDateInDecimals(
                        values.otherNonBillableHrs,
                      )}
                      setDuration={(duration) => {
                        setFieldValue(
                          'otherNonBillableHrs',
                          formatTimeDurationInDecimals(duration),
                        );
                      }}
                    />
                    {errors.otherNonBillableHrs &&
                      touched.otherNonBillableHrs && (
                        <SmallTypography
                          sxProps={{ color: 'red' }}
                          labelId={errors.otherNonBillableHrs}
                          defaultLabelId="Field should not be empty"
                        />
                      )}
                  </Grid>
                )}

                {employeeType === 'Hourly Non-Exempt' && (
                  <Grid item xs={6} key={'noShowDescText'}>
                    <DurationPicker
                      key={'noShowDescText'}
                      interval={25}
                      required={false}
                      disabled={!isEmployee}
                      labelId="noShowDescText"
                      defaultLabel="No Show (Travel, Wait, Doc, Other)"
                      maxDuration={{ hour: 12, minutes: 0 }}
                      duration={formatStringDateInDecimals(
                        values.noShowTravelDoc,
                      )}
                      setDuration={(duration) => {
                        setFieldValue(
                          'noShowTravelDoc',
                          formatTimeDurationInDecimals(duration),
                        );
                      }}
                    />
                    {errors.noShowTravelDoc && touched.noShowTravelDoc && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.noShowTravelDoc}
                        defaultLabelId="Field should not be empty"
                      />
                    )}
                  </Grid>
                )}
              </Grid>

              <Box
                sx={{ marginTop: '24px' }}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  labelId={'cancelText'}
                  variantType="outlined"
                  onClick={handleClose}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  labelId={'Insurance.update'}
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityEditModal;

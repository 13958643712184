import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import { ReactComponent as Download } from '../../assets/images/downloadIcon.svg';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ConsentListData } from '../../services/configApi/forms/Consent/ConsentServices';
import moment from 'moment';
import { ReactComponent as ShareIcon } from '../../assets/images/shareIcon.svg';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
// import { ReactComponent as Print } from '../../assets/images/printerIcon.svg';
// import { ReactComponent as Mail } from '../../assets/images/mail.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';

interface ConsentProps {
  data: ConsentListData;
  onDelete?: () => void;
  onView: (rowData: ConsentListData) => void;
  onDownload: (rowData: ConsentListData) => void;
  onShare: (rowData: ConsentListData) => void;
  offlineFlag?: boolean;
  onPrint?: () => void;
  onMail?: () => void;
}

const ConsentTableRow: FC<ConsentProps> = (props) => {
  const {
    consentName,
    consentDate,
    expirationDate,
    status,
    permissionGrantedTo,
  } = props.data;

  const renderCell = (
    content: string | null | undefined,
    format?: string,
    textColor?: string,
  ) => {
    if (!content || content === '') {
      return (
        <TableCell>
          <MediumTypography label="-" sxProps={{ textAlign: 'left' }} />
        </TableCell>
      );
    } else if (content === 'N/A') {
      return <MediumTypography label="N/A" sxProps={{ textAlign: 'left' }} />;
    }
    return (
      <MediumTypography
        label={format ? moment(content).local().format(format) : content}
        textColor={textColor}
      />
    );
  };

  const renderStatusCell = () => {
    let textColor: string | undefined;
    switch (status) {
      case 'Revoked':
      case 'Expired':
        textColor = '#EB4C60';
        break;
      case 'Current':
        textColor = '#2A4241';
        break;
      default:
        textColor = '#97A6A5';
        break;
    }

    return renderCell(status, undefined, textColor);
  };

  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
      }}
    >
      <TableCell padding="checkbox">
        <CheckBoxComponent ischecked={false} />
      </TableCell>
      <TooltipTableCell
        value={consentName}
        visible={true}
        textSxProps={{ maxWidth: 'auto' }}
      />
      <TableCell>{renderCell(consentDate, 'MM/DD/YYYY')}</TableCell>
      <TableCell>{renderCell(expirationDate, 'MM/DD/YYYY')}</TableCell>
      <TableCell>{renderStatusCell()}</TableCell>
      <TooltipTableCell
        value={permissionGrantedTo ? permissionGrantedTo : 'N/A'}
        visible={true}
        textSxProps={{ maxWidth: '200px' }}
      />
      <TableCell>
        <Box className="flex__">
          <Box
            className="cursorPointer"
            onClick={() => props.onView(props.data)}
          >
            <ToolTipIcon
              labelId="ConsentForms.listActionViewToolTipLabel"
              defaultLabel="View"
              cursor="pointer"
              Icon={<ViewEye />}
            />
          </Box>
          {!props.offlineFlag && (
            <Box
              className="cursorPointer"
              onClick={() => props.onShare(props.data)}
            >
              <ToolTipIcon
                labelId="ConsentForms.listActionShareToolTipLabel"
                defaultLabel="Share"
                cursor="pointer"
                Icon={<ShareIcon />}
              />
            </Box>
          )}
          {!props.offlineFlag && (
            <Box
              className="cursorPointer"
              onClick={() => props.onDownload(props.data)}
            >
              <ToolTipIcon
                labelId="ConsentForms.listActionDownloadToolTipLabel"
                defaultLabel="Download"
                cursor="pointer"
                Icon={<Download />}
              />
            </Box>
          )}
          {/* <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionPrintToolTipLabel"
              defaultLabel="Print"
              Icon={<Print onClick={props.onPrint} />}
            />
          </Box>
          <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionDeleteToolTipLabel"
              defaultLabel="Delete"
              Icon={<DeleteIcon onClick={props.onDelete} />}
            />
          </Box>
          <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionMailToolTipLabel"
              defaultLabel="Mail"
              Icon={<Mail onClick={props.onMail} />}
            />
          </Box> */}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ConsentTableRow;

import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { AlertColor, Grid, Tooltip } from '@mui/material';

import { useEffect, useState } from 'react';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { SignatureStateType } from '../../utils/type';
import moment from 'moment';
import { isOtherLanguage } from '../../TranslateWidget';
import React from 'react';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

export type SignatureDataType = {
  id?: string;
  name?: string;
  relationship?: string;
  email?: string;
  signature?: string | null;
  date?: string | null;
  license?: string;
  rowKey?: string;
  partitionKey?: string;
};

type ConsentSignatureType = {
  signatureData: SignatureDataType[];
  relationshipWithChildId?: string;
  defaultRelationshipWithChildId?: string;
  emailId?: string;
  dateId?: string;
  defaultDateId?: string;
  isDateRequired?: boolean;
  defaultEmailId?: string;
  addSignatureId?: string;
  defaultAddSignatureId?: string;
  viewUpdateId?: string;
  defaultVerbalConsentId?: string;
  verbalConsentId?: string;
  defaultViewUpdateId?: string;
  defaultParentGuardianNoId?: string;
  parentGuardianNoId?: string;
  parentSignatureId?: string;
  defaultParentSignatureId?: string;
  verbalConsentValue?: { verbal: boolean }[];
  onSaveClick?: (sign: string, id: number) => void;
  onVerbalConsentClick?: (value: boolean, index: number) => void;
  staffSignature?: boolean;
  nameLabelId?: string;
  nameDefaultLabelId?: string;
  licenseLabelId?: string;
  licenseDefaultLabelId?: string;
};

const ConsentSignature = ({
  signatureData,
  addSignatureId,
  nameLabelId,
  nameDefaultLabelId,
  licenseLabelId,
  licenseDefaultLabelId,
  defaultAddSignatureId,
  defaultParentGuardianNoId,
  parentGuardianNoId,
  defaultEmailId,
  defaultRelationshipWithChildId,
  defaultViewUpdateId,
  defaultVerbalConsentId,
  defaultParentSignatureId,

  emailId,
  dateId,
  defaultDateId,
  isDateRequired,
  relationshipWithChildId,
  viewUpdateId,
  verbalConsentId,
  parentSignatureId,
  onSaveClick,
  verbalConsentValue,

  onVerbalConsentClick,
  staffSignature,
}: ConsentSignatureType) => {
  const [signClicked, setSignClicked] = useState<boolean>(false);

  const [signature, setSignature] = useState<SignatureDataType[]>([
    {
      id: '',
      name: '',
      relationship: '',
      email: '',
      signature: '',
      date: '',
      license: '',
      rowKey: '',
      partitionKey: '',
    },
  ]);
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const { lookups } = React.useContext(LookUpContext) as LookUpContextType;

  useEffect(() => {
    setSignature(signatureData);
  }, [signatureData]);

  const signatureCallBack = (response: SignatureStateType[], index: number) => {
    setClickedIndex(index);
    const updatedSignatures: SignatureDataType[] = [];
    signatureData.forEach((sign: SignatureDataType, signatureIndex: number) => {
      if (clickedIndex === signatureIndex) {
        const signatureResponseData: SignatureDataType = {
          id: sign.id,
          signature: response[0].signaturedata,
          date: signatureData[signatureIndex].date ?? null,
          partitionKey: sign.partitionKey,
          rowKey: sign.rowKey,
        };
        updatedSignatures.push(signatureResponseData);
      } else {
        const signatureResponseData: SignatureDataType = {
          id: sign.id,
          signature: sign.signature,
          date: sign.date,
          partitionKey: sign.partitionKey,
          rowKey: sign.rowKey,
        };
        updatedSignatures.push(signatureResponseData);
      }
    });

    setSignature(updatedSignatures);
  };

  const signatureSaveOrUPdate = (signed: string) => {
    if (onSaveClick) {
      const updatedSignatures = signature;
      updatedSignatures[clickedIndex] = {
        ...updatedSignatures[clickedIndex],
        partitionKey: '',
        rowKey: '',
        signature: signed,
      };
      onSaveClick(signed, clickedIndex);
    }
    setSignClicked(!signClicked);
  };
  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpenSnackBar(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };
  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <Box>
      <Grid container>
        <SnackBarComponent
          open={openSnackBar}
          handleClose={handleCloseSnakBar}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        {signatureData && signatureData.length > 0 ? (
          signatureData.slice(0, 2).map((data, index) => (
            <Grid item xs={6} key={data.id}>
              {!staffSignature ? (
                <Box className="width__ pt-md pb-md">
                  <Box className="flex__">
                    <MediumTypography
                      textColor="#008C82"
                      fontSize="14px"
                      fontweight={400}
                      labelid={parentGuardianNoId}
                      defaultlabel={defaultParentGuardianNoId}
                    />
                  </Box>
                  <Box className="width150px">
                    <Tooltip
                      title={
                        data.name !== null &&
                        data.name !== undefined &&
                        data.name.length > 10
                          ? data.name
                          : ''
                      }
                      followCursor
                      arrow={true}
                    >
                      <Box className="flex__">
                        <MediumTypography
                          textColor="#2A4241"
                          className="pt-sm"
                          label={data.name}
                          sxProps={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box className="flex__ pt-sm pb-sm">
                    <MediumTypography
                      labelid={relationshipWithChildId}
                      defaultlabel={defaultRelationshipWithChildId}
                      textColor="#97A6A5"
                    />

                    <Box className="width100px">
                      <Tooltip
                        title={
                          data.relationship && data.relationship.length > 4
                            ? lookups?.guardianType?.find(
                                (g) =>
                                  g.code.toUpperCase() ===
                                  data.relationship?.toUpperCase(),
                              )?.description || ''
                            : ''
                        }
                        followCursor
                        arrow={true}
                      >
                        <Box>
                          <MediumTypography
                            textColor="#2A4241"
                            className="pl-xs ellipsis"
                            label={
                              lookups?.guardianType?.find(
                                (g) =>
                                  g.code.toUpperCase() ===
                                  data.relationship?.toUpperCase(),
                              )?.description || '-'
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    <MediumTypography
                      labelid={emailId}
                      defaultlabel={defaultEmailId}
                      textColor="#97A6A5"
                      className="pl-sm"
                    />
                    <Box className="width200px">
                      <Tooltip
                        title={
                          data.email !== null &&
                          data.email !== undefined &&
                          data.email.length > 10
                            ? data.email
                            : ''
                        }
                        followCursor
                        arrow={true}
                      >
                        <Box>
                          <MediumTypography
                            textColor="#2A4241"
                            className="pl-xs "
                            label={
                              data.email === '' ||
                              data.email === null ||
                              data.email === undefined
                                ? '-'
                                : data.email
                            }
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box className="flex__">
                    {(data.rowKey === '' ||
                      data.rowKey === null ||
                      data.rowKey === undefined) &&
                    (data.signature === '' ||
                      data.signature === null ||
                      data.signature === undefined) ? (
                      <ButtonComponent
                        className="btn-primary btn-cancel signature_add_Box"
                        labelId={addSignatureId}
                        defaultLabelId={defaultAddSignatureId}
                        variantType="outlined"
                        onClick={() => {
                          setClickedIndex(index);
                          setSignClicked(!signClicked);
                        }}
                        disabled={
                          isOtherLanguage() ||
                          verbalConsentValue?.[index]?.verbal
                        }
                      />
                    ) : (
                      <ButtonComponent
                        className="btn-primary btn-cancel signature_view_Box"
                        labelId={viewUpdateId}
                        defaultLabelId={defaultViewUpdateId}
                        variantType="outlined"
                        LeftIcon={<Editsignature />}
                        onClick={() => {
                          setClickedIndex(index);
                          setSignClicked(!signClicked);
                        }}
                        disabled={
                          isOtherLanguage() ||
                          verbalConsentValue?.[index]?.verbal
                        }
                      />
                    )}
                    <Box className="ml-sm " sx={{ alignSelf: 'center' }}>
                      <Box className="flex__ alignItemCenter">
                        <CheckBoxComponent
                          ischecked={
                            verbalConsentValue?.[index]?.verbal ?? false
                          }
                          CheckHandleChange={(e) => {
                            if (onVerbalConsentClick) {
                              onVerbalConsentClick(e.target.checked, index);
                            }
                          }}
                          disabled={
                            signatureData[index].signature !== '' ||
                            signatureData[index].rowKey !== ''
                          }
                          labelid={verbalConsentId}
                          defaultlabelid={defaultVerbalConsentId}
                        />
                      </Box>
                      {(signatureData[index].rowKey !== '' ||
                        signatureData[index].signature !== '' ||
                        verbalConsentValue?.[index]?.verbal) &&
                        isDateRequired && (
                          <Box className="flex__">
                            <MediumTypography
                              labelid={dateId}
                              defaultlabel={defaultDateId}
                              textColor="#97A6A5"
                              className="pl-sm"
                            />
                            <Box className="width150px">
                              <Tooltip
                                title={
                                  data.date !== null
                                    ? moment(data.date, 'YYYY-MM-DDTHH:mm:ss')
                                        .utc(true)
                                        .local()
                                        .format('MM/DD/YYYY')
                                    : ''
                                }
                                followCursor
                                arrow={true}
                              >
                                <Box>
                                  <MediumTypography
                                    textColor="#2A4241"
                                    className="pl-xs "
                                    label={
                                      data.date !== null
                                        ? moment(
                                            data.date,
                                            'YYYY-MM-DDTHH:mm:ss',
                                          )
                                            .utc(true)
                                            .local()
                                            .format('MM/DD/YYYY')
                                        : ''
                                    }
                                    sxProps={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className="width__100 pt-xs pb-lg">
                  {(data.rowKey === '' ||
                    data.rowKey === null ||
                    data.rowKey === undefined) &&
                  (data.signature === '' ||
                    data.signature === null ||
                    data.signature === undefined) ? (
                    <ButtonComponent
                      className="btn-primary btn-cancel signature_add_Box"
                      labelId={addSignatureId}
                      defaultLabelId={defaultAddSignatureId}
                      variantType="outlined"
                      onClick={() => {
                        setClickedIndex(index);
                        setSignClicked(!signClicked);
                      }}
                      disabled={isOtherLanguage()}
                    />
                  ) : (
                    <ButtonComponent
                      className="btn-primary btn-cancel signature_view_Box"
                      labelId={viewUpdateId}
                      defaultLabelId={defaultViewUpdateId}
                      variantType="outlined"
                      LeftIcon={<Editsignature />}
                      onClick={() => {
                        setClickedIndex(index);
                        setSignClicked(!signClicked);
                      }}
                      disabled={isOtherLanguage()}
                    />
                  )}
                  <Box className="flex__ pt-lg">
                    <MediumTypography
                      labelid={nameLabelId}
                      defaultlabel={nameDefaultLabelId}
                      textColor="#97A6A5"
                    />

                    <Box className="width100px">
                      <Tooltip
                        title={
                          data.name !== undefined &&
                          data.name !== null &&
                          data.name.length > 10
                            ? data.name
                            : ''
                        }
                        followCursor
                        arrow={true}
                      >
                        <Box>
                          <MediumTypography
                            textColor="#2A4241"
                            className="pl-xs ellipsis"
                            label={
                              data.name === null ||
                              data.name === '' ||
                              data.name === undefined
                                ? '-'
                                : data.name
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>

                    <MediumTypography
                      labelid={licenseLabelId}
                      defaultlabel={licenseDefaultLabelId}
                      textColor="#97A6A5"
                      className="pl-sm"
                    />
                    <Box className="width200px">
                      <Tooltip
                        title={
                          data.license !== null &&
                          data.license &&
                          data.license.length > 10
                            ? data.license
                            : ''
                        }
                        followCursor
                        arrow={true}
                      >
                        <Box>
                          <MediumTypography
                            textColor="#2A4241"
                            className="pl-xs "
                            label={
                              data.license === null ||
                              data.license === '' ||
                              data.license === undefined
                                ? '-'
                                : data.license
                            }
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          ))
        ) : (
          <MediumTypography
            defaultlabel="-"
            labelid="-"
            className="pt-md pb-md width100px"
          />
        )}

        {signatureData && signatureData.length > 0 && signClicked && (
          <Box component="div">
            <SignatureComponent
              onCancelClick={() => {
                setSignClicked(false);
              }}
              signatureRowKey={signatureData[clickedIndex].rowKey ?? ''}
              signaturePartitionKey={
                signatureData[clickedIndex].partitionKey ?? ''
              }
              signatureSaveOrUPdate={signatureSaveOrUPdate}
              handleSuccess={handleSuccess}
              type={staffSignature ? 'Employee' : 'others'}
              signatureCallBack={(sign: SignatureStateType[]) => {
                signatureCallBack(sign, clickedIndex);
              }}
              signature={signature[clickedIndex].signature ?? ''}
              signClicked={signClicked}
              labelId={parentSignatureId}
              defaultLabelId={defaultParentSignatureId}
              resetCallBackForTypeEmployee={() => {
                setSignClicked(false);
                const updateSign = signature;
                updateSign[clickedIndex] = {
                  ...updateSign[clickedIndex],
                  signature: '',
                  partitionKey: '',
                  rowKey: '',
                };
              }}
              hideRemoveButton={true}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ConsentSignature;

import {
  AlertColor,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import VoiceRecognition, {
  turnOfMic,
} from '../../components/formlib/VoiceRecognition';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useContext, useEffect, useRef, useState } from 'react';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import {
  ProgressNoteType,
  ProgressNoteContact,
  ProgressNoteClient,
  ProgressNoteVisit,
  OptionType,
  SignatureStateType,
} from '../../utils/type';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { ReactComponent as Stop } from '../../assets/images/stopRed.svg';
import { ReactComponent as StopWhite } from '../../assets/images/stopWhite.svg';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import CustomRadioButton, {
  RadioButtonData,
} from '../../components/formlib/CustomRadioButton';
import {
  CreateDeleteProgressNote,
  EditProgressNote,
  UpdateGroupBillig,
  downloadProgressNote,
} from '../../services/progressNotes/ProgressNotesApi';
import moment from 'moment';
import { ReactComponent as Delete } from '../../assets/images/deleteAlertIcon.svg';
import './listeningDot.css';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ACTIVITY_APPROVAL_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  PROGRESS_NOTE_FACESHEET,
  PROGRESS_NOTE_LIST_ROUTE_NAME,
  SCHEDULER_ROUTE_NAME,
} from '../../routes/Routing';
import dayjs, { Dayjs } from 'dayjs';
import ModalPopup from '../../components/formlib/ModalPopup';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { IdType } from './ProgressNoteSetup';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import ThomProgram from '../../assets/images/thomchildlogo.svg';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as Undo } from '../../assets/images/undoIcon1.svg';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import _ from 'lodash';
import DurationPicker from '../../components/formlib/DurationPicker';
import {
  convertTimeDuration,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../utils/dateUtil';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { ReactComponent as DownloadIcon } from '../../assets/images/downloadIcon.svg';

import SelectedContacts from './SelectedContacts';
import Textinput from '../../components/formlib/Textinput';

import { checkPermissionForFeature } from '../../utils/checkPermission';
import CancelVisits from './CancelVisits';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { LookupItem } from '../../services/configApi/tcsLookups';
import { checkOffline } from '../../services/Offline';
import ShareDownloadModal from '../../components/formlib/modal/ShareDownloadModal';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import { ReactComponent as ShareIcon } from '../../assets/images/shareIcon.svg';
import { useIntl } from 'react-intl';
import { formatName } from '../../utils/nameUtils';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

type SelectedClientListType = {
  progressNoteId: string;
  clientId: string;
  clientName?: string;
  isSelected: boolean;
};

export type CreateDeleteProgressNoteType = {
  clients: SelectedClientListType[];
};

interface Code {
  id: string;
  codeId: number;
  code: string;
  description: string;
}

export interface CodeListType {
  settingCode: Code[];
  serviceCode: Code[];
}

const appointmentStageRadioData = [
  {
    value: 'evaluation',
    labelId: 'ProgressNote.Evaluation',
    defaultLabel: 'Evaluation',
  },
  {
    value: 'ifsp',
    labelId: 'ProgressNote.IFSP',
    defaultLabel: 'IFSP',
  },
  {
    value: 'other',
    labelId: 'otherText',
    defaultLabel: 'Other',
  },
];

const TeleHealthRadioData = [
  { value: 'audio', labelId: 'ProgressNote.Audio', defaultLabel: 'Audio' },
  { value: 'video', labelId: 'ProgressNote.Video', defaultLabel: 'Video' },
];

export type Data = {
  name: string;
  createdOn: string;
  createdBy: string;
  program: string;
  actions: string;
  modifiedBy: string;
  modifiedOn: string;
};

export type SelectedRowType = {
  id: string;
  clientId: string;
};

const propertiesToCheck = [
  'individualHrs',
  'coTreatmentHrs',
  'tpc',
  'clientLocationType',
  'telehealth',
  'telehealthType',
  'providerLocationType',
  'visits',
  'selectedContacts',
  'othersPresentInVisit',
  'notes',
  'signature',
];

let hasChanged = false;

const ProgressNote = (props: IdType) => {
  const [validUser, setValidUser] = useState<boolean>(false);
  const [startDuration, setStartDuration] = useState<number>(0);
  const [endDuration, setEndDuration] = useState<number>(15);
  const [localAppointmentDate, setLocalAppointmentDate] = useState<string>('');
  const [localAppointmentTime, setLocalAppointmentTime] = useState<string>('');
  const [noteError, setNoteError] = useState<boolean>(false);
  const [signClicked, setSignClicked] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [multiSelectContact, setMultiSelectContact] = useState<
    ProgressNoteContact[]
  >([]);
  const [attendeeName, setAttendeeName] = useState<string>('');
  const [attendeeLicenseNo, setAttendeeLicenseNo] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');

  const [initialData, setInitialData] = useState<ProgressNoteType | null>(null);
  const [origainalInitialData, setOrigainalInitialData] =
    useState<ProgressNoteType | null>(null);
  const [clientLocation, setClientLocation] = useState<OptionType[]>([]);

  const [serviceCode, setServiceCode] = useState<LookupItem[]>([]);
  const [settingCode, setSettingCode] = useState<LookupItem[]>([]);

  const [firstEntry, setFirstEntry] = useState<boolean>(true);
  const [openClientModal, setOpenClientModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<
    SelectedClientListType[]
  >([]);
  const [shareClientId, setShareClientId] = useState<string>('');
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [clientCheckList, setClientCheckList] = useState<
    SelectedClientListType[]
  >([]);
  const [durationClickedIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(true);
  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [clientCount, setClientCount] = useState<number>(0);
  const [isAnyDurationZero, setIsAnyDurationZero] = useState<boolean>(false);
  const [offline, setOffline] = useState<boolean>(false);
  const [screenFrom, setScreenFrom] = useState<string>('');
  const [openCancelVisitModal, setOpenCancelVisitModal] =
    useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [showGroupBillingBlock, setShowGroupBillingBlock] =
    useState<boolean>(false);
  const [groupBillingStartTime, setGroupBillingStartTime] = useState<
    string | null
  >('');
  const [groupBillingDuration, setGroupBillingDuration] = useState<string>('');
  const [groupBillingCancelModal, setGroupBillingCancelModal] =
    useState<boolean>(false);
  const [isCotxForGivenMonth, setIsCotxForGivenMonth] =
    useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const rctl = useIntl();

  useEffect(() => {
    if (props.clientIdsFromActivity) {
      const selectedData = props.progressNoteData.clients.filter(
        (data) => data.isSelected,
      );
      const index = selectedData.findIndex(
        (data) => data.client.clientId === props.clientIdsFromActivity[0],
      );
      setOpenAccordianIndex(index);
    }
  }, [props.clientIdsFromActivity]);

  const formikRef = useRef<FormikProps<ProgressNoteType>>(null);
  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setServiceCode(() => {
      return lookups.serviceCode.map((e) => {
        return e;
      });
    });

    setSettingCode(() => {
      return lookups.settingsCode.map((e) => {
        return e;
      });
    });

    setClientLocation(() => {
      return lookups.location.map((e) => {
        return { id: e.code, label: e.description };
      });
    });
  }, [lookups]);
  useEffect(() => {
    if (
      (initialData &&
        initialData.clients[openAccordianIndex]?.status === 'Canceled') ||
      (initialData && initialData.clients[openAccordianIndex]?.cancelReason) ||
      formikRef.current?.values.clients[openAccordianIndex]?.cancelReason
    ) {
      setIsScreenLocked(true);
    } else {
      setIsScreenLocked(false);
    }
    const initialSelectedContacts = formikRef.current?.values.clients[
      openAccordianIndex
    ].selectedContacts.map(
      (selectedId) =>
        initialData &&
        initialData.clients[openAccordianIndex].contact.find(
          (contact) => contact.contactId === selectedId,
        ),
    );
    if (initialSelectedContacts) {
      setMultiSelectContact(
        initialSelectedContacts.map((contact) => {
          if (!contact) return {};
          return {
            contactId: contact.contactId,
            email: contact.email,
            homePhoneNumber: contact.homePhoneNumber,
            isPrimary: contact.isPrimary,
            name: contact.name ?? 'Referral Contact',
            relationshipToClient: contact.relationshipToClient,
            type: contact.type,
          };
        }) as ProgressNoteContact[],
      );
    }
  }, [
    initialData && initialData.clients[openAccordianIndex]?.contact,
    openAccordianIndex,
  ]);

  useEffect(() => {
    const getOfflineMode = async () => {
      const offlineFlag = await checkOffline();
      setOffline(offlineFlag);
    };
    getOfflineMode();

    return () => {
      turnOfMic();
    };
  }, []);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const navigate = useNavigate();
  useEffect(() => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    const attendee = props.progressNoteData.clients[
      openAccordianIndex
    ].attendeeList?.find((attendeeList) => attendeeList.attendeeId === userId);
    if (
      attendee?.attendeeId === props.clickedAttendeeId &&
      checkPermissionForFeature('backend.progress_note', 'editPermission')
    ) {
      setOpenClientModal(true);
      setValidUser(true);
    }

    const isAllNotSelected = props.progressNoteData.clients.every(
      (item) => !item.isSelected,
    );
    if (isAllNotSelected) {
      const mapClientData = (clients: ProgressNoteClient[]) =>
        clients.map((item) => ({
          progressNoteId: item.progressNoteId ? item.progressNoteId : '',
          clientId: item.client.clientId,
          isSelected: true,
          clientName: formatName(
            item.client.firstName,
            item.client.middleName,
            item.client.lastName,
            item.client.suffix,
          ),
        }));
      setSelectedClient(mapClientData(props.progressNoteData.clients));
      setClientCheckList(mapClientData(props.progressNoteData.clients));
    } else {
      setSelectedClient(
        props.progressNoteData.clients
          .filter((item) => item.isSelected)
          .map((item) => ({
            progressNoteId: item.progressNoteId ? item.progressNoteId : '',
            clientId: item.client.clientId,
            clientName: formatName(
              item.client.firstName,
              item.client.middleName,
              item.client.lastName,
              item.client.suffix,
            ),
            isSelected: true,
          })),
      );

      setClientCheckList(
        props.progressNoteData.clients.map((item) => ({
          progressNoteId: item.progressNoteId ? item.progressNoteId : '',
          clientId: item.client.clientId,
          clientName: formatName(
            item.client.firstName,
            item.client.middleName,
            item.client.lastName,
            item.client.suffix,
          ),
          isSelected: item.isSelected ? true : false,
        })),
      );
    }
    setClientCount(props.progressNoteData.clients.length);
    setScreenFrom(props.screenFrom);

    const currentAttendee =
      props.progressNoteData.clients[0].attendeeList &&
      props.progressNoteData.clients[0].attendeeList.filter(
        (item) => item.attendeeId === props.clickedAttendeeId,
      );
    if (currentAttendee && currentAttendee.length > 0) {
      setAttendeeName(
        currentAttendee[openAccordianIndex]?.firstName +
          ' ' +
          currentAttendee[openAccordianIndex].lastName,
      );
      setAttendeeLicenseNo(currentAttendee[openAccordianIndex]?.licenseNo);
    } else {
      const client = props.progressNoteData?.clients?.[openAccordianIndex];
      const attendees = client?.attendeeList?.[0];

      if (attendees) {
        setAttendeeName(`${attendees.firstName} ${attendees.lastName}`);
        setAttendeeLicenseNo(attendees.licenseNo);
      } else {
        setAttendeeName('');
        setAttendeeLicenseNo('');
      }
    }
    const localDate = moment(
      props.progressNoteData.clients[openAccordianIndex].appointmentDate,
      'YYYY-MM-DDTHH:mm',
    )
      .utc(true)
      .local()
      .format('MM/DD/YYYY');
    setLocalAppointmentDate(localDate);
    const localTime = moment(
      props.progressNoteData.clients[0].appointmentDate,
      'YYYY-MM-DDTHH:mm',
    )
      .utc(true)
      .local()
      .format('HH:mm A');
    setLocalAppointmentTime(localTime);
    setGroupBillingDuration(
      convertTimeDuration(
        props.progressNoteData.groupBilling?.groupBillingDuration || '00:00',
        true,
      ),
    );
    setGroupBillingStartTime(
      props.progressNoteData.groupBilling?.groupBillingStartTime
        ? moment(
            props.progressNoteData.groupBilling?.groupBillingStartTime,
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
          )
            .utc(true)
            .local()
            .format('HH:mm')
        : '',
    );
    const mapClientsData = (clients: ProgressNoteClient[]) => {
      return clients
        .filter((item) => item.isSelected)
        .map((item, index) => ({
          ...item,
          clientLocationType:
            item.status === 'Pending' &&
            (item.appointmentType === 'appointmentType1' ||
              item.appointmentType === 'appointmentType2' ||
              item.appointmentType === 'appointmentType3' ||
              item.appointmentType === 'appointmentType4')
              ? 'HOME'
              : item.clientLocationType,
          coTreatmentHrs:
            item.coTreatmentHrs === '' ? '00.00' : item.coTreatmentHrs,
          individualHrs:
            item.individualHrs === ''
              ? convertTimeDuration(item.visits[0].duration, true)
              : item.individualHrs,
          clientIndex: index,
          visits: mapVisitsData(item.visits, index),
          appointmentId: item.appointmentId,
        }));
    };

    const mapVisitsData = (
      visits: ProgressNoteVisit[],
      clientIndex: number,
    ) => {
      return visits.map((visit) => ({
        ...visit,
        appointmentStage:
          visit.appointmentStage === '' || visit.appointmentStage === null
            ? 'other'
            : visit.appointmentStage,
        duration: convertTimeDuration(visit.duration, true),
        clientVisitsIndex: clientIndex,
        startTime: moment(visit.startTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          .utc(true)
          .local()
          .format('HH:mm'),
      }));
    };

    setOrigainalInitialData(() => ({
      programDetails: {
        faxNumber: props.progressNoteData.programDetails.faxNumber,
        imageUrl:
          props.progressNoteData.programDetails.imageUrl !== ''
            ? props.progressNoteData.programDetails.imageUrl
            : ThomProgram,
        name: props.progressNoteData.programDetails.name,
        phoneNumber: props.progressNoteData.programDetails.phoneNumber,
        addressLine1: props.progressNoteData.programDetails.addressLine1,
        addressLine2: props.progressNoteData.programDetails.addressLine2,
        email: props.progressNoteData.programDetails.email,
        website: props.progressNoteData.programDetails.website,
      },
      clients: mapClientsData(props.progressNoteData.clients),
    }));
    setInitialData(() => ({
      programDetails: {
        faxNumber: props.progressNoteData.programDetails.faxNumber,
        imageUrl:
          props.progressNoteData.programDetails.imageUrl !== ''
            ? props.progressNoteData.programDetails.imageUrl
            : ThomProgram,
        name: props.progressNoteData.programDetails.name,
        phoneNumber: props.progressNoteData.programDetails.phoneNumber,
        addressLine1: props.progressNoteData.programDetails.addressLine1,
        addressLine2: props.progressNoteData.programDetails.addressLine2,
        email: props.progressNoteData.programDetails.email,
        website: props.progressNoteData.programDetails.website,
      },
      clients: mapClientsData(props.progressNoteData.clients),
    }));
  }, []);

  const handelCoTreatmentDuration = (index: number, value?: boolean) => {
    setStartDuration(-1);
    setEndDuration(-1);
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, idx) => {
        if (idx === openAccordianIndex) {
          let totalCoTxHrs = 0;
          let totalCoTxMins = 0;
          let totalIndividualHrs = 0;
          let totalIndividualMins = 0;

          client.visits.forEach((visit) => {
            const visitDurationHrs = parseInt(
              visit.duration?.split('.')[0] || '00',
            );
            const visitDurationMins = parseInt(
              visit.duration?.split('.')[1] || '00',
            );

            if (visit.coTx) {
              totalCoTxHrs += visitDurationHrs;
              totalCoTxMins += visitDurationMins;
            } else {
              totalIndividualHrs += visitDurationHrs;
              totalIndividualMins += visitDurationMins;
            }
          });

          // Adjust total co-treatment minutes
          if (totalCoTxMins >= 100) {
            const carryOverHours = Math.floor(totalCoTxMins / 100);
            totalCoTxHrs += carryOverHours;
            totalCoTxMins %= 100;
          }

          // Adjust total individual minutes
          if (totalIndividualMins >= 100) {
            const carryOverHours = Math.floor(totalIndividualMins / 100);
            totalIndividualHrs += carryOverHours;
            totalIndividualMins %= 100;
          }

          // Format the result
          const totalCoTx = `${totalCoTxHrs
            .toString()
            .padStart(2, '0')}.${totalCoTxMins.toString().padStart(2, '0')}`;

          const totalIndividual = `${totalIndividualHrs
            .toString()
            .padStart(2, '0')}.${totalIndividualMins
            .toString()
            .padStart(2, '0')}`;

          return {
            ...client,
            coTreatmentHrs: totalCoTx,
            individualHrs: totalIndividual,
            visits: client.visits.map((visit, i) => {
              if (i === index) {
                return {
                  ...visit,
                  coTx: value,
                };
              }
              return visit;
            }),
          };
        }
        return client;
      }),
    );
  };

  useEffect(() => {
    handelCoTreatmentDuration(
      durationClickedIndex,
      formikRef.current?.values.clients[openAccordianIndex].visits[
        durationClickedIndex
      ]?.coTx,
    );
  }, [
    startDuration,
    endDuration,
    initialData && initialData.clients[openAccordianIndex]?.visits,
    formikRef.current?.values.clients[openAccordianIndex]?.visits[
      durationClickedIndex
    ]?.duration,
  ]);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const closeDurationModal = () => {
    const status =
      initialData && initialData.clients[openAccordianIndex].status;
    if ((status === 'In Progress' || status === 'Pending') && validUser) {
      turnOfMic();
    }
  };

  const onCancelClick = () => {
    setSignClicked(!signClicked);
  };

  const onSignatureSave = async (sign: string) => {
    setSignClicked(!signClicked);
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          return {
            ...client,
            signature: {
              partitionKey: '',
              rowKey: '',
              signatureValue: sign,
            },
          };
        } else {
          return client;
        }
      }),
    );
  };

  const signatureCallBack = (response: SignatureStateType[]) => {
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          return {
            ...client,
            signature: {
              partitionKey: response[0].partitionkey,
              rowKey: response[0].rowkey,
              signatureValue: response[0].signaturedata,
            },
          };
        } else {
          return client;
        }
      }),
    );
  };

  const clearSignature = () => {
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          let clientStatus = '';

          if (client.status === 'Pending') {
            clientStatus = 'Pending';
          } else if (client.status === 'In Progress') {
            clientStatus = 'In Progress';
          } else if (
            client.status === 'Canceled' &&
            client.visits[0].serviceCode.label !== ''
          ) {
            clientStatus = 'In Progress';
          } else if (
            client.status === 'Canceled' &&
            client.visits[0].serviceCode.label === ''
          ) {
            clientStatus = 'Pending';
          } else {
            clientStatus = 'In Progress';
          }
          return {
            ...client,
            status: clientStatus,
            signature: {
              partitionkey: '',
              rowkey: '',
              signatureValue: '',
            },
          };
        } else {
          return client;
        }
      }),
    );

    const programDetails = formikRef.current?.values.programDetails;
    const clients = formikRef.current?.values.clients;
    if (programDetails && clients) {
      setInitialData({
        programDetails: programDetails,
        clients: clients.map((client, index) => {
          if (index === openAccordianIndex) {
            let clientStatus = '';

            if (client.status === 'Pending') {
              clientStatus = 'Pending';
            } else if (client.status === 'In Progress') {
              clientStatus = 'In Progress';
            } else if (client.status === 'Canceled') {
              clientStatus =
                client.visits[0].serviceCode.label !== ''
                  ? 'In Progress'
                  : 'Pending';
            } else {
              clientStatus = 'In Progress';
            }
            return {
              ...client,
              status: clientStatus,
              signature: {
                partitionkey: '',
                rowkey: '',
                signatureValue: '',
              },
            };
          } else {
            return client;
          }
        }),
      });
    }
    setOpen(true);
    setToastrVariable('success');
    setToastrId('ProgressNote.signatureResetedsuccess');
    setToastrDefaultMessage('Signature Reset Successfully');
    setSignClicked(!signClicked);
  };
  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const checkNoteEntered = () => {
    if (initialData && initialData.clients[0].notes.length > 8000) {
      setNoteError(true);
    } else {
      setNoteError(false);
    }
  };

  const validationSchema = yup.object().shape({
    clients: yup.array().of(
      yup.object().shape({
        clientIndex: yup.number(),
        telehealth: yup.boolean().nullable(),
        notes: yup.string().when('clientIndex', {
          is: (clientIndex: number) => clientIndex === openAccordianIndex,
          then: yup.string().required('Note is required'),
          otherwise: yup.string(),
        }),
        selectedContacts: yup.array().of(yup.string()),
        individualHrs: yup.string(),
        coTreatementHrs: yup.string(),
        telehealthType: yup.string().when('telehealth', {
          is: true,
          then: yup.string().required('Telehealth type is required'),
          otherwise: yup.string(),
        }),
        clientLocationType: yup.string().when('clientIndex', {
          is: (clientIndex: number) => clientIndex === openAccordianIndex,
          then: yup.string().required('Client location type is required'),
          otherwise: yup.string(),
        }),
        providerLocationType: yup.string().when('telehealth', {
          is: true,
          then: yup.string().required('Provider location type is required'),
          otherwise: yup.string(),
        }),
        visits: yup.array().of(
          yup.object().shape({
            clientVisitsIndex: yup.number(),
            appointmentStage: yup.string().when('clientVisitsIndex', {
              is: (clientVisitsIndex: number) =>
                clientVisitsIndex === openAccordianIndex,
              then: yup.string().nullable(),
              otherwise: yup.string().nullable(),
            }),
            startTime: yup.string().when('clientVisitsIndex', {
              is: (clientVisitsIndex: number) =>
                clientVisitsIndex === openAccordianIndex,
              then: yup
                .string()
                .required('Start time is required')
                .typeError('Start time is required'),
              otherwise: yup.string().nullable(),
            }),
            coTx: yup.boolean().nullable(),
            duration: yup.string().nullable(),
            serviceCode: yup.object().when('clientVisitsIndex', {
              is: (clientVisitsIndex: number) =>
                clientVisitsIndex === openAccordianIndex,
              then: yup.object().shape({
                id: yup.string().required('Service code is required'),
                label: yup.string().required('Service label is required'),
              }),
              otherwise: yup.object().shape({
                id: yup.string(),
                label: yup.string(),
              }),
            }),
            settingCode: yup.object().when('clientVisitsIndex', {
              is: (clientVisitsIndex: number) =>
                clientVisitsIndex === openAccordianIndex,
              then: yup.object().shape({
                id: yup.string().required('Setting code is required'),
                label: yup.string().required('Setting label is required'),
              }),
              otherwise: yup.object().shape({
                id: yup.string(),
                label: yup.string(),
              }),
            }),
            coTreatPartnerName: yup
              .string()
              .when(['clientVisitsIndex', 'coTx'], {
                is: (clientVisitsIndex: number, coTx: boolean) =>
                  clientVisitsIndex === openAccordianIndex && coTx,
                then: yup.string().required('Clinician ia required'),
                otherwise: yup.string().nullable(),
              }),
          }),
        ),
      }),
    ),
  });
  const handleClientSaveCheckList = () => {
    setOpenAccordianIndex(0);
    formikRef.current?.setTouched({}, false);
    toggleLoader(true);
    setOpenClientModal(!openClientModal);
    setSelectedClient(clientCheckList.filter((item) => item.isSelected));
    CreateDeleteProgressNote(
      {
        clients: clientCheckList.map((item) => ({
          clientId: item.clientId,
          progressNoteId: item.progressNoteId,
          isSelected: item.isSelected,
        })),
      },
      props.clickedAttendeeId,
      props.progressNoteData.clients[openAccordianIndex].appointmentId,
    )
      .then(() => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('success');
        setToastrId('ProgressNoteCreatedSuccess');
        setToastrDefaultMessage('Progress note created successfully');
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ProgressNoteFailedToCreateError');
          setToastrDefaultMessage('Failed to create progress note');
        }
      });
  };

  const handleClientCancelCheckList = () => {
    if (firstEntry) {
      navigate(SCHEDULER_ROUTE_NAME, {
        state: {
          screenFrom: screenFrom,
        },
      });
    } else {
      setClientCheckList((prev) => {
        return prev.map((item) => {
          const isSelected = selectedClient.some(
            (client) => client.clientId === item.clientId,
          );
          return { ...item, isSelected };
        });
      });
      setOpenClientModal(false);
    }
  };

  useEffect(() => {
    if (selectedClient && selectedClient.length > 0 && initialData) {
      const updatedData = props.progressNoteData.clients.map((client) => {
        const matchingClient = selectedClient.find(
          (newClient) => newClient.clientId === client.client.clientId,
        );
        return matchingClient;
      });
      const updatedClients: ProgressNoteClient[] = [];
      let clientIndex: number;
      if (formikRef.current?.values?.clients === undefined) {
        updatedData.forEach((client) => {
          props.progressNoteData.clients.forEach((item) => {
            if (item.client.clientId === client?.clientId) {
              updatedClients.push({
                client: item.client,
                appointmentDate: item.appointmentDate,
                cancelReason: item.cancelReason,
                tpc: item.tpc,
                attendeeLicenseNo: item.attendeeLicenseNo,
                actualAppointmentTime: item.actualAppointmentTime,
                attendeeName: item.attendeeName,
                clientLocationType:
                  item.status === 'Pending' &&
                  (item.appointmentType === 'appointmentType1' ||
                    item.appointmentType === 'appointmentType2' ||
                    item.appointmentType === 'appointmentType3' ||
                    item.appointmentType === 'appointmentType4')
                    ? 'HOME'
                    : item.clientLocationType,
                coTreatmentHrs:
                  item.coTreatmentHrs === '' ? '00.00' : item.coTreatmentHrs,
                individualHrs:
                  item.individualHrs === ''
                    ? convertTimeDuration(item.visits[0].duration, true)
                    : item.individualHrs,
                notes: item.notes,
                providerLocationType: item.providerLocationType,
                selectedContacts: item.selectedContacts,
                signature: item.signature,
                contact: item.contact,
                status: item.status,
                telehealth: item.telehealth,
                telehealthType: item.telehealthType,
                visits: item.visits.map((visit) => {
                  return {
                    coTx: visit.coTx,
                    asessmentHomeVisit: visit.asessmentHomeVisit,
                    ifspMeeting: visit.ifspMeeting,
                    supervisionCoTxHV: visit.supervisionCoTxHV,
                    appointmentStage:
                      visit.appointmentStage === '' ||
                      visit.appointmentStage === null
                        ? 'other'
                        : visit.appointmentStage,
                    startTime: visit.startTime,
                    duration: convertTimeDuration(visit.duration, true),
                    serviceCode: visit.serviceCode,
                    settingCode: visit.settingCode,
                    coTreatPartnerName: visit.coTreatPartnerName,
                  };
                }),
                activitySheetStatus: item.activitySheetStatus,
                attendeeList: item.attendeeList,
                clientIndex: item.clientIndex,
                forms: item.forms,
                isSelected: item.isSelected,
                progressNoteId: item.progressNoteId,
                appointmentId: item.appointmentId,
                othersPresentInVisit: item.othersPresentInVisit,
                appointmentType: item.appointmentType,
              });
            }
          });
        });
      } else {
        const selectedIds = selectedClient.map((item) => item.clientId);
        selectedIds.forEach((id, index) => {
          const matchingClient = formikRef.current?.values?.clients.find(
            (client) => client.client.clientId === id,
          );
          if (matchingClient) {
            updatedClients.push(matchingClient);
          } else {
            clientIndex = props.progressNoteData.clients.findIndex(
              (item) => item.client.clientId === id,
            );
            updatedClients.push({
              client:
                clientIndex > -1
                  ? props.progressNoteData.clients[clientIndex].client
                  : props.progressNoteData.clients[index].client,
              visits: [
                {
                  coTx: false,
                  asessmentHomeVisit: false,
                  ifspMeeting: false,
                  supervisionCoTxHV: false,
                  appointmentStage: 'other',
                  startTime: '',
                  duration: convertTimeDuration(
                    props.progressNoteData.clients[clientIndex].visits[0]
                      .duration,
                    true,
                  ),
                  serviceCode: {
                    id: '',
                    label: '',
                  },
                  settingCode: {
                    id: '',
                    label: '',
                  },
                  coTreatPartnerName: '',
                },
              ],
              selectedContacts: [],
              contact:
                clientIndex > -1
                  ? props.progressNoteData.clients[clientIndex].contact
                  : props.progressNoteData.clients[index].contact,
              attendeeList: props.progressNoteData.clients[0].attendeeList,
              actualAppointmentTime:
                props.progressNoteData.clients[0].actualAppointmentTime,
              cancelReason: '',
              progressNoteId: '',
              isSelected: false,
              tpc: false,
              status: 'Pending',
              activitySheetStatus: 'Not Submitted',
              appointmentDate: '',
              appointmentId:
                props.progressNoteData.clients[clientIndex].appointmentId,
              appointmentType: '',
              othersPresentInVisit: '',
              individualHrs: convertTimeDuration(
                props.progressNoteData.clients[clientIndex].visits[0].duration,
                true,
              ),

              coTreatmentHrs: '00.00',
              telehealth: false,
              telehealthType: '',
              clientLocationType: '',
              providerLocationType: '',
              forms: [],
              notes: '',
              attendeeLicenseNo: '',
              attendeeName: '',
              signature: {
                rowkey: '',
                partitionkey: '',
                signatureValue: '',
              },
            });
          }
        });
      }

      setOrigainalInitialData(() => ({
        programDetails: props.progressNoteData.programDetails,
        clients: updatedClients.map((client, index) => {
          return {
            ...client,
            clientIndex: index,
            visits: client.visits.map((visit, i) => ({
              ...visit,
              clientVisitsIndex: index,
              startTime: moment(
                props.progressNoteData.clients[index]?.visits[i]?.startTime,
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
              )
                .utc(true)
                .local()
                .format('HH:mm'),
            })),
          };
        }),
      }));
      setInitialData(() => ({
        programDetails: props.progressNoteData.programDetails,
        clients: updatedClients.map((client, index) => {
          return {
            ...client,
            clientIndex: index,
            visits: client.visits.map((visit, i) => ({
              ...visit,
              clientVisitsIndex: index,
              startTime: moment(
                props.progressNoteData.clients[index]?.visits[i]?.startTime,
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
              )
                .utc(true)
                .local()
                .format('HH:mm'),
            })),
          };
        }),
      }));
      formikRef.current?.setFieldValue(
        'clients',
        updatedClients.map((client, index) => ({
          ...client,
          clientIndex: index,
          visits: client.visits.map((visit, i) => ({
            ...visit,
            clientVisitsIndex: index,
            startTime: moment(
              props.progressNoteData.clients[index]?.visits[i]?.startTime,
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
            )
              .utc(true)
              .local()
              .format('HH:mm'),
          })),
        })),
      );
    }
  }, [selectedClient]);
  const handleClientCheckBoxClick = (e: boolean, id: string) => {
    setClientCheckList((prev) => {
      return prev.map((item) => {
        if (item.clientId === id) {
          return { ...item, isSelected: e };
        }
        return item;
      });
    });
  };

  const navigateTo = () => {
    if (screenFrom === 'EmployeeActivity') {
      if (props.progressNoteData?.clients[0]?.attendeeList) {
        const firstName =
          props.progressNoteData.clients[0].attendeeList[0]?.firstName;
        const lastName =
          props.progressNoteData.clients[0].attendeeList[0]?.lastName;
        navigate(ACTIVITY_SHEET_ROUTE_NAME, {
          state: {
            id: props.clickedAttendeeId,
            date: props.selectedDateInReviewActivityScreen,
            firstName: firstName,
            lastName: lastName,
          },
        });
      }
    } else if (screenFrom === 'ApprovalActivity') {
      if (props.progressNoteData?.clients[0]?.attendeeList) {
        const firstName =
          props.progressNoteData.clients[0].attendeeList[0]?.firstName;
        const lastName =
          props.progressNoteData.clients[0].attendeeList[0]?.lastName;
        navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
          state: {
            id: props.clickedAttendeeId,
            date: props.selectedDateInReviewActivityScreen,
            firstName: firstName,
            lastName: lastName,
          },
        });
      }
    } else if (screenFrom === 'schedulerEvent') {
      navigate(SCHEDULER_ROUTE_NAME, {
        state: {
          screenFrom: screenFrom,
        },
      });
    } else if (screenFrom === 'ProgressNoteList') {
      navigate(PROGRESS_NOTE_LIST_ROUTE_NAME);
    } else if (screenFrom === 'ProgressNoteFacesheet') {
      navigate(PROGRESS_NOTE_FACESHEET);
    } else {
      history.back();
    }
  };

  const handleOnSubmit = async (value: ProgressNoteType) => {
    if (value.clients?.[openAccordianIndex].cancelReason) {
      setIsScreenLocked(true);
    } else {
      setIsScreenLocked(false);
    }
    const localAppointmentDateToUtc = moment(
      `${localAppointmentDate} ${localAppointmentTime}`,
      'MM/DD/YYYY hh:mm',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm');

    const paramsData: ProgressNoteType = {
      clients: [
        {
          client: value.clients[openAccordianIndex].client,
          status:
            value.clients[openAccordianIndex].signature.signatureValue !== '' &&
            value.clients[openAccordianIndex].cancelReason !== ''
              ? 'Canceled'
              : value.clients[openAccordianIndex].signature.signatureValue !==
                  '' && value.clients[openAccordianIndex].cancelReason === ''
              ? 'Completed'
              : 'In Progress',
          appointmentDate: localAppointmentDateToUtc,
          appointmentId: value.clients[openAccordianIndex].appointmentId,
          appointmentType: value.clients[openAccordianIndex].appointmentType,
          othersPresentInVisit:
            value.clients[openAccordianIndex].othersPresentInVisit,
          actualAppointmentTime:
            value.clients[openAccordianIndex].actualAppointmentTime,
          individualHrs: value.clients[openAccordianIndex].individualHrs,
          tpc: value.clients[openAccordianIndex].tpc,
          coTreatmentHrs: value.clients[openAccordianIndex].coTreatmentHrs,
          telehealth: value.clients[openAccordianIndex].telehealth,
          telehealthType: value.clients[openAccordianIndex].telehealthType,
          clientLocationType:
            value.clients[openAccordianIndex].clientLocationType,
          providerLocationType:
            value.clients[openAccordianIndex].providerLocationType,
          visits: value.clients[openAccordianIndex].visits.map((visit) => ({
            appointmentStage: visit.appointmentStage,
            coTx: visit.coTx,
            asessmentHomeVisit: visit.asessmentHomeVisit,
            ifspMeeting: visit.ifspMeeting,
            supervisionCoTxHV: visit.supervisionCoTxHV,
            startTime: moment(
              `${localAppointmentDate} ${visit.startTime}`,
              'MM/DD/YYYY HH:mm',
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            duration: convertTimeDuration(visit.duration, false),
            serviceCode: visit.serviceCode,
            settingCode: visit.settingCode,
            coTreatPartnerName: visit.coTreatPartnerName,
          })),
          selectedContacts: value.clients[openAccordianIndex].selectedContacts,
          contact: value.clients[openAccordianIndex].contact,
          cancelReason: value.clients[openAccordianIndex].cancelReason,
          notes: value.clients[openAccordianIndex].notes,
          signature: {
            partitionkey:
              value.clients[openAccordianIndex].signature.partitionkey,
            rowkey: value.clients[openAccordianIndex].signature.rowkey,
            signatureValue:
              value.clients[openAccordianIndex].signature.signatureValue,
          },
          attendeeName: value.clients[openAccordianIndex].attendeeName,
          attendeeLicenseNo:
            value.clients[openAccordianIndex].attendeeLicenseNo,
          attendeeList: value.clients[openAccordianIndex].attendeeList,
        },
      ],
      programDetails: value.programDetails,
    };

    if (!noteError) {
      toggleLoader(true);
      EditProgressNote(paramsData, props.clickedAttendeeId, props.eventId)
        .then(() => {
          setOrigainalInitialData({
            programDetails: value.programDetails,
            clients: value.clients.map((client, index) => {
              if (index === openAccordianIndex) {
                return {
                  ...client,
                  status:
                    value.clients[openAccordianIndex].signature
                      .signatureValue !== '' &&
                    value.clients[openAccordianIndex].cancelReason !== ''
                      ? 'Canceled'
                      : value.clients[openAccordianIndex].signature
                          .signatureValue !== '' &&
                        value.clients[openAccordianIndex].cancelReason === ''
                      ? 'Completed'
                      : 'In Progress',
                };
              } else {
                return client;
              }
            }),
          });
          formikRef.current?.setFieldValue(
            'clients',
            formikRef.current?.values.clients.map((client, index) => {
              if (index === openAccordianIndex) {
                return {
                  ...client,
                  status:
                    value.clients[openAccordianIndex].signature
                      .signatureValue !== '' &&
                    value.clients[openAccordianIndex].cancelReason !== ''
                      ? 'Canceled'
                      : value.clients[openAccordianIndex].signature
                          .signatureValue !== '' &&
                        value.clients[openAccordianIndex].cancelReason === ''
                      ? 'Completed'
                      : 'In Progress',
                };
              } else {
                return client;
              }
            }),
          );

          setInitialData({
            programDetails: value.programDetails,
            clients: value.clients.map((client, index) => {
              if (index === openAccordianIndex) {
                return {
                  ...client,
                  status:
                    value.clients[openAccordianIndex].signature
                      .signatureValue !== '' &&
                    value.clients[openAccordianIndex].cancelReason !== ''
                      ? 'Canceled'
                      : value.clients[openAccordianIndex].signature
                          .signatureValue !== '' &&
                        value.clients[openAccordianIndex].cancelReason === ''
                      ? 'Completed'
                      : 'In Progress',
                };
              } else {
                return client;
              }
            }),
          });
          setOpen(true);
          setToastrVariable('success');
          setToastrId('ProgressNote.savedsuccess');
          setToastrDefaultMessage('Data Saved Successfully');
          toggleLoader(false);
          if (
            formikRef.current?.values.clients &&
            formikRef.current?.values.clients.length > 1 &&
            formikRef.current?.values.clients[openAccordianIndex]
              .cancelReason !== '' &&
            formikRef.current?.values.clients[openAccordianIndex].signature
              .signatureValue !== ''
          ) {
            setOpenAccordian(false);
          }
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1013) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.NoAppointmentError');
              setTimeout(navigateTo, 2000);
            } else if (response.code === 1012) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.NoAttendeeError');
              setTimeout(navigateTo, 2000);
            } else if (response.code === 1055) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.EnrollmentIdError');
            } else if (response.code === 1010) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.NoClientError');
              setTimeout(navigateTo, 2000);
            } else if (response.code === 1062) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.IfspNotCompleted');
            } else if (response.code === 1095) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.WaiverInsufficient');
            } else if (response.code === 1096) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.WaiverInsufficient');
            } else {
              setToastrId('ProgressNote.err');
              setToastrDefaultMessage(response.message);
            }
          }
        });
    }
  };

  const handlePendingSubmit = (value: ProgressNoteType) => {
    toggleLoader(true);
    const clientData = value.clients[openAccordianIndex];

    setIsScreenLocked(clientData.cancelReason !== '');

    const localAppointmentDateToUtc = moment(
      `${localAppointmentDate} ${localAppointmentTime}`,
      'MM/DD/YYYY hh:mm',
    )
      .utc()
      .format('YYYY-MM-DDTHH:mm');

    const filteredInitialPendingData = props.progressNoteData.clients.filter(
      (data) => data.client.clientId === clientData.client.clientId,
    );

    if (filteredInitialPendingData.length === 0) {
      toggleLoader(false);
      return;
    }

    const originalClientData = filteredInitialPendingData[0];

    const paramsData: ProgressNoteType = {
      clients: [
        {
          client: { ...originalClientData.client },
          status:
            clientData.signature.signatureValue !== '' &&
            clientData.cancelReason !== ''
              ? 'Canceled'
              : clientData.signature.signatureValue !== '' &&
                clientData.cancelReason === ''
              ? 'Completed'
              : 'Pending',
          appointmentDate: localAppointmentDateToUtc,
          appointmentId: originalClientData.appointmentId,
          appointmentType: originalClientData.appointmentType,
          othersPresentInVisit: originalClientData.othersPresentInVisit,
          actualAppointmentTime: originalClientData.actualAppointmentTime,
          individualHrs: originalClientData.individualHrs,
          coTreatmentHrs: originalClientData.coTreatmentHrs,
          telehealth: originalClientData.telehealth,
          telehealthType: originalClientData.telehealthType,
          clientLocationType: originalClientData.clientLocationType,
          tpc: originalClientData.tpc,
          providerLocationType: originalClientData.providerLocationType,
          visits: value.clients[openAccordianIndex].visits.map((visit) => ({
            appointmentStage: visit.appointmentStage,
            coTx: visit.coTx,
            asessmentHomeVisit: visit.asessmentHomeVisit,
            supervisionCoTxHV: visit.supervisionCoTxHV,
            ifspMeeting: visit.ifspMeeting,
            startTime: moment(
              `${localAppointmentDate} ${visit.startTime}`,
              'MM/DD/YYYY HH:mm',
            )
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            duration:
              `${convertTimeDuration(visit.duration, false).split(':')[0]}` +
              ':' +
              `${convertTimeDuration(visit.duration, false).split(':')[1]}`,
            serviceCode: visit.serviceCode,
            settingCode: visit.settingCode,
            coTreatPartnerName: visit.coTreatPartnerName,
          })),
          selectedContacts: originalClientData.selectedContacts,
          contact: originalClientData.contact,
          cancelReason: clientData.cancelReason,
          notes: originalClientData.notes,
          signature: clientData.signature,
          attendeeName: originalClientData.attendeeName,
          attendeeLicenseNo: originalClientData.attendeeLicenseNo,
          attendeeList: originalClientData.attendeeList,
        },
      ],
      programDetails: value.programDetails,
    };

    EditProgressNote(paramsData, props.clickedAttendeeId, props.eventId)
      .then(() => {
        setInitialData({
          programDetails: value.programDetails,
          clients: value.clients.map((client, index) => {
            if (
              index === openAccordianIndex &&
              formikRef.current?.initialValues
            ) {
              return {
                ...formikRef.current?.initialValues.clients[openAccordianIndex],
                cancelReason: clientData.cancelReason,
                signature:
                  clientData.status === 'Pending' &&
                  clientData.cancelReason === ''
                    ? {
                        partitionkey: '',
                        rowkey: '',
                        signatureValue: '',
                      }
                    : clientData.signature,
                visits: originalClientData.visits.map((visit) => ({
                  ...visit,
                  appointmentStage: visit.appointmentStage,
                  coTx: visit.coTx,
                  asessmentHomeVisit: visit.asessmentHomeVisit,
                  supervisionCoTxHV: visit.supervisionCoTxHV,
                  ifspMeeting: visit.ifspMeeting,
                  startTime: moment(
                    visit.startTime,
                    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                  )
                    .utc(true)
                    .local()
                    .format('HH:mm'),
                  duration:
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[0]
                    }` +
                    ':' +
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[1]
                    }`,
                  serviceCode: visit.serviceCode,
                  settingCode: visit.settingCode,
                  coTreatPartnerName: visit.coTreatPartnerName,
                })),
                status:
                  clientData.signature.signatureValue !== '' &&
                  clientData.cancelReason !== ''
                    ? 'Canceled'
                    : clientData.signature.signatureValue !== '' &&
                      clientData.cancelReason === ''
                    ? 'Completed'
                    : 'Pending',
              };
            } else {
              return client;
            }
          }),
        });

        setOrigainalInitialData({
          programDetails: value.programDetails,
          clients: value.clients.map((client, index) => {
            if (
              index === openAccordianIndex &&
              formikRef.current?.initialValues
            ) {
              return {
                ...formikRef.current?.initialValues.clients[openAccordianIndex],
                cancelReason: clientData.cancelReason,
                signature:
                  clientData.status === 'Pending' &&
                  clientData.cancelReason === ''
                    ? {
                        partitionkey: '',
                        rowkey: '',
                        signatureValue: '',
                      }
                    : clientData.signature,
                visits: originalClientData.visits.map((visit) => ({
                  ...visit,
                  appointmentStage: visit.appointmentStage,
                  coTx: visit.coTx,
                  asessmentHomeVisit: visit.asessmentHomeVisit,
                  supervisionCoTxHV: visit.supervisionCoTxHV,
                  ifspMeeting: visit.ifspMeeting,
                  startTime: moment(
                    visit.startTime,
                    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                  )
                    .utc(true)
                    .local()
                    .format('HH:mm'),
                  duration:
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[0]
                    }` +
                    ':' +
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[1]
                    }`,
                  serviceCode: visit.serviceCode,
                  settingCode: visit.settingCode,
                  coTreatPartnerName: visit.coTreatPartnerName,
                })),
                status:
                  clientData.signature.signatureValue !== '' &&
                  clientData.cancelReason !== ''
                    ? 'Canceled'
                    : clientData.signature.signatureValue !== '' &&
                      clientData.cancelReason === ''
                    ? 'Completed'
                    : 'Pending',
              };
            } else {
              return client;
            }
          }),
        });

        formikRef.current?.setFieldValue(
          'clients',
          formikRef.current?.values.clients.map((client, index) => {
            if (index === openAccordianIndex) {
              return {
                ...formikRef.current?.initialValues.clients[openAccordianIndex],
                cancelReason: clientData.cancelReason,
                signature:
                  clientData.status === 'Pending' &&
                  clientData.cancelReason === ''
                    ? {
                        partitionkey: '',
                        rowkey: '',
                        signatureValue: '',
                      }
                    : clientData.signature,
                visits: originalClientData.visits.map((visit) => ({
                  ...visit,
                  appointmentStage: visit.appointmentStage,
                  coTx: visit.coTx,
                  startTime: moment(
                    visit.startTime,
                    'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                  )
                    .utc(true)
                    .local()
                    .format('HH:mm'),
                  duration:
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[0]
                    }` +
                    ':' +
                    `${
                      convertTimeDuration(visit.duration, false).split(':')[1]
                    }`,
                  serviceCode: visit.serviceCode,
                  settingCode: visit.settingCode,
                })),
                status:
                  clientData.signature.signatureValue !== '' &&
                  clientData.cancelReason !== ''
                    ? 'Canceled'
                    : clientData.signature.signatureValue !== '' &&
                      clientData.cancelReason === ''
                    ? 'Completed'
                    : 'Pending',
              };
            } else {
              return client;
            }
          }),
        );
        setOpen(true);
        setToastrVariable('success');
        setToastrId('ProgressNote.savedsuccess');
        setToastrDefaultMessage('Data Saved Successfully');
        toggleLoader(false);
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 1013) {
            setToastrDefaultMessage(response.message);
            setToastrId('ProgressNote.NoAppointmentError');
            setTimeout(navigateTo, 2000);
          } else if (response.code === 1012) {
            setToastrDefaultMessage(response.message);
            setToastrId('ProgressNote.NoAttendeeError');
            setTimeout(navigateTo, 2000);
          } else if (response.code === 1010) {
            setToastrDefaultMessage(response.message);
            setToastrId('ProgressNote.NoClientError');
            setTimeout(navigateTo, 2000);
          } else if (response.code === 1081) {
            setToastrId('ApiError.InsufficientAssessmentHrs');
            setToastrDefaultMessage(
              'Insufficient assessment hours. Please use service codes other than TG and TH to complete your progress notes.',
            );
          } else if (response.code === 1083) {
            setToastrId('ApiError.AppointmentCannotBePriorToVisitDate');
            setToastrDefaultMessage(
              'This Appointment cannot be prior to First Visit Date',
            );
          } else if (response.code === 1062) {
            setToastrDefaultMessage(response.message);
            setToastrId('ProgressNote.IfspNotCompleted');
          } else {
            setToastrId('ProgressNote.err');
            setToastrDefaultMessage(response.message);
          }
        }
      });
  };

  const addVisit = () => {
    const defaultAddVisitStartTime = moment(
      props.progressNoteData.clients[openAccordianIndex].visits[0].startTime,
      'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    )
      .utc(true)
      .local()
      .format('HH:mm');
    const newVisit = {
      appointmentStage: 'other',
      duration: convertTimeDuration('00.00', true),
      coTx: false,
      coTreatPartnerName: '',
      asessmentHomeVisit: false,
      ifspMeeting: false,
      supervisionCoTxHV: false,
      clientVisitsIndex: openAccordianIndex,
      serviceCode: {
        id: '',
        label: '',
      },
      settingCode: {
        id: '',
        label: '',
      },
      startTime: defaultAddVisitStartTime,
    };
    // Update form values directly
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          return {
            ...client,
            visits: [...client.visits, newVisit],
          };
        }
        return client;
      }),
    );
    if (initialData) {
      setInitialData({
        ...initialData,
        clients: initialData.clients.map((client, index) => {
          if (index === openAccordianIndex) {
            return {
              ...client,
              visits: [
                ...client.visits,
                {
                  appointmentStage: '',
                  duration: '',
                  coTx: false,
                  asessmentHomeVisit: false,
                  ifspMeeting: false,
                  supervisionCoTxHV: false,
                  clientVisitsIndex: openAccordianIndex,
                  serviceCode: {
                    id: '',
                    label: '',
                  },
                  settingCode: {
                    id: '',
                    label: '',
                  },
                  coTreatPartnerName: '',
                  startTime: '',
                },
              ],
            };
          }
          return client;
        }),
      });
    }
  };
  const deleteVisit = (index: number) => {
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, clientIndex) => {
        if (clientIndex === openAccordianIndex) {
          return {
            ...client,
            visits: client.visits.filter((__, i) => i !== index),
          };
        }
        return client;
      }),
    );
    if (initialData) {
      setInitialData({
        ...initialData,
        clients: initialData.clients.map((client, clientIndex) => {
          if (clientIndex === openAccordianIndex) {
            return {
              ...client,
              visits: client.visits.filter((__, i) => i !== index),
            };
          }
          return client;
        }),
      });
    }
  };

  const handleDownload = (
    selectedClientId: string,
    attendeeId: string,
    appointmentId: string,
  ) => {
    toggleLoader(true);
    const localTimezone = dayjs.tz.guess();
    downloadProgressNote(
      selectedClientId,
      attendeeId,
      appointmentId,
      localTimezone,
    )
      .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Open the Blob URL in a new tab
        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.target = '_blank';
        anchor.download = 'ProgressNotes.pdf'; // Set desired file name here

        // Append anchor to document body
        document.body.appendChild(anchor);

        // Trigger anchor click
        anchor.click();

        window.open(blobUrl, '_blank');

        // Clean up after use
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);

        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ConsentForms.downloadErrorMsg');
          setToastrDefaultMessage('Failed to download');
        }
      });
  };

  const handleCancelVisit = () => {
    setOpenCancelVisitModal(true);
  };

  const handleCloseCancelVisit = () => {
    formikRef.current?.setTouched({}, false);
    setOpenCancelVisitModal(false);
  };

  const handelLockScreen = (reason: string) => {
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          return {
            ...client,
            cancelReason: reason,
            status:
              client.status === 'Pending'
                ? 'Pending'
                : client.status === 'In Progress'
                ? 'In Progress'
                : client.status === 'Canceled' &&
                  client.visits[0].serviceCode.label !== ''
                ? 'In Progress'
                : client.status === 'Canceled' &&
                  client.visits[0].serviceCode.label === ''
                ? 'Pending'
                : 'In Progress',
          };
        }
        return client;
      }),
    );
    setIsScreenLocked(true);
    setTimeout(() => {
      if (
        formikRef.current &&
        formikRef.current.values &&
        formikRef.current.values.clients[openAccordianIndex].cancelReason &&
        formikRef.current.values.clients[openAccordianIndex].status ===
          'Pending'
      ) {
        handlePendingSubmit(formikRef.current.values as ProgressNoteType);
      } else {
        turnOfMic();
        checkNoteEntered();
        formikRef.current?.submitForm();
      }
    }, 0);
  };

  const showCancelToaster = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          maxWidth: 'max-content',
          width: '100%',
          marginTop: '100px',
          justifyContent: 'center',
          alignSelf: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#EB4C60',
            width: '100%',
            padding: '24px 32px 24px 32px',
            borderRadius: '60px',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ paddingTop: '3px' }}>
            <StopWhite />
          </Box>
          <Box>
            <MediumTypography
              textColor="#FFFFFF"
              sxProps={{ marginLeft: '8px' }}
              label={`This Progress Note is Canceled due to ${formikRef.current?.values.clients[openAccordianIndex].cancelReason}`}
            />
          </Box>
        </Box>
        {(formikRef.current?.values.clients[openAccordianIndex].signature
          .rowkey === '' ||
          formikRef.current?.values.clients[openAccordianIndex].signature
            .signatureValue === '') &&
          validUser &&
          initialData?.clients[openAccordianIndex].activitySheetStatus !==
            'Submitted' &&
          initialData?.clients[openAccordianIndex].activitySheetStatus !==
            'Approved' && (
            <Box
              sx={{
                display: 'flex',
                paddingY: '4px',
                paddingX: '12px',
                marginTop: '16px',
                width: '16%',
                pointerEvents: 'auto',
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className="cursorPointer"
              onClick={handleUndoVisit}
            >
              <Box sx={{ paddingTop: '3px' }}>
                <Undo />
              </Box>
              <MediumTypography
                labelid="ProgressNote.Undo"
                defaultlabel="Undo"
                textColor="#97A6A5"
                fontSize="14px"
                textstyle="center"
                fontweight={600}
              />
            </Box>
          )}
      </Box>
    );
  };

  const handleUndoVisit = () => {
    setIsScreenLocked(false);
    formikRef.current?.setFieldValue(
      'clients',
      formikRef.current?.values.clients.map((client, index) => {
        if (index === openAccordianIndex) {
          return {
            ...client,
            status:
              client.status === 'Pending'
                ? 'Pending'
                : client.status === 'In Progress'
                ? 'In Progress'
                : client.status === 'Canceled' &&
                  client.visits[0].serviceCode.label !== ''
                ? 'In Progress'
                : client.status === 'Canceled' &&
                  client.visits[0].serviceCode.label === ''
                ? 'Pending'
                : 'In Progress',
            cancelReason: '',
            signature: {
              partitionKey: '',
              rowKey: '',
              signatureValue: '',
              partitionkey: '',
            },
          };
        }
        return client;
      }),
    );
    setTimeout(() => {
      if (
        formikRef.current &&
        formikRef.current.values &&
        formikRef.current.values.clients[openAccordianIndex].cancelReason ===
          '' &&
        formikRef.current.values.clients[openAccordianIndex].status ===
          'Pending'
      ) {
        handlePendingSubmit(formikRef.current.values as ProgressNoteType);
      } else {
        turnOfMic();
        checkNoteEntered();
        formikRef.current?.submitForm();
      }
    }, 0);
  };

  return (
    <>
      {shareModal && (
        <ShareDownloadModal
          modalVisible={shareModal}
          closeModal={() => setShareModal(false)}
          screenName="progressNote"
          attendeeId={props.clickedAttendeeId}
          commonAppointmentId={props.eventId}
          clientId={shareClientId}
        />
      )}
      <SnackBarComponent
        open={open}
        handleClose={handleCloseSnakBar}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <CancelVisits
        openModal={openCancelVisitModal}
        onClose={handleCloseCancelVisit}
        handelLockScreen={handelLockScreen}
      />
      {initialData &&
        initialData.clients &&
        initialData.clients.length > 0 &&
        initialData.clients[openAccordianIndex] &&
        initialData.clients[openAccordianIndex].client.firstName &&
        initialData.clients[openAccordianIndex].client.lastName &&
        initialData.programDetails && (
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            innerRef={formikRef}
            enableReinitialize={true}
            onSubmit={(value) => {
              if (
                value.clients[openAccordianIndex].visits.some(
                  (visit) =>
                    visit.duration === '0.00' || visit.duration === '00.00',
                )
              ) {
                setIsAnyDurationZero(true);
              } else {
                handleOnSubmit(value);
              }
            }}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => {
              useEffect(() => {
                for (const property of propertiesToCheck) {
                  if (
                    !_.isEqual(
                      _.get(
                        origainalInitialData,
                        `clients[${openAccordianIndex}].${property}`,
                      ),
                      _.get(
                        values,
                        `clients[${openAccordianIndex}].${property}`,
                      ),
                    )
                  ) {
                    handleUpdateUnsavedChanges(true);
                    break;
                  } else {
                    handleUpdateUnsavedChanges(false);
                    // break;
                  }
                }
              }, [values, origainalInitialData, initialData]);
              return (
                <Box sx={{}}>
                  <Card
                    sx={{ padding: '24px' }}
                    onClick={() => {
                      closeDurationModal();
                    }}
                  >
                    {/* Program Details */}
                    <Box
                      sx={{
                        paddingBottom: '24px',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Box sx={{ display: 'flex', paddingBottom: '16px' }}>
                          <MediumTypography
                            labelid="ProgressNote.Program"
                            defaultlabel="Program:"
                            sxProps={{
                              fontSize: '20px',
                              fontWeight: 700,
                              color: '#97A6A5',
                            }}
                          />
                          <MediumTypography
                            sxProps={{
                              fontSize: '20px',
                              fontWeight: 700,
                              paddingLeft:
                                initialData.programDetails.name === ''
                                  ? '24px'
                                  : '6px',
                              color: '#2A4241',
                            }}
                            label={
                              initialData.programDetails.name === ''
                                ? '-'
                                : initialData.programDetails.name
                            }
                          />
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ display: 'flex' }}>
                            <MediumTypography
                              labelid="ProgressNote.Phone"
                              defaultlabel="Phone:"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft:
                                  initialData.programDetails.phoneNumber === ''
                                    ? '24px'
                                    : '6px',
                                paddingRight: '24px',
                                color: '#2A4241',
                              }}
                              label={
                                initialData.programDetails.phoneNumber === ''
                                  ? '-'
                                  : initialData.programDetails.phoneNumber
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <MediumTypography
                              labelid="ProgressNote.Fax"
                              defaultlabel="Fax:"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft:
                                  initialData.programDetails.faxNumber === ''
                                    ? '24px'
                                    : '6px',
                                paddingRight: '24px',
                                color: '#2A4241',
                                fontFamily: 'Lato-Regular',
                              }}
                              label={
                                initialData.programDetails.faxNumber === ''
                                  ? '-'
                                  : initialData.programDetails.faxNumber
                              }
                            />
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <MediumTypography
                              labelid="ProgressNote.Website"
                              defaultlabel="Website:"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <Tooltip
                              title={initialData.programDetails.website}
                              arrow
                              followCursor
                              className="minWidthContent"
                            >
                              <Box>
                                <MediumTypography
                                  sxProps={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '260px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    paddingLeft:
                                      initialData.programDetails.website === ''
                                        ? '24px'
                                        : '6px',
                                    color: '#2A4241',
                                  }}
                                  label={
                                    initialData.programDetails.website === ''
                                      ? '-'
                                      : initialData.programDetails.website
                                  }
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{}}>
                        <img
                          src={initialData.programDetails.imageUrl}
                          alt="logo"
                          style={{ width: '250px', height: '64px' }}
                        />
                      </Box>
                    </Box>

                    {/* Group Billing */}
                    {selectedClient.length > 1 && (
                      <Box
                        sx={{
                          marginBottom: '24px',
                          paddingY: '24px',
                          borderBottom: '1px solid #CFDEDE',
                          borderTop: '1px solid #CFDEDE',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            onClick={() =>
                              setShowGroupBillingBlock(!showGroupBillingBlock)
                            }
                            sx={{ cursor: 'pointer' }}
                          >
                            <MediumTypography
                              labelid="groupBillingText"
                              defaultlabel="Clinician Group Billable Hours"
                              sxProps={{
                                fontSize: '18px',
                                fontWeight: 600,
                                color: '#008C82',
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() =>
                              setShowGroupBillingBlock(!showGroupBillingBlock)
                            }
                            sx={{
                              display: 'flex',
                              padding: '8px',
                              cursor: 'pointer',
                            }}
                          >
                            {!showGroupBillingBlock ? (
                              <DownArrow />
                            ) : (
                              <UpArrow />
                            )}
                          </Box>
                        </Box>
                        {/*Group Billing Start time and Duration */}
                        {showGroupBillingBlock && (
                          <Box>
                            <Box
                              sx={{
                                display: 'flex',
                                marginTop: '24px',
                              }}
                            >
                              <Box
                                sx={{
                                  width: '170px',
                                  marginRight: '24px',
                                }}
                              >
                                <AppTimePicker
                                  disableManualInput={true}
                                  needCrossIcon={false}
                                  value={dayjs(groupBillingStartTime, 'HH:mm')}
                                  onTimeChange={(time: Dayjs | null) => {
                                    if (time === null) {
                                      setGroupBillingStartTime(null);
                                    } else {
                                      setGroupBillingStartTime(
                                        time.format('HH:mm'),
                                      );
                                    }
                                  }}
                                  sxButtonProps={{
                                    height: '36px',
                                    borderColor:
                                      !validUser ||
                                      initialData?.clients[openAccordianIndex]
                                        .activitySheetStatus === 'Submitted' ||
                                      initialData?.clients[openAccordianIndex]
                                        .activitySheetStatus === 'Approved'
                                        ? '#CFDEDE'
                                        : undefined,
                                  }}
                                  disable={
                                    !validUser ||
                                    initialData?.clients[openAccordianIndex]
                                      .activitySheetStatus === 'Submitted' ||
                                    initialData?.clients[openAccordianIndex]
                                      .activitySheetStatus === 'Approved'
                                  }
                                  minuteStep={1}
                                />
                              </Box>
                              {/* Duration */}
                              <Box>
                                <DurationPicker
                                  interval={25}
                                  disabled={
                                    !validUser ||
                                    initialData?.clients[openAccordianIndex]
                                      .activitySheetStatus === 'Submitted' ||
                                    initialData?.clients[openAccordianIndex]
                                      .activitySheetStatus === 'Approved'
                                  }
                                  required={false}
                                  labelId="ProgressNote.duration"
                                  defaultLabel="Duration"
                                  maxDuration={{
                                    hour: 24,
                                    minutes: 0,
                                  }}
                                  duration={formatStringDateInDecimals(
                                    groupBillingDuration || '00:00',
                                  )}
                                  setDuration={(duration) => {
                                    setGroupBillingDuration(
                                      formatTimeDurationInDecimals(duration),
                                    );
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{ display: 'flex', marginTop: '16px' }}
                            ></Box>
                            {validUser &&
                              initialData?.clients[openAccordianIndex]
                                .activitySheetStatus !== 'Submitted' &&
                              initialData?.clients[openAccordianIndex]
                                .activitySheetStatus !== 'Approved' && (
                                <Box>
                                  <ButtonComponent
                                    className="btn-primary btn-cancel alrt_Width_btn"
                                    variantType="contained"
                                    labelId="ProgressNote.Cancel"
                                    defaultLabelId="Cancel"
                                    onClick={() => {
                                      const formattedDutaion =
                                        groupBillingDuration.split('.');
                                      if (
                                        _.isEqual(
                                          props.progressNoteData.groupBilling
                                            ?.groupBillingStartTime
                                            ? moment(
                                                props.progressNoteData
                                                  .groupBilling
                                                  ?.groupBillingStartTime,
                                                'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                                              )
                                                .utc(true)
                                                .local()
                                                .format('HH:mm')
                                            : '',
                                          groupBillingStartTime,
                                        ) &&
                                        _.isEqual(
                                          convertTimeDuration(
                                            props.progressNoteData.groupBilling
                                              ?.groupBillingDuration || '00:00',
                                            true,
                                          ),
                                          `${formattedDutaion[0].padStart(
                                            2,
                                            '0',
                                          )}.${formattedDutaion[1].padStart(
                                            2,
                                            '0',
                                          )}`,
                                        )
                                      ) {
                                        setShowGroupBillingBlock(
                                          !showGroupBillingBlock,
                                        );
                                      } else {
                                        setGroupBillingCancelModal(true);
                                      }
                                    }}
                                  />
                                  <ButtonComponent
                                    variantType="contained"
                                    type="submit"
                                    className="btn-primary btn-submit alrt_Width_btn ml-md"
                                    labelId="ProgressNote.Save"
                                    defaultLabelId="Save"
                                    onClick={() => {
                                      toggleLoader(true);
                                      UpdateGroupBillig({
                                        appointmentId:
                                          props.progressNoteData.clients[0]
                                            .appointmentId,
                                        attendeeId: props.clickedAttendeeId,
                                        groupBillingDuration:
                                          convertTimeDuration(
                                            groupBillingDuration,
                                            false,
                                          ),
                                        groupBillingStartTime:
                                          groupBillingStartTime
                                            ? moment(
                                                `${localAppointmentDate} ${groupBillingStartTime}`,
                                                'MM/DD/YYYY HH:mm',
                                              )
                                                .utc()
                                                .format(
                                                  'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                                                )
                                            : null,
                                      })
                                        .then(() => {
                                          setOpen(true);
                                          setToastrVariable('success');
                                          setToastrId(
                                            'GroupBilling.UpdatedSuccess',
                                          );
                                          setToastrDefaultMessage(
                                            'Group Billing Updated Successfully',
                                          );
                                          toggleLoader(false);
                                        })
                                        .catch(() => {
                                          setOpen(true);
                                          setToastrVariable('error');
                                          setToastrId(
                                            'GroupBilling.UpdatedError',
                                          );
                                          setToastrDefaultMessage(
                                            'Failed to Update Group Billing',
                                          );
                                          toggleLoader(false);
                                        });
                                    }}
                                  />
                                </Box>
                              )}
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* Client List Bubbel */}

                    {clientCount > 1 && (
                      <Grid
                        container
                        sx={{ marginBottom: '16px' }}
                        rowGap={2}
                        alignItems={'center'}
                      >
                        {selectedClient.map((item, index) => (
                          <>
                            <Grid item key={item.clientId}>
                              <Box
                                component={Button}
                                tabIndex={0}
                                sx={{
                                  '&:hover': {
                                    backgroundColor:
                                      openAccordianIndex === index
                                        ? '#00C6B8'
                                        : '#ffffff', // or whatever your default background color is
                                    color: 'inherit', // or the default text color
                                    // Add any other styles you want to reset on hover
                                  },
                                  display: 'flex',
                                  height: '30px',
                                  width: '120px',
                                  borderRadius: '30px',
                                  textTransform: 'none',
                                  border:
                                    openAccordianIndex !== index
                                      ? '1px solid #97A6A5'
                                      : 'none',
                                  marginRight: '16px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor:
                                    openAccordianIndex === index
                                      ? '#00C6B8'
                                      : '#ffffff',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (openAccordianIndex === index) {
                                    setOpenAccordianIndex(openAccordianIndex);
                                    setOpenAccordian(true);
                                  } else {
                                    formikRef.current?.setTouched({}, false);
                                    setOpenAccordianIndex(index);
                                    setOpenAccordian(true);
                                  }
                                }}
                              >
                                <Tooltip
                                  title={item.clientName}
                                  arrow
                                  followCursor
                                  className="minWidthContent"
                                >
                                  <Box>
                                    <MediumTypography
                                      label={item.clientName}
                                      textColor={
                                        openAccordianIndex === index
                                          ? '#ffffff'
                                          : '#97A6A5'
                                      }
                                      sxProps={{
                                        paddingX: '8px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100px',
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Grid>
                            {index === selectedClient.length - 1 &&
                              initialData?.clients[openAccordianIndex]
                                .activitySheetStatus !== 'Submitted' &&
                              initialData?.clients[openAccordianIndex]
                                .activitySheetStatus !== 'Approved' && (
                                <Grid item>
                                  {validUser && (
                                    <MediumTypography
                                      labelid="Progressote.AddDeleteClient"
                                      defaultlabel="Add/Delete Clients"
                                      fontweight={700}
                                      fontSize="14px"
                                      sxProps={{
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '3px',
                                        color: '#00C6B8',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setFirstEntry(false);
                                        setOpenClientModal(true);
                                      }}
                                    />
                                  )}
                                </Grid>
                              )}
                          </>
                        ))}
                      </Grid>
                    )}

                    {initialData.clients
                      .filter((initialClient) =>
                        selectedClient.some(
                          (client) =>
                            client.clientId === initialClient.client.clientId,
                        ),
                      )
                      .map((item, sectionIndex) => {
                        return (
                          <Box
                            key={item.client.clientId}
                            sx={{
                              backgroundColor: '#ECF9F8',
                              paddingBottom: '24px',
                              marginBottom:
                                sectionIndex === initialData.clients.length - 1
                                  ? '0px'
                                  : '16px',
                              border: '1px solid #00C6B8',
                              borderRadius: '4px',
                            }}
                          >
                            {/* Client details */}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '24px',
                                marginX: '24px',
                                paddingBottom:
                                  openAccordian &&
                                  openAccordianIndex === sectionIndex
                                    ? '24px'
                                    : '0px',
                                borderBottom:
                                  openAccordian &&
                                  openAccordianIndex === sectionIndex
                                    ? '1px solid #00C6B8'
                                    : 'none',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    columnGap: '10px',
                                    cursor: 'default',
                                    marginRight: '4px',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      formatName(
                                        item.client.firstName,
                                        item.client.middleName,
                                        item.client.lastName,
                                        item.client.suffix,
                                      ).length > 10
                                        ? formatName(
                                            item.client.firstName,
                                            item.client.middleName,
                                            item.client.lastName,
                                            item.client.suffix,
                                          )
                                        : null
                                    }
                                    followCursor
                                    arrow={true}
                                    sx={{ maxWidth: '200px' }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontSize: '18px',
                                          fontWeight: 600,
                                          color: '#008C82',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          cursor: 'pointer',
                                          textOverflow: 'ellipsis',
                                          maxWidth: '150px',
                                        }}
                                        label={
                                          item.client.firstName === ''
                                            ? '-'
                                            : formatName(
                                                item.client.firstName,
                                                item.client.middleName,
                                                item.client.lastName,
                                                item.client.suffix,
                                              )
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                </Box>
                                {!offline &&
                                  validUser &&
                                  item.status !== 'Pending' && (
                                    <DownloadIcon
                                      style={{
                                        cursor: 'pointer',
                                        marginLeft: '4px',
                                      }}
                                      onClick={() => {
                                        handleDownload(
                                          item.client.clientId,
                                          props.clickedAttendeeId,
                                          props.eventId,
                                        );
                                      }}
                                    />
                                  )}
                                {!offline &&
                                  validUser &&
                                  item.status !== 'Pending' && (
                                    <Box
                                      className="cursorPointer"
                                      onClick={() => {
                                        setShareClientId(item.client.clientId);
                                        setShareModal(true);
                                      }}
                                    >
                                      <ToolTipIcon
                                        labelId="ConsentForms.listActionShareToolTipLabel"
                                        defaultLabel="Share"
                                        Icon={<ShareIcon />}
                                      />
                                    </Box>
                                  )}
                              </Box>

                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  columnGap: '24px',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '118px',
                                  }}
                                >
                                  <MediumTypography
                                    labelid="ProgressNote.Sex"
                                    defaultlabel="Sex:"
                                    textColor="#97A6A5"
                                    sxProps={{ fontSize: '16px' }}
                                  />
                                  <Box>
                                    <MediumTypography
                                      sxProps={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        paddingLeft:
                                          item.client.sex === ''
                                            ? '16px'
                                            : '4px',
                                        color: '#2A4241',
                                      }}
                                      label={
                                        item.client.sex === ''
                                          ? '-'
                                          : convertGenderToFrontend(
                                              item.client.sex,
                                            )
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '208px',
                                  }}
                                >
                                  <MediumTypography
                                    labelid="ProgressNote.DPHID"
                                    defaultlabel="Enrollment ID:"
                                    textColor="#97A6A5"
                                    sxProps={{ fontSize: '16px' }}
                                  />
                                  <Box>
                                    <MediumTypography
                                      textstyle="center"
                                      sxProps={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        paddingLeft:
                                          item.client.dphId === ''
                                            ? '50px'
                                            : '4px',
                                        color: '#2A4241',
                                      }}
                                      label={
                                        item.client.dphId === ''
                                          ? '-'
                                          : item.client.dphId
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                  <MediumTypography
                                    labelid="ProgressNote.DateofBirth"
                                    defaultlabel="Date of Birth:"
                                    textColor="#97A6A5"
                                    sxProps={{ fontSize: '16px' }}
                                  />
                                  <Box>
                                    <MediumTypography
                                      textstyle="center"
                                      sxProps={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        marginLeft:
                                          item.client.dob === ''
                                            ? '16px'
                                            : '4px',
                                        color: '#2A4241',
                                        fontFamily: 'Lato-Regular',
                                      }}
                                      label={
                                        item.client.dob === ''
                                          ? '-'
                                          : item.client.dob
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '135px',
                                  }}
                                >
                                  <MediumTypography
                                    labelid="ProgressNote.Status"
                                    defaultlabel="Status:"
                                    textColor="#97A6A5"
                                    sxProps={{ fontSize: '16px' }}
                                  />
                                  <Box>
                                    <MediumTypography
                                      textstyle="center"
                                      sxProps={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        marginLeft: '4px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        color:
                                          item.status === 'Completed'
                                            ? '#37D183'
                                            : item.status === 'In Progress'
                                            ? '#F9B803'
                                            : item.status === 'Canceled'
                                            ? '#EB4C60'
                                            : '#F9B803',
                                        fontFamily: 'Lato-Regular',
                                      }}
                                      label={
                                        item.status === 'Completed'
                                          ? 'Completed'
                                          : item.status === 'In Progress'
                                          ? 'In Progress'
                                          : item.status === 'Canceled'
                                          ? 'Canceled'
                                          : 'Pending'
                                      }
                                    />
                                  </Box>
                                </Box>
                                {clientCount > 1 && (
                                  <Box>
                                    {openAccordian &&
                                    openAccordianIndex === sectionIndex ? (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          padding: '5px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          formikRef.current?.setTouched(
                                            {},
                                            false,
                                          );
                                          setOpenAccordianIndex(
                                            openAccordianIndex,
                                          );
                                          setOpenAccordian(false);
                                        }}
                                      >
                                        <UpArrow />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          padding: '5px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          formikRef.current?.setTouched(
                                            {},
                                            false,
                                          );
                                          setOpenAccordianIndex(sectionIndex);
                                          setOpenAccordian(true);
                                        }}
                                      >
                                        <DownArrow />
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            {openAccordian &&
                              openAccordianIndex === sectionIndex && (
                                <Box sx={{ position: 'relative' }}>
                                  {isScreenLocked &&
                                  formikRef.current?.values.clients[
                                    openAccordianIndex
                                  ].cancelReason ? (
                                    <Box
                                      className="showCancelPopup"
                                      sx={{
                                        position: 'absolute',
                                        zIndex: 3,
                                        maxWidth: '100%',
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        paddingTop: '34px',
                                      }}
                                    >
                                      {showCancelToaster()}
                                    </Box>
                                  ) : null}
                                  <div
                                    ref={parentRef}
                                    style={{
                                      pointerEvents: isScreenLocked
                                        ? 'none'
                                        : 'auto',
                                    }}
                                    onKeyDownCapture={(e) => {
                                      if (isScreenLocked) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    }}
                                    onFocus={() => {
                                      if (isScreenLocked) {
                                        if (parentRef.current)
                                          parentRef.current.focus();
                                      }
                                    }}
                                  >
                                    <Box sx={{ position: 'relative' }}>
                                      {isScreenLocked &&
                                      formikRef.current?.values.clients[
                                        openAccordianIndex
                                      ].cancelReason ? (
                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            zIndex: 2,
                                            width: '100%',
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            opacity: isScreenLocked
                                              ? '0.3'
                                              : '1 ',
                                            paddingY: '0px',
                                            backgroundColor: '#97A6A5',
                                          }}
                                        ></Box>
                                      ) : null}

                                      {/* Visit Info */}
                                      <Box
                                        sx={{
                                          paddingY: '24px',
                                          marginX: '24px',
                                          borderBottom: '1px solid #00C6B8',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <Box>
                                            <MediumTypography
                                              labelid="ProgressNote.VisitInformation"
                                              defaultlabel="Visit Information"
                                              textColor="#213262"
                                              sxProps={{
                                                fontSize: '18px',
                                                fontWeight: 600,
                                                paddingBottom: '16px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              columnGap: '16px',
                                            }}
                                          >
                                            <Box sx={{ display: 'flex' }}>
                                              <MediumTypography
                                                labelid="ProgressNote.Date"
                                                defaultlabel="Date"
                                                textColor="#97A6A5"
                                                sxProps={{ fontSize: '16px' }}
                                              />
                                              <MediumTypography
                                                sxProps={{
                                                  fontSize: '16px',
                                                  fontWeight: 500,
                                                  paddingLeft:
                                                    localAppointmentDate ===
                                                      '' || null
                                                      ? '16px'
                                                      : '4px',
                                                  color: '#2A4241',
                                                  fontFamily: 'Lato-Regular',
                                                }}
                                                labelid={
                                                  localAppointmentDate === ''
                                                    ? '-'
                                                    : localAppointmentDate
                                                }
                                              />
                                            </Box>
                                            <Box sx={{ display: 'flex' }}>
                                              <MediumTypography
                                                labelid="ProgressNote.Individual"
                                                defaultlabel="Individual"
                                                textColor="#97A6A5"
                                                sxProps={{ fontSize: '16px' }}
                                              />
                                              <MediumTypography
                                                label={
                                                  values.clients[
                                                    openAccordianIndex
                                                  ].individualHrs
                                                }
                                                fontSize="16px"
                                                fontweight={500}
                                                textColor="#2A4241"
                                                className="ml-xs"
                                              />
                                            </Box>
                                            <Box sx={{ display: 'flex' }}>
                                              <MediumTypography
                                                labelid="ProgressNote.ActualAppointmentDuration"
                                                defaultlabel="Appointment scheduled:"
                                                textColor="#97A6A5"
                                                sxProps={{ fontSize: '16px' }}
                                              />
                                              <MediumTypography
                                                label={
                                                  values.clients[
                                                    openAccordianIndex
                                                  ].actualAppointmentTime
                                                }
                                                fontSize="16px"
                                                fontweight={500}
                                                textColor="#2A4241"
                                                className="ml-xs"
                                              />
                                            </Box>
                                            <Box sx={{ display: 'flex' }}>
                                              <MediumTypography
                                                labelid="ProgressNote.Co-Tx"
                                                defaultlabel="Co-Treatment:"
                                                textColor="#97A6A5"
                                                sxProps={{ fontSize: '16px' }}
                                              />
                                              <MediumTypography
                                                label={
                                                  values.clients[
                                                    openAccordianIndex
                                                  ].coTreatmentHrs
                                                }
                                                fontSize="16px"
                                                fontweight={500}
                                                textColor="#2A4241"
                                                className="ml-xs"
                                              />
                                            </Box>
                                            {!offline &&
                                            item.status !== 'Completed' &&
                                            validUser ? (
                                              <Box sx={{ cursor: 'pointer' }}>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                  }}
                                                  onClick={() =>
                                                    handleCancelVisit()
                                                  }
                                                >
                                                  <Stop />
                                                  <MediumTypography
                                                    labelid="ProgressNote.CancelVisit"
                                                    defaultlabel="Cancel visit"
                                                    textColor="#EB4C60"
                                                    sxProps={{
                                                      fontSize: '16px',
                                                      paddingLeft: '4px',
                                                    }}
                                                  />
                                                </Box>
                                              </Box>
                                            ) : null}
                                          </Box>
                                        </Box>

                                        {/* Client Location */}
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingBottom:
                                              errors.clients &&
                                              errors.clients[
                                                openAccordianIndex
                                              ] &&
                                              (
                                                errors.clients[
                                                  sectionIndex
                                                ] as FormikErrors<ProgressNoteClient>
                                              ).clientLocationType &&
                                              touched.clients &&
                                              touched.clients[
                                                openAccordianIndex
                                              ] &&
                                              touched.clients[
                                                openAccordianIndex
                                              ].clientLocationType
                                                ? '32px'
                                                : '16px',
                                          }}
                                        >
                                          <MediumTypography
                                            labelid="ProgressNote.ClientLocation"
                                            defaultlabel="Client’s Location:"
                                            sxProps={{
                                              paddingRight: '16px',
                                              fontSize: '16px',
                                            }}
                                            textColor="#97A6A5"
                                          />
                                          <Box sx={{ display: 'flex' }}>
                                            <CustomRadioButton
                                              needUnSelect={false}
                                              data={clientLocation.map(
                                                (i) =>
                                                  ({
                                                    label: i.label,
                                                    value: i.id,
                                                  } as RadioButtonData),
                                              )}
                                              onSelectItem={(value) => {
                                                setFieldValue(
                                                  `clients[${sectionIndex}].clientLocationType`,
                                                  value,
                                                );
                                              }}
                                              value={
                                                values.clients[sectionIndex]
                                                  .clientLocationType
                                              }
                                              disable={
                                                item.status === 'Completed' ||
                                                item.status === 'Canceled' ||
                                                !validUser
                                              }
                                            />
                                            {errors.clients &&
                                              errors.clients[
                                                openAccordianIndex
                                              ] &&
                                              (
                                                errors.clients[
                                                  sectionIndex
                                                ] as FormikErrors<ProgressNoteClient>
                                              ).clientLocationType &&
                                              touched.clients &&
                                              touched.clients[
                                                openAccordianIndex
                                              ] &&
                                              touched.clients[
                                                openAccordianIndex
                                              ].clientLocationType && (
                                                <MediumTypography
                                                  label="Client Location is Required"
                                                  sxProps={{
                                                    marginTop: '24px',
                                                    color:
                                                      'var(--thom-radical-red)',
                                                    position: 'absolute',
                                                  }}
                                                />
                                              )}
                                          </Box>
                                        </Box>

                                        {/* Telehealth */}
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingBottom:
                                              errors.clients &&
                                              errors.clients[
                                                openAccordianIndex
                                              ] &&
                                              (
                                                errors.clients[
                                                  openAccordianIndex
                                                ] as FormikErrors<ProgressNoteClient>
                                              ).telehealthType &&
                                              touched.clients &&
                                              touched.clients[
                                                openAccordianIndex
                                              ].telehealthType
                                                ? '32px'
                                                : '16px',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <CheckBoxComponent
                                              ischecked={
                                                values.clients[sectionIndex].tpc
                                              }
                                              CheckHandleChange={(e) => {
                                                setFieldValue(
                                                  `clients[${openAccordianIndex}].tpc`,
                                                  e.target.checked,
                                                );
                                              }}
                                              disabled={
                                                item.status === 'Completed' ||
                                                item.status === 'Canceled' ||
                                                !validUser
                                              }
                                              labelid="ProgressNote.TPC"
                                              defaultlabelid="TPC"
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <CheckBoxComponent
                                              ischecked={
                                                values.clients[sectionIndex]
                                                  .telehealth
                                              }
                                              CheckHandleChange={(value) => {
                                                setFieldValue(
                                                  `clients[${openAccordianIndex}].telehealth`,
                                                  value.target.checked,
                                                );
                                                if (!value.target.checked) {
                                                  setFieldValue(
                                                    `clients[${openAccordianIndex}].telehealthType`,
                                                    '',
                                                  );
                                                  setFieldValue(
                                                    `clients[${openAccordianIndex}].providerLocationType`,
                                                    '',
                                                  );
                                                }
                                              }}
                                              disabled={
                                                item.status === 'Completed' ||
                                                item.status === 'Canceled' ||
                                                !validUser
                                              }
                                              labelid="ProgressNote.Telehealth"
                                              defaultlabelid="Telehealth"
                                            />
                                          </Box>
                                          {values.clients[sectionIndex]
                                            .telehealth && (
                                            <Box sx={{ display: 'flex' }}>
                                              <CustomRadioButton
                                                needUnSelect={false}
                                                data={TeleHealthRadioData}
                                                onSelectItem={(value) =>
                                                  setFieldValue(
                                                    `clients[${openAccordianIndex}].telehealthType`,
                                                    value,
                                                  )
                                                }
                                                value={
                                                  values.clients[sectionIndex]
                                                    .telehealthType
                                                }
                                                disable={
                                                  item.status === 'Completed' ||
                                                  item.status === 'Canceled' ||
                                                  !validUser
                                                }
                                              />
                                              {errors.clients &&
                                                errors.clients[
                                                  openAccordianIndex
                                                ] &&
                                                (
                                                  errors.clients[
                                                    openAccordianIndex
                                                  ] as FormikErrors<ProgressNoteClient>
                                                ).telehealthType &&
                                                touched.clients &&
                                                touched.clients[
                                                  openAccordianIndex
                                                ].telehealthType && (
                                                  <MediumTypography
                                                    labelid={
                                                      errors.clients &&
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).telehealthType
                                                    }
                                                    defaultlabel="Audio or Video is Required"
                                                    sxProps={{
                                                      marginTop: '24px',
                                                      color:
                                                        'var( --thom-radical-red)',
                                                      position: 'absolute',
                                                    }}
                                                  />
                                                )}
                                            </Box>
                                          )}
                                        </Box>
                                        {/*Provider location */}
                                        {values.clients[sectionIndex]
                                          .telehealth && (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              paddingBottom:
                                                errors.clients &&
                                                errors.clients[
                                                  openAccordianIndex
                                                ] &&
                                                (
                                                  errors.clients[
                                                    openAccordianIndex
                                                  ] as FormikErrors<ProgressNoteClient>
                                                ).providerLocationType &&
                                                touched.clients &&
                                                touched.clients[
                                                  openAccordianIndex
                                                ].providerLocationType
                                                  ? '32px'
                                                  : '16px',
                                            }}
                                          >
                                            <MediumTypography
                                              labelid="ProgressNote.ProviderLocation"
                                              defaultlabel="Provider’s Location:"
                                              sxProps={{
                                                paddingRight: '16px',
                                                fontSize: '16px',
                                              }}
                                              textColor="#97A6A5"
                                            />
                                            <Box sx={{ display: 'flex' }}>
                                              <CustomRadioButton
                                                needUnSelect={false}
                                                data={clientLocation.map(
                                                  (i) =>
                                                    ({
                                                      label: i.label,
                                                      value: i.id,
                                                    } as RadioButtonData),
                                                )}
                                                onSelectItem={(value) => {
                                                  setFieldValue(
                                                    `clients[${openAccordianIndex}].providerLocationType`,
                                                    value,
                                                  );
                                                }}
                                                value={
                                                  values.clients[sectionIndex]
                                                    .providerLocationType
                                                }
                                                disable={
                                                  item.status === 'Completed' ||
                                                  item.status === 'Canceled' ||
                                                  !validUser
                                                }
                                              />
                                              {errors.clients &&
                                                errors.clients[
                                                  openAccordianIndex
                                                ] &&
                                                (
                                                  errors.clients[
                                                    openAccordianIndex
                                                  ] as FormikErrors<ProgressNoteClient>
                                                ).providerLocationType &&
                                                touched.clients &&
                                                touched.clients[
                                                  openAccordianIndex
                                                ].providerLocationType && (
                                                  <MediumTypography
                                                    labelid={
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).providerLocationType
                                                    }
                                                    defaultlabel="Provider Location is Required"
                                                    sxProps={{
                                                      marginTop: '24px',
                                                      color:
                                                        'var( --thom-radical-red)',
                                                      position: 'absolute',
                                                    }}
                                                  />
                                                )}
                                            </Box>
                                          </Box>
                                        )}

                                        {initialData.clients[
                                          openAccordianIndex
                                        ].visits.map((__, index) => (
                                          <Box
                                            sx={{ display: 'flex' }}
                                            key={index}
                                          >
                                            <Box
                                              sx={{
                                                backgroundColor: '#ffffff',
                                                border: '1px solid #CFDEDE',
                                                borderRadius: '4px',
                                                padding: '24px',
                                                marginBottom:
                                                  index !==
                                                  initialData.clients[
                                                    openAccordianIndex
                                                  ].visits.length -
                                                    1
                                                    ? '16px'
                                                    : '0px',
                                              }}
                                            >
                                              {/* Start time and Duration */}
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  paddingBottom:
                                                    errors.clients &&
                                                    errors.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    (
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] as FormikErrors<ProgressNoteClient>
                                                    ).visits &&
                                                    (
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits as FormikErrors<
                                                        ProgressNoteVisit[]
                                                      >
                                                    )[index]?.startTime &&
                                                    touched.clients &&
                                                    touched.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index]
                                                      ?.startTime
                                                      ? '24px'
                                                      : '16px',
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    width: '170px',
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <AppTimePicker
                                                    name="Start Time"
                                                    disableManualInput={true}
                                                    needCrossIcon={false}
                                                    value={
                                                      values.clients[
                                                        sectionIndex
                                                      ].visits[index] &&
                                                      values.clients[
                                                        sectionIndex
                                                      ].visits[index]
                                                        .startTime !== ''
                                                        ? dayjs(
                                                            values.clients[
                                                              sectionIndex
                                                            ].visits[index]
                                                              .startTime,
                                                            'HH:mm',
                                                          )
                                                        : null
                                                    }
                                                    onTimeChange={(
                                                      time: Dayjs | null,
                                                    ) => {
                                                      if (time === null) {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.startTime`,
                                                          '',
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.startTime`,
                                                          time.format('HH:mm'),
                                                        );
                                                      }
                                                    }}
                                                    sxButtonProps={{
                                                      height: '36px',
                                                      borderColor:
                                                        item.status ===
                                                          'Completed' ||
                                                        !validUser
                                                          ? '#CFDEDE'
                                                          : undefined,
                                                    }}
                                                    disable={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser
                                                    }
                                                    minuteStep={1}
                                                  />
                                                  {errors.clients &&
                                                    errors.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    (
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] as FormikErrors<ProgressNoteClient>
                                                    ).visits &&
                                                    (
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits as FormikErrors<
                                                        ProgressNoteVisit[]
                                                      >
                                                    )[index]?.startTime &&
                                                    touched.clients &&
                                                    touched.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index]
                                                      ?.startTime && (
                                                      <MediumTypography
                                                        labelid={
                                                          (
                                                            errors.clients[
                                                              openAccordianIndex
                                                            ] as FormikErrors<ProgressNoteClient>
                                                          ).visits &&
                                                          (
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            )
                                                              .visits as FormikErrors<
                                                              ProgressNoteVisit[]
                                                            >
                                                          )[index]?.startTime
                                                        }
                                                        defaultlabel="Start Time is Required"
                                                        sxProps={{
                                                          color:
                                                            'var( --thom-radical-red)',
                                                          position: 'absolute',
                                                        }}
                                                      />
                                                    )}
                                                </Box>
                                                {/* Duration */}
                                                <Box>
                                                  {values.clients[
                                                    openAccordianIndex
                                                  ].visits[index]?.duration && (
                                                    <DurationPicker
                                                      interval={25}
                                                      disabled={
                                                        item.status ===
                                                          'Completed' ||
                                                        item.status ===
                                                          'Canceled' ||
                                                        !validUser
                                                      }
                                                      required={false}
                                                      labelId="ProgressNote.duration"
                                                      defaultLabel="Duration"
                                                      maxDuration={{
                                                        hour: 24,
                                                        minutes: 0,
                                                      }}
                                                      duration={formatStringDateInDecimals(
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          ?.duration,
                                                      )}
                                                      setDuration={(
                                                        duration,
                                                      ) => {
                                                        setStartDuration(
                                                          duration.hour,
                                                        );
                                                        setEndDuration(
                                                          duration.minutes,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.duration`,
                                                          formatTimeDurationInDecimals(
                                                            duration,
                                                          ),
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                  {errors.clients &&
                                                    errors.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    (
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] as FormikErrors<ProgressNoteClient>
                                                    ).visits &&
                                                    (
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits as FormikErrors<
                                                        ProgressNoteVisit[]
                                                      >
                                                    )[index]?.duration &&
                                                    touched.clients &&
                                                    touched.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index]
                                                      ?.duration && (
                                                      <MediumTypography
                                                        labelid={
                                                          (
                                                            errors.clients[
                                                              openAccordianIndex
                                                            ] as FormikErrors<ProgressNoteClient>
                                                          ).visits &&
                                                          (
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            )
                                                              .visits as FormikErrors<
                                                              ProgressNoteVisit[]
                                                            >
                                                          )[index]?.duration
                                                        }
                                                        defaultlabel="Duration is Required"
                                                        sxProps={{
                                                          color:
                                                            'var( --thom-radical-red)',
                                                          marginTop: '8px',
                                                        }}
                                                      />
                                                    )}
                                                </Box>
                                              </Box>
                                              {/* Codes */}
                                              <Grid
                                                container
                                                xs={12}
                                                sx={{
                                                  marginBottom:
                                                    (errors.clients &&
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits &&
                                                      (
                                                        (
                                                          errors.clients[
                                                            openAccordianIndex
                                                          ] as FormikErrors<ProgressNoteClient>
                                                        )
                                                          .visits as FormikErrors<
                                                          ProgressNoteVisit[]
                                                        >
                                                      )[index]?.serviceCode
                                                        ?.id &&
                                                      touched.clients &&
                                                      touched.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index]
                                                        ?.serviceCode?.id) ||
                                                    (errors.clients &&
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits &&
                                                      (
                                                        (
                                                          errors.clients[
                                                            openAccordianIndex
                                                          ] as FormikErrors<ProgressNoteClient>
                                                        )
                                                          .visits as FormikErrors<
                                                          ProgressNoteVisit[]
                                                        >
                                                      )[index]?.settingCode
                                                        ?.id &&
                                                      touched.clients &&
                                                      touched.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index]
                                                        ?.settingCode?.id)
                                                      ? '32px'
                                                      : '16px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  sx={{ paddingRight: '20px' }}
                                                >
                                                  <AutocompleteSearch
                                                    key={index}
                                                    labelId={
                                                      'ProgressNote.ServiceCode'
                                                    }
                                                    defaultId={'Service Code'}
                                                    selectedValue={{
                                                      id:
                                                        values.clients &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ] &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          ? values.clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              .serviceCode?.id
                                                          : props
                                                              .progressNoteData
                                                              .clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              ?.serviceCode?.id,
                                                      label:
                                                        values.clients &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ] &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          ? values.clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              .serviceCode
                                                              ?.label
                                                          : props
                                                              .progressNoteData
                                                              .clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              ?.serviceCode
                                                              ?.label,
                                                    }}
                                                    data={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].status === 'Completed'
                                                        ? [
                                                            item.visits[index]
                                                              .serviceCode,
                                                          ]
                                                        : serviceCode
                                                            .filter((code) => {
                                                              return (
                                                                code.active ||
                                                                code.code ===
                                                                  item.visits[
                                                                    index
                                                                  ].serviceCode
                                                                    .id
                                                              );
                                                            })
                                                            .map((code) => ({
                                                              id: code.code,
                                                              label: `${code.code} - ${code.description}`,
                                                            }))
                                                    }
                                                    onSelectItem={(
                                                      value: OptionType | null,
                                                    ) => {
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                        '',
                                                      );
                                                      if (value) {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.serviceCode`,
                                                          {
                                                            id: value.id,
                                                            label: value.label,
                                                          },
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.settingCode`,
                                                          {
                                                            id: '',
                                                            label: '',
                                                          },
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                          false,
                                                        );
                                                        setTimeout(() => {
                                                          handelCoTreatmentDuration(
                                                            index,
                                                            false,
                                                          );
                                                        }, 100);
                                                      } else {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.serviceCode`,
                                                          {
                                                            id: '',
                                                            label: '',
                                                          },
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.settingCode`,
                                                          {
                                                            id: '',
                                                            label: '',
                                                          },
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                          false,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                          false,
                                                        );
                                                        setTimeout(() => {
                                                          handelCoTreatmentDuration(
                                                            index,
                                                            false,
                                                          );
                                                        }, 100);
                                                      }
                                                    }}
                                                    disableLabel={false}
                                                    disabled={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser
                                                    }
                                                  />
                                                  {errors.clients &&
                                                    errors.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    (
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] as FormikErrors<ProgressNoteClient>
                                                    ).visits &&
                                                    (
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits as FormikErrors<
                                                        ProgressNoteVisit[]
                                                      >
                                                    )[index]?.serviceCode?.id &&
                                                    touched.clients &&
                                                    touched.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index]
                                                      ?.serviceCode?.id && (
                                                      <MediumTypography
                                                        labelid={
                                                          (
                                                            errors.clients[
                                                              openAccordianIndex
                                                            ] as FormikErrors<ProgressNoteClient>
                                                          ).visits &&
                                                          (
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            )
                                                              .visits as FormikErrors<
                                                              ProgressNoteVisit[]
                                                            >
                                                          )[index]?.serviceCode
                                                            ?.id
                                                        }
                                                        defaultlabel="Service code is Required"
                                                        sxProps={{
                                                          color:
                                                            'var( --thom-radical-red)',
                                                          position: 'absolute',
                                                          marginTop: '8px',
                                                        }}
                                                      />
                                                    )}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  sx={{ paddingLeft: '20px' }}
                                                >
                                                  <AutocompleteSearch
                                                    key={index}
                                                    labelId={
                                                      'ProgressNote.SettingsCode'
                                                    }
                                                    defaultId={'Settings Code'}
                                                    onSelectItem={(
                                                      value: OptionType | null,
                                                    ) => {
                                                      if (value) {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.settingCode`,
                                                          {
                                                            id: value.id,
                                                            label: value.label,
                                                          },
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.settingCode`,
                                                          {
                                                            id: '',
                                                            label: '',
                                                          },
                                                        );
                                                      }
                                                    }}
                                                    data={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].status === 'Completed'
                                                        ? [
                                                            item.visits[index]
                                                              .settingCode,
                                                          ]
                                                        : values.clients[
                                                            openAccordianIndex
                                                          ].visits[index]
                                                            .serviceCode.id ===
                                                          ''
                                                        ? []
                                                        : settingCode
                                                            .filter((code) => {
                                                              const dhpServiceCode =
                                                                serviceCode.find(
                                                                  (cod) =>
                                                                    cod.code ===
                                                                    values
                                                                      .clients[
                                                                      openAccordianIndex
                                                                    ].visits[
                                                                      index
                                                                    ]
                                                                      .serviceCode
                                                                      ?.id,
                                                                )?.dphServiceCode;

                                                              return code?.dphServiceCode?.includes(
                                                                dhpServiceCode ||
                                                                  '',
                                                              );
                                                            })
                                                            .map((code) => ({
                                                              id: code.code,
                                                              label: `${code.code} - ${code.description}`,
                                                            }))
                                                    }
                                                    selectedValue={{
                                                      id:
                                                        values.clients &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ] &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          ? values.clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              .settingCode?.id
                                                          : props
                                                              .progressNoteData
                                                              .clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              ?.settingCode?.id,
                                                      label:
                                                        values.clients &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ] &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          ? values.clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              .settingCode
                                                              ?.label
                                                          : props
                                                              .progressNoteData
                                                              .clients[
                                                              openAccordianIndex
                                                            ].visits[index]
                                                              ?.settingCode
                                                              ?.label,
                                                    }}
                                                    disableLabel={false}
                                                    disabled={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser
                                                    }
                                                  />
                                                  {errors.clients &&
                                                    errors.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    (
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] as FormikErrors<ProgressNoteClient>
                                                    ).visits &&
                                                    (
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits as FormikErrors<
                                                        ProgressNoteVisit[]
                                                      >
                                                    )[index]?.settingCode?.id &&
                                                    touched.clients &&
                                                    touched.clients[
                                                      openAccordianIndex
                                                    ] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index] &&
                                                    touched.clients?.[
                                                      openAccordianIndex
                                                    ]?.visits?.[index]
                                                      ?.settingCode?.id && (
                                                      <MediumTypography
                                                        labelid={
                                                          (
                                                            errors.clients[
                                                              openAccordianIndex
                                                            ] as FormikErrors<ProgressNoteClient>
                                                          ).visits &&
                                                          (
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            )
                                                              .visits as FormikErrors<
                                                              ProgressNoteVisit[]
                                                            >
                                                          )[index]?.settingCode
                                                            ?.id
                                                        }
                                                        defaultlabel="Settings code is Required"
                                                        sxProps={{
                                                          color:
                                                            'var( --thom-radical-red)',
                                                          position: 'absolute',
                                                          marginTop: '8px',
                                                        }}
                                                      />
                                                    )}
                                                </Grid>
                                              </Grid>

                                              {/* CoTx and Type */}
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                {/* CoTx */}
                                                <Box>
                                                  <MediumTypography
                                                    labelid="ProgressNote.Modifier"
                                                    defaultlabel="Modifier:"
                                                    sxProps={{
                                                      paddingRight: '8px',
                                                      fontSize: '16px',
                                                    }}
                                                    textColor="#97A6A5"
                                                  />
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <CheckBoxComponent
                                                    ischecked={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index] &&
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index].coTx
                                                    }
                                                    CheckHandleChange={(
                                                      value,
                                                    ) => {
                                                      if (
                                                        value.target.checked
                                                      ) {
                                                        if (
                                                          values.clients[
                                                            openAccordianIndex
                                                          ].isCotxForGivenMonth
                                                        ) {
                                                          setIsCotxForGivenMonth(
                                                            true,
                                                          );
                                                        } else {
                                                          setFieldValue(
                                                            `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                            value.target
                                                              .checked,
                                                          );
                                                          setFieldValue(
                                                            `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                            false,
                                                          );
                                                          setFieldValue(
                                                            `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                            false,
                                                          );
                                                          setFieldValue(
                                                            `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                            false,
                                                          );
                                                          setTimeout(() => {
                                                            handelCoTreatmentDuration(
                                                              index,
                                                              value.target
                                                                .checked,
                                                            );
                                                          }, 100);
                                                        }
                                                      } else {
                                                        setTimeout(() => {
                                                          handelCoTreatmentDuration(
                                                            index,
                                                            value.target
                                                              .checked,
                                                          );
                                                        }, 100);
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                          value.target.checked,
                                                        );
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                          '',
                                                        );
                                                      }
                                                    }}
                                                    disabled={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser ||
                                                      (values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .serviceCode.id !==
                                                        'TA' &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          .serviceCode.id !==
                                                          'TB') ||
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits.some(
                                                        (visit, i) =>
                                                          visit.coTx &&
                                                          i !== index,
                                                      )
                                                    }
                                                    labelid="ProgressNote.CoTreatment"
                                                    defaultlabelid="Co-Treatment"
                                                  />
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <CheckBoxComponent
                                                    ischecked={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index] &&
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .ifspMeeting
                                                    }
                                                    CheckHandleChange={(
                                                      value,
                                                    ) => {
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                        '',
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                        value.target.checked,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                        false,
                                                      );
                                                      setTimeout(() => {
                                                        handelCoTreatmentDuration(
                                                          index,
                                                          false,
                                                        );
                                                      }, 100);
                                                    }}
                                                    disabled={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser ||
                                                      (values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .serviceCode.id !==
                                                        'TA' &&
                                                        values.clients[
                                                          openAccordianIndex
                                                        ].visits[index]
                                                          .serviceCode.id !==
                                                          'TB')
                                                    }
                                                    labelid="ProgressNote.IFSPMeeting"
                                                    defaultlabelid="IFSP Meeting"
                                                  />
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <CheckBoxComponent
                                                    ischecked={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index] &&
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .asessmentHomeVisit
                                                    }
                                                    CheckHandleChange={(
                                                      value,
                                                    ) => {
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                        '',
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                        value.target.checked,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                        false,
                                                      );
                                                      setTimeout(() => {
                                                        handelCoTreatmentDuration(
                                                          index,
                                                          false,
                                                        );
                                                      }, 100);
                                                    }}
                                                    disabled={
                                                      item.status ===
                                                        'Completed' ||
                                                      item.status ===
                                                        'Canceled' ||
                                                      !validUser ||
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .serviceCode.id !== 'TA'
                                                    }
                                                    labelid="ProgressNote.AssessmentHomeVisit"
                                                    defaultlabelid="Assessment Home Visit"
                                                  />
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <CheckBoxComponent
                                                    ischecked={
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index] &&
                                                      values.clients[
                                                        openAccordianIndex
                                                      ].visits[index]
                                                        .supervisionCoTxHV
                                                    }
                                                    CheckHandleChange={(
                                                      value,
                                                    ) => {
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                        '',
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.supervisionCoTxHV`,
                                                        value.target.checked,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.coTx`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.ifspMeeting`,
                                                        false,
                                                      );
                                                      setFieldValue(
                                                        `clients.${openAccordianIndex}.visits.${index}.asessmentHomeVisit`,
                                                        false,
                                                      );
                                                      setTimeout(() => {
                                                        handelCoTreatmentDuration(
                                                          index,
                                                          false,
                                                        );
                                                      }, 100);
                                                    }}
                                                    disabled={true}
                                                    labelid="ProgressNote.SupervisionCoTxHV"
                                                    defaultlabelid="Supervision Co-Tx HV"
                                                  />
                                                </Box>
                                                {/* Type */}
                                                <Box
                                                  sx={{
                                                    display: 'none',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <MediumTypography
                                                    labelid="ProgressNote.Type"
                                                    defaultlabel="Type:"
                                                    sxProps={{
                                                      paddingRight: '8px',
                                                      fontSize: '16px',
                                                    }}
                                                    textColor="#97A6A5"
                                                  />
                                                  <Box sx={{ display: 'flex' }}>
                                                    <CustomRadioButton
                                                      needUnSelect={false}
                                                      data={
                                                        appointmentStageRadioData
                                                      }
                                                      onSelectItem={(value) => {
                                                        setFieldValue(
                                                          `clients.${openAccordianIndex}.visits.${index}.appointmentStage`,
                                                          value,
                                                        );
                                                      }}
                                                      value={
                                                        values.clients[
                                                          sectionIndex
                                                        ].visits[index] &&
                                                        values.clients[
                                                          sectionIndex
                                                        ].visits[index]
                                                          .appointmentStage
                                                      }
                                                      disable={
                                                        item.status ===
                                                          'Completed' ||
                                                        item.status ===
                                                          'Canceled' ||
                                                        !validUser
                                                      }
                                                    />
                                                    {errors.clients &&
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits &&
                                                      (
                                                        (
                                                          errors.clients[
                                                            openAccordianIndex
                                                          ] as FormikErrors<ProgressNoteClient>
                                                        )
                                                          .visits as FormikErrors<
                                                          ProgressNoteVisit[]
                                                        >
                                                      )[index]
                                                        ?.appointmentStage &&
                                                      touched.clients &&
                                                      touched.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index]
                                                        ?.appointmentStage && (
                                                        <MediumTypography
                                                          labelid={
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            ).visits &&
                                                            (
                                                              (
                                                                errors.clients[
                                                                  openAccordianIndex
                                                                ] as FormikErrors<ProgressNoteClient>
                                                              )
                                                                .visits as FormikErrors<
                                                                ProgressNoteVisit[]
                                                              >
                                                            )[index]
                                                              ?.appointmentStage
                                                          }
                                                          defaultlabel="Type is Required"
                                                          sxProps={{
                                                            color:
                                                              'var( --thom-radical-red)',
                                                            position:
                                                              'absolute',
                                                            paddingTop: '18px',
                                                          }}
                                                        />
                                                      )}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              {values.clients[
                                                openAccordianIndex
                                              ].visits[index].coTx && (
                                                <>
                                                  <Grid
                                                    xs={12}
                                                    container
                                                    sx={{ marginTop: '16px' }}
                                                  >
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sx={{
                                                        paddingRight: '20px',
                                                      }}
                                                    >
                                                      <AutocompleteSearch
                                                        data={
                                                          props.progressNoteData.clients[
                                                            openAccordianIndex
                                                          ].attendeeList
                                                            ?.filter(
                                                              (clickedId) =>
                                                                clickedId.attendeeId !==
                                                                props.clickedAttendeeId,
                                                            )
                                                            .map(
                                                              (
                                                                filteredValue,
                                                              ) => ({
                                                                id: filteredValue.attendeeId,
                                                                label:
                                                                  filteredValue.name,
                                                              }),
                                                            ) || []
                                                        }
                                                        labelId="ProgressNote.Clinician"
                                                        defaultId="Clinician"
                                                        selectedValue={{
                                                          id:
                                                            (props
                                                              .progressNoteData
                                                              .clients[
                                                              openAccordianIndex
                                                            ].attendeeList &&
                                                              props.progressNoteData.clients[
                                                                openAccordianIndex
                                                              ].attendeeList?.filter(
                                                                (clickedId) =>
                                                                  clickedId.attendeeId ===
                                                                  values
                                                                    .clients[
                                                                    openAccordianIndex
                                                                  ].visits[
                                                                    index
                                                                  ]
                                                                    .coTreatPartnerName,
                                                              )[0]
                                                                ?.attendeeId) ||
                                                            '',
                                                          label:
                                                            props.progressNoteData.clients[
                                                              openAccordianIndex
                                                            ].attendeeList?.filter(
                                                              (clickedName) =>
                                                                clickedName.attendeeId ===
                                                                values.clients[
                                                                  openAccordianIndex
                                                                ].visits[index]
                                                                  .coTreatPartnerName,
                                                            )[0]?.name || '',
                                                        }}
                                                        onSelectItem={(
                                                          value,
                                                        ) => {
                                                          if (value) {
                                                            setFieldValue(
                                                              `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                              value?.id,
                                                            );
                                                          } else {
                                                            setFieldValue(
                                                              `clients.${openAccordianIndex}.visits.${index}.coTreatPartnerName`,
                                                              null,
                                                            );
                                                          }
                                                        }}
                                                        disableLabel={false}
                                                        disabled={
                                                          item.status ===
                                                            'Completed' ||
                                                          item.status ===
                                                            'Canceled' ||
                                                          !validUser
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box>
                                                    {errors.clients &&
                                                      errors.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      (
                                                        errors.clients[
                                                          openAccordianIndex
                                                        ] as FormikErrors<ProgressNoteClient>
                                                      ).visits &&
                                                      (
                                                        (
                                                          errors.clients[
                                                            openAccordianIndex
                                                          ] as FormikErrors<ProgressNoteClient>
                                                        )
                                                          .visits as FormikErrors<
                                                          ProgressNoteVisit[]
                                                        >
                                                      )[index]
                                                        ?.coTreatPartnerName &&
                                                      touched.clients &&
                                                      touched.clients[
                                                        openAccordianIndex
                                                      ] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index] &&
                                                      touched.clients?.[
                                                        openAccordianIndex
                                                      ]?.visits?.[index]
                                                        ?.coTreatPartnerName && (
                                                        <MediumTypography
                                                          labelid={
                                                            (
                                                              errors.clients[
                                                                openAccordianIndex
                                                              ] as FormikErrors<ProgressNoteClient>
                                                            ).visits &&
                                                            (
                                                              (
                                                                errors.clients[
                                                                  openAccordianIndex
                                                                ] as FormikErrors<ProgressNoteClient>
                                                              )
                                                                .visits as FormikErrors<
                                                                ProgressNoteVisit[]
                                                              >
                                                            )[index]
                                                              ?.coTreatPartnerName
                                                          }
                                                          defaultlabel="Clinician is Required"
                                                          sxProps={{
                                                            color:
                                                              'var( --thom-radical-red)',
                                                            // position: 'absolute',
                                                            marginTop: '8px',
                                                          }}
                                                        />
                                                      )}
                                                  </Box>
                                                </>
                                              )}
                                            </Box>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end',
                                                marginBottom:
                                                  index !==
                                                  initialData.clients[
                                                    openAccordianIndex
                                                  ].visits.length -
                                                    1
                                                    ? '24px'
                                                    : '16px',
                                                marginLeft: '8px',
                                              }}
                                            >
                                              {values.clients[
                                                openAccordianIndex
                                              ].visits.length > 1 &&
                                                validUser &&
                                                item.status !== 'Completed' && (
                                                  <Delete
                                                    onClick={() => {
                                                      if (formikRef.current) {
                                                        const clientsTouched =
                                                          touched.clients ?? [];
                                                        const { clients } =
                                                          formikRef.current
                                                            .values;

                                                        if (
                                                          clients &&
                                                          clients[
                                                            openAccordianIndex
                                                          ]
                                                        ) {
                                                          const currentClient =
                                                            clients[
                                                              openAccordianIndex
                                                            ];
                                                          const clientTouched =
                                                            clientsTouched[
                                                              openAccordianIndex
                                                            ] || {};
                                                          const clientVisits =
                                                            clientTouched.visits ||
                                                            [];

                                                          // Create touchedVisit object with all fields marked as false
                                                          const touchedVisit: {
                                                            [
                                                              key: string
                                                            ]: boolean;
                                                          } = {};
                                                          Object.keys(
                                                            currentClient
                                                              .visits[index] ||
                                                              {},
                                                          ).forEach((key) => {
                                                            touchedVisit[key] =
                                                              false;
                                                          });

                                                          // Ensure that the visits array is not empty before accessing its length
                                                          if (
                                                            currentClient.visits
                                                              .length > 0
                                                          ) {
                                                            // Assign touchedVisit to the last visit of the current client
                                                            clientVisits[
                                                              currentClient
                                                                .visits.length -
                                                                1
                                                            ] = touchedVisit;
                                                          }

                                                          // Update clientTouched object
                                                          clientTouched.visits =
                                                            clientVisits;
                                                          clientsTouched[
                                                            openAccordianIndex
                                                          ] = clientTouched;

                                                          // Update Formik's touched state
                                                          formikRef.current.setTouched(
                                                            {
                                                              ...touched,
                                                              clients:
                                                                clientsTouched,
                                                            },
                                                          );
                                                        }
                                                      }
                                                      deleteVisit(index);
                                                    }}
                                                  />
                                                )}
                                            </Box>
                                          </Box>
                                        ))}
                                        {validUser &&
                                          item.status !== 'Completed' && (
                                            <Box
                                              sx={{
                                                marginTop: '16px',
                                                cursor: 'pointer',
                                                display: 'inline-flex',
                                              }}
                                              onClick={addVisit}
                                            >
                                              <MediumTypography
                                                labelid="ProgressNote.AddVisit"
                                                defaultlabel="+ Add Visit"
                                                textColor="#00C6B8"
                                                fontSize="16px"
                                                fontweight={700}
                                                sxProps={{
                                                  textDecorationLine:
                                                    'underline',
                                                  textUnderlineOffset: '3px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                      </Box>
                                      {/* Visiter */}
                                      <Box
                                        sx={{
                                          paddingY: '24px',
                                          marginX: '24px',
                                          borderBottom: '1px solid #00C6B8',
                                        }}
                                      >
                                        <MediumTypography
                                          labelid="ProgressNote.PresentForVisit"
                                          defaultlabel="Present for Visit"
                                          textColor="#213262"
                                          sxProps={{
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            paddingBottom: '16px',
                                          }}
                                        />
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: '400px',
                                              paddingRight: '16px',
                                            }}
                                          >
                                            <MultipleAutocompleteSearch
                                              data={
                                                item &&
                                                item.contact &&
                                                item.contact
                                                  .filter(
                                                    (contact) =>
                                                      contact.name &&
                                                      contact.type !== 'Family',
                                                  )
                                                  .map((contact) => ({
                                                    id: contact.contactId,
                                                    label:
                                                      contact.name ??
                                                      'Referral Contact',
                                                  }))
                                              }
                                              sxProps={{
                                                backgroundColor:
                                                  item.status === 'Completed' ||
                                                  !validUser
                                                    ? '#CFDEDE'
                                                    : '#ffffff',
                                              }}
                                              labelId="ProgressNote.SelectConatct"
                                              defaultId="Select name"
                                              onSelectItem={(value) => {
                                                const selectedContactIds =
                                                  value.map(
                                                    (contact) => contact.id,
                                                  );
                                                setFieldValue(
                                                  `clients.${openAccordianIndex}.selectedContacts`,
                                                  selectedContactIds,
                                                );
                                                const filteredContacts =
                                                  item.contact.filter(
                                                    (contact) =>
                                                      selectedContactIds.includes(
                                                        contact.contactId,
                                                      ),
                                                  );
                                                setMultiSelectContact(
                                                  filteredContacts,
                                                );
                                              }}
                                              value={values.clients[
                                                openAccordianIndex
                                              ].selectedContacts.map(
                                                (contactId) => ({
                                                  id: contactId,
                                                  label:
                                                    values.clients[
                                                      sectionIndex
                                                    ].contact.find(
                                                      (contact) =>
                                                        contact.contactId ===
                                                        contactId,
                                                    )?.name || '',
                                                }),
                                              )}
                                              optionHintId={'No contacts found'}
                                              disabled={
                                                item.status === 'Completed' ||
                                                item.status === 'Canceled' ||
                                                !validUser
                                              }
                                            />
                                          </Box>
                                        </Box>
                                        {multiSelectContact &&
                                        multiSelectContact.length > 0 ? (
                                          <SelectedContacts
                                            multiSelectContact={
                                              multiSelectContact
                                            }
                                          />
                                        ) : null}
                                      </Box>
                                      <Box
                                        sx={{
                                          paddingY: '24px',
                                          marginX: '24px',
                                          borderBottom: '1px solid #00C6B8',
                                        }}
                                      >
                                        <MediumTypography
                                          labelid="ProgressNote.OtherPresentForVisit"
                                          defaultlabel="Others present for the visit"
                                          textColor="#213262"
                                          sxProps={{
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            paddingBottom: '16px',
                                          }}
                                        />
                                        <Textinput
                                          className="bg__white"
                                          sxProps={{ width: '385px' }}
                                          inputProps={{ maxLength: 200 }}
                                          defaultlabelid="Others present for the visit"
                                          labelid="ProgressNote.OtherPresentForVisit"
                                          Value={
                                            values.clients[openAccordianIndex]
                                              .othersPresentInVisit
                                          }
                                          handlechange={(value: string) => {
                                            setFieldValue(
                                              `clients[${openAccordianIndex}].othersPresentInVisit`,
                                              value,
                                            );
                                          }}
                                          disabled={
                                            item.status === 'Completed' ||
                                            item.status === 'Canceled' ||
                                            !validUser
                                          }
                                        />

                                        {props.interpreter && (
                                          <Box className="text-align-end">
                                            <MediumTypography
                                              className="mainText-md mt-md"
                                              label={`* ${
                                                props.interpreter.firstName ??
                                                ''
                                              } ${
                                                props.interpreter.lastName ?? ''
                                              } ${rctl.formatMessage({
                                                id: 'interpreterExistText',
                                                defaultMessage:
                                                  'is present for the visit as interpreter',
                                              })}`}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </div>
                                  {/* Comment */}
                                  {item.client.clientId ===
                                  values.clients[openAccordianIndex].client
                                    .clientId ? (
                                    <Box
                                      sx={{
                                        paddingY: '24px',
                                        marginX: '24px',
                                        borderBottom: '1px solid #00C6B8',
                                      }}
                                    >
                                      <MediumTypography
                                        labelid="ProgressNote.Notes"
                                        defaultlabel="Notes"
                                        textColor="#213262"
                                        sxProps={{
                                          fontSize: '18px',
                                          fontWeight: 600,
                                          paddingBottom: '16px',
                                        }}
                                      />
                                      <VoiceRecognition
                                        initialNote={
                                          item.client.clientId ===
                                          values.clients[openAccordianIndex]
                                            .client.clientId
                                            ? values.clients[openAccordianIndex]
                                                .notes
                                            : 'kuh'
                                        }
                                        onNoteChange={(value: string) => {
                                          setFieldValue(
                                            `clients[${openAccordianIndex}].notes`,
                                            value,
                                          );
                                        }}
                                        disabled={
                                          item.status === 'Completed' ||
                                          item.status === 'Canceled' ||
                                          !validUser
                                        }
                                      />
                                      {errors.clients &&
                                        errors.clients[openAccordianIndex] &&
                                        (
                                          errors.clients[
                                            openAccordianIndex
                                          ] as FormikErrors<ProgressNoteClient>
                                        ).notes &&
                                        touched.clients &&
                                        touched.clients[openAccordianIndex]
                                          .notes && (
                                          <MediumTypography
                                            labelid={
                                              (
                                                errors.clients[
                                                  openAccordianIndex
                                                ] as FormikErrors<ProgressNoteClient>
                                              ).notes
                                            }
                                            defaultlabel="Note is Required"
                                            sxProps={{
                                              paddingTop: '8px',
                                              color: 'var( --thom-radical-red)',
                                            }}
                                          />
                                        )}
                                    </Box>
                                  ) : null}
                                  {/* Signature */}
                                  <Box
                                    sx={{ marginTop: '24px', marginX: '24px' }}
                                  >
                                    <MediumTypography
                                      labelid="ProgressNote.Signature"
                                      defaultlabel="Signature"
                                      textColor="#213262"
                                      sxProps={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        paddingBottom: '8px',
                                      }}
                                    />
                                    <Box sx={{ paddingBottom: '24px' }}>
                                      {values.clients[openAccordianIndex]
                                        .signature.signatureValue === '' && (
                                        <ButtonComponent
                                          className="btn-primary btn-cancel signature_add_Box"
                                          labelId="Insurance.Addsignature"
                                          defaultLabelId="Add Signature"
                                          variantType="outlined"
                                          onClick={() => setSignClicked(true)}
                                          disabled={!validUser || offline}
                                        />
                                      )}
                                      {values.clients[openAccordianIndex]
                                        .signature.signatureValue !== '' && (
                                        <ButtonComponent
                                          className="btn-primary btn-cancel signature_view_Box"
                                          labelId={
                                            item.status === 'Completed'
                                              ? 'ProgressNoteViewSignature'
                                              : 'ProgressNote.ViewUpdateSignature'
                                          }
                                          defaultLabelId={
                                            item.status === 'Completed'
                                              ? 'View signature'
                                              : 'View/update signature'
                                          }
                                          variantType="outlined"
                                          LeftIcon={<Editsignature />}
                                          onClick={() => setSignClicked(true)}
                                          disabled={offline}
                                        />
                                      )}
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                      <Box sx={{ display: 'flex' }}>
                                        <MediumTypography
                                          labelid="name"
                                          defaultlabel="Name:"
                                          textColor="#97A6A5"
                                          sxProps={{ fontSize: '16px' }}
                                        />
                                        <MediumTypography
                                          sxProps={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            paddingLeft:
                                              item.attendeeName === ''
                                                ? '24px'
                                                : '6px',
                                            color: '#2A4241',
                                          }}
                                          label={
                                            attendeeName === ''
                                              ? '-'
                                              : attendeeName
                                          }
                                        />
                                      </Box>
                                      {(attendeeLicenseNo !== '' ||
                                        attendeeLicenseNo !== null) && (
                                        <Box sx={{ display: 'flex' }}>
                                          <MediumTypography
                                            labelid="ProgressNote.LicenseNo"
                                            defaultlabel="License No:"
                                            textColor="#97A6A5"
                                            sxProps={{
                                              fontSize: '16px',
                                              paddingLeft: '32px',
                                            }}
                                          />
                                          <MediumTypography
                                            sxProps={{
                                              fontSize: '16px',
                                              fontWeight: 500,
                                              paddingLeft: '6px',
                                              color: '#2A4241',
                                              fontFamily: 'Lato-Regular',
                                            }}
                                            label={attendeeLicenseNo || '-'}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                    {/* Footer botton */}
                                    {validUser && (
                                      <Grid className="flex__ justifyContent-FlexEnd pt-lg ">
                                        <Grid item>
                                          <ButtonComponent
                                            className="btn-primary btn-cancel"
                                            labelId="ProgressNote.Cancel"
                                            defaultLabelId="Cancel"
                                            onClick={() => {
                                              for (const property of propertiesToCheck) {
                                                if (
                                                  !_.isEqual(
                                                    _.get(
                                                      origainalInitialData,
                                                      `clients[${openAccordianIndex}].${property}`,
                                                    ),
                                                    _.get(
                                                      values,
                                                      `clients[${openAccordianIndex}].${property}`,
                                                    ),
                                                  )
                                                ) {
                                                  hasChanged = true;
                                                  break;
                                                }
                                              }
                                              if (hasChanged) {
                                                setShowAlertDialog(true);
                                              } else if (
                                                !hasChanged &&
                                                clientCount === 1
                                              ) {
                                                navigateTo();
                                              } else if (
                                                !hasChanged &&
                                                clientCount > 1
                                              ) {
                                                setOpenAccordian(false);
                                              }
                                              turnOfMic();
                                              // }
                                            }}
                                          />
                                        </Grid>

                                        <Grid item>
                                          <ButtonComponent
                                            className="btn-primary btn-submit ml-md"
                                            labelId="ProgressNote.Save"
                                            defaultLabelId="Save"
                                            onClick={() => {
                                              if (
                                                formikRef.current &&
                                                formikRef.current.values &&
                                                formikRef.current.values
                                                  .clients[openAccordianIndex]
                                                  .cancelReason &&
                                                formikRef.current.values
                                                  .clients[openAccordianIndex]
                                                  .status === 'Pending'
                                              ) {
                                                handlePendingSubmit(
                                                  formikRef.current
                                                    .values as ProgressNoteType,
                                                );
                                              } else {
                                                turnOfMic();
                                                checkNoteEntered();
                                                handleSubmit();
                                              }
                                            }}
                                            disabled={
                                              item.status === 'Completed' ||
                                              item.status === 'Canceled' ||
                                              !validUser
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Box>
                                </Box>
                              )}
                          </Box>
                        );
                      })}
                  </Card>
                </Box>
              );
            }}
          </Formik>
        )}
      <ModalPopup
        open={isAnyDurationZero}
        onClose={() => setIsAnyDurationZero(false)}
        onOk={() => {
          setIsAnyDurationZero(false);
          turnOfMic();
          checkNoteEntered();
          handleOnSubmit(formikRef.current?.values as ProgressNoteType);
        }}
        onCancel={() => setIsAnyDurationZero(false)}
        description="ProgressNote.durationZeroError"
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      <ModalPopup
        open={isCotxForGivenMonth}
        onClose={() => setIsCotxForGivenMonth(false)}
        onOk={() => {
          setIsCotxForGivenMonth(false);
        }}
        onCancel={() => setIsCotxForGivenMonth(false)}
        description="ProgressNote.CotxValidationMsg"
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          onCancel={() => {
            setShowAlertDialog(false);
          }}
          description="formUnsavedChangesMessage"
          onOk={() => {
            if (clientCount > 1) {
              setOpenAccordian(false);
            }
            hasChanged = false;
            setShowAlertDialog(false);

            const initialSelectedContacts =
              formikRef.current?.initialValues.clients[
                openAccordianIndex
              ].selectedContacts.map(
                (selectedId) =>
                  origainalInitialData &&
                  origainalInitialData.clients[openAccordianIndex].contact.find(
                    (contact) => contact.contactId === selectedId,
                  ),
              );
            if (initialSelectedContacts) {
              setMultiSelectContact(
                initialSelectedContacts as ProgressNoteContact[],
              );
            }

            if (initialData && origainalInitialData) {
              setInitialData({
                ...origainalInitialData,
                clients: origainalInitialData.clients.map(
                  (client, clientIndex) => {
                    if (clientIndex === openAccordianIndex) {
                      return {
                        ...client,
                        status:
                          origainalInitialData.clients[clientIndex].status ===
                          'Completed'
                            ? 'Completed'
                            : origainalInitialData.clients[clientIndex]
                                .status === 'In Progress'
                            ? 'In Progress'
                            : 'Pending',
                        visits:
                          origainalInitialData.clients[clientIndex].visits ??
                          [],
                      };
                    }
                    return client;
                  },
                ),
              });
            }
            if (initialData && origainalInitialData) {
              formikRef.current?.setFieldValue(
                'clients',
                origainalInitialData.clients.map((client) => client),
              );
            }
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
      )}
      <ModalPopup
        open={groupBillingCancelModal}
        onCancel={() => {
          setGroupBillingCancelModal(false);
        }}
        description="formUnsavedChangesMessage"
        onOk={() => {
          setGroupBillingDuration(
            convertTimeDuration(
              props.progressNoteData.groupBilling?.groupBillingDuration ||
                '00:00',
              true,
            ),
          );
          setGroupBillingStartTime(
            props.progressNoteData.groupBilling?.groupBillingStartTime
              ? moment(
                  props.progressNoteData.groupBilling?.groupBillingStartTime,
                  'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                )
                  .utc(true)
                  .local()
                  .format('HH:mm')
              : '',
          );
          setGroupBillingCancelModal(false);
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      {/* Signature pad */}
      {signClicked && (
        <SignatureComponent
          signClicked={signClicked}
          labelId="signatureText"
          defaultLabelId="Signature"
          onCancelClick={onCancelClick}
          signature={
            formikRef.current?.values.clients[openAccordianIndex].signature
              .signatureValue
          }
          signaturePartitionKey={
            formikRef.current?.values.clients[openAccordianIndex].signature
              .partitionkey ?? ''
          }
          signatureRowKey={
            formikRef.current?.values.clients[openAccordianIndex].signature
              .rowkey ?? ''
          }
          signatureSaveOrUPdate={onSignatureSave}
          resetCallBackForTypeEmployee={clearSignature}
          signatureCallBack={signatureCallBack}
          handleSuccess={handleSuccess}
          type="Employee"
          hideRemoveButton={
            !validUser ||
            initialData?.clients[openAccordianIndex].activitySheetStatus ===
              'Submitted' ||
            initialData?.clients[openAccordianIndex].activitySheetStatus ===
              'Approved'
          }
        />
      )}
      {/* Client selection checklist */}
      {firstEntry &&
      initialData &&
      initialData.clients.some((client) => client.isSelected) ? null : (
        <Dialog
          open={openClientModal && clientCount > 1 && validUser}
          fullWidth={false}
          maxWidth="xs"
          sx={{
            height: '70%',
            justifyContent: 'center',
            alignSelf: 'center',
            display: 'flex',
          }}
        >
          <DialogTitle sx={{ padding: '16px 16px 6px 16px' }}>
            <MediumTypography
              labelid="ProgressNote.CheckClient"
              defaultlabel="Choose Clients for Progress Note"
              fontSize="24px"
              fontweight={700}
              textColor="#2A4241"
            />
          </DialogTitle>
          <DialogContent sx={{ padding: '16px' }}>
            {clientCheckList.map((clientData) => (
              <Box
                key={clientData.clientId}
                className="flex__ alignItemFlexStart  pb-sm pt-sm"
                sx={{ borderBottom: '1px solid #CFDEDE' }}
              >
                <CheckBoxComponent
                  ischecked={clientData.isSelected}
                  CheckHandleChange={(e) => {
                    handleClientCheckBoxClick(
                      e.target.checked,
                      clientData.clientId,
                    );
                  }}
                  label={clientData.clientName}
                />
                {/* <MediumTypography
                  label={clientData.clientName}
                  textColor="#2A4241"
                  sxProps={{
                    width: '90%',
                    paddingTop: '4px',
                    overflowWrap: 'break-word',
                  }}
                  fontSize="16px"
                  fontweight={400}
                /> */}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
                padding: '0px 16px 16px 0px',
              }}
            >
              <ButtonComponent
                variantType="contained"
                type="submit"
                className="btn-primary btn-submit alrt_Width_btn"
                labelId="ProgressNote.Add"
                defaultLabelId="Add"
                onClick={handleClientSaveCheckList}
                disabled={
                  clientCheckList.filter((item) => item.isSelected).length === 0
                }
              />
              <ButtonComponent
                className="btn-primary btn-cancel alrt_Width_btn ml-md"
                variantType="contained"
                labelId="ProgressNote.Cancel"
                defaultLabelId="Cancel"
                onClick={handleClientCancelCheckList}
              />
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProgressNote;
import dayjs from 'dayjs';
import { ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export interface CancelationReportJSON {
  totalCount: number;
  cancellationReportAppointments: CancelationReportAppointments[];
}
export type CancelationReportAppointments = {
  appointmentStartTime: string;
  appointmentType: string;
  commonAppointmentId: string;
  clientId: string;
  clientFirstName: string | null;
  clientLastName: string | null;
  clientMiddleName: string | null;
  clientSuffix: string | null;
  attendeeId: string;
  attendeeFirstName: string | null;
  attendeeLastName: string | null;
  cancelReason: string | null;
  progressNotesId: string;
  progressNotesStatus: string;
  dphId: string | null;
};

export const getCancelationReport = async (
  page: number,
  size: number,
  filter: string,
): Promise<CancelationReportJSON> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<CancelationReportJSON>(
      `report/cancellation?sort=appointmentStartTime&sortType=DESC&page=${page}&size=${size}&filter=${filter}&timezone=${timezone}`,
    ),
  );
};

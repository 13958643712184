import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from '../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/DeleteIcon.svg';
import { ReactComponent as CheckIcon } from '../assets/images/Success.svg';
import * as yup from 'yup';
import {
  AddNewGuardian,
  deleteParentGuardian,
  getParentGuardians,
  updateParentGuardian,
} from '../services/configApi/User';
import { AddNewClient, AddNewParent, OptionType, Order } from '../utils/type';
import {
  Card,
  Box,
  Typography,
  Grid,
  Tooltip,
  AlertColor,
  styled,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import Textinput from '../components/formlib/Textinput';
import SmallTypography from '../components/formlib/SmallTypography';
import DatePickerComponent from '../components/formlib/DatePickerComponent';
import SelectComponent from '../components/formlib/SelectComponent';
import SwitchComponent from '../components/formlib/SwitchComponent';
import CheckBoxComponent from '../components/formlib/CheckBoxComponent';
import dayjs, { Dayjs } from 'dayjs';
import { FormattedMessage } from 'react-intl';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import ModalPopup from '../components/formlib/ModalPopup';
import { Gender } from './Dropdown';
import { useLocation } from 'react-router-dom';
import AddressForm from '../components/formlib/AddressForm';
import ButtonComponent from '../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../components/formlib/TableHeader';
import TooltipTableCell from '../components/formlib/TooltipTableCell';
import _, { debounce } from 'lodash';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../utils/checkPermission';
import MediumTypography from '../components/formlib/MediumTypography';
import { LoaderContext, LoaderContextType } from '../layouts/AppSidebar';
import { datePickerMinDateMaxDateValidate } from '../utils/dateUtil';
import {
  convertGenderToBackend,
  convertGenderToFrontend,
} from '../utils/genderUtil';
import { LookUpContext } from '../context/LookUpContextProvider';
import DropdownComponent from '../components/formlib/DropdownComponent';
import { getPhoneNumber } from '../utils/utilities';
import { UnSavedChangesContext } from '../context/UnSavedChangesProvider';

const CustomTableCell = styled(TableCell)(() => ({
  fontFamily: 'Lato-Regular',
  fontWeight: 500,
  fontSize: '14px',
}));

const ellipsisCellSX = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '170px',
  borderBottom: 'none',
  padding: '6px 0px',
};

interface AddressProps {
  addressData: AddNewClient;
}

interface MyObject {
  isPrimary: boolean;
}

interface Data {
  name: string;
  relationshipToClient: string;
  dob: string;
  primaryPhoneNumber: string;
  email: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'Parent.Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'relationshipToClient',
    labelId: 'Parent.Relationship',
    defaultLabelId: 'Guardian Type',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'dob',
    labelId: 'Parent.DOB',
    defaultLabelId: 'DOB',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'primaryPhoneNumber',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Parent.emails',
    defaultLabelId: 'Email ID',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const initialValidationSchema = yup.object().shape({
  firstName: yup.string().required('parent.firstnamemessage'),
  lastName: yup.string().required('parent.lastnameMessage'),
  dateOfBirth: yup.string().nullable(),
  relationShipToClient: yup.string().required('parent.relationshipMessage'),
  sex: yup.string().nullable(),
  isPrimary: yup.boolean().required(''),
  homePhoneNumber: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  workPhoneNumber: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  mobilePhoneNumber: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  email: yup.string().required('parent.emailRequired'),
  street: yup.string().required('addressLine1RequiredMessage'),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('parent.stateRequired'),
  zipCode: yup.object().shape({
    label: yup.string().required('Clientpage.Zipcode.validation'),
  }),
  apartment: yup.string().nullable(),
});

const validateForm = (value: AddNewParent) => {
  const errors: Partial<AddNewParent> = {};
  if (dayjs(value.dateOfBirth).isAfter(dayjs())) {
    errors.dateOfBirth = 'validDate';
  }
  if (datePickerMinDateMaxDateValidate(value.dateOfBirth)) {
    errors.dateOfBirth = 'datePickerMinDateMaxDateValidate';
  }

  return errors;
};

const nullInitialValues: AddNewParent = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  relationShipToClient: '',
  sex: null,
  isPrimary: false,
  isLegal: false,
  homePhoneNumber: '',
  workPhoneNumber: '',
  mobilePhoneNumber: '',
  email: '',
  street: '',
  city: '',
  zipCode: { id: '', label: '' },
  state: '',
  isAddressSameAsClientAddress: false,
  isParentIndex: false,
  careGiverId: '',
  apartment: '',
  isEdit: false,
  contactId: '',
};

const ParentGuardianInfo: React.FC<AddressProps> = ({ addressData }) => {
  const [tableData, setTableData] = React.useState<AddNewParent[]>([]);
  const formRef = useRef<FormikProps<AddNewParent>>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [errorEmail, setErrorEmail] = useState('');
  const [parentData, setParentData] = useState(nullInitialValues);
  const [openModal, setOpenModal] = React.useState(false);
  const [initialAddressCheckBox, setInitialAddressCheckBox] =
    React.useState<boolean>(false);
  const [addressCheckBox, setAddressCheckBox] = React.useState<boolean>(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [caregiverIdDelete, setCaregiverIdDelete] = React.useState('');
  const location = useLocation();
  const [checkIsPrimary, setCheckIsPrimary] = React.useState<boolean>(false);
  const systemCheck = React.useRef(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('relationshipToClient');
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  const { lookups } = React.useContext(LookUpContext);
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const [guardianValues, setGuardianValues] = React.useState<OptionType[]>([]);

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setGuardianValues(() => {
      return lookups.guardianType.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
  }, [lookups]);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  function customSort(a: MyObject, b: MyObject): number {
    if (a.isPrimary && !b.isPrimary) return -1;
    if (!a.isPrimary && b.isPrimary) return 1;
    return 0;
  }

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedResponse = [...tableData]; // Make a copy of the array
    sortedResponse.sort((a, b) => {
      if (order === 'desc') {
        return a.relationShipToClient.localeCompare(b.relationShipToClient);
      } else {
        return b.relationShipToClient.localeCompare(a.relationShipToClient);
      }
    });
    setTableData(sortedResponse);
  };

  const getAllParentGuardians = () => {
    window.scrollTo(0, 0);
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    toggleLoader(true);
    getParentGuardians(clientId)
      .then((response: AddNewGuardian) => {
        toggleLoader(false);

        if (response.caregivers !== null && response.caregivers.length !== 0) {
          const sortedCaregivers = response.caregivers.slice().sort(customSort);
          setTableData(sortedCaregivers);
          setCheckIsPrimary(false);

          setAddressCheckBox(false);
          setInitialAddressCheckBox(false);
          setParentData({
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            relationShipToClient: '',
            sex: null,
            isPrimary: false,
            isLegal: false,
            homePhoneNumber: '',
            workPhoneNumber: '',
            mobilePhoneNumber: '',
            email: '',
            street: '',
            city: '',
            zipCode: { id: '', label: '' },
            state: '',
            isAddressSameAsClientAddress: false,
            isParentIndex: false,
            careGiverId: '',
            apartment: '',
            isEdit: false,
            contactId: '',
          });
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setParentData({
          firstName: '',
          lastName: '',
          dateOfBirth: null,
          relationShipToClient: '',
          sex: null,
          isPrimary: true,
          isLegal: false,
          homePhoneNumber: '',
          workPhoneNumber: '',
          mobilePhoneNumber: '',
          email: '',
          street: '',
          city: '',
          zipCode: { id: '', label: '' },
          state: '',
          isAddressSameAsClientAddress: false,
          isParentIndex: false,
          careGiverId: '',
          apartment: '',
          isEdit: false,
          contactId: '',
        });
        setTableData([]);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllParentGuardians();
  }, []);

  const nameValidation = (value: string) => {
    const trimmedValue = value.replace(/^\s+/, '');
    const regex = /^[A-Za-z!,'-. ]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const handleEmailChange = (e: string) => {
    const value = e;
    const emailRegex = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;
    if (value !== '' && !emailRegex.test(value)) {
      setErrorEmail('Invalid email address');
    } else {
      setErrorEmail('');
    }
  };

  const addOrUpdateGuardian = useCallback(
    debounce((id: string, values: AddNewParent) => {
      toggleLoader(true);
      const params: AddNewGuardian = {
        caregivers: [values],
      };

      updateParentGuardian(id, params)
        .then(async (response: AddNewGuardian) => {
          if (response) {
            toggleLoader(false);
            getAllParentGuardians();
            setParentData({
              firstName: '',
              lastName: '',
              dateOfBirth: null,
              relationShipToClient: '',
              sex: null,
              isPrimary: false,
              isLegal: false,
              homePhoneNumber: '',
              workPhoneNumber: '',
              mobilePhoneNumber: '',
              email: '',
              street: '',
              city: '',
              zipCode: { id: '', label: values.zipCode } as OptionType,
              state: '',
              isAddressSameAsClientAddress: false,
              isParentIndex: false,
              careGiverId: '',
              apartment: '',
              isEdit: false,
              contactId: '',
            });

            setOpen(true);
            setToastrVariable('success');
            const navParams = location.state;
            navParams.parentFirstName = '';
            navParams.parentLastName = '';
            navParams.parentHomePhone = '';
            navParams.parentMobilePhone = '';
            navParams.parentEmail = '';
            if (values.careGiverId !== '') {
              setToastrId('Parent.updatesuccess');
              setToastrDefaultMessage('Guardian updated Successfully');
            } else {
              setToastrId('Family.submitsuccess');
              setToastrDefaultMessage('Guardian Added Successfully');
            }

            formRef.current?.resetForm();
          }
        })

        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1076) {
              setToastrId(response.message);
              setToastrDefaultMessage(response.message);
            } else if (response.code === 1077) {
              setToastrId(response.message);
              setToastrDefaultMessage(response.message);
            } else {
              setToastrId('updateFailureMessage');
              setToastrDefaultMessage('Failed to update details');
            }
          }
        });
    }, 500),
    [],
  );

  const updateGuardian = (updatedValues: AddNewParent) => {
    updatedValues.zipCode = (updatedValues.zipCode as OptionType).label;
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    updatedValues.dateOfBirth = updatedValues.dateOfBirth
      ? dayjs(updatedValues.dateOfBirth).format('MM/DD/YYYY')
      : null;

    updatedValues.sex =
      updatedValues.sex?.length === 1
        ? updatedValues.sex
        : convertGenderToBackend(
            updatedValues.sex !== null ? updatedValues.sex : '',
          ) ?? '';

    updatedValues.isLegal =
      updatedValues.relationShipToClient === 'LEGAL' ? true : false;
    const indexToUpdate = tableData.findIndex(
      (item) => item.careGiverId === updatedValues.careGiverId,
    );

    if (indexToUpdate !== -1) {
      const previousIsPrimary = tableData[indexToUpdate].isPrimary;
      // const previousIsLegal = tableData[indexToUpdate].isLegal;

      tableData[indexToUpdate] = {
        ...tableData[indexToUpdate],
        ...updatedValues,
      };

      if (updatedValues.isPrimary === true && previousIsPrimary !== true) {
        tableData.forEach((item, index) => {
          if (index !== indexToUpdate) {
            item.isPrimary = false;
          }
        });
      }

      // This is commented for supporting Update flag based on multiple Legal Guardians
      // if (updatedValues.isLegal === true && previousIsLegal !== true) {
      //   tableData.forEach((item, index) => {
      //     if (index !== indexToUpdate) {
      //       item.isLegal = false;
      //     }
      //   });
      // }
    } else {
      if (updatedValues.isPrimary === true) {
        tableData.forEach((item) => {
          item.isPrimary = false;
        });
      }
      // This is commented for supporting Update flag based on multiple Legal Guardians
      // if (updatedValues.isLegal === true) {
      //   tableData.forEach((item) => {
      //     item.isLegal = false;
      //   });
      // }
      tableData.push(updatedValues);
    }
    // This is commented for supporting not to send all the guardians to the backend
    // const paramsUpdate: AddNewGuardian = {
    //   caregivers: tableData,
    // };
    formRef.current?.setValues((values) => ({
      ...values,
      zipCode: { id: '', label: updatedValues.zipCode } as OptionType,
    }));

    if (
      updatedValues.homePhoneNumber?.trim() ||
      updatedValues.mobilePhoneNumber?.trim()
    ) {
      addOrUpdateGuardian(clientId, updatedValues);
    } else {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('parentGuardian.numberRequired');
      setToastrDefaultMessage('Enter at least one phone number: Cell or Home');
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getFormValues = (rowValues: AddNewParent) => {
    formRef.current?.resetForm();
    setIsEditing(true);
    systemCheck.current = true;
    if (rowValues.isPrimary) {
      setCheckIsPrimary(true);
    } else {
      setCheckIsPrimary(false);
    }
    const editableValues: AddNewParent = {
      firstName: rowValues.firstName,
      lastName: rowValues.lastName,
      relationShipToClient: rowValues.relationShipToClient,
      sex:
        rowValues.sex !== null ? convertGenderToFrontend(rowValues.sex) : null,
      isAddressSameAsClientAddress: rowValues.isAddressSameAsClientAddress,
      email: rowValues.email !== null ? rowValues.email : '',
      dateOfBirth: rowValues.dateOfBirth ? dayjs(rowValues.dateOfBirth) : null,
      isPrimary: rowValues.isPrimary,
      isLegal: rowValues.isLegal,
      homePhoneNumber: rowValues.homePhoneNumber
        ? rowValues.homePhoneNumber
        : '',
      workPhoneNumber: rowValues.workPhoneNumber
        ? rowValues.workPhoneNumber
        : '',
      mobilePhoneNumber: rowValues.mobilePhoneNumber
        ? rowValues.mobilePhoneNumber
        : '',
      street: rowValues.street,
      city: rowValues.city,
      zipCode: { id: 'aa', label: rowValues.zipCode } as OptionType,
      state: rowValues.state,
      isParentIndex: rowValues.isParentIndex,
      careGiverId: rowValues.careGiverId,
      apartment: rowValues.apartment,
      isEdit: true,
      contactId: rowValues.contactId,
    };

    setInitialAddressCheckBox(rowValues.isAddressSameAsClientAddress);
    setAddressCheckBox(rowValues.isAddressSameAsClientAddress);

    formRef.current?.setValues(() => editableValues);
    setParentData(editableValues);
  };

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.setValues(formRef.current?.initialValues);
    if (formRef.current?.initialValues) {
      setParentData(formRef.current?.initialValues);
    }
    setAddressCheckBox(initialAddressCheckBox);
  };

  const handleDelete = (caregiverId: string) => {
    setCaregiverIdDelete(caregiverId);
    setShowAlertDialog(true);
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    if (caregiverIdDelete !== '') {
      deleteParentGuardian(caregiverIdDelete)
        .then(() => {
          getAllParentGuardians();
          setOpen(true);
          setToastrVariable('success');
          formRef.current?.setValues(() => ({
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            relationShipToClient: '',
            sex: null,
            isPrimary: false,
            isLegal: false,
            homePhoneNumber: '',
            workPhoneNumber: '',
            mobilePhoneNumber: '',
            email: '',
            street: '',
            city: '',
            zipCode: { id: '', label: '' },
            state: '',
            isAddressSameAsClientAddress: false,
            isParentIndex: false,
            careGiverId: '',
            apartment: '',
            isEdit: false,
            contactId: '',
          }));
          setToastrId('Contacts.deleteSuccessToastr');
          setToastrDefaultMessage('Record deleted Successfully');
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        });
    }
  };

  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  const getParentName = (firstName: string, lastName: string) => {
    let parentName = '';
    if (firstName !== null) {
      parentName += firstName;
    }
    if (lastName !== null) {
      parentName = parentName + ' ' + lastName;
    }
    return parentName;
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Box component="div">
      <div
        ref={parentRef}
        style={{
          pointerEvents: isScreenLocked ? 'none' : 'auto',
          opacity: isScreenLocked ? '0.5' : '1 ',
        }}
        onKeyDownCapture={(e) => {
          if (isScreenLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (isScreenLocked) {
            if (parentRef.current) parentRef.current.focus();
          }
        }}
      >
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        {showPopup && (
          <ModalPopup
            open={showPopup}
            description="Parent.insuranceDeletePopup"
            onOk={() => closePopup()}
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
        {showAlertDialog && (
          <ModalPopup
            open={showAlertDialog}
            onCancel={() => {
              setShowAlertDialog(false);
            }}
            description="CommunicationLogTableView.deleteDescription"
            onOk={() => deleteLog()}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}

        <Box>
          <Box component="section">
            <TableContainer sx={{ maxHeight: 310 }} className="tableStyles">
              <Table stickyHeader aria-label="sticky table">
                <TableHeader
                  className="listDataTableHead"
                  headerNames={headCells}
                  checkBoxRequired={false}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody className="tableRowcss">
                  {tableData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center" id="nodata">
                        <FormattedMessage
                          id="Parent.nodatavalidation"
                          defaultMessage="No Parents/Guardians are added yet"
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    tableData.map((row: AddNewParent, index: number) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.careGiverId}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                        }}
                      >
                        <Tooltip
                          title={getParentName(row.firstName, row.lastName)}
                          arrow
                          followCursor
                          placement="bottom"
                        >
                          <Box
                            className="flex__"
                            sx={{
                              height: '40px',
                              padding: '6px 14px',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            }}
                          >
                            <CustomTableCell sx={ellipsisCellSX}>
                              {getParentName(row.firstName, row.lastName)}
                            </CustomTableCell>
                            {row.isPrimary && (
                              <Tooltip
                                id="tool-tip"
                                title={'Primary Parent'}
                                placement="right"
                              >
                                <CheckIcon
                                  className="position__relative cursor__pointer"
                                  style={{
                                    top: '7px',
                                    left: '0.9px',
                                  }}
                                />
                              </Tooltip>
                            )}

                            {/* Temparory Hide */}
                            {/* {row.isLegal && (
                              <Tooltip
                                id="tool-tip"
                                title={'Legal Guardian'}
                                placement="right"
                              >
                                <CheckIcon2
                                  className="position__relative cursor__pointer"
                                  style={{
                                    top: '7px',
                                    left: '0.9px',
                                  }}
                                />
                              </Tooltip>
                            )} */}
                          </Box>
                        </Tooltip>

                        <TooltipTableCell
                          value={
                            lookups?.guardianType?.find(
                              (g) =>
                                g.code.toUpperCase() ===
                                row.relationShipToClient.toUpperCase(),
                            )?.description || '-'
                          }
                          visible={true}
                        />
                        <TooltipTableCell
                          value={
                            row.dateOfBirth ? row.dateOfBirth?.toString() : '-'
                          }
                          visible={true}
                        />
                        <TooltipTableCell
                          value={getPhoneNumber(
                            row.mobilePhoneNumber,
                            row.homePhoneNumber,
                          )}
                          visible={true}
                        />
                        <TooltipTableCell
                          value={row.email ? row.email : '-'}
                          visible={true}
                        />

                        <TableCell>
                          <Box className="flex__ alignItemCenter cursorPointer">
                            <Box>
                              <Tooltip title="Edit">
                                <EditIcon onClick={() => getFormValues(row)} />
                              </Tooltip>
                            </Box>
                            <Box className="ml-md cursorPointer">
                              <DeleteIcon
                                style={{
                                  position: 'relative',
                                  ...(row?.isPrimary === true ||
                                  row.isLegal === true
                                    ? {
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                        opacity: '0.5',
                                      }
                                    : { cursor: 'pointer' }),
                                }}
                                onClick={() => handleDelete(row?.careGiverId)}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid rowSpacing={'24px'} columnSpacing={'16px'}>
              <Typography id="headercontact">
                <FormattedMessage
                  id={isEditing ? 'Parent.update' : 'Parent.add'}
                  defaultMessage={
                    isEditing ? 'Update Guardian' : 'Add Guardian'
                  }
                />
              </Typography>
            </Grid>
            <Box sx={{ my: '2%' }}>
              <Formik
                initialValues={parentData}
                validationSchema={initialValidationSchema}
                validate={validateForm}
                enableReinitialize={true}
                validateOnChange={true}
                innerRef={formRef}
                onSubmit={(values) => {
                  setIsEditing(false);
                  updateGuardian(values);
                }}
              >
                {({
                  setFieldValue,
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  initialValues,
                }) => {
                  useEffect(() => {
                    if (!_.isEqual(initialValues, values)) {
                      handleUpdateUnsavedChanges(true);
                    } else {
                      handleUpdateUnsavedChanges(false);
                    }
                  }, [values, initialValues]);
                  return (
                    <Box>
                      <Card className="formCardview">
                        <Grid
                          container
                          rowSpacing={'24px'}
                          columnSpacing={'40px'}
                        >
                          <Grid item xs={4.5}>
                            <Textinput
                              name={`firstName`}
                              labelid="parentGuardian.firstname"
                              defaultlabelid="First Name"
                              handlechange={(value: string) => {
                                const inputValue = value;
                                const validatedValue =
                                  nameValidation(inputValue);
                                if (validatedValue !== undefined) {
                                  setFieldValue('firstName', validatedValue);
                                }
                              }}
                              Required={true}
                              Value={values.firstName}
                              inputProps={{
                                maxLength: 50,
                                pattern: '[A-Za-z.,!-]',
                              }}
                            />
                            {errors.firstName && touched.firstName && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.firstName}
                                defaultLabelId="First Name is required"
                              />
                            )}
                          </Grid>

                          <Grid item xs={4.5} sx={{ paddingTop: '24px' }}>
                            <Textinput
                              name={`lastName`}
                              labelid="parentGuardian.lastname"
                              defaultlabelid="Last Name"
                              handlechange={(value: string) => {
                                const inputValue = value;
                                const validatedValue =
                                  nameValidation(inputValue);
                                if (validatedValue !== undefined) {
                                  setFieldValue('lastName', validatedValue);
                                }
                              }}
                              inputProps={{
                                maxLength: 50,
                                pattern: '[A-Za-z.,!-]+',
                              }}
                              Required={true}
                              Value={values.lastName}
                            />
                            {errors.lastName && touched.lastName && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.lastName}
                                defaultLabelId="Last Name is required"
                              />
                            )}
                          </Grid>

                          <Grid item xs={4.5}>
                            {values.dateOfBirth && (
                              <DatePickerComponent
                                disableFuture={true}
                                labelid="parentGuardian.dateofbirth"
                                defaultlabelid="Date of Birth"
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  if (date === null) {
                                    setFieldValue('dateOfBirth', null);
                                  } else {
                                    setFieldValue('dateOfBirth', formattedDate);
                                  }
                                }}
                                value={dayjs(values?.dateOfBirth) as Dayjs}
                              />
                            )}
                            {!values.dateOfBirth && (
                              <DatePickerComponent
                                disableFuture={true}
                                labelid="parentGuardian.dateofbirth"
                                defaultlabelid="Date of Birth"
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  if (date === null) {
                                    setFieldValue('dateOfBirth', null);
                                  } else {
                                    setFieldValue('dateOfBirth', formattedDate);
                                  }
                                }}
                                value={null}
                              />
                            )}
                            {errors.dateOfBirth && touched.dateOfBirth && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.dateOfBirth}
                                defaultLabelId="Please enter valid Date of Birth"
                              />
                            )}
                          </Grid>
                          <Grid item xs={4.5} sx={{ marginTop: '0.5px' }}>
                            <SelectComponent
                              labelid="Clientpage.Sex"
                              defaultlabelid="sex"
                              name="sex"
                              value={values.sex ?? ''}
                              handleChange={(value: string) => {
                                setFieldValue('sex', value);
                              }}
                              names={Gender}
                            />
                            {errors.sex && touched.sex && (
                              <MediumTypography
                                labelid={errors.sex}
                                className="errorText-md"
                              />
                            )}
                          </Grid>
                          <Grid item xs={4.5} sx={{ marginTop: '0.5px' }}>
                            <DropdownComponent
                              names={guardianValues}
                              labelid="parentGuardian.relationShipToChild"
                              value={values.relationShipToClient}
                              defaultlabelid="Guardian Type"
                              handleChange={(e) => {
                                setFieldValue(`relationShipToClient`, e);
                              }}
                              Required
                            />
                            {errors.relationShipToClient &&
                              touched.relationShipToClient && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.relationShipToClient}
                                  defaultLabelId="Guardian Type is required"
                                />
                              )}
                          </Grid>

                          <Grid
                            item
                            xs={4.5}
                            sx={{ marginTop: '0.5px' }}
                          ></Grid>

                          <Grid item xs={4.5} sx={{ marginTop: '-1%' }}>
                            <SwitchComponent
                              toggle={values.isPrimary}
                              name={`isPrimary`}
                              labelid="parentGuardian.isPrimary"
                              defaultlabelid="Primary Guardian"
                              handlechange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const { checked } = e.target;
                                if (!checkIsPrimary) {
                                  setFieldValue(`isPrimary`, checked);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={4.5} sx={{ marginTop: '-1%' }}>
                            <Box sx={{ display: 'none' }}>
                              <SwitchComponent
                                toggle={values.isLegal}
                                name={`isLegal`}
                                labelid="parentGuardian.legalGuardian"
                                defaultlabelid="Legal Guardian"
                                handlechange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const { checked } = e.target;
                                  setFieldValue(`isLegal`, checked);
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4.5}
                            lg={4.5}
                            sx={{ paddingTop: '24px', marginTop: '-1%' }}
                          >
                            <Textinput
                              name={`mobilePhoneNumber`}
                              labelid="parentGuardian.mobilePhoneNumber"
                              defaultlabelid="Cell Phone Number"
                              handlechange={(value: string) => {
                                setFieldValue(
                                  'mobilePhoneNumber',
                                  NumValidation(value),
                                );
                              }}
                              Value={values.mobilePhoneNumber}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4.5}
                            lg={4.5}
                            sx={{ paddingTop: '24px', marginTop: '-1%' }}
                          >
                            <Textinput
                              name={`homePhoneNumber`}
                              labelid="parentGuardian.homePhoneNumber"
                              defaultlabelid="Home Phone Number"
                              handlechange={(value: string) => {
                                setFieldValue(
                                  'homePhoneNumber',
                                  NumValidation(value),
                                );
                              }}
                              inputProps={{
                                pattren: ['0-9'],
                              }}
                              Value={values.homePhoneNumber}
                            />
                            {errors.homePhoneNumber &&
                              touched.homePhoneNumber && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.homePhoneNumber}
                                  defaultLabelId="Home Number is required"
                                />
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={4.5}
                            lg={4.5}
                            sx={{ paddingTop: '24px !important' }}
                          >
                            <Textinput
                              name={`workPhoneNumber`}
                              labelid="parentGuardian.workPhoneNumber"
                              defaultlabelid="Work Phone Number"
                              handlechange={(value: string) => {
                                setFieldValue(
                                  'workPhoneNumber',
                                  NumValidation(value),
                                );
                              }}
                              Value={values.workPhoneNumber}
                            />
                            {errors.workPhoneNumber &&
                              touched.workPhoneNumber && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.workPhoneNumber}
                                  defaultLabelId="Work Phone Number pattern should contain 10 digits"
                                />
                              )}
                          </Grid>

                          <Grid
                            item
                            xs={4.5}
                            lg={4.5}
                            sx={{ paddingTop: '24px !important' }}
                          >
                            <Textinput
                              name={`email`}
                              labelid="parentGuardian.email"
                              defaultlabelid="Email Address"
                              handlechange={(value: string) => {
                                setFieldValue('email', value);
                                handleEmailChange(value);
                              }}
                              Value={values.email}
                              inputProps={{
                                maxLength: 320,
                              }}
                              disabled={values.email === 'None' ? true : false}
                              Required
                            />
                            {errorEmail !== '' && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId="parent.emailMessage"
                                defaultLabelId="Invalid Email"
                              />
                            )}
                            {errors.email && touched.email && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.email}
                                defaultLabelId="Email Address is required"
                              />
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            lg={2}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <CheckBoxComponent
                              ischecked={values.email === 'None' ? true : false}
                              CheckHandleChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('email', 'None');
                                  setErrorEmail('');
                                } else {
                                  setFieldValue('email', '');
                                }
                              }}
                              labelid="Intake.RadioLabelC"
                              defaultlabelid="Unknown"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4.5}
                            sx={{ paddingTop: '24px !important' }}
                          >
                            <MediumTypography
                              labelid="Address"
                              defaultlabel="Address"
                              textColor="#2a4241"
                              marginTop="8px"
                              fontweight={600}
                            />
                            <CheckBoxComponent
                              className="font-bold"
                              defaultlabelid="Same as client Home address"
                              labelid="parentGuardian.isAddressSameAsClientAddress"
                              ischecked={addressCheckBox}
                              CheckHandleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setAddressCheckBox(e.target.checked);
                                if (e.target.checked) {
                                  formRef.current?.setValues((value) => ({
                                    ...value,
                                    isAddressSameAsClientAddress:
                                      e.target.checked,
                                    zipCode: addressData.zipCode,
                                    state: addressData.state,
                                    city: addressData.city,
                                    street: addressData.street,
                                    apartment: addressData.address2,
                                  }));
                                } else {
                                  formRef.current?.setValues((value) => ({
                                    ...value,
                                    isAddressSameAsClientAddress:
                                      e.target.checked,
                                    zipCode: { id: '', label: '' },
                                    street: '',
                                    apartment: '',
                                    city: '',
                                    state: '',
                                  }));
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={4.5}></Grid>

                          <Grid item xs={9}>
                            <AddressForm
                              address1PlaceholderId="address1Text"
                              address2PlaceholderId="address2Text"
                              zipCodePlaceholderId="Clientpage.Zip"
                              cityPlaceholderId="Clientpage.City"
                              statePlaceholderId="Clientpage.State"
                              value={{
                                address1: values.street,
                                address2: values.apartment,
                                zipCode: values.zipCode,
                                city: values.city,
                                state: values.state,
                              }}
                              isClient={false}
                              formRequired={true}
                              disableForm={values.isAddressSameAsClientAddress}
                              formikErrors={{
                                address1: errors.street,
                                address2: errors.apartment,
                                zipCode: errors.zipCode,
                                city: errors.city,
                                state: errors.state,
                              }}
                              formikTouched={{
                                address1: touched.street,
                                address2: touched.apartment,
                                zipCode: touched.zipCode,
                                city: touched.city,
                                state: touched.state,
                              }}
                              handleAddressUpdate={(newAddress) => {
                                setFieldValue('street', newAddress.address1);
                                setFieldValue('apartment', newAddress.address2);
                                setFieldValue(
                                  'zipCode',
                                  newAddress.zipCode as OptionType,
                                );
                                setFieldValue('city', newAddress.city);
                                setFieldValue('state', newAddress.state);

                                formRef.current?.setValues({
                                  ...values,
                                  street: newAddress.address1,
                                  apartment: newAddress.address2,
                                  zipCode: newAddress.zipCode,
                                  city: newAddress.city,
                                  state: newAddress.state,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>

                      <Box>
                        <Grid
                          className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                          container
                          direction="row"
                          alignItems="right"
                        >
                          <Grid item>
                            <ButtonComponent
                              className="btn-primary btn-cancel mr-md"
                              variantType="contained"
                              defaultLabelId="Cancel"
                              labelId="Contacts.cancelbtn"
                              onClick={() => {
                                if (!_.isEqual(initialValues, values)) {
                                  setOpenModal(true);
                                } else {
                                  formRef.current?.setValues(nullInitialValues);
                                  if (formRef.current) {
                                    setParentData(nullInitialValues);
                                  }
                                  setAddressCheckBox(false);
                                  setIsEditing(false);
                                }
                              }}
                            />

                            {values.isEdit === false && (
                              <ButtonComponent
                                className="btn-primary btn-submit"
                                variantType="contained"
                                type="submit"
                                labelId="parentGuardian.save"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              />
                            )}
                            {values.isEdit && (
                              <ButtonComponent
                                className="btn-primary btn-submit"
                                variantType="contained"
                                type="submit"
                                labelId="parentGuardian.update"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
        <ModalPopup
          open={openModal}
          description="formUnsavedChangesMessage"
          onCancel={() => setOpenModal(false)}
          onOk={() => clearForm()}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="No"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="Yes"
        />
      </div>
    </Box>
  );
};

export default ParentGuardianInfo;

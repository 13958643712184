import { Box, Card, Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useIntl } from 'react-intl';
import { EmployeeType } from '../../utils/type';
import { ActivitySheet } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import {
  convertTimeDuration,
  minutesToTime,
  timeToMinutes,
} from '../../utils/dateUtil';

interface ActivityWeekPeekProps {
  activity: ActivitySheet;
  employeeType: EmployeeType | null;
  cardStyle?: SxProps;
}

const ActivitySheetWeekPeekNew: FC<ActivityWeekPeekProps> = ({
  cardStyle,
  activity,
  employeeType,
}) => {
  const rctl = useIntl();

  const predefinedMinutes = [0, 15, 30, 45];

  const getNextHigherMinute = (minutes: number) => {
    for (let i = 0; i < predefinedMinutes.length; i++) {
      if (minutes <= predefinedMinutes[i]) {
        return predefinedMinutes[i];
      }
    }
    return predefinedMinutes[predefinedMinutes.length - 1];
  };

  const calculateProductivityHoursFromPercentage = () => {
    const workingHours = activity.totalWorkingHours ?? '00:00';
    const productivityGoal = activity.productivityGoal ?? '00.00';
    const workingHoursArray = workingHours.split(':');
    const totalWorkingHours =
      Number(workingHoursArray[0]) + Number(workingHoursArray[1]) / 60;

    const productiveHours =
      totalWorkingHours * (Number(productivityGoal) / 100);

    const resultHours = Math.floor(productiveHours);
    let resultMinutes = Math.round((productiveHours - resultHours) * 60);
    resultMinutes = getNextHigherMinute(resultMinutes);
    return convertTimeDuration(
      `${String(resultHours).padStart(2, '0')}:${String(resultMinutes).padStart(
        2,
        '0',
      )}`,
      true,
    );
  };

  const calculateTotalActivityValue = () => {
    if (activity.employeeType === 'Hourly Non-Exempt') {
      const completedBillableHours = activity.totalBillableHours ?? '00:00';
      const approvedNonBillableHours =
        activity.totalApprovedNonBillableHours ?? '00:00';
      const noShowTravelDocHours =
        activity.totalNoShowTravelDocHours ?? '00:00';
      const otherAssocWorkHrs = activity.totalOtherAssocWorkHrs ?? '00:00';
      const nonBillableHours = activity.totalOtherNonBillableHrs ?? '00:00';

      return convertTimeDuration(
        minutesToTime(
          timeToMinutes(completedBillableHours) +
            timeToMinutes(approvedNonBillableHours) +
            timeToMinutes(noShowTravelDocHours) +
            timeToMinutes(otherAssocWorkHrs) +
            timeToMinutes(nonBillableHours),
        ),
        true,
      );
    } else {
      const completedBillableHours = activity.totalBillableHours ?? '00:00';
      const approvedNonBillableHours =
        activity.totalApprovedNonBillableHours ?? '00:00';
      const otherAssocWorkHrs = activity.totalOtherAssocWorkHrs ?? '00:00';

      return convertTimeDuration(
        minutesToTime(
          timeToMinutes(completedBillableHours) +
            timeToMinutes(approvedNonBillableHours) +
            timeToMinutes(otherAssocWorkHrs),
        ),
        true,
      );
    }
  };

  const calculateTotalHoursOut = () => {
    return convertTimeDuration(activity.totalHoursOut ?? '00:00', true);
  };

  const getProductivityPercentage = () => {
    const productivity = activity.productivity ?? [];
    if (productivity.length !== 0) {
      return productivity[productivity.length - 1].percent;
    }
    return '00:00';
  };

  const getProductivityPercentageHours = () => {
    const productivity = activity.productivity ?? [];
    if (productivity.length !== 0) {
      const percentage = productivity[productivity.length - 1].percent;
      return (
        (Number(parseFloat(percentage).toFixed(2)) / 100) *
        Number(parseFloat(activity.totalWorkingHours ?? '00:00').toFixed(2))
      ).toFixed(2);
    }
    return '00.00';
  };

  const checkHoursOutApplied = () => {
    if (activity.employeeType === 'Hourly Non-Exempt') {
      return (
        activity.totalSickHours === null &&
        activity.totalLtiHours === null &&
        activity.totalJuryDutyHours === null &&
        activity.totalPfmlHours === null
      );
    } else {
      return (
        activity.totalVacationHours === null &&
        activity.totalEtHours === null &&
        activity.totalFlexHours === null &&
        activity.totalProfessionalHours === null &&
        activity.totalHolidayTimeHours === null &&
        activity.totalSickHours === null &&
        activity.totalLtiHours === null &&
        activity.totalJuryDutyHours === null &&
        activity.totalPfmlHours === null
      );
    }
  };

  return (
    <Box sx={{ ...cardStyle }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid container className={'p-md'} spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Box>
                  <Box className={'flex__'} justifyContent={'center'}>
                    <MediumTypography
                      className="mainText-xlg pr-xs"
                      textColor="var(--thom-green-mid)"
                      label={`${
                        activity.productivity !== null
                          ? getProductivityPercentageHours()
                          : '00:00'
                      } Hrs`}
                    />
                    <MediumTypography
                      className="mainText-xlg pr-xs"
                      textColor="var(--thom-green-mid)"
                      label={`(${
                        activity.productivity !== null
                          ? getProductivityPercentage()
                          : '00:00'
                      }%)`}
                    />
                  </Box>
                  <Box className={'flex__ mt-xs'} justifyContent={'center'}>
                    <MediumTypography
                      label={`${rctl.formatMessage({
                        id: 'productivityGoalText',
                        defaultMessage: 'Productivity Goal(%)',
                      })} : `}
                    />
                    <MediumTypography
                      className="ml-xs mr-xs"
                      label={calculateProductivityHoursFromPercentage()}
                    />
                    <MediumTypography
                      label={`(${
                        activity.productivityGoal !== null &&
                        activity.productivityGoal !== ''
                          ? activity.productivityGoal
                          : '00.00'
                      }%)`}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={'flex__'} flexGrow={1}>
                  <Box
                    sx={{
                      width: '1px',
                      backgroundColor: 'var(--thom-mono-gray2)',
                    }}
                  />

                  <Box flexGrow={1}>
                    <MediumTypography
                      textstyle="center"
                      className="mainText-xlg pr-xs mb-xs"
                      textColor="var(--thom-green-mid)"
                      label={
                        activity.hrsLeftToAchieveGoal !== null
                          ? convertTimeDuration(
                              activity.hrsLeftToAchieveGoal,
                              true,
                            ) + ' Hrs'
                          : '00.00 Hrs'
                      }
                    />
                    <MediumTypography
                      textstyle="center"
                      label={`${rctl.formatMessage({
                        id: 'productivityReachRequiredText',
                        defaultMessage: 'Required to reach productivity',
                      })}`}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Grid container className={'p-md'} alignItems={'center'}>
              <Grid item xs={2.5} display={'flex'}>
                <Box flexGrow={1}>
                  <MediumTypography
                    textstyle="center"
                    className="mainText-xlg pr-xs"
                    textColor="var(--thom-green-mid)"
                    label={`${calculateTotalActivityValue()} Hrs`}
                  />
                  <MediumTypography
                    className="mainText-md mt-xs"
                    textstyle={'center'}
                    labelid="totalActivityText"
                    defaultlabel={'Total Activity'}
                  />
                </Box>
                <Box
                  sx={{
                    marginRight: '16px',
                    width: '1px',
                    backgroundColor: 'var(--thom-mono-gray2)',
                  }}
                />
              </Grid>

              <Grid item xs={9.5}>
                <Grid container columnSpacing={2} rowSpacing={0.5}>
                  <Grid item display={'flex'}>
                    <MediumTypography
                      className="mainText-md mr-xs"
                      label={`${rctl.formatMessage({
                        id: 'billableHoursScheduledText',
                        defaultMessage: 'Scheduled Billable Hours',
                      })} : `}
                    />
                    <MediumTypography
                      label={
                        activity.totalScheduledBillableHours !== null
                          ? convertTimeDuration(
                              activity.totalScheduledBillableHours,
                              true,
                            ) + ' Hrs'
                          : '00.00 Hrs'
                      }
                      textColor="var(--thom-green-mid)"
                    />
                    {activity.scheduledProductivity && (
                      <MediumTypography
                        label={`(${activity.scheduledProductivity})`}
                        textColor="var(--thom-green-mid)"
                      />
                    )}
                  </Grid>

                  <Grid item display={'flex'}>
                    <MediumTypography
                      className="mainText-md mr-xs"
                      label={`${rctl.formatMessage({
                        id: 'billableHoursCompletedText',
                        defaultMessage: 'Completed Billable Hours',
                      })} : `}
                    />
                    <MediumTypography
                      label={
                        activity.totalBillableHours !== null
                          ? convertTimeDuration(
                              activity.totalBillableHours,
                              true,
                            ) + ' Hrs'
                          : '00.00 Hrs'
                      }
                      textColor="var(--thom-green-mid)"
                    />
                  </Grid>

                  <Grid item display={'flex'}>
                    <MediumTypography
                      className="mainText-md mr-xs"
                      label={`${rctl.formatMessage({
                        id: 'approvedNonBillableHoursText',
                        defaultMessage: 'Approved Non-Billable Hours',
                      })} : `}
                    />
                    <MediumTypography
                      label={
                        activity.totalApprovedNonBillableHours !== null
                          ? convertTimeDuration(
                              activity.totalApprovedNonBillableHours,
                              true,
                            ) + ' Hrs'
                          : '00.00 Hrs'
                      }
                      textColor="var(--thom-green-mid)"
                    />
                  </Grid>

                  {employeeType && employeeType === 'Hourly Non-Exempt' && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'noShowDescText',
                          defaultMessage: 'No Show (Travel, Wait, Doc, Other)',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalNoShowTravelDocHours !== null
                            ? convertTimeDuration(
                                activity.totalNoShowTravelDocHours,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}

                  <Grid item display={'flex'}>
                    <MediumTypography
                      className="mainText-md mr-xs"
                      label={`${rctl.formatMessage({
                        id: 'otherAssocWorkHoursText',
                        defaultMessage: 'Other Assoc. Work Hours',
                      })} : `}
                    />
                    <MediumTypography
                      label={
                        activity.totalOtherAssocWorkHrs !== null
                          ? convertTimeDuration(
                              activity.totalOtherAssocWorkHrs,
                              true,
                            ) + ' Hrs'
                          : '00.00 Hrs'
                      }
                      textColor="var(--thom-green-mid)"
                    />
                  </Grid>

                  {employeeType && employeeType === 'Hourly Non-Exempt' && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'otherNonBillableHoursText',
                          defaultMessage: 'Other Non-Billable Hours',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalOtherNonBillableHrs !== null
                            ? convertTimeDuration(
                                activity.totalOtherNonBillableHrs,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Grid container className={'p-md'} alignItems={'center'}>
              <Grid item xs={2.5} display={'flex'}>
                <Box flexGrow={1}>
                  <Box className={'flex__ justifyContent-Center'}>
                    <MediumTypography
                      className="mainText-xlg pr-xs"
                      textColor="var(--thom-green-mid)"
                      label={`${calculateTotalHoursOut()} Hrs`}
                    />
                  </Box>
                  <MediumTypography
                    className="mainText-md mt-xs"
                    textstyle={'center'}
                    labelid="totalHoursOutText"
                    defaultlabel={'Total Hours Out'}
                  />
                </Box>
                <Box
                  sx={{
                    marginRight: '16px',
                    width: '1px',
                    backgroundColor: 'var(--thom-mono-gray2)',
                  }}
                />
              </Grid>

              {checkHoursOutApplied() && (
                <Grid item xs={9.5}>
                  <MediumTypography
                    textstyle="center"
                    label={`${rctl.formatMessage({
                      id: 'noHoursOutAppliedText',
                      defaultMessage: 'No Hours Out Applied',
                    })}`}
                  />
                </Grid>
              )}

              <Grid item xs={9.5}>
                <Grid container columnSpacing={1} rowSpacing={0.5}>
                  {employeeType !== 'Hourly Non-Exempt' &&
                    activity.totalVacationHours !== null && (
                      <Grid item display={'flex'}>
                        <MediumTypography
                          className="mainText-md mr-xs"
                          label={`${rctl.formatMessage({
                            id: 'vacationHoursText',
                            defaultMessage: 'Vacation',
                          })} : `}
                        />
                        <MediumTypography
                          label={
                            activity.totalVacationHours !== null
                              ? convertTimeDuration(
                                  activity.totalVacationHours,
                                  true,
                                ) + ' Hrs'
                              : '00.00 Hrs'
                          }
                          textColor="var(--thom-green-mid)"
                        />
                      </Grid>
                    )}

                  {employeeType !== 'Hourly Non-Exempt' &&
                    activity.totalEtHours !== null && (
                      <Grid item display={'flex'}>
                        <MediumTypography
                          className="mainText-md mr-xs"
                          label={`${rctl.formatMessage({
                            id: 'etHoursText',
                            defaultMessage: 'Earned Time',
                          })} : `}
                        />
                        <MediumTypography
                          label={
                            activity.totalEtHours !== null
                              ? convertTimeDuration(
                                  activity.totalEtHours,
                                  true,
                                ) + ' Hrs'
                              : '00.00 Hrs'
                          }
                          textColor="var(--thom-green-mid)"
                        />
                      </Grid>
                    )}

                  {employeeType !== 'Hourly Non-Exempt' &&
                    activity.totalFlexHours !== null && (
                      <Grid item display={'flex'}>
                        <MediumTypography
                          className="mainText-md mr-xs"
                          label={`${rctl.formatMessage({
                            id: 'flexHoursText',
                            defaultMessage: 'Flex Time',
                          })} : `}
                        />
                        <MediumTypography
                          label={
                            activity.totalFlexHours !== null
                              ? convertTimeDuration(
                                  activity.totalFlexHours,
                                  true,
                                ) + ' Hrs'
                              : '00.00 Hrs'
                          }
                          textColor="var(--thom-green-mid)"
                        />
                      </Grid>
                    )}

                  {employeeType !== 'Hourly Non-Exempt' &&
                    activity.totalProfessionalHours !== null && (
                      <Grid item display={'flex'}>
                        <MediumTypography
                          className="mainText-md mr-xs"
                          label={`${rctl.formatMessage({
                            id: 'profHoursText',
                            defaultMessage: 'Professional',
                          })} : `}
                        />
                        <MediumTypography
                          label={
                            activity.totalProfessionalHours !== null
                              ? convertTimeDuration(
                                  activity.totalProfessionalHours,
                                  true,
                                ) + ' Hrs'
                              : '00.00 Hrs'
                          }
                          textColor="var(--thom-green-mid)"
                        />
                      </Grid>
                    )}

                  {employeeType !== 'Hourly Non-Exempt' &&
                    activity.totalHolidayTimeHours !== null && (
                      <Grid item display={'flex'}>
                        <MediumTypography
                          className="mainText-md mr-xs"
                          label={`${rctl.formatMessage({
                            id: 'holidayTimeText',
                            defaultMessage: 'Holiday Time',
                          })} : `}
                        />
                        <MediumTypography
                          label={
                            activity.totalHolidayTimeHours !== null
                              ? convertTimeDuration(
                                  activity.totalHolidayTimeHours,
                                  true,
                                ) + ' Hrs'
                              : '00.00 Hrs'
                          }
                          textColor="var(--thom-green-mid)"
                        />
                      </Grid>
                    )}

                  {activity.totalSickHours !== null && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'sickHrsText',
                          defaultMessage: 'Sick',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalSickHours !== null
                            ? convertTimeDuration(
                                activity.totalSickHours,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}

                  {activity.totalLtiHours !== null && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'ltiHoursText',
                          defaultMessage: 'Sick bank (LTI)',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalLtiHours !== null
                            ? convertTimeDuration(
                                activity.totalLtiHours,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}

                  {activity.totalJuryDutyHours !== null && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'juryDutyText',
                          defaultMessage: 'Jury Duty',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalJuryDutyHours !== null
                            ? convertTimeDuration(
                                activity.totalJuryDutyHours,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}

                  {activity.totalPfmlHours !== null && (
                    <Grid item display={'flex'}>
                      <MediumTypography
                        className="mainText-md mr-xs"
                        label={`${rctl.formatMessage({
                          id: 'pfmlText',
                          defaultMessage:
                            'Paid Family and Medical Leave (PFML)',
                        })} : `}
                      />
                      <MediumTypography
                        label={
                          activity.totalPfmlHours !== null
                            ? convertTimeDuration(
                                activity.totalPfmlHours,
                                true,
                              ) + ' Hrs'
                            : '00.00 Hrs'
                        }
                        textColor="var(--thom-green-mid)"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivitySheetWeekPeekNew;

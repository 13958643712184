import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MediumTypography from './MediumTypography';
import { Checkbox } from '@mui/material';

interface CheckboxProps {
  labelid?: string;
  defaultlabelid?: string;
  ischecked?: boolean;
  disabled?: boolean;
  CheckHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  name?: string;
  className?: string;
}
const CheckBoxComponent: React.FC<CheckboxProps> = (props) => {
  const {
    labelid,
    defaultlabelid,
    ischecked,
    disabled,
    name,
    label,
    CheckHandleChange,
    className,
  } = props;
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      (event.currentTarget as HTMLButtonElement).click();
    }
  };
  return (
    <FormControlLabel
      className={className}
      disabled={disabled}
      style={disabled ? { color: 'var(--thom-mono-gray)' } : {}}
      control={
        <Checkbox
          name={name}
          checked={ischecked}
          onChange={CheckHandleChange}
          onKeyDown={handleKeyDown}
          style={
            !disabled
              ? {
                  color: ischecked
                    ? 'var(--thom-green-primary)'
                    : 'var(--thom-mono-gray)',
                }
              : {}
          }
        />
      }
      label={
        <MediumTypography
          className={className}
          label={label}
          labelid={labelid}
          defaultlabel={defaultlabelid}
          sxProps={{ opacity: disabled ? 0.5 : 1 }}
          textColor="var(--thom-dark-green-table-black)"
        />
      }
    />
  );
};
export default CheckBoxComponent;

import React, { useEffect, FC, useState, useCallback } from 'react';
import {
  Box,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Grid,
  Card,
  Badge,
  AlertColor,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import SearchBox from '../../components/formlib/SearchBox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/system';

import { ReactComponent as AddIcon } from '../../assets/images/AddIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';

import { useLocation, useNavigate } from 'react-router';
import { ADD_USER_GROUPS } from '../../routes/Routing';
import {
  GroupType,
  deleteGroupsById,
  getAllGroupsAPI,
} from '../../services/configApi/userGroups/userGroupServices';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import AddEmployeeGroupModal from '../../components/formlib/modal/AddEmployeeGroupModal';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import EmptyScreen from '../../components/shared/EmptyScreen';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const CustomBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#37D183',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

const CustomBadges = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#CF273C',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

type HeadCell = {
  id: keyof Data;
  label: string;
  numeric: boolean;
  requiredSorting: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: 'groupName',
    label: 'Group Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'createdBy',
    label: 'Created By',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'employees',
    label: 'Employees',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    label: 'Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export type Data = {
  groupName: string;
  createdBy: string;
  employees: string;
  status: string;
  actions: string;
};
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
export type Order = 'asc' | 'desc';

const EnhancedTableHead: FC<EnhancedTableProps> = (
  props: EnhancedTableProps,
) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof Data, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className="listDataTableHead">
      <TableRow>
        <TableCell padding="checkbox">
          <CheckBoxComponent ischecked={false} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              onClick={createSortHandler(headCell.id, headCell.requiredSorting)}
              className="Tableheaderstyle"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={!headCell.requiredSorting}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const UserGroupListGrid = () => {
  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));

  const [selected] = useState<readonly string[]>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data | string>('createdOn');
  const [logs, setLogs] = useState<GroupType[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const [onsearchquery, setOnsearchquery] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [searchquery, setSearchquery] = useState('');
  const PAGE_SIZE = 10;
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [open, setOpen] = React.useState(false);
  const [openNoteModel, setOpenNoteModel] = useState<boolean>(false);
  const [getGroupId, setGroupId] = React.useState('');
  const location = useLocation();
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== pageNumber) {
      setPageNumber(newPage - 1);
      setLogs([]);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setPageNumber(0);
      setSearchquery(value);
      setSearchTriggered(true);
    }, 500),
    [],
  );

  useEffect(() => {
    if (location.state?.text) {
      setOpen(true);
      setToastrVariable('success');
      setToastrId('userGroup.employeeAdd');
      setToastrDefaultMessage('Employee Updated Successfully');
    }
    getAllGroups();
    setGroupId('');
  }, [order, orderBy, pageNumber, onsearchquery]);

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);

  const addGroup = () => {
    navigate(ADD_USER_GROUPS);
  };

  const getAllGroups = () => {
    toggleLoader(true);
    getAllGroupsAPI(pageNumber, PAGE_SIZE, orderBy, order, onsearchquery)
      .then((response) => {
        const selectedProgram: string | null =
          localStorage.getItem('selectedProgram');
        const superProgramId: string | null =
          localStorage.getItem('superProgramId');
        if (selectedProgram !== null && superProgramId !== null) {
          if (JSON.parse(selectedProgram).id === superProgramId) {
            setLogs(response.groups);
          } else {
            setLogs(
              response.groups.filter(
                (group) =>
                  group.createdBy.toLowerCase() !== 'default' ||
                  group.level !== 1,
              ),
            );
          }
        }
        setPaginationCount(response.count);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('employeeError');
          setToastrDefaultMessage('Failed to Fetch Data');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (id: string) => {
    setGroupId(id);
    setShowAlertDialog(true);
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    if (getGroupId !== '') {
      deleteGroupsById(getGroupId)
        .then(() => {
          getAllGroups();
          setOpen(true);
          setToastrVariable('success');
          setToastrId('Contacts.deleteSuccessToastr');
          setToastrDefaultMessage('Record deleted Successfully');
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Contacts.deleteToastr');
            setToastrDefaultMessage('Failed to delete');
          }
        });
    }
  };

  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z0-9(),'."! -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />

      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          onCancel={() => {
            setShowAlertDialog(false);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => deleteLog()}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}

      {openNoteModel && (
        <AddEmployeeGroupModal
          open={openNoteModel}
          groupId={getGroupId}
          handleClose={() => {
            setOpenNoteModel(!openNoteModel);
          }}
          successOrErrorText={(val) => {
            if (val === 'Success') {
              setOpen(true);
              setToastrVariable('success');
              setToastrId('userGroup.employeeAdd');
              setToastrDefaultMessage('Employee Updated Successfully');
              getAllGroups();
              setGroupId('');
            }
          }}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <TitleText
              labelid="userGroup.tittletext"
              defaultlabel="User Access Group"
            />
          </Box>
          <Box sx={{ flexGrow: 0, mx: '24px' }}>
            <SearchBox
              labelId="userGroup.searchText"
              defaultlabel="Search Group"
              // onChange={handleSearchChange}
              onChange={(value: string) => {
                const inputValue = value;
                const validatedValue = nameValidation(inputValue);
                if (validatedValue !== undefined)
                  handleSearchChange(validatedValue);
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                type="submit"
                labelId="userGroup.Addnewbtn"
                defaultLabelId="Add Group"
                onClick={addGroup}
              />
            </Box>
          </Box>
        </Toolbar>
        {searchquery !== '' && logs && logs.length === 0 && <SearchNotFound />}
        {logs && logs.length !== 0 && (
          <Card sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <TableContainer>
                <Table
                  sx={{ maxWidth: '100%' }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={logs ? logs.length : 0}
                  />
                  <TableBody className="tableRowcss">
                    {logs &&
                      logs.length > 0 &&
                      logs.map((row: GroupType) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            sx={{
                              alignContent: 'flex-start',
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#ECF9F8',
                              },
                            }}
                          >
                            <TooltipTableCell
                              value={row.groupName as string}
                              visible={true}
                              textSxProps={{ maxWidth: 'auto' }}
                            />
                            <CustomTableCell>{row.createdBy}</CustomTableCell>
                            <CustomTableCell>
                              {row.employeeCount}
                            </CustomTableCell>
                            <CustomTableCell id="name-head">
                              <div>
                                <>
                                  {row.employeeCount === 0 ? (
                                    <CustomBadges variant="dot" />
                                  ) : (
                                    <CustomBadge variant="dot" />
                                  )}
                                  {row.employeeCount === 0
                                    ? 'In-Active'
                                    : 'Active'}
                                </>
                              </div>
                            </CustomTableCell>
                            <CustomTableCell align="left">
                              <AddIcon
                                className="editImage"
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '10px',
                                }}
                                onClick={() => {
                                  setGroupId(row.id);
                                  setOpenNoteModel(!openNoteModel);
                                }}
                              />

                              <EditIcon
                                className="editImage"
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '10px',
                                }}
                                onClick={() => {
                                  navigate(ADD_USER_GROUPS, {
                                    replace: true,
                                    state: {
                                      groupId: row.id,
                                      level: row.level,
                                    },
                                  });
                                }}
                              />
                              <DeleteIcon
                                onClick={() => {
                                  if (
                                    row.createdBy.toLowerCase() !== 'default' &&
                                    row.employeeCount === 0
                                  ) {
                                    handleDelete(row.id);
                                  }
                                }}
                                opacity={
                                  row.createdBy.toLowerCase() === 'default' ||
                                  (row.createdBy.toLowerCase() !== 'default' &&
                                    row.employeeCount > 0)
                                    ? 0.5
                                    : 1
                                }
                                className={`mr-sm ${
                                  row.createdBy.toLowerCase() === 'default' ||
                                  (row.createdBy.toLowerCase() !== 'default' &&
                                    row.employeeCount > 0)
                                    ? 'cursor__none'
                                    : 'cursorPointer'
                                }`}
                              />
                            </CustomTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Card>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {paginationCount > 10 && (
            <Grid item>
              <Pagination
                boundaryCount={1}
                page={pageNumber + 1}
                onChange={handleChangePage}
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontFamily: 'Lato-Regular',
                    fontWeight: 400,
                    fontSize: '16px',
                    margin: '16px 0px 20px 0px',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#008C82',
                    color: '#ffffff',
                  },
                }}
                shape="circular"
                count={Math.ceil(paginationCount / 10)}
                siblingCount={1}
              />
            </Grid>
          )}
        </div>
        {logs && logs.length === 0 && searchquery === '' && (
          <EmptyScreen
            titleLabelId="noGroupsMessage"
            defaultTitleText="No Groups added yet."
            showButton={false}
          />
        )}
      </Box>
    </>
  );
};

export default UserGroupListGrid;

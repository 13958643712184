import {
  create,
  ApisauceInstance,
  ApiResponse,
  ApisauceConfig,
} from 'apisauce';
import { USER_BASE_URL } from '../services/Constant';
import { RefreshTokenResponse } from '../utils/type';

export default class UserApiClient {
  private static _instance: UserApiClient;

  private api: ApisauceInstance;

  constructor() {
    this.api = create({
      baseURL: USER_BASE_URL,
    });
    this.api.addRequestTransform((request) => {
          const requestConfig = request;
          if (requestConfig && requestConfig.headers) {
            requestConfig.headers = {
              ...requestConfig.headers,
              appSource: `TCS`,
              Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
            };
          }
          return requestConfig;
        });
    
        this.api.axiosInstance.interceptors.response.use(
          (response) => {
            return response;
          },
          async (error) => {
            const originalConfig = error.config;
            if (
              error.response &&
              error.response.status == 403 &&
              (error.response.data.code == 1045 || error.response.data.code == 1039 || error.response.data.code == 13001)
            ) {
              if (error.response.data.code == 1039) {
                localStorage.setItem('permissionDenied', 'true');
              }
              return Promise.reject(error.response);
            } else if (error.response && error.response.status == 403) {
              const api = create({
                baseURL: USER_BASE_URL,
              });
              const response = await api.post(`login`, {
                refreshToken: localStorage.getItem('refreshToken'),
                grantType: 'refresh_token',
              });
              if (response.ok) {
                const data = response.data as RefreshTokenResponse;
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                originalConfig.headers.Authorization = data.accessToken;
                return this.api.axiosInstance.request(originalConfig);
              } else {
                return Promise.reject(response.originalError);
              }
            }
            return Promise.reject(error);
          },
        );
  }

  public static getInstance(): UserApiClient {
    if (!this._instance) {
      this._instance = new UserApiClient();
    }
    return this._instance;
  }

  async post<T, U>(
    url: string,
    payload?: T,
    axiosConfig?: ApisauceConfig,
  ): Promise<ApiResponse<T, U>> {
    return this.api.post<T, U>(url, payload, axiosConfig);
  }

  async get<T>(url: string): Promise<ApiResponse<T, T>> {
    return this.api.get<T>(url);
  }

  async put<T>(
    url: string,
    payload?: T | string | number | boolean,
    axiosConfig?: ApisauceConfig,
  ): Promise<ApiResponse<T, T>> {
    return this.api.put<T>(url, payload, axiosConfig);
  }

  async delete<T>(url: string): Promise<ApiResponse<T, T>> {
    return this.api.delete<T>(url);
  }
}

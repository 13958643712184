import {
  Box,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Order } from '../../utils/type';
import { visuallyHidden } from '@mui/utils';
import MediumTypography from './MediumTypography';
import CheckBoxComponent from './CheckBoxComponent';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import ActionToolTip from './ActionToolTip';

interface EnhancedTableProps<T> {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order?: Order;
  orderBy?: string;
  headerNames: HeadCell<T>[];
  checkBoxRequired: boolean;
  onCheckBoxClick?: (value: boolean) => void;
  checkBoxValue?: boolean;
  needStickyHeader?: boolean;
  labelSxProp?: SxProps;
  className?: string;
  disableHeaderCheckBox?: boolean;
}

export interface HeadCell<T> {
  id: keyof T;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
  requiredSorting: boolean;
  requireInfoIcon?: boolean;
  tooltipProps?: {
    labelId?: string;
    defaultLabel?: string;
    label?: string;
  };
}

export const TableHeader = <T,>(props: EnhancedTableProps<T>) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headerNames,
    checkBoxRequired,
    onCheckBoxClick,
    checkBoxValue,
    labelSxProp,
    className,
    needStickyHeader,
    disableHeaderCheckBox,
  } = props;

  const createSortHandler =
    (property: keyof T, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort && onRequestSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className={className}>
      <TableRow>
        {checkBoxRequired && (
          <TableCell
            sx={{
              position: 'sticky',
              left: 0,
              zIndex: 100,
              backgroundColor: '#ECF9F8',
            }}
          >
            <CheckBoxComponent
              ischecked={checkBoxValue ?? false}
              CheckHandleChange={(value) => {
                if (onCheckBoxClick) onCheckBoxClick(value.target.checked);
              }}
              disabled={disableHeaderCheckBox}
            />
          </TableCell>
        )}
        {headerNames.map((cell: HeadCell<T>, index) =>
          cell.id === 'empty' ? (
            <TableCell
              key={cell.labelId}
              sx={{
                backgroundColor: 'transparent !important',
                borderBottom: 'unset',
                borderLeft: index === 0 ? '1px solid #00C6B8' : '0px',
                marginRight: index === 0 ? '20px' : '0px',
                position: index === 0 && needStickyHeader ? 'sticky' : 'unset',
                left: checkBoxRequired ? '40px' : 0,
                zIndex: index === 0 && needStickyHeader ? 101 : 0,
              }}
            />
          ) : (
            <TableCell
              key={cell.labelId}
              align={cell.numeric ? 'right' : 'left'}
              padding="none"
              sx={{
                paddingTop: '8px',
                paddingBottom: '8px',
                verticalAlign: 'top',
                position: index === 0 && needStickyHeader ? 'sticky' : 'unset',
                left: checkBoxRequired ? '40px' : 0,
                zIndex: index === 0 && needStickyHeader ? 101 : 0,
              }}
            >
              <TableSortLabel
                sx={{
                  fontFamily: 'Lato-Bold',
                  cursor: cell.requiredSorting ? 'pointer' : 'default',
                }}
                active={orderBy === cell.id}
                direction={order}
                onClick={createSortHandler(cell.id, cell.requiredSorting)}
                hideSortIcon={!cell.requiredSorting}
              >
                <MediumTypography
                  sxProps={{
                    fontFamily: 'Lato-Bold',
                    color: '#97A6A5',
                    whiteSpace: 'nowrap',
                    // padding: '8px',
                    ...labelSxProp,
                  }}
                  labelid={cell.labelId}
                  defaultlabel={cell.defaultLabelId}
                />
                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
                {cell.requireInfoIcon && (
                  <ActionToolTip {...cell.tooltipProps}>
                    <Info
                      stroke={'var(--white)'}
                      style={{
                        cursor: 'pointer',
                        alignSelf: 'end',
                        marginBottom: '4px',
                      }}
                    />
                  </ActionToolTip>
                )}
              </TableSortLabel>
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

import { FC, ReactNode, createContext, useState } from 'react';
import {
  TCSLookUpResponse,
  getTCSLookUps,
} from '../services/configApi/tcsLookups';
import { initDB } from '../services/storageDB';
import { FaceSheetDemographicsType, OptionType } from '../utils/type';
import { STORAGE_USER_ID_KEY } from '../services/Constant';

export interface LookUpContextType {
  lookups: TCSLookUpResponse | null;
  serviceCodes: OptionType[];
  settingsCodes: OptionType[];
  notificationCount: number;
  updateLookup: () => void;
  lookupsDemographics: FaceSheetDemographicsType | null;
  updateDemographics: (deographics: FaceSheetDemographicsType) => void;
  isNotificationAndProfileEnabled: boolean;
  updateNotificationAndProfileEnabled: (enableOrDisable: boolean) => void;
  updateServiceAndSettingCodes: (
    serviceCodes: OptionType[],
    settingCodes: OptionType[],
  ) => void;
  updateNotificationCount: (count: number) => void;
}

export const LookUpContext = createContext<LookUpContextType>({
  lookups: null,
  serviceCodes: [],
  settingsCodes: [],
  notificationCount: 0,
  updateLookup: () => {},
  updateDemographics: () => {},
  lookupsDemographics: null,
  isNotificationAndProfileEnabled: false,
  updateNotificationAndProfileEnabled: () => {},
  updateServiceAndSettingCodes: () => {},
  updateNotificationCount: () => {},
});

export const LookUpContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [serviceCodes, setServiceCodes] = useState<OptionType[]>([]);
  const [settingsCodes, setSettingsCodes] = useState<OptionType[]>([]);
  const [lookups, setLookups] = useState<TCSLookUpResponse | null>(null);
  const [lookupsDemographics, setLookupsDemographics] =
    useState<FaceSheetDemographicsType | null>(null);
  const [isNotificationAndProfileEnabled, setIsNotificationAndProfileEnabled] =
    useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const updateLookup = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId) {
      initDB()
        .then(async () => {
          console.log('DB initialized');
          await getTCSLookUps()
            .then((response) => {
              if (response) setLookups(response);
            })
            .catch((errorapi) => {
              console.log('getTCSLookUps', errorapi);
            });
        })
        .catch((error) => {
          console.log('DB initialized', error);
        });
    }
  };

  const updateServiceAndSettingCodes = (
    serviceCodeOptions: OptionType[],
    settingCodeOptions: OptionType[],
  ) => {
    setServiceCodes(serviceCodeOptions);
    setSettingsCodes(settingCodeOptions);
  };

  const updateNotificationAndProfileEnabled = (enableOrDisable: boolean) => {
    setIsNotificationAndProfileEnabled(enableOrDisable);
  };

  const updateDemographics = (demographics: FaceSheetDemographicsType) => {
    localStorage.setItem('Demographics', JSON.stringify(demographics));
    setLookupsDemographics(demographics);
  };

  const updateNotificationCount = (count: number) => {
    setNotificationCount(count);
  };

  return (
    <LookUpContext.Provider
      value={{
        lookups,
        serviceCodes,
        settingsCodes,
        notificationCount,
        updateLookup,
        updateDemographics,
        lookupsDemographics,
        isNotificationAndProfileEnabled,
        updateNotificationAndProfileEnabled,
        updateServiceAndSettingCodes,
        updateNotificationCount,
      }}
    >
      {children}
    </LookUpContext.Provider>
  );
};

import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  AlertColor,
  Card,
  Badge,
} from '@mui/material';
import { styled } from '@mui/system';
import TitleText from '../../components/formlib/TitleText';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useNavigate } from 'react-router';
import { visuallyHidden } from '@mui/utils';
import { AddNewReferral } from '../../utils/type';
import {
  deleteReferralById,
  getReferralDetails,
} from '../../services/referralClients/referralDashboard';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import SearchBox from '../../components/formlib/SearchBox';
import { ReactComponent as MoreIcon } from '../../assets/images/moreIcon.svg';
import ActionMenu from '../../components/formlib/ActionMenu';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import '../ClientsDashboardPage.css';
import ModalPopup from '../../components/formlib/ModalPopup';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  CLIENTS_ROUTE_NAME,
  REFERRAL_DASHBOARD_ADD_REFERRAL_ROUTE_NAME,
} from '../../routes/Routing';
import EmptyScreen from '../../components/shared/EmptyScreen';
import MediumTypography from '../../components/formlib/MediumTypography';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { CLIENT_NAME_KEY, REFERRAL_ID_KEY } from '../../services/Constant';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  ReferralResponseType,
  getAllReferrals,
} from '../../services/configApi/Clients';
import { calculateMonths } from '../../utils/dateUtil';
import { formatName } from '../../utils/nameUtils';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

type HeadCell = {
  id: keyof Data;
  label: string;
  numeric: boolean;
  requiredSorting: boolean;
};

const initialHeadCells: HeadCell[] = [
  {
    id: 'referralBeginDate',
    label: 'Referral Begin Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'firstName',
    label: 'Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'dateOfBirth',
    label: 'Date of Birth',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'Age',
    label: 'Age',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'referralType',
    label: 'Referral Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'dphId',
    label: 'Enrollment ID',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'createdBy',
    label: 'Created by',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    label: 'Status',
    numeric: false,
    requiredSorting: false,
  },
];
export type Data = {
  createdOn: string;
  referralBeginDate: string;
  firstName: string;
  dateOfBirth: string;
  Age: string;
  referralType: string;
  dphId: string;
  createdBy: string;
  status: string;
  actions: string;
};
interface EnhancedTableProps {
  headCells: HeadCell[];
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
export type Order = 'asc' | 'desc';
const PAGE_SIZE = 10;

export interface ReferralNavStateProps {
  data: AddNewReferral;
  editable: boolean;
}

const TableHeader: FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof Data, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className="listDataTableHead">
      <TableRow>
        <TableCell padding="checkbox">
          <CheckBoxComponent ischecked={false} />
        </TableCell>
        {props.headCells.map((cell: HeadCell) => (
          <TableCell
            key={cell.id}
            align={cell.numeric ? 'right' : 'left'}
            sx={{ height: '49px', padding: '12px 14px' }}
          >
            <TableSortLabel
              className="Tableheaderstyle"
              sx={{
                fontFamily: 'Lato-Bold',
                color: '#97A6A5',
                textWrap: 'nowrap',
              }}
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={createSortHandler(cell.id, cell.requiredSorting)}
              hideSortIcon={!cell.requiredSorting}
            >
              {cell.label}
              {orderBy === cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ReferralDashboard = () => {
  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data | string>('createdOn');
  const [logs, setLogs] = useState<AddNewReferral[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected] = useState<readonly string[]>([]);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [searchquery, setSearchquery] = useState('');
  const [onsearchquery, setOnsearchquery] = useState('');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] =
    React.useState<boolean>(false);
  const [selectedReferralId, setSelectedReferralId] = React.useState<string>();
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const navigate = useNavigate();

  const redirect = () => {
    return navigate(REFERRAL_DASHBOARD_ADD_REFERRAL_ROUTE_NAME);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    if (
      headCells.length === 8 &&
      checkPermissionForFeature('backend.clients', 'editPermission')
    ) {
      setHeadCells((prevHeadCells) => [
        ...prevHeadCells,
        {
          id: 'actions',
          label: 'Action',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, []);

  useEffect(() => {
    readReferralDetails();
  }, [order, orderBy, pageNumber, onsearchquery]);

  const readReferralDetails = () => {
    toggleLoader(true);
    getReferralDetails(onsearchquery, pageNumber, PAGE_SIZE, orderBy, order)
      .then((response) => {
        if (response.referrals) {
          setLogs(response.referrals);
          setPaginationCount(response.referralCount);
          if (
            response.referralCount === 0 &&
            pageNumber === 0 &&
            onsearchquery === ''
          ) {
            setShowEmptyScreen(true);
          } else {
            setShowEmptyScreen(false);
          }
        } else {
          setLogs([]);
          setPaginationCount(0);
        }
        toggleLoader(false);
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  const displayDeleteConfirmationMessage = (referralId: string) => {
    setShowDeleteAlertDialog(true);
    setSelectedReferralId(referralId);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  interface CustomToolTipProps {
    data: string;
  }

  const CustomToolTip = ({ data }: CustomToolTipProps) => {
    return (
      <Tooltip title={data} arrow followCursor>
        <CustomTableCell
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100px',
          }}
        >
          {data}
        </CustomTableCell>
      </Tooltip>
    );
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(0);
        setSearchquery(value);
        setSearchTriggered(true);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);

  const editReferral = async (
    clientId: string,
    clientName: string,
    referralId: string,
  ) => {
    sessionStorage.setItem('tabName', '0');
    localStorage.setItem('ClientId', clientId);
    localStorage.setItem(CLIENT_NAME_KEY, clientName);
    localStorage.setItem(REFERRAL_ID_KEY, referralId);
    localStorage.setItem('defaultReferralId', referralId);
    await getAllReferralsBasedOnSelectedClient(clientId, referralId);
    navigate(CLIENTS_ROUTE_NAME, {
      state: { clientId: clientId, isCreate: true },
    });
  };

  const getAllReferralsBasedOnSelectedClient = async (
    clientId: string,
    defaultReferralId: string,
  ) => {
    toggleLoader(true);
    if (clientId) {
      await getAllReferrals(clientId, defaultReferralId)
        .then(async (response: ReferralResponseType[]) => {
          if (response) {
            localStorage.setItem(
              'clientReferralDetails',
              JSON.stringify(response),
            );
          }
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('clientLoadError');
            setToastrDefaultMessage('Failed to get client details');
          }
        });
    }
  };

  const editDraft = (referralId: string) => {
    localStorage.setItem(REFERRAL_ID_KEY, referralId);
    redirect();
  };

  const editCurrentReferral = (row: AddNewReferral) => {
    if (row.clientId !== null) {
      if (checkPermissionForFeature('backend.clients', 'editPermission')) {
        editReferral(
          row.clientId,
          `${formatName(
            row.firstName,
            row.middleName,
            row.lastName,
            row.suffix,
          )}`,
          row.id,
        );
      }
    }
    if (row.savedAsDraft) {
      editDraft(row.id);
    }
  };

  const closeModal = () => {
    setShowAlertDialog(false);
  };

  const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: color || '#37D183',
      height: '8px',
      width: '8px',
      top: 0,
      bottom: 0,
      marginRight: '10px',
    },
  }));

  const badgePicker = (status: string) => {
    switch (status) {
      case 'Inactive':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'red',
              },
            }}
          />
        );
      case 'Pending':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#FFA500',
              },
            }}
          />
        );
      case 'Not Started':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#808080',
              },
            }}
          />
        );
      default:
        return <CustomBadge variant="dot" />;
    }
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          description="ReferralDashboard.dateValidationText"
          onOk={() => closeModal()}
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
      {showDeleteAlertDialog && (
        <ModalPopup
          open={showDeleteAlertDialog}
          description="deleteReferralText"
          defaultDescription="Are you sure you want to delete the Referral?"
          onOk={() => {
            setShowDeleteAlertDialog(false);
            setSelectedReferralId(undefined);
          }}
          onClose={() => {
            setShowDeleteAlertDialog(false);
            setSelectedReferralId(undefined);
          }}
          onCancel={() => {
            if (selectedReferralId) {
              toggleLoader(true);
              setShowDeleteAlertDialog(false);
              deleteReferralById(selectedReferralId)
                .then(() => {
                  readReferralDetails();
                  setOpen(true);
                  setToastrVariable('success');
                  setSelectedReferralId(undefined);
                  setToastrId('referralDeletionSuccessMessage');
                  setToastrDefaultMessage('Referral deleted successfully');
                })
                .catch((error) => {
                  setOpen(true);
                  toggleLoader(false);
                  setToastrVariable('error');
                  setSelectedReferralId(undefined);
                  if (isCustomError(error)) {
                    const apiError = error as ApiError;
                    setToastrId(apiError.id);
                    setToastrDefaultMessage(apiError.message);
                  } else {
                    const errorMessage = error as ApiMessage;
                    if (errorMessage.code === 1097) {
                      setToastrId('referralStatusIssueMessage');
                      setToastrDefaultMessage(
                        'Referral with status other than Not Started cannot be deleted',
                      );
                    } else {
                      setToastrId('referralDeletionFailureMessage');
                      setToastrDefaultMessage('Failed to delete the Referral');
                    }
                  }
                });
            }
          }}
          labelId1="Clientpage.Yesbtn"
          negativeActionLabel="Yes"
          labelId2="Clientpage.Nobtn"
          positiveActionLabel="No"
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <TitleText
              labelid="Referraldashboard.Titletext"
              defaultlabel="Referral List"
            />
          </Box>
          <Box sx={{ flexGrow: 0, mx: logs.length > 0 ? '24px' : '0px' }}>
            <SearchBox
              labelId="Referraldashboard.searchText"
              defaultlabel="Search Referral Name"
              onChange={handleSearchChange}
            />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {checkPermissionForFeature(
                'backend.referral_dashboard',
                'addPermission',
              ) &&
                logs.length > 0 && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="Referralmangement.Addnewbtn"
                    defaultLabelId=" Add New Referral"
                    onClick={() => {
                      localStorage.removeItem(REFERRAL_ID_KEY);
                      redirect();
                    }}
                  />
                )}
            </Box>
          </Box>
        </Toolbar>
        {showEmptyScreen && (
          <EmptyScreen
            titleLabelId="noReferralMessage"
            defaultTitleText="No Referrals added yet."
            buttonLabelId="Referraldashboard.AddTitletext"
            defaultButtonTitleText="Add New Referral"
            showButton={true}
            onButtonClick={() => {
              localStorage.removeItem(REFERRAL_ID_KEY);
              redirect();
            }}
          />
        )}
        {searchquery !== '' && logs.length === 0 && <SearchNotFound />}
        {logs.length !== 0 && (
          <Box>
            <Card sx={{ width: '100%' }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750, maxWidth: '100%' }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <TableHeader
                    headCells={headCells}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={logs.length}
                  />
                  <TableBody className="tableRowcss">
                    {logs.map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          sx={{
                            alignContent: 'flex-start',
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#ECF9F8',
                            },
                          }}
                        >
                          <TableCell padding="checkbox">
                            <CheckBoxComponent ischecked={false} />
                          </TableCell>
                          <CustomTableCell>
                            {row.referralBeginDate
                              ? (row.referralBeginDate as string)
                              : '-'}
                          </CustomTableCell>
                          <CustomToolTip
                            data={formatName(
                              row.firstName,
                              row.middleName,
                              row.lastName,
                              row.suffix,
                            )}
                          />
                          <CustomTableCell>
                            {row.dateOfBirth ? row.dateOfBirth.toString() : '-'}
                          </CustomTableCell>
                          <CustomTableCell>
                            {row.dateOfBirth !== null
                              ? calculateMonths(row.dateOfBirth.toString())
                                  .replace('(', ', ')
                                  .replace(')', '')
                              : '-'}
                          </CustomTableCell>
                          <CustomTableCell>{row.referralType}</CustomTableCell>
                          <CustomTableCell>
                            <MediumTypography
                              label={
                                row.dphId !== null && row.dphId.length > 0
                                  ? row.dphId
                                  : '-'
                              }
                            />
                          </CustomTableCell>
                          <CustomTableCell>{row.createdBy}</CustomTableCell>
                          <CustomTableCell
                            sx={{
                              fontFamily: 'Lato-Regular',
                            }}
                          >
                            {badgePicker(row.status ?? '') ?? ''}
                            {row.status}
                          </CustomTableCell>
                          {checkPermissionForFeature(
                            'backend.clients',
                            'editPermission',
                          ) ? (
                            <TableCell>
                              <ActionMenu
                                rootView={<MoreIcon />}
                                items={[
                                  {
                                    labelId: 'MyTimeOffEdit',
                                    defaultLabelId: 'Edit',
                                    visible:
                                      checkPermissionForFeature(
                                        'backend.clients',
                                        'editPermission',
                                      ) && true,
                                  },
                                  {
                                    labelId: 'deleteText',
                                    defaultLabelId: 'Delete',
                                    visible: true,
                                    disabled: row.status !== 'Not Started',
                                  },
                                ]}
                                onItemClick={(index: number) => {
                                  if (index === 0) {
                                    editCurrentReferral(row);
                                  } else if (index === 1) {
                                    displayDeleteConfirmationMessage(row.id);
                                  }
                                }}
                              />
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            {paginationCount > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={pageNumber}
                  paginationCount={paginationCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== pageNumber) {
                      setPageNumber(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReferralDashboard;

import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import SearchBox from '../../components/formlib/SearchBox';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import {
  Employee,
  LocalProgram,
  getEmployeeList,
} from '../../services/configApi/employees/employeeServices';
import { getFullName, getRoleName } from '../../utils/nameUtils';
import { Order, ResponseType } from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { PAGE_SIZE } from '../../services/Constant';
import { useNavigate } from 'react-router';
import { EDIT_EMPLOYEE_ROUTE_NAME } from '../../routes/Routing';
import { debounce } from 'lodash';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import React from 'react';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
interface Data {
  name: string;
  program: string;
  role: string;
  phoneNumber: string;
  email: string;
  emergencyContanct: string;
  emergencyPhone: string;
  actions: string;
}

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'Parent.Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'program',
    labelId: 'programText',
    defaultLabelId: 'Program',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'role',
    labelId: 'roleText',
    defaultLabelId: 'Role',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Contacts.email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'emergencyContanct',
    labelId: 'emergencyContactText',
    defaultLabelId: 'Emergency Contact',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'emergencyPhone',
    labelId: 'emergencyPhoneText',
    defaultLabelId: 'Emergency Phone',
    numeric: false,
    requiredSorting: false,
  },
];

const EmployeeListScreen = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();
  const [pageNumber, setPageNumber] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const handleButtonClick = (employee: Employee) => {
    navigate(EDIT_EMPLOYEE_ROUTE_NAME, {
      replace: false,
      state: {
        employee: employee,
        screenFrom: 'employeeList',
      },
    });
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const changeTextDebouncer = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(0);
        setSearchQuery(value);
      } else {
        setSuccessOrError('error');
        setToastrId('Dashboard.ClientList.searchError');
      }
    }, 500),
    [],
  );

  useEffect(() => {
    toggleLoader(true);
    getEmployeeList(searchQuery, pageNumber, PAGE_SIZE, orderBy, order)
      .then((response) => {
        if (response.employeeCount === 0) {
          toggleLoader(false);
          setEmployees([]);
          setPaginationCount(0);
          return;
        }
        setPaginationCount(response.employeeCount);
        setEmployees(response.employees);

        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to load details');
        }
      });
  }, [searchQuery, pageNumber, order]);

  useEffect(() => {
    if (
      headCells.length === 7 &&
      checkPermissionForFeature('backend.employee', 'editPermission')
    ) {
      setHeadCells((prevHeadCells) => [
        ...prevHeadCells,
        {
          id: 'actions',
          labelId: 'actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, []);

  const getPrograms = (programs: LocalProgram[] | null): string => {
    if (programs === null || programs.length == 0) {
      return '-';
    }
    const programList: string[] = [];
    programs.forEach((program) => {
      if (program.acronym !== null) {
        programList?.push(program.acronym);
      }
    });
    return programList.join(', ');
  };

  return (
    <Box component="main">
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={successOrError}
          labelId={toastrId}
          defaultMessageId={defaultMessage}
        />
      )}

      <Box className="rowContainer">
        <Grid container>
          <Grid item xs={6} lg={6}>
            <TitleText
              labelid="employeeListText"
              defaultlabel="Employee List"
            />
          </Grid>
          <Grid item xs={6} lg={6} className="text-align-end">
            <SearchBox
              labelId="searchEmployeeText"
              defaultlabel="Search Employee"
              onChange={changeTextDebouncer}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        {searchQuery !== '' && employees.length === 0 && <SearchNotFound />}
        {employees.length !== 0 && (
          <Box>
            <Card>
              <TableContainer>
                <>
                  <Table>
                    <TableHeader
                      className="listDataTableHead"
                      headerNames={headCells}
                      checkBoxRequired={false}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody className="tableRowcss">
                      {employees.map((employee, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {}}
                            key={index.toString()}
                            sx={{
                              cursor: 'pointer',
                              backgroundColor:
                                index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                            }}
                          >
                            <TableCell padding="checkbox">
                              <CheckBoxComponent ischecked={false} />
                            </TableCell>
                            <TooltipTableCell
                              value={getFullName(
                                employee.firstName,
                                employee.lastName,
                              )}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={getPrograms(employee.programs)}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={
                                employee.employeeRole === null
                                  ? '-'
                                  : getRoleName(employee.employeeRole)
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              value={
                                employee.phone1 === null ? '-' : employee.phone1
                              }
                              visible={false}
                            />
                            <TooltipTableCell
                              value={
                                employee.email === null ? '-' : employee.email
                              }
                              visible={true}
                            />
                            <TooltipTableCell
                              value={getFullName(
                                employee.emergencyFirstName,
                                employee.emergencyLastName,
                              )}
                              visible={true}
                            />
                            <TooltipTableCell
                              value={
                                employee.emergencyPhone === null
                                  ? '-'
                                  : employee.emergencyPhone
                              }
                              visible={false}
                            />
                            {checkPermissionForFeature(
                              'backend.employee',
                              'editPermission',
                            ) ? (
                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {checkPermissionForFeature(
                                    'backend.employee',
                                    'editPermission',
                                  ) && (
                                    <Box
                                      onClick={() => {
                                        handleButtonClick(employee);
                                      }}
                                    >
                                      <Tooltip title="Edit">
                                        <EditIcon />
                                      </Tooltip>
                                    </Box>
                                  )}
                                </Box>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              </TableContainer>
            </Card>

            {paginationCount > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={pageNumber}
                  paginationCount={paginationCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== pageNumber) {
                      setPageNumber(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmployeeListScreen;

import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  styled,
  Badge,
  Box,
  Grid,
  Tooltip,
  AlertColor,
  autocompleteClasses,
  Card,
} from '@mui/material';
import * as yup from 'yup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import './ParentGuardianInfo.css';
import { ReactComponent as EditIcon } from '../assets/images/editvector.svg';
import { ReactComponent as CheckEligibility } from '../assets/images/eligibility.svg';
import { ReactComponent as Editsignature } from '../assets/images/editsignature.svg';
import './Insurance.css';
import { Formik, FormikProps } from 'formik';
import MediumTypography from '../components/formlib/MediumTypography';
import Textinput from '../components/formlib/Textinput';
import DatePickerComponent from '../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  AddNewParent,
  ContactsList,
  FaceSheetDemographicsType,
  InsuranceDetails,
  InsuranceOtherDetails,
  OptionType,
} from '../utils/type';
import {
  createUpdateInsurance,
  getInsuranceDetails,
  getSubscriberList,
} from '../services/configApi/User';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import ModalPopup from '../components/formlib/ModalPopup';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabProps } from '../components/formlib/TabNavigationComponent';
import ButtonComponent from '../components/formlib/ButtonComponent';
import { LoaderContext, LoaderContextType } from '../layouts/AppSidebar';
import {
  InsuranceType,
  getMasterInsuranceListAll,
} from '../services/configApi/insurance/insuranceProvider';
import AutocompleteSearch from '../components/formlib/AutocompleteSearch';
import {
  CLIENT_ID_KEY,
  DATE_FORMAT,
  EXCLUDING_DATES,
  LOCAL_DATE_TIME_FORMAT,
  LOCAL_DATE_TIME_UTC_FORMAT,
} from '../services/Constant';
import { HeadCell, TableHeader } from '../components/formlib/TableHeader';
import TooltipTableCell from '../components/formlib/TooltipTableCell';
import _, { debounce } from 'lodash';
import { ApiError, isCustomError } from '../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../utils/dateUtil';
import DropdownComponent from '../components/formlib/DropdownComponent';
import CustomRadioButton, {
  RadioButtonData,
} from '../components/formlib/CustomRadioButton';
import CommentTextbox from '../components/formlib/CommentTextbox';
import SignatureComponent from '../components/formlib/modal/SignatureComponent';
import SelectComponent from '../components/formlib/SelectComponent';
import { Gender } from './Dropdown';
import { LookUpContext } from '../context/LookUpContextProvider';
import {
  convertGenderToBackend,
  convertGenderToFrontend,
} from '../utils/genderUtil';
import { ReferralResponseType } from '../services/configApi/Clients';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import moment from 'moment';
import AddressForm from '../components/formlib/AddressForm';
import { UnSavedChangesContext } from '../context/UnSavedChangesProvider';
import CheckBoxComponent from '../components/formlib/CheckBoxComponent';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const consentToInsuranceJson: RadioButtonData[] = [
  {
    value: 'authorize',
    titleId: 'accessInsuranceText',
    defaultTitleId: 'Access Insurance',
    labelId: 'insuranceConsent.authorize',
    defaultLabel:
      'I/We authorize the Thom Child and Family Services to bill our insurance company for services provided and to release related records to the insurance company/companies added above.',
  },
  {
    value: 'uninsured',
    titleId: 'uninsuredText',
    defaultTitleId: 'Uninsured',
    labelId: 'insuranceConsent.uninsured',
    defaultLabel:
      'My child is presently uninsured. I/we have received information about programs that I/we may qualify for in order to obtain medical coverage through one of the programs offered by the Division of Medical Assistance.',
  },
  {
    value: 'unauthorize',
    titleId: 'unauthorizedAccessInsuranceFormText',
    defaultTitleId: 'Refusal To Access Insurance Form',
    labelId: 'insuranceConsent.unauthorize',
    defaultLabel:
      'While I recognize that Early Intervention services are typically covered under public and/or private insurance without co-pays or deductibles for such services, I refuse access to my family/child’s insurance to pay for my child’s Early Intervention services.',
  },
];

const coverageTypes: OptionType[] = [
  {
    id: 'Primary',
    label: 'Primary',
  },
  {
    id: 'Secondary',
    label: 'Secondary',
  },
  {
    id: 'Tertiary',
    label: 'Tertiary',
  },
];

export const MassHealthValues = [
  {
    value: 'Straight Medicaid',
    labelId: 'straightmedicaidText',
    defaultLabel: 'Straight Medicaid',
  },
  {
    value: 'HMO Medicaid',
    labelId: 'hmomedicaidText',
    defaultLabel: 'HMO Medicaid',
  },
];

const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color ?? '#FFA500',
    height: '8px',
    width: '8px',
    marginRight: '10px',
    position: 'relative',
    top: '3px',
  },
}));
export const badgePicker = (status: string) => {
  if (status === 'InActive') {
    return (
      <CustomBadge
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'red',
          },
        }}
      />
    );
  } else {
    return <CustomBadge variant="dot" />;
  }
};
interface Data {
  name: string;
  type: string;
  Subscriber: string;
  tableEffectiveStartdate: string;
  effectivedate: string;
  status: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'Insurance.Name',
    defaultLabelId: 'Insurance Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'type',
    labelId: 'Insurance.Type',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'Subscriber',
    labelId: 'Insurance.Subscriber',
    defaultLabelId: 'Subscriber',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'tableEffectiveStartdate',
    labelId: 'insurance.effectiveStartDate',
    defaultLabelId: 'Effective Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'effectivedate',
    labelId: 'Insurance.Effectivedate',
    defaultLabelId: 'Effective End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'Insurance.status',
    defaultLabelId: 'Eligibility Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export interface CustomOptionsType {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
  maxIteration?: number;
  exifOrientation?: number;
}

interface AddressProps {
  setTabProp: React.Dispatch<React.SetStateAction<TabProps>>;
}

const insuranceValues: InsuranceDetails = {
  insuranceDetailsId: null,
  dph3rdPartyPayer: null,
  insuranceCompany: null,
  dphReasonCode: null,
  dphAddendum: null,
  masterInsuranceId: null,
  dph3rdPartyPayerName: null,
  coverageType2: null,
  subscriber: {
    firstName: null,
    lastName: null,
    middleName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zipCode: { id: 'asd', label: '' },
    phone: '',
    dateOfBirth: null,
    sex: null,
    subscriberMemberId: null,
    patientRelationToInsured: null,
    subscriberType: '',
  },
  effectiveStartDate: null,
  effectiveEndDate: null,
  isEdit: false,
  updated: false,
};

const initialInsuranceOther: InsuranceOtherDetails = {
  insuranceId: '',
  consentToInsurance: 'authorize',
  parentSignature: null,
  secondaryParentSignature: null,
  parentSignatureDate: null,
  secondaryParentSignatureDate: null,
  parentSignatureVerbalConsent: false,
  secondaryParentSignatureVerbalConsent: false,
  insuranceDetails: [],
  updatedBy: '',
  modifiedOn: null,
  notes: null,
};

const validationSchemaOthers = yup.object().shape({
  insuranceId: yup.string().nullable(),
  clientRecipientId: yup.string(),
  parentSignature: yup
    .object()
    .shape({
      signatureData: yup.string(),
      partitionKey: yup.string(),
      rowKey: yup.string(),
      id: yup.string().nullable(),
    })
    .nullable(),
  secondaryParentSignature: yup
    .object()
    .shape({
      signatureData: yup.string(),
      partitionKey: yup.string(),
      rowKey: yup.string(),
      id: yup.string().nullable(),
    })
    .nullable(),
  parentSignatureVerbalConsent: yup.bool().default(false),
  secondaryParentSignatureVerbalConsent: yup.bool().default(false),
  consentToInsurance: yup.string().required().nullable(),
  coverageType1: yup.string().nullable(),
  notes: yup.string().nullable(),
  parentSignatureDate: yup.string().nullable(),
  secondaryParentSignatureDate: yup.string().nullable(),
});

const Insurance: React.FC<AddressProps> = ({ setTabProp }) => {
  const [insuranceCompanies, setInsuranceCompanies] = React.useState<
    OptionType[]
  >([]);
  const [activeInsurances, setActiveInsurances] = React.useState<OptionType[]>(
    [],
  );
  const [activeMasterInsurance, setActiveMasterInsurance] = React.useState<
    InsuranceType[]
  >([]);
  const rctl = useIntl();
  const [insuranceData, setInsuranceData] = React.useState<InsuranceDetails>(
    JSON.parse(JSON.stringify(insuranceValues)),
  );
  const [initialInsuranceData, setInitialInsuranceData] =
    React.useState<InsuranceDetails>(
      JSON.parse(JSON.stringify(insuranceValues)),
    );
  const [tableData, setTableData] = React.useState<InsuranceDetails[]>([]);
  const [initialTableDataValue, setInitialTableDataValue] = React.useState<
    InsuranceDetails[]
  >([]);
  const [hideBtn, setHideBtn] = React.useState(false);
  const itemIndexValue = useRef<number>(0);
  const [otherInsuranceDetails, setOtherInsuranceDetails] =
    React.useState<InsuranceOtherDetails>(initialInsuranceOther);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const formRef = useRef<FormikProps<InsuranceDetails>>(null);
  const formRefOthers = useRef<FormikProps<InsuranceOtherDetails>>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [lastUpdatedBy, setLastUpdatedBy] = React.useState('');
  const [lastUpdatedOn, setLastUpdatedOn] = React.useState<string | null>(null);
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [isDPHInsuranceActive, setIsDPHInsuranceActive] =
    useState<boolean>(false);

  const { lookups } = useContext(LookUpContext);
  const { handleUpdateUnsavedChanges } = React.useContext(
    UnSavedChangesContext,
  );

  const [lookupDphInsuranceAddendum, setLookupDphInsuranceAddendum] = useState<
    OptionType[]
  >([]);
  const [lookupDphReasonCode, setLookupDphReasonCode] = useState<OptionType[]>(
    [],
  );
  const [lookupPatientRelation, setLookupPatientRelation] = useState<
    OptionType[]
  >([]);

  const [selectedProgram, setSelectedProgram] =
    React.useState<OptionType | null>(null);
  const [initialSelectedProgram, setInitialSelectedProgram] =
    React.useState<OptionType | null>(null);
  const [selectedInsuranceAddendum, setSelectedInsuranceAddendum] =
    React.useState<OptionType | null>(null);
  const [isScreenLocked, setIsScreenLocked] = React.useState<boolean>(false);
  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [subscriberSearchKey, setSubscriberSearchKey] = useState<string | null>(
    null,
  );
  const [subscribers, setSubscribers] = useState<AddNewParent[]>([]);
  const [selectedSubscriber, setSelectedSubscriber] =
    useState<OptionType | null>(null);

  const [parentSignClicked, setParentSignClicked] = useState<boolean>(false);
  const [parentSecondarySignClicked, setParentSecondarySignClicked] =
    useState<boolean>(false);
  const [isChangeInTableForm, setIsChangeInTableForm] =
    useState<boolean>(false);
  const [isChangeInInsuranceData, setIsChangeInInsuranceData] =
    useState<boolean>(false);

  const validationSchema = yup.object().shape({
    dph3rdPartyPayer: yup
      .string()
      .nullable()
      .required('insurance.dph3rdPartyPayerRequiredText'),
    insuranceCompany: yup.string().when(['dph3rdPartyPayer'], {
      is: 'OTHER',
      then: yup.string().required('insurance.companyrequired'),
      otherwise: yup.string().nullable(),
    }),
    dphReasonCode: yup.string().when(['dph3rdPartyPayer'], {
      is: '00',
      then: yup.string().required('insurance.dphReasonCodeReq'),
      otherwise: yup.string().nullable(),
    }),
    dphAddendum: yup.string().nullable(),
    coverageType2: yup.string().required('coverageTypeRequired').nullable(),
    subscriber: yup.object().shape({
      firstName: yup.string().required('Clientpage.First.validation'),
      lastName: yup.string().required('Clientpage.last.validation'),
      middleName: yup.string().nullable(),
      dateOfBirth: yup.date().required('parent.dateofbirthMessage').nullable(),
      sex: yup.string().required('Clientpage.Gender.validation').nullable(),
      subscriberMemberId: yup
        .string()
        .required('insurance.subscriberMemberIdReq')
        .nullable(),
      patientRelationToInsured: yup
        .string()
        .required('insurance.clientRelationtoInsuredReq')
        .nullable(),
      phone: yup
        .string()
        .required('ContactOthers.phonenumberMandatory')
        .nullable(),
      address1: yup.string().required('addressLine1RequiredMessage').nullable(),
      address2: yup.string().nullable(),
      city: yup.string().required('cityRequiredMessage').nullable(),
      state: yup.string().required('stateRequiredMessage').nullable(),
      zipCode: yup.object().shape({
        label: yup.string().required('zipCodeRequiredMessage'),
      }),
    }),
    effectiveStartDate: yup
      .date()
      .required('insurance.effectiveStartDateReq')
      .nullable(),
    effectiveEndDate: yup.date().nullable(),
  });

  useEffect(() => {
    const hasDPHInsurance = formRefOthers.current?.values.insuranceDetails.find(
      (item) =>
        item.dph3rdPartyPayer === '00' &&
        (!item.effectiveEndDate ||
          dayjs(item.effectiveEndDate).isAfter(dayjs())),
    );
    setIsDPHInsuranceActive(hasDPHInsurance ? true : false);
  }, [formRefOthers.current?.values]);

  useEffect(() => {
    const clientId = localStorage.getItem(CLIENT_ID_KEY);
    if (clientId && subscriberSearchKey && subscriberSearchKey.length > 1) {
      getSubscriberList(clientId, subscriberSearchKey)
        .then((response) => {
          setSubscriberSearchKey(null);
          setSubscribers(response);
        })
        .catch(() => {});
    }
  }, [subscriberSearchKey]);

  const updateSubscriberSearchKey = useCallback(
    debounce((searchKey: string) => {
      setSubscriberSearchKey(searchKey ?? null);
    }, 500),
    [],
  );

  const validateForm = (value: InsuranceDetails) => {
    const errors: Partial<InsuranceDetails> = {};
    if (value.effectiveEndDate) {
      if (
        dayjs(value.effectiveEndDate).isBefore(dayjs(value.effectiveStartDate))
      ) {
        errors.effectiveEndDate = 'effectiveEndDateValidationMessage';
      }
    }
    if (dayjs(value.effectiveStartDate).isAfter(dayjs(), 'date')) {
      errors.effectiveStartDate = 'notFutureDateMessage';
    }
    if (datePickerMinDateMaxDateValidate(value.effectiveStartDate)) {
      errors.effectiveStartDate = 'datePickerMinDateMaxDateValidate';
    }

    if (datePickerMinDateMaxDateValidate(value.effectiveEndDate)) {
      errors.effectiveEndDate = 'datePickerMinDateMaxDateValidate';
    }

    if (datePickerMinDateMaxDateValidate(value.subscriber.dateOfBirth)) {
      errors.subscriber = {};
      errors.subscriber.dateOfBirth = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const onParentSignatureSaveOrUpdate = (signed: string, index: number) => {
    if (index === 0) {
      formRefOthers.current?.setFieldValue('parentSignature', {
        partitionKey: '',
        rowKey: '',
        signatureData: signed,
      });
      formRefOthers.current?.setFieldValue(
        'parentSignatureDate',
        dayjs.utc().format(),
      );
      setParentSignClicked(false);
    } else {
      formRefOthers.current?.setFieldValue('secondaryParentSignature', {
        partitionKey: '',
        rowKey: '',
        signatureData: signed,
      });
      formRefOthers.current?.setFieldValue(
        'secondaryParentSignatureDate',
        dayjs.utc().format(),
      );
      setParentSecondarySignClicked(false);
    }
  };

  const getRelationshipValue = (code: string) => {
    if (code && lookups) {
      const guardianType = lookups.guardianType.find(
        (type) => type.code === code,
      );
      if (guardianType) {
        return '-' + guardianType.description;
      }
    }
    return '';
  };

  const clearSignatures = () => {
    if (formRefOthers.current) {
      formRefOthers.current.setValues((value) => ({
        ...value,
        parentSignature: null,
        parentSignatureDate: null,
        secondaryParentSignature: null,
        secondaryParentSignatureDate: null,
        parentSignatureVerbalConsent: false,
        secondaryParentSignatureVerbalConsent: false,
      }));
    }
  };

  const onParentSignCancelClick = () => {
    setParentSignClicked(false);
  };
  const onSecondaryParentSignCancelClick = () => {
    setParentSecondarySignClicked(false);
  };

  const getInsuranceName = (id: string) => {
    const foundInsuranceName = insuranceCompanies.find((val) => {
      return val.id === id;
    })?.label;
    return foundInsuranceName ?? '';
  };

  const getInsuranceAddendumName = (id: string) => {
    const foundInsuranceAddendumName = lookupDphInsuranceAddendum.find(
      (val) => {
        return val.id === id;
      },
    )?.label;
    return foundInsuranceAddendumName ?? '';
  };

  const getFormValues = (rowValues: InsuranceDetails, index: number) => {
    itemIndexValue.current = index;
    setHideBtn(true);
    const subscriberdate: Dayjs | null = rowValues.subscriber.dateOfBirth
      ? dayjs(rowValues.subscriber.dateOfBirth)
      : null;
    const effectiveDates: Dayjs = dayjs(rowValues.effectiveStartDate);
    let endDates: Dayjs | null = null;
    if (rowValues.effectiveEndDate) {
      endDates = dayjs(rowValues.effectiveEndDate);
    }

    setSelectedProgram({
      id: '',
      label:
        rowValues.masterInsuranceId !== null
          ? getInsuranceName(rowValues.masterInsuranceId)
          : '',
    });

    setInitialSelectedProgram({
      id: '',
      label:
        rowValues.masterInsuranceId !== null
          ? getInsuranceName(rowValues.masterInsuranceId)
          : '',
    });

    setSelectedInsuranceAddendum({
      id: '',
      label:
        rowValues.dphAddendum !== null
          ? getInsuranceAddendumName(rowValues.dphAddendum)
          : '',
    });
    setSelectedSubscriber(null);
    setInitialInsuranceData({
      insuranceDetailsId: rowValues.insuranceDetailsId,
      dph3rdPartyPayer: rowValues.dph3rdPartyPayer,
      insuranceCompany: rowValues.insuranceCompany,
      dph3rdPartyPayerName: rowValues.dph3rdPartyPayerName,
      masterInsuranceId: rowValues.masterInsuranceId,
      dphReasonCode: rowValues.dphReasonCode,
      dphAddendum: rowValues.dphAddendum,
      coverageType2: rowValues.coverageType2,
      subscriber: {
        firstName: rowValues.subscriber.firstName,
        lastName: rowValues.subscriber.lastName,
        middleName: rowValues.subscriber.middleName,
        address1: rowValues.subscriber.address1,
        address2: rowValues.subscriber.address2,
        city: rowValues.subscriber.city,
        state: rowValues.subscriber.state,
        zipCode: rowValues.subscriber.zipCode,
        phone: rowValues.subscriber.phone,
        dateOfBirth: subscriberdate,
        sex: rowValues.subscriber.sex
          ? convertGenderToFrontend(rowValues.subscriber.sex)
          : '',
        subscriberMemberId: rowValues.subscriber.subscriberMemberId,
        patientRelationToInsured: rowValues.subscriber.patientRelationToInsured,
        subscriberType: rowValues.dph3rdPartyPayer === '00' ? 'client' : '',
      },
      effectiveStartDate: effectiveDates,
      effectiveEndDate: endDates,
      // Will be removed after geting confirmation from respective dev
      // medicaidInfo: rowValues.medicaidInfo,
      isEdit: true,
      updated: rowValues.updated,
    });
    setInsuranceData({
      insuranceDetailsId: rowValues.insuranceDetailsId,
      dph3rdPartyPayer: rowValues.dph3rdPartyPayer,
      insuranceCompany: rowValues.insuranceCompany,
      dph3rdPartyPayerName: rowValues.dph3rdPartyPayerName,
      masterInsuranceId: rowValues.masterInsuranceId,
      dphReasonCode: rowValues.dphReasonCode,
      dphAddendum: rowValues.dphAddendum,
      coverageType2: rowValues.coverageType2,
      subscriber: {
        firstName: rowValues.subscriber.firstName,
        lastName: rowValues.subscriber.lastName,
        middleName: rowValues.subscriber.middleName,
        address1: rowValues.subscriber.address1,
        address2: rowValues.subscriber.address2,
        city: rowValues.subscriber.city,
        state: rowValues.subscriber.state,
        zipCode: rowValues.subscriber.zipCode,
        phone: rowValues.subscriber.phone,
        dateOfBirth: subscriberdate,
        sex: rowValues.subscriber.sex
          ? convertGenderToFrontend(rowValues.subscriber.sex)
          : '',
        subscriberMemberId: rowValues.subscriber.subscriberMemberId,
        patientRelationToInsured: rowValues.subscriber.patientRelationToInsured,
        subscriberType: rowValues.dph3rdPartyPayer === '00' ? 'client' : '',
      },
      effectiveStartDate: effectiveDates,
      effectiveEndDate: endDates,
      isEdit: true,
      updated: rowValues.updated,
    });
  };

  const formatDate = (date: string | Dayjs | null) => {
    return date === null ? null : dayjs(date).format('MM/DD/YYYY');
  };

  const handleUpdateCall = useCallback(
    debounce((value: InsuranceDetails, data: InsuranceDetails[]) => {
      if (value.isEdit) {
        value.effectiveEndDate = formatDate(value.effectiveEndDate);

        value.effectiveStartDate = formatDate(value.effectiveStartDate);
        value.subscriber.dateOfBirth = value.subscriber.dateOfBirth
          ? formatDate(value.subscriber.dateOfBirth)
          : null;

        value.subscriber.sex = value.subscriber.sex
          ? convertGenderToBackend(value.subscriber.sex)
          : null;
        value.updated = true;
        const objUpdate = value;
        const newDataUpdate = data;

        newDataUpdate[itemIndexValue.current] = objUpdate;

        const formValues = formRefOthers.current?.values;
        if (formValues) {
          formValues.insuranceDetails = newDataUpdate;
          if (value.dph3rdPartyPayer === '00') {
            formValues.consentToInsurance = 'uninsured';
          }
          formRefOthers.current?.setValues(formValues);
        }
        setTableData(newDataUpdate);
        setInsuranceData(JSON.parse(JSON.stringify(insuranceValues)));

        setSelectedProgram(null);
        setSelectedInsuranceAddendum(null);
        setHideBtn(false);
        insuranceValues.subscriber.firstName = null;
        insuranceValues.subscriber.lastName = null;
        insuranceValues.subscriber.middleName = null;
        insuranceValues.subscriber.subscriberMemberId = null;
        insuranceValues.subscriber.patientRelationToInsured = null;
        insuranceValues.subscriber.dateOfBirth = null;
        insuranceValues.subscriber.sex = null;
        insuranceValues.subscriber.zipCode = { id: 'asd', label: '' };
        formRef.current?.setValues(insuranceValues);
        formRef.current?.setErrors({});
      } else {
        value.effectiveEndDate = formatDate(value.effectiveEndDate);
        value.effectiveStartDate = formatDate(value.effectiveStartDate);
        value.subscriber.dateOfBirth = value.subscriber.dateOfBirth
          ? formatDate(value.subscriber.dateOfBirth)
          : null;

        value.subscriber.sex = value.subscriber.sex
          ? convertGenderToBackend(value.subscriber.sex)
          : null;

        const newData = data;
        newData.push(value);
        setTableData(newData);
        setSelectedProgram(null);
        setSelectedInsuranceAddendum(null);
        setInsuranceData(JSON.parse(JSON.stringify(insuranceValues)));
        const formValues = formRefOthers.current?.values;
        if (formValues) {
          formValues.insuranceDetails = newData;
          if (value.dph3rdPartyPayer === '00') {
            formValues.consentToInsurance = 'uninsured';
          }
          formRefOthers.current?.setValues(formValues);
        }
        insuranceValues.subscriber.subscriberMemberId = null;
        insuranceValues.subscriber.patientRelationToInsured = null;
        insuranceValues.subscriber.dateOfBirth = null;
        insuranceValues.subscriber.sex = null;
        insuranceValues.subscriber.zipCode = { id: 'asd', label: '' };
        formRef.current?.setValues(insuranceValues);
        formRef.current?.setTouched({});
        formRef.current?.setErrors({});
      }
    }, 500),
    [],
  );

  const handleSubmitOthers = (vals: InsuranceOtherDetails) => {
    vals.insuranceDetails = [...tableData];
    vals.insuranceDetails.forEach((insurance) => {
      insurance.subscriber.zipCode = (
        insurance.subscriber.zipCode as OptionType
      ).label;
    });
    vals.updatedBy = localStorage.getItem('userName') ?? '';
    const clientId: string = localStorage.getItem('ClientId') ?? '';

    toggleLoader(true);
    createUpdateInsurance(clientId, vals)
      .then(async (response) => {
        toggleLoader(false);
        if (response) {
          formRef.current?.setErrors({});
          formRef.current?.resetForm();
          setSelectedProgram(null);
          setSelectedInsuranceAddendum(null);
          setOpen(true);
          setToastrVariable('success');

          if (vals.insuranceId) {
            if (!checkParentSignatureSigned(vals)) {
              setToastrDefaultMessage(
                'Insurance Updated Successfully. Parents need to sign the Insurance form.',
              );
              setToastrId('Insurance.noSignatureUpdateSuccessMessage');
            } else {
              setToastrDefaultMessage('Insurance Updated Successfully');
              setToastrId('Insurance.updatesuccess');
            }
            getClientInsuranceList();
          } else if (!checkParentSignatureSigned(vals)) {
            setToastrDefaultMessage(
              'Insurance Added Successfully. Parents need to sign the Insurance form.',
            );
            setToastrId('Insurance.noSignatureAddSuccessMessage');
          } else {
            setToastrDefaultMessage('Insurance Added Successfully');
            setToastrId('Insurance.createsuccess');
          }

          if (!vals.insuranceId) {
            setTimeout(() => {
              handleTabPropUpdate();
            }, 800);
          }
        }
      })
      .catch(async (error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId(
            vals.insuranceId === ''
              ? 'Insurance.createFailureApiMsg'
              : 'Insurance.updateFailureApiMsg',
          );
          setToastrDefaultMessage(
            vals.insuranceId === ''
              ? 'Failed to create Insurance'
              : 'Failed to update Insurance',
          );
        }
      });
  };

  const checkParentSignatureSigned = (vals: InsuranceOtherDetails) => {
    return (
      vals.parentSignature !== null ||
      vals.parentSignatureVerbalConsent ||
      vals.secondaryParentSignature !== null ||
      vals.secondaryParentSignatureVerbalConsent
    );
  };

  const handleTabPropUpdate = () => {
    setTabProp((value) => {
      return {
        clientCreated: true,
        tabPosition: value.tabPosition + 1,
      };
    });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getMasterInsuranceList();
    getClientInsuranceList();
  }, []);

  const getClientInsuranceList = () => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    getInsuranceDetails(clientId)
      .then((response: InsuranceOtherDetails) => {
        if (response) {
          response.consentToInsurance =
            response.consentToInsurance === ''
              ? 'authorize'
              : response.consentToInsurance;
          const updatedValue = response.insuranceDetails.filter((item) => {
            item.subscriber.zipCode = {
              id: 'asd',
              label: item.subscriber.zipCode as string,
            };

            if (EXCLUDING_DATES.includes(item.effectiveEndDate as string)) {
              item.effectiveEndDate = null;
            }
            if (EXCLUDING_DATES.includes(item.effectiveStartDate as string)) {
              item.effectiveStartDate = null;
            }
            return item;
          });

          setTableData(updatedValue);
          setInitialTableDataValue(updatedValue);
          setLastUpdatedBy(response.updatedBy);
          setLastUpdatedOn(response.modifiedOn);
        } else {
          setTableData([]);
          setInitialTableDataValue([]);
        }
        setOtherInsuranceDetails(response);
      })
      .catch((error) => {
        setTableData([]);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  const getActiveInActiveInsuranceName = (payerId: string) => {
    const insurance = activeMasterInsurance.find(
      (val) => val.payerId === payerId,
    );
    if (insurance) {
      const name = `${insurance.payerId} - ${insurance.insuranceCompanyName}`;
      const endDate = dayjs(insurance.endDate, 'MM/DD/YYYY');
      const today = dayjs();
      const isSameOrAfterToday =
        endDate.isSame(today, 'day') || endDate.isAfter(today, 'day');
      let insuranceName = name;
      if (insurance.endDate !== null && !isSameOrAfterToday) {
        insuranceName += ' (Not Honored)';
      }
      return insuranceName;
    }
    return '-';
  };

  const getClientDetail = (): ContactsList | null => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    const clientInfo = localStorage.getItem('Demographics');
    if (clientInfo !== null) {
      const client = JSON.parse(clientInfo) as FaceSheetDemographicsType;
      const clientReferralDetails = JSON.parse(
        localStorage.getItem('clientReferralDetails') as unknown as string,
      ) as ReferralResponseType[];
      const defaultReferralId = localStorage.getItem('defaultReferralId');
      if (
        clientReferralDetails &&
        clientReferralDetails.length > 0 &&
        defaultReferralId
      )
        clientReferralDetails.forEach((referral: ReferralResponseType) => {
          if (referral.referralId === defaultReferralId) {
            client.dphId = referral.dphId;
          }
        });
      const value: ContactsList = {
        id: clientId,
        firstName: client.firstName,
        lastName: client.lastName,
        middleName: client.middleName,
        type: 'Client',
        isPrimary: false,
        DOB: client.dateOfBirth,
        sex: convertGenderToFrontend(client.sex ?? '') ?? '',
        homeNumber: '',
        isLegal: client.isLegal ?? false,
        contactType: 'client',
        enrollmentId: client.dphId,
      };
      return value;
    }
    return null;
  };
  const getMasterInsuranceList = () => {
    getMasterInsuranceListAll()
      .then((response: InsuranceType[]) => {
        const InsuranceCList: OptionType[] = [];
        const activeList: OptionType[] = [];
        const newActiveInsurances: InsuranceType[] = [];
        response?.forEach((item) => {
          const name = `${item.payerId} - ${item.insuranceCompanyName}`;
          InsuranceCList.push({
            id: item.insuranceId ?? '',
            label: name,
          });
          const endDate = dayjs(item.endDate, 'MM/DD/YYYY');
          const today = dayjs();
          const isSameOrAfterToday =
            endDate.isSame(today, 'day') || endDate.isAfter(today, 'day');
          let insuranceName = name;
          if (item.endDate !== null && !isSameOrAfterToday) {
            insuranceName += ' (Not Honored)';
          }
          if (!item.isDeleted) {
            activeList.push({
              id: item.insuranceId ?? '',
              label: insuranceName,
            });
          }
          newActiveInsurances.push(item);
        });

        setInsuranceCompanies(InsuranceCList);
        setActiveMasterInsurance(newActiveInsurances);
        setActiveInsurances(activeList);
        toggleLoader(false);
      })
      .catch((error) => {
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  useEffect(() => {
    if (lookups) {
      setLookupDphReasonCode(() => {
        return lookups.dphInsuranceReasonCode.map((item) => {
          return {
            id: item.code,
            label: item.code.concat(` - `).concat(item.description),
          };
        });
      });
      setLookupDphInsuranceAddendum(() => {
        return lookups.eicsInsuranceAddendum.map((item) => {
          return { id: item.code, label: item.description };
        });
      });
      setLookupPatientRelation(() => {
        return lookups.eicsPatientRelation.map((item) => {
          return { id: item.code, label: item.description };
        });
      });
    }
  }, [lookups]);

  const alphaNumericValidation = (value: string) => {
    let trimmedValue = value.replace(/^\s+/, '');
    const maxLength = 100;

    if (trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.slice(0, maxLength);
    }

    const regex = /^[A-Za-z0-9.,!' ]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z0-9,'. -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  const clearForm = () => {
    formRef.current?.resetForm();
    formRefOthers.current?.setValues(formRefOthers.current?.initialValues);
    setInsuranceData(JSON.parse(JSON.stringify(insuranceValues)));
    setOpenModal(false);
    setTableData(initialTableDataValue);
  };

  const closeModal = () => {
    setShowAlertDialog(false);
  };

  const getCoverageType = (coverageType: string | null) => {
    if (coverageType === null) {
      return '-';
    }
    const value = coverageTypes.find((type) => type.id === coverageType);
    return value?.label ?? '-';
  };

  const displayCoverageType = (coverageType: string) => {
    if (
      coverageType === 'primary' ||
      coverageType === 'secondary' ||
      coverageType === 'tertiary'
    ) {
      return getCoverageType(coverageType);
    }
    return coverageType;
  };

  const handleDuplicateInsurance = () => {
    setOpen(true);
    setToastrVariable('error');
    setToastrId('duplicateInsuranceMessage');
    setToastrDefaultMessage(
      'You cannot have duplicate coverage types in Insurance',
    );
  };

  const handleOverlapInsurance = () => {
    setOpen(true);
    setToastrVariable('error');
    setToastrId('overlapInsuranceMessage');
    setToastrDefaultMessage(
      'The same Coverage Types should not have overlapping dates.',
    );
  };

  const handleFormSubmission = (value: InsuranceDetails) => {
    if (value.isEdit) {
      handleInsuranceEdit(value);
    } else {
      handleInsuranceAdd(value);
    }
  };

  const handleInsuranceAdd = (value: InsuranceDetails) => {
    if (tableData.length === 0) {
      clearSignatures();
      handleUpdateCall(value, tableData);
      return;
    }

    const similarCoverageInsurance = tableData.filter(
      (item) =>
        item.coverageType2 && item.coverageType2 === value.coverageType2,
    );
    if (similarCoverageInsurance.length === 0) {
      clearSignatures();
      handleUpdateCall(value, tableData);
    } else {
      similarCoverageInsurance.sort(
        (a: InsuranceDetails, b: InsuranceDetails) =>
          dayjs(a.effectiveStartDate).isBefore(dayjs(b.effectiveStartDate))
            ? 1
            : -1,
      );
      let hasError = false;
      similarCoverageInsurance.every((item) => {
        const previousStartDate = dayjs(item.effectiveStartDate, DATE_FORMAT);
        const previousEndDate =
          item.effectiveEndDate !== null
            ? dayjs(item.effectiveEndDate, DATE_FORMAT)
            : null;

        const currentStartDate = dayjs(value.effectiveStartDate, DATE_FORMAT);
        const currentEndDate =
          value.effectiveEndDate !== null
            ? dayjs(value.effectiveEndDate, DATE_FORMAT)
            : null;
        if (
          previousEndDate === null ||
          dayjs(previousEndDate).isAfter(dayjs())
        ) {
          hasError = true;
          handleDuplicateInsurance();
          return false;
        }
        if (
          currentEndDate === null &&
          dayjs(currentStartDate).isBefore(previousStartDate)
        ) {
          hasError = true;
          handleDuplicateInsurance();
          return false;
        }

        if (
          dayjs(currentStartDate).isBefore(previousStartDate) &&
          dayjs(currentEndDate).isBefore(previousStartDate)
        ) {
          hasError = true;
          handleOverlapInsurance();
          return false;
        }
        if (
          dayjs(currentStartDate).isBetween(previousStartDate, previousEndDate)
        ) {
          hasError = true;
          handleOverlapInsurance();
          return false;
        }
        if (
          currentEndDate &&
          dayjs(currentEndDate).isBetween(previousStartDate, previousEndDate)
        ) {
          hasError = true;
          handleOverlapInsurance();
          return false;
        }
        return true;
      });
      if (hasError) {
        return;
      }
      clearSignatures();
      handleUpdateCall(value, tableData);
    }
  };

  const handleInsuranceEdit = (value: InsuranceDetails) => {
    if (tableData.length === 1) {
      clearSignatures();
      handleUpdateCall(value, tableData);
    } else {
      const filteredInsurances = tableData.filter(
        (__, index) => index !== itemIndexValue.current,
      );
      const similarCoverageInsurance = filteredInsurances.filter(
        (item) =>
          item.coverageType2 && item.coverageType2 === value.coverageType2,
      );
      if (similarCoverageInsurance.length === 0) {
        clearSignatures();
        handleUpdateCall(value, tableData);
      } else {
        similarCoverageInsurance.sort(
          (a: InsuranceDetails, b: InsuranceDetails) =>
            dayjs(a.effectiveStartDate).isBefore(dayjs(b.effectiveStartDate))
              ? 1
              : -1,
        );
        let hasError = false;
        similarCoverageInsurance.every((item) => {
          const previousStartDate = dayjs(item.effectiveStartDate, DATE_FORMAT);
          const previousEndDate =
            item.effectiveEndDate !== null
              ? dayjs(item.effectiveEndDate, DATE_FORMAT)
              : null;

          const currentStartDate = dayjs(value.effectiveStartDate, DATE_FORMAT);
          const currentEndDate =
            value.effectiveEndDate !== null
              ? dayjs(value.effectiveEndDate, DATE_FORMAT)
              : null;
          if (
            previousEndDate === null ||
            dayjs(previousEndDate).isAfter(dayjs())
          ) {
            hasError = true;
            handleDuplicateInsurance();
            return false;
          }
          if (
            dayjs(previousStartDate).isBetween(
              currentStartDate,
              currentEndDate,
            ) ||
            dayjs(previousEndDate).isBetween(currentStartDate, currentEndDate)
          ) {
            hasError = true;
            handleOverlapInsurance();
            return false;
          }
          if (
            dayjs(currentStartDate).isBetween(
              previousStartDate,
              previousEndDate,
            ) ||
            dayjs(currentEndDate).isBetween(previousStartDate, previousEndDate)
          ) {
            hasError = true;
            handleOverlapInsurance();
            return false;
          }
          return true;
        });
        if (hasError) {
          return;
        }
        clearSignatures();
        handleUpdateCall(value, tableData);
      }
    }
  };

  const checkActiveInsurancInCaseOfDPH = () => {
    if (formRefOthers.current?.values.insuranceDetails.length === 0)
      return false;
    const hasActiveInsurance =
      formRefOthers.current?.values.insuranceDetails.find(
        (item) =>
          !item.effectiveEndDate ||
          dayjs(item.effectiveEndDate).isAfter(dayjs()),
      );
    if (hasActiveInsurance) return true;
    return false;
  };

  const showErrorMessage = () => {
    setOpen(true);
    setToastrVariable('error');
    setToastrId('activeInsuranceValidation');
    setToastrDefaultMessage(
      'Active insurance exists for the client. Make them In-active to add DPH.',
    );
  };

  const setSubscriberSelection = (sub: OptionType | null) => {
    const values = formRef.current?.values;
    if (sub && values) {
      const subscriber = subscribers.find(
        (item) => item.careGiverId === sub.id,
      );
      if (subscriber) {
        values.subscriber.firstName = subscriber.firstName;
        values.subscriber.lastName = subscriber.lastName;
        values.subscriber.middleName = null;
        values.subscriber.dateOfBirth = subscriber.dateOfBirth;
        values.subscriber.sex = subscriber.sex
          ? convertGenderToFrontend(subscriber.sex)
          : '';
        values.subscriber.phone = getSubscriberPhoneNumber(subscriber);
        values.subscriber.zipCode = {
          id: 'sda',
          label: subscriber.zipCode,
        } as OptionType;
        values.subscriber.address1 = subscriber.street;
        values.subscriber.address2 = subscriber.apartment;
        values.subscriber.city = subscriber.city;
        values.subscriber.state = subscriber.state;
      } else {
        values.subscriber.firstName = null;
        values.subscriber.lastName = null;
        values.subscriber.middleName = null;
        values.subscriber.dateOfBirth = null;
        values.subscriber.sex = null;
        values.subscriber.patientRelationToInsured = null;
        values.subscriber.zipCode = '';
        values.subscriber.address1 = null;
        values.subscriber.address2 = null;
        values.subscriber.city = null;
        values.subscriber.state = null;
      }
      values.subscriber.subscriberMemberId = null;
      formRef.current?.setValues(values);
    }
    setSelectedSubscriber(sub);
  };

  useEffect(() => {
    if (isChangeInInsuranceData || isChangeInTableForm) {
      handleUpdateUnsavedChanges(true);
    } else {
      handleUpdateUnsavedChanges(false);
    }
  }, [isChangeInInsuranceData, isChangeInTableForm]);

  return (
    <>
      <Box component="div">
        <Box
          ref={parentRef}
          style={{
            pointerEvents: isScreenLocked ? 'none' : 'auto',
            opacity: isScreenLocked ? '0.5' : '1 ',
          }}
          onKeyDownCapture={(e) => {
            if (isScreenLocked) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (isScreenLocked) {
              if (parentRef.current) parentRef.current.focus();
            }
          }}
        >
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
          {showAlertDialog && (
            <ModalPopup
              open={showAlertDialog}
              description="insurance.medicaidPopup"
              onOk={() => closeModal()}
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="deleteText"
            />
          )}
          <Box component="section">
            <TableContainer sx={{ maxHeight: 310 }} className="tableStyles">
              <Table stickyHeader aria-label="sticky table">
                <TableHeader
                  className="listDataTableHead"
                  headerNames={headCells}
                  checkBoxRequired={false}
                />

                <TableBody className="tableRowcss">
                  {tableData.length === 0 ? ( // Check if tableData is empty
                    <TableRow>
                      <TableCell colSpan={6} align="center" id="nodata">
                        <FormattedMessage
                          id="Insurance.nodataValidation"
                          defaultMessage="No Insurance added yet"
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    tableData.map((row: InsuranceDetails, index: number) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.coverageType2}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                        }}
                      >
                        <TooltipTableCell
                          value={getActiveInActiveInsuranceName(
                            row.dph3rdPartyPayer ?? '',
                          )}
                          textSxProps={{
                            maxWidth: 200,
                          }}
                          visible={true}
                        />
                        <TooltipTableCell
                          value={
                            row.coverageType2 !== null
                              ? displayCoverageType(row.coverageType2)
                              : '-'
                          }
                          visible={true}
                        />
                        <TooltipTableCell
                          value={`${row.subscriber.lastName ?? ''}, ${
                            row.subscriber.firstName ?? ''
                          } ${row.subscriber.middleName ?? ''}`}
                          visible={true}
                        />
                        <TableCell>
                          {row.effectiveStartDate
                            ? ` ${row.effectiveStartDate} `
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {row.effectiveEndDate
                            ? ` ${row.effectiveEndDate} `
                            : '-'}
                        </TableCell>

                        <TableCell
                          className="postion__relative"
                          sx={{
                            fontFamily: 'Lato-Regular',
                          }}
                        >
                          {badgePicker('Not Verified')}
                          Not verified
                        </TableCell>

                        <TableCell>
                          <Box className="flex__ alignItemCenter cursorPointer">
                            <Box>
                              <Tooltip title="Edit">
                                <EditIcon
                                  onClick={() => getFormValues(row, index)}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <Box className="mt-sm">
                <Card>
                  <Formik
                    initialValues={insuranceData}
                    validationSchema={validationSchema}
                    validate={validateForm}
                    innerRef={formRef}
                    enableReinitialize={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                      if (
                        dayjs(values.effectiveEndDate).isBefore(
                          dayjs(values.effectiveStartDate),
                        )
                      ) {
                        formRef.current?.setValues((formvalues) => ({
                          ...formvalues,
                          effectiveEndDate: null,
                        }));
                      } else {
                        handleFormSubmission(values);
                      }
                    }}
                  >
                    {({
                      setFieldValue,
                      handleSubmit,
                      errors,
                      touched,
                      values,
                    }) => {
                      useEffect(() => {
                        if (
                          _.isEqual(values, initialInsuranceData) &&
                          _.isEqual(selectedProgram, initialSelectedProgram)
                        ) {
                          setIsChangeInTableForm(false);
                          // handleUpdateUnsavedChanges(false);
                        } else {
                          setIsChangeInTableForm(true);
                          // handleUpdateUnsavedChanges(true);
                        }
                      }, [
                        values,
                        selectedProgram,
                        initialInsuranceData,
                        initialSelectedProgram,
                      ]);
                      return (
                        <>
                          <Box sx={{ padding: '16px 16px 0px 16px' }}>
                            <Grid
                              container
                              rowSpacing={'24px'}
                              columnSpacing={'40px'}
                            >
                              <Grid item xs={12} lg={8}>
                                <MediumTypography
                                  labelid="Insurance.details"
                                  defaultlabel="Insurance Details"
                                  sxProps={{
                                    color: '#2A4241',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginRight: '16px',
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <AutocompleteSearch
                                  labelId={'insurance.dph3rdPartyPayer'}
                                  defaultId={'No Search found'}
                                  Required
                                  data={activeInsurances}
                                  onTextChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    if (event.target.value.length === 0) {
                                      formRef.current?.resetForm();
                                    }
                                  }}
                                  onSelectItem={(value: OptionType | null) => {
                                    if (value !== null) {
                                      setSelectedProgram(value);
                                      values.masterInsuranceId = value.id;
                                      const selectInsurancePdph3rdPartyPayer =
                                        activeMasterInsurance.find(
                                          (item) =>
                                            item.insuranceId === value.id,
                                        );
                                      if (selectInsurancePdph3rdPartyPayer) {
                                        if (
                                          selectInsurancePdph3rdPartyPayer.payerId ===
                                          '00'
                                        ) {
                                          const client = getClientDetail();
                                          if (client) {
                                            const dateofBirth: string | null =
                                              client.DOB
                                                ? dayjs(client.DOB).format(
                                                    'MM/DD/YYYY',
                                                  )
                                                : null;
                                            values.subscriber.firstName =
                                              client.firstName;
                                            values.subscriber.lastName =
                                              client.lastName;
                                            values.subscriber.middleName =
                                              client.middleName;
                                            values.subscriber.subscriberMemberId =
                                              client.enrollmentId;
                                            values.subscriber.sex = client.sex;
                                            values.subscriber.dateOfBirth =
                                              dateofBirth;
                                            values.subscriber.subscriberType =
                                              client.contactType;
                                            values.subscriber.phone =
                                              client.homeNumber
                                                ? client.homeNumber
                                                : '';
                                            values.subscriber.patientRelationToInsured =
                                              '18';
                                          } else {
                                            values.subscriber.firstName = null;
                                            values.subscriber.lastName = null;
                                            values.subscriber.middleName = null;
                                            values.subscriber.subscriberMemberId =
                                              null;
                                            values.subscriber.sex = null;
                                            values.subscriber.dateOfBirth =
                                              null;
                                            values.subscriber.subscriberType =
                                              '';
                                            values.subscriber.phone = '';
                                            values.subscriber.patientRelationToInsured =
                                              '';
                                          }
                                          values.coverageType2 = 'Primary';
                                          values.effectiveEndDate = null;
                                          const exists =
                                            checkActiveInsurancInCaseOfDPH();
                                          if (exists) {
                                            showErrorMessage();
                                          }
                                        } else {
                                          values.coverageType2 = null;
                                          values.subscriber.firstName = null;
                                          values.subscriber.lastName = null;
                                          values.subscriber.middleName = null;
                                          values.subscriber.subscriberMemberId =
                                            null;
                                          values.subscriber.sex = null;
                                          values.subscriber.dateOfBirth = null;
                                          values.subscriber.subscriberType = '';
                                          values.subscriber.phone = '';
                                          values.subscriber.patientRelationToInsured =
                                            '';
                                        }
                                        values.subscriber.address1 = null;
                                        values.subscriber.address2 = null;
                                        values.subscriber.zipCode = {
                                          id: 'asd',
                                          label: '',
                                        };
                                        values.subscriber.city = null;
                                        values.subscriber.state = null;
                                        values.dph3rdPartyPayerName =
                                          selectInsurancePdph3rdPartyPayer.insuranceCompanyName;
                                        values.dph3rdPartyPayer =
                                          selectInsurancePdph3rdPartyPayer.payerId;
                                        formRef.current?.setValues(values);
                                        setSelectedSubscriber(null);
                                      }

                                      setFieldValue(`dphReasonCode`, null);
                                      setFieldValue('dphAddendum', null);
                                      setSelectedInsuranceAddendum(null);
                                    }
                                  }}
                                  disableClearable={true}
                                  disableLabel={true}
                                  optionHintId={'No Search found'}
                                  sxProps={{
                                    [`& .${autocompleteClasses.popupIndicator}`]:
                                      {
                                        transform: 'none',
                                      },
                                  }}
                                  selectedValue={selectedProgram as OptionType}
                                />
                                {errors.dph3rdPartyPayer &&
                                  touched.dph3rdPartyPayer && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.dph3rdPartyPayer}
                                      defaultlabel="3rd Party Payer is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <DropdownComponent
                                  names={coverageTypes}
                                  name={'coverageType2'}
                                  labelid="Insurance.coverageType"
                                  defaultlabelid="Coverage Type"
                                  value={values.coverageType2 ?? ''}
                                  handleChange={(value: string) => {
                                    setFieldValue(`coverageType2`, value);
                                  }}
                                  disabled={values.dph3rdPartyPayer === '00'}
                                  Required
                                />

                                {errors.coverageType2 &&
                                  touched.coverageType2 && (
                                    <MediumTypography
                                      labelid={errors.coverageType2}
                                      defaultlabel="Coverage Type is required"
                                      sxProps={{ color: 'red' }}
                                    />
                                  )}
                              </Grid>
                              {values.dph3rdPartyPayer === 'OTHER' && (
                                <Grid item xs={4.5} lg={4.5}>
                                  <Textinput
                                    name={'insuranceCompany'}
                                    labelid="insuranceCompanyText"
                                    defaultlabelid="Insurance Company"
                                    handlechange={(value: string) => {
                                      const inputValue = value;
                                      const validatedValue =
                                        alphaNumericValidation(inputValue);
                                      if (validatedValue !== undefined) {
                                        setFieldValue(
                                          'insuranceCompany',
                                          validatedValue,
                                        );
                                      }
                                    }}
                                    Required={
                                      values.dph3rdPartyPayer === 'OTHER'
                                    }
                                    Value={values.insuranceCompany}
                                    inputProps={{
                                      maxLength: 50,
                                    }}
                                  />
                                  {errors.insuranceCompany &&
                                    touched.insuranceCompany && (
                                      <MediumTypography
                                        sxProps={{ color: 'red' }}
                                        labelid={errors.insuranceCompany}
                                        defaultlabel="Insurance Company is required"
                                      />
                                    )}
                                </Grid>
                              )}
                              <Grid item xs={4.5} lg={4.5}>
                                <DropdownComponent
                                  names={lookupDphReasonCode}
                                  name={'DphReasonCode'}
                                  labelid="Insurance.DphReasonCode"
                                  defaultlabelid="DPH Reason Code"
                                  value={values.dphReasonCode ?? ''}
                                  handleChange={(value: string) => {
                                    setFieldValue(`dphReasonCode`, value);
                                  }}
                                  disabled={values.dph3rdPartyPayer !== '00'}
                                  Required={values.dph3rdPartyPayer === '00'}
                                />

                                {errors.dphReasonCode &&
                                  touched.dphReasonCode && (
                                    <MediumTypography
                                      labelid={errors.dphReasonCode}
                                      defaultlabel="DPH Reason Code is required"
                                      sxProps={{ color: 'red' }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <AutocompleteSearch
                                  labelId={'Insurance.DphInsuranceAddendum'}
                                  defaultId={'DPH Insurance Addendum'}
                                  data={lookupDphInsuranceAddendum}
                                  onSelectItem={(value: OptionType | null) => {
                                    if (value !== null) {
                                      setFieldValue('dphAddendum', value.id);
                                      setSelectedInsuranceAddendum(value);
                                    } else {
                                      setFieldValue('dphAddendum', '');
                                      setSelectedInsuranceAddendum(null);
                                    }
                                  }}
                                  disableLabel={true}
                                  optionHintId={'No Search found'}
                                  sxProps={{
                                    [`& .${autocompleteClasses.popupIndicator}`]:
                                      {
                                        transform: 'none',
                                      },
                                  }}
                                  selectedValue={
                                    selectedInsuranceAddendum as OptionType
                                  }
                                  disabled={values.dph3rdPartyPayer === '00'}
                                />
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <DatePickerComponent
                                  disableFuture={true}
                                  required={true}
                                  labelid="insurance.effectiveStartDate"
                                  defaultlabelid="Effective Start Date"
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(date).format('MM/DD/YYYY');
                                    if (date === null) {
                                      setFieldValue('effectiveStartDate', null);
                                    } else {
                                      setFieldValue(
                                        'effectiveStartDate',
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  value={
                                    values.effectiveStartDate !== null
                                      ? dayjs(values.effectiveStartDate)
                                      : null
                                  }
                                />
                                {errors.effectiveStartDate &&
                                  touched.effectiveStartDate && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.effectiveStartDate}
                                      defaultlabel="Effective Start Date is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5}>
                                <DatePickerComponent
                                  labelid="insurance.effectiveEndDate"
                                  minDate={dayjs(values.effectiveStartDate)}
                                  defaultlabelid="Effective End Date"
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(date).format('MM/DD/YYYY');
                                    if (date === null) {
                                      setFieldValue('effectiveEndDate', null);
                                    } else {
                                      setFieldValue(
                                        'effectiveEndDate',
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  value={
                                    values.effectiveEndDate !== null
                                      ? dayjs(values.effectiveEndDate)
                                      : null
                                  }
                                />
                                {errors.effectiveEndDate &&
                                  touched.effectiveEndDate && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.effectiveEndDate}
                                      defaultlabel="Effective End Date is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={12} lg={8}>
                                <MediumTypography
                                  labelid="subscriberInformationText"
                                  defaultlabel="Subscriber Information"
                                  sxProps={{
                                    color: '#2A4241',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginRight: '16px',
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <AutocompleteSearch
                                  sxProps={{
                                    [`& .${autocompleteClasses.popupIndicator}`]:
                                      {
                                        transform: 'none',
                                      },
                                  }}
                                  optionHintId={'No Search found'}
                                  labelId="searchSubscriberText"
                                  defaultId="Search Subscriber"
                                  disabled={values.dph3rdPartyPayer === '00'}
                                  data={subscribers.map((item) => {
                                    return {
                                      id: item.careGiverId,
                                      label: `${item.lastName ?? ''}, ${
                                        item.firstName ?? ''
                                      } ${getRelationshipValue(
                                        item.relationShipToClient,
                                      )}`,
                                    } as OptionType;
                                  })}
                                  selectedValue={selectedSubscriber}
                                  disableLabel={true}
                                  onBlur={() => setSubscribers([])}
                                  onTextChange={(event) => {
                                    updateSubscriberSearchKey(
                                      event.target.value,
                                    );
                                  }}
                                  onSelectItem={(value: OptionType | null) => {
                                    setSubscriberSelection(value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5} lg={4.5} />
                              <Grid item xs={4.5} lg={4.5}>
                                <Textinput
                                  name={'subscriberFirstName'}
                                  labelid="Clientpage.First"
                                  defaultlabelid="First Name"
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        'subscriber.firstName',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  Required
                                  Value={values.subscriber.firstName ?? ''}
                                  inputProps={{
                                    maxLength: 300,
                                  }}
                                />
                                {errors.subscriber?.firstName &&
                                  touched.subscriber?.firstName && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.subscriber.firstName}
                                      defaultlabel="First Name is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <Textinput
                                  name={'subscriberMiddleName'}
                                  labelid="Clientpage.Middle"
                                  defaultlabelid="Middle Name"
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        'subscriber.middleName',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  Value={values.subscriber.middleName ?? ''}
                                  inputProps={{
                                    maxLength: 300,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <Textinput
                                  name={'subscriberLastName'}
                                  labelid="Clientpage.Last"
                                  defaultlabelid="Last Name"
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        'subscriber.lastName',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  Required
                                  Value={values.subscriber.lastName ?? ''}
                                  inputProps={{
                                    maxLength: 300,
                                  }}
                                />
                                {errors.subscriber?.lastName &&
                                  touched.subscriber?.lastName && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.subscriber.lastName}
                                      defaultlabel="Last Name is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <Textinput
                                  name={'subscriberMemberId'}
                                  labelid="insurance.subscriberMemberIdText"
                                  defaultlabelid="Subscriber Member ID"
                                  handlechange={(value: string) => {
                                    const validatedValue =
                                      nameValidation(value);
                                    if (validatedValue !== undefined) {
                                      setFieldValue(
                                        'subscriber.subscriberMemberId',
                                        validatedValue,
                                      );
                                    }
                                  }}
                                  Required
                                  Value={
                                    values.subscriber.subscriberMemberId ?? ''
                                  }
                                  inputProps={{
                                    maxLength: 300,
                                  }}
                                />
                                {errors.subscriber?.subscriberMemberId &&
                                  touched.subscriber?.subscriberMemberId && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={
                                        errors.subscriber.subscriberMemberId
                                      }
                                      defaultlabel="Subscriber Member ID is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <DropdownComponent
                                  names={lookupPatientRelation}
                                  name={'patientRelationToInsured'}
                                  labelid="Insurance.ClientRelationtoInsured"
                                  defaultlabelid="Subscriber Relation to Insured"
                                  value={
                                    values.subscriber
                                      .patientRelationToInsured ?? ''
                                  }
                                  handleChange={(value: string) => {
                                    setFieldValue(
                                      `subscriber.patientRelationToInsured`,
                                      value,
                                    );
                                  }}
                                  disabled={
                                    values.subscriber.subscriberType ===
                                    'client'
                                  }
                                  Required
                                />

                                {errors.subscriber?.patientRelationToInsured &&
                                  touched.subscriber
                                    ?.patientRelationToInsured && (
                                    <MediumTypography
                                      labelid={
                                        errors.subscriber
                                          .patientRelationToInsured
                                      }
                                      defaultlabel="Subscriber Relation to Insured is required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <DatePickerComponent
                                  disableFuture={true}
                                  labelid="insurance.subscriberDateOfBirth"
                                  defaultlabelid="Subscriber Date of Birth"
                                  handlechange={(date: Dayjs | null) => {
                                    const formattedDate =
                                      dayjs(date).format('MM/DD/YYYY');
                                    if (date === null) {
                                      setFieldValue(
                                        'subscriber.dateOfBirth',
                                        null,
                                      );
                                    } else {
                                      setFieldValue(
                                        'subscriber.dateOfBirth',
                                        formattedDate,
                                      );
                                    }
                                  }}
                                  required
                                  value={
                                    values.subscriber.dateOfBirth !== null
                                      ? dayjs(values.subscriber.dateOfBirth)
                                      : null
                                  }
                                />
                                {errors.subscriber?.dateOfBirth &&
                                  touched.subscriber?.dateOfBirth && (
                                    <MediumTypography
                                      labelid={errors.subscriber.dateOfBirth}
                                      defaultlabel="Date of Birth is required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <SelectComponent
                                  labelid="Clientpage.Sex"
                                  defaultlabelid="sex"
                                  name="sex"
                                  Required
                                  value={values.subscriber.sex ?? ''}
                                  handleChange={(value: string) => {
                                    setFieldValue('subscriber.sex', value);
                                  }}
                                  names={Gender}
                                />
                                {errors.subscriber?.sex &&
                                  touched.subscriber?.sex && (
                                    <MediumTypography
                                      labelid={errors.subscriber.sex}
                                      defaultlabel="Sex is required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4.5} lg={4.5}>
                                <Textinput
                                  name={`homePhoneNumber`}
                                  labelid="Contacts.phone"
                                  defaultlabelid="Phone Number"
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      'subscriber.phone',
                                      NumValidation(value),
                                    );
                                  }}
                                  inputProps={{
                                    pattren: ['0-9'],
                                  }}
                                  Required
                                  Value={values.subscriber.phone}
                                />
                                {errors.subscriber?.phone &&
                                  touched.subscriber?.phone && (
                                    <MediumTypography
                                      sxProps={{ color: 'red' }}
                                      labelid={errors.subscriber.phone}
                                      defaultlabel="Phone Number is required"
                                    />
                                  )}
                              </Grid>
                              <Grid container item xs={9}>
                                <AddressForm
                                  address1PlaceholderId="address1Text"
                                  address2PlaceholderId="address2Text"
                                  zipCodePlaceholderId="Clientpage.Zip"
                                  cityPlaceholderId="Clientpage.City"
                                  statePlaceholderId="Clientpage.State"
                                  value={{
                                    address1: values.subscriber.address1 ?? '',
                                    address2: values.subscriber.address2 ?? '',
                                    zipCode: values.subscriber.zipCode ?? {
                                      id: 'asd',
                                      label: '',
                                    },
                                    city: values.subscriber.city ?? '',
                                    state: values.subscriber.state ?? '',
                                  }}
                                  isClient={true}
                                  disableForm={false}
                                  formRequired={true}
                                  formikErrors={{
                                    address1: errors.subscriber?.address1,
                                    address2: errors.subscriber?.address2,
                                    zipCode:
                                      errors.subscriber?.zipCode &&
                                      typeof errors.subscriber?.zipCode ===
                                        'object'
                                        ? (
                                            errors.subscriber
                                              ?.zipCode as OptionType
                                          ).label
                                        : undefined,
                                    city: errors.subscriber?.city,
                                    state: errors.subscriber?.state,
                                  }}
                                  formikTouched={{
                                    address1: touched.subscriber?.address1,
                                    address2: touched.subscriber?.address2,
                                    zipCode: touched.subscriber?.zipCode,
                                    city: touched.subscriber?.city,
                                    state: touched.subscriber?.state,
                                  }}
                                  handleAddressUpdate={(newAddress) => {
                                    formRef.current?.setValues({
                                      ...values,
                                      subscriber: {
                                        ...values.subscriber,
                                        address1: newAddress.address1,
                                        address2: newAddress.address2,
                                        zipCode: newAddress.zipCode,
                                        city: newAddress.city,
                                        state: newAddress.state,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid container item xs={9} />
                            </Grid>
                          </Box>
                          <Box sx={{ marginLeft: '16px' }}>
                            <Grid
                              container
                              direction="row"
                              sx={{ display: 'flex' }}
                            >
                              <Grid item>
                                <ButtonComponent
                                  className="btn-primary btn-cancel"
                                  variantType="contained"
                                  labelId="clearFormText"
                                  defaultLabelId="Clear Form"
                                  onClick={() => {
                                    if (formRef.current) {
                                      setHideBtn(false);
                                      setSelectedProgram(null);
                                      setSubscribers([]);
                                      setSelectedSubscriber(null);
                                      setSelectedInsuranceAddendum(null);
                                      const initialValue = JSON.parse(
                                        JSON.stringify(insuranceValues),
                                      );
                                      formRef.current.setValues(initialValue);
                                      setInsuranceData(initialValue);
                                    }
                                  }}
                                />
                              </Grid>

                              <Grid item style={{ display: 'none' }}>
                                <ButtonComponent
                                  className="btn-primary btn-cancel ml-md"
                                  labelId="eligibility-btn"
                                  defaultLabelId="Check Eligibility"
                                  variantType="outlined"
                                  disabled={true}
                                  LeftIcon={<CheckEligibility />}
                                />
                              </Grid>
                              {!hideBtn && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit ml-md"
                                    variantType="contained"
                                    type="submit"
                                    labelId="Insurance.Addinsurance"
                                    defaultLabelId="Add Insurance"
                                    onClick={() => {
                                      if (values.isEdit) {
                                        handleSubmit();
                                        return;
                                      }
                                      if (
                                        values.dph3rdPartyPayer === '00' &&
                                        checkActiveInsurancInCaseOfDPH()
                                      ) {
                                        showErrorMessage();
                                      } else {
                                        handleSubmit();
                                      }
                                    }}
                                  />
                                </Grid>
                              )}
                              {hideBtn && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit ml-md"
                                    variantType="contained"
                                    type="submit"
                                    labelId="Insurance.Updateinsurance"
                                    defaultLabelId="Update Insurance"
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <MediumTypography
                              sxProps={{ fontStyle: 'italic' }}
                              className="mt-md"
                              labelid="insuranceParentConcentMessage"
                              defaultlabel="* Ensure the consent is signed before accessing the Insurance."
                            />
                          </Box>
                        </>
                      );
                    }}
                  </Formik>
                  <Formik
                    initialValues={{
                      insuranceId: otherInsuranceDetails.insuranceId,
                      consentToInsurance:
                        otherInsuranceDetails.consentToInsurance,
                      parentSignature: otherInsuranceDetails.parentSignature,
                      secondaryParentSignature:
                        otherInsuranceDetails.secondaryParentSignature,
                      insuranceDetails: otherInsuranceDetails.insuranceDetails,
                      updatedBy: otherInsuranceDetails.updatedBy,
                      notes: otherInsuranceDetails.notes,
                      modifiedOn: otherInsuranceDetails.modifiedOn,
                      parentSignatureDate:
                        otherInsuranceDetails.parentSignatureDate,
                      secondaryParentSignatureDate:
                        otherInsuranceDetails.secondaryParentSignatureDate,
                      parentSignatureVerbalConsent:
                        otherInsuranceDetails.parentSignatureVerbalConsent,
                      secondaryParentSignatureVerbalConsent:
                        otherInsuranceDetails.secondaryParentSignatureVerbalConsent,
                    }}
                    validationSchema={validationSchemaOthers}
                    validateOnChange={true}
                    innerRef={formRefOthers}
                    enableReinitialize
                    onSubmit={handleSubmitOthers}
                  >
                    {({ setFieldValue, values, errors, touched }) => {
                      const initialValues =
                        formRefOthers?.current?.initialValues;
                      const val = formRefOthers?.current?.values;
                      useEffect(() => {
                        if (
                          !_.isEqual(initialTableDataValue, tableData) ||
                          !_.isEqual(initialValues, val)
                        ) {
                          setIsChangeInInsuranceData(true);
                          // handleUpdateUnsavedChanges(true);
                        } else {
                          setIsChangeInInsuranceData(false);
                          // handleUpdateUnsavedChanges(false);
                        }
                      }, [
                        values,
                        val,
                        tableData,
                        initialValues,
                        initialTableDataValue,
                      ]);
                      return (
                        <Box sx={{ padding: '24px', ml: '0px' }}>
                          <Grid
                            container
                            rowSpacing={'24px'}
                            columnSpacing={'16px'}
                          ></Grid>

                          <Grid item xs={12} lg={12} className="mt-md">
                            <CustomRadioButton
                              data={consentToInsuranceJson}
                              value={values.consentToInsurance}
                              onSelectItem={(value) => {
                                if (value) {
                                  if (
                                    isDPHInsuranceActive &&
                                    value === 'authorize'
                                  ) {
                                    return;
                                  }
                                  setFieldValue('consentToInsurance', value);
                                }
                              }}
                              textStyleClassName="mt-sm mb-sm"
                            />
                            {errors.consentToInsurance &&
                              touched.consentToInsurance && (
                                <MediumTypography
                                  labelid={errors.consentToInsurance}
                                  defaultlabel="Consent To Insurance is Required"
                                  className="errorText-md"
                                />
                              )}
                          </Grid>

                          <Box sx={{ marginTop: '16px' }}>
                            <CommentTextbox
                              placeholder={rctl.formatMessage({
                                id: 'CommunicationLogPage.enterNote',
                                defaultMessage: 'Enter Note',
                              })}
                              name="notes"
                              maxLength={1000}
                              rows={4}
                              Value={values.notes}
                              handlechange={(text) => {
                                setFieldValue('notes', text);
                              }}
                            />
                          </Box>

                          <Box display={'flex'} className={'mt-md mb-md'}>
                            <Box>
                              <MediumTypography
                                className="mainText-lg mb-sm"
                                labelid="Insurance.Parentsignature"
                                defaultlabel="Guardian Signature"
                              />
                              <Box className="flex__ alignItemCenter">
                                {values.parentSignature !== null &&
                                  (values.parentSignature.signatureData !==
                                    '' ||
                                    values.parentSignature.rowKey !== '') && (
                                    <Box>
                                      <ButtonComponent
                                        className="btn-primary btn-cancel signature_view_Box"
                                        labelId={'ProgressNoteViewSignature'}
                                        defaultLabelId={'View signature'}
                                        variantType="outlined"
                                        LeftIcon={<Editsignature />}
                                        onClick={() =>
                                          setParentSignClicked(true)
                                        }
                                      />
                                      {values.parentSignatureDate && (
                                        <MediumTypography
                                          className="mt-xs"
                                          label={
                                            rctl.formatMessage({
                                              id: 'dateText',
                                              defaultMessage: 'Date',
                                            }) +
                                            ': ' +
                                            dayjs(
                                              values.parentSignatureDate,
                                              LOCAL_DATE_TIME_UTC_FORMAT,
                                            ).format(LOCAL_DATE_TIME_FORMAT)
                                          }
                                        />
                                      )}
                                    </Box>
                                  )}
                                {values.parentSignature === null && (
                                  <ButtonComponent
                                    className="btn-primary btn-cancel signature_add_Box"
                                    labelId="Insurance.Addsignature"
                                    defaultLabelId="Add Signature"
                                    variantType="outlined"
                                    disabled={
                                      values.parentSignatureVerbalConsent
                                    }
                                    onClick={() => setParentSignClicked(true)}
                                  />
                                )}
                                <Box
                                  className={`flex__ alignItemCenter ml-md ${
                                    values.parentSignature === null
                                      ? ''
                                      : 'pb-sm'
                                  }`}
                                >
                                  <CheckBoxComponent
                                    ischecked={
                                      values.parentSignatureVerbalConsent
                                    }
                                    CheckHandleChange={(e) => {
                                      setFieldValue(
                                        'parentSignatureVerbalConsent',
                                        e.target.checked,
                                      );
                                    }}
                                    disabled={values.parentSignature !== null}
                                    labelid="ConsentVerbalConsent"
                                    defaultlabelid="Verbal Consent"
                                  />
                                </Box>
                              </Box>
                            </Box>

                            {parentSignClicked && (
                              <Box component="div">
                                <SignatureComponent
                                  onCancelClick={onParentSignCancelClick}
                                  signaturePartitionKey={
                                    values.parentSignature !== null
                                      ? values.parentSignature.partitionKey
                                      : ''
                                  }
                                  signatureRowKey={
                                    values.parentSignature !== null
                                      ? values.parentSignature.rowKey
                                      : ''
                                  }
                                  signature={
                                    values.parentSignature !== null
                                      ? values.parentSignature.signatureData
                                      : ''
                                  }
                                  signatureSaveOrUPdate={(value) =>
                                    onParentSignatureSaveOrUpdate(value, 0)
                                  }
                                  handleSuccess={handleSuccess}
                                  signClicked={parentSignClicked}
                                  hideRemoveButton={false}
                                  type="Parent"
                                />
                              </Box>
                            )}

                            <Box className={'ml-xlg'}>
                              <MediumTypography
                                className="mainText-lg mb-sm"
                                labelid="Insurance.Parentsignature2"
                                defaultlabel="Guardian Signature 2"
                              />
                              <Box className="flex__ alignItemCenter">
                                {values.secondaryParentSignature !== null &&
                                  (values.secondaryParentSignature
                                    .signatureData !== '' ||
                                    values.secondaryParentSignature.rowKey !==
                                      '') && (
                                    <Box>
                                      <ButtonComponent
                                        className="btn-primary btn-cancel signature_view_Box"
                                        labelId={'ProgressNoteViewSignature'}
                                        defaultLabelId={'View signature'}
                                        variantType="outlined"
                                        LeftIcon={<Editsignature />}
                                        onClick={() =>
                                          setParentSecondarySignClicked(true)
                                        }
                                      />
                                      {values.secondaryParentSignatureDate && (
                                        <MediumTypography
                                          className="mt-xs"
                                          label={
                                            rctl.formatMessage({
                                              id: 'dateText',
                                              defaultMessage: 'Date',
                                            }) +
                                            ': ' +
                                            dayjs(
                                              values.secondaryParentSignatureDate,
                                              LOCAL_DATE_TIME_UTC_FORMAT,
                                            ).format(LOCAL_DATE_TIME_FORMAT)
                                          }
                                        />
                                      )}
                                    </Box>
                                  )}

                                <Box className="flex__ alignItemCenter">
                                  {values.secondaryParentSignature === null && (
                                    <ButtonComponent
                                      className="btn-primary btn-cancel signature_add_Box"
                                      labelId="Insurance.Addsignature"
                                      defaultLabelId="Add Signature"
                                      variantType="outlined"
                                      disabled={
                                        values.secondaryParentSignatureVerbalConsent
                                      }
                                      onClick={() =>
                                        setParentSecondarySignClicked(true)
                                      }
                                    />
                                  )}

                                  <Box
                                    className={`flex__ alignItemCenter ml-md ${
                                      values.secondaryParentSignature === null
                                        ? ''
                                        : 'pb-sm'
                                    }`}
                                  >
                                    <CheckBoxComponent
                                      ischecked={
                                        values.secondaryParentSignatureVerbalConsent
                                      }
                                      CheckHandleChange={(e) => {
                                        setFieldValue(
                                          'secondaryParentSignatureVerbalConsent',
                                          e.target.checked,
                                        );
                                      }}
                                      disabled={
                                        values.secondaryParentSignature !== null
                                      }
                                      labelid="ConsentVerbalConsent"
                                      defaultlabelid="Verbal Consent"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {parentSecondarySignClicked && (
                              <Box component="div">
                                <SignatureComponent
                                  onCancelClick={
                                    onSecondaryParentSignCancelClick
                                  }
                                  signaturePartitionKey={
                                    values.secondaryParentSignature !== null
                                      ? values.secondaryParentSignature
                                          .partitionKey
                                      : ''
                                  }
                                  signatureRowKey={
                                    values.secondaryParentSignature !== null
                                      ? values.secondaryParentSignature.rowKey
                                      : ''
                                  }
                                  signature={
                                    values.secondaryParentSignature !== null
                                      ? values.secondaryParentSignature
                                          .signatureData
                                      : ''
                                  }
                                  signatureSaveOrUPdate={(value) =>
                                    onParentSignatureSaveOrUpdate(value, 1)
                                  }
                                  handleSuccess={handleSuccess}
                                  signClicked={parentSecondarySignClicked}
                                  hideRemoveButton={false}
                                  type="Parent"
                                />
                              </Box>
                            )}
                          </Box>

                          <Box display={'flex'} className="pt-md">
                            <MediumTypography
                              labelid="insurance.lastUpdated"
                              defaultlabel="Last Updated By : "
                              textColor="#2a4241"
                              fontweight={600}
                            />
                            <Box className={'ml-sm'}>
                              <MediumTypography
                                label={lastUpdatedBy}
                                textColor="#2a4241"
                                sxProps={{ marginLeft: '4px' }}
                              />
                              <MediumTypography
                                label={
                                  lastUpdatedOn !== null
                                    ? moment(
                                        lastUpdatedOn,
                                        'YYYY-MM-DD HH:mm:ss',
                                      )
                                        .utc(true)
                                        .local()
                                        .format(LOCAL_DATE_TIME_FORMAT)
                                    : ''
                                }
                                textColor="#2a4241"
                                sxProps={{ marginLeft: '4px' }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    }}
                  </Formik>
                </Card>

                <Grid
                  item
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '16px',
                  }}
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId="Insurance.cancel"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (formRefOthers.current === null) {
                          return;
                        }
                        const initialValues =
                          formRefOthers.current.initialValues;
                        const values = formRefOthers.current.values;
                        if (
                          !_.isEqual(initialTableDataValue, tableData) ||
                          !_.isEqual(initialValues, values)
                        ) {
                          setOpenModal(true);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-submit ml-md"
                      variantType="contained"
                      type="submit"
                      labelId="Insurance.submit"
                      onClick={() => {
                        if (formRefOthers.current === null) {
                          return;
                        }
                        const initialValues =
                          formRefOthers.current.initialValues;
                        const values = JSON.parse(
                          JSON.stringify(formRefOthers.current.values),
                        ) as InsuranceOtherDetails;

                        if (hideBtn) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrId('insuranceNoUpdateDataMessage');
                          setToastrDefaultMessage(
                            'Details have been not updated in the insurance to save.',
                          );
                        } else if (_.isEqual(initialValues, values)) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrId('insuranceNoDataMessage');
                          setToastrDefaultMessage(
                            'No details have been entered in the insurance to save.',
                          );
                        } else if (values.consentToInsurance !== 'authorize') {
                          const dph3rdPartyPayerInsurance =
                            values.insuranceDetails.find(
                              (insurance) =>
                                insurance.dph3rdPartyPayer === '00',
                            );
                          if (!dph3rdPartyPayerInsurance) {
                            setOpen(true);
                            setToastrVariable('error');
                            setToastrId('nodphThirdPartyPayerMessage');
                            setToastrDefaultMessage(
                              'DPH needs to be selected as 3rd Party payer.',
                            );
                          } else {
                            handleSubmitOthers(values);
                          }
                        } else {
                          handleSubmitOthers(values);
                        }
                      }}
                      defaultLabelId="Save"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
    </>
  );
};

export default Insurance;
const getSubscriberPhoneNumber = (
  subscriber: AddNewParent,
): string | undefined => {
  if (subscriber.mobilePhoneNumber) {
    return subscriber.mobilePhoneNumber;
  } else if (subscriber.workPhoneNumber) {
    return subscriber.workPhoneNumber;
  } else if (subscriber.homePhoneNumber) {
    return subscriber.homePhoneNumber;
  }
};

import {
  AlertColor,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  autocompleteClasses,
} from '@mui/material';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  OptionType,
  ResourceRepoDTO,
  SignatureStateType,
} from '../../utils/type';
import ModalPopup from '../../components/formlib/ModalPopup';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import _, { debounce } from 'lodash';
import {
  IntakeReviewResponseType,
  getIntakeReviewDetails,
  putIntakeReviewDetails,
} from '../../services/configApi/forms/Intake/IntakeServices';
import { LookUpContext } from '../../context/LookUpContextProvider';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import Textinput from '../../components/formlib/Textinput';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import { BirthRadioData, IntakeRadioData } from './IntakePregnancyInfo';
import { EICSRadioData } from './IntakeClientsInfo';
import PovertyJsonData from './IntakeJSON/FamilyHistory/Poverty.json';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import dayjs from 'dayjs';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import { ReactComponent as Document } from '../../assets/images/documentUpload.svg';
import { extractFileExtension } from '../../utils/fileUtils';
import { PREVIEW_DOCUMENT_EXTENSIONS } from '../../services/Constant';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const initialValues: IntakeReviewResponseType = {
  demographics: {
    race: '',
    ethnicity: '',
    multipleBirth: '',
    childBorn: '',
    childAdopted: '',
    primarySpokenLanguage: '',
    primarySpokenLanguageOther: '',
    primaryWrittenLanguage: '',
    primaryWrittenLanguageOther: '',
  },
  pregnancy: {
    diagnosisOfSubstanceExposedNewborn: '',
    gestationalAge: '',
    birthWeight: '',
    weightInLbs: '',
    weightInOz: '',
    weightInGrams: '',
    specialCareAfterBirth: '',
    childInNICU: '',
    daysInNICU: '',
  },
  currentHealth: {
    childHospitalized: '',
    childBloodLevel: '',
    childInsecureDifficulties: '',
    childNervousSystem: '',
    childMultipleTrauma: '',
    childDiagnosedAtBirth: '',
    childChronicFeeding: '',
    smallSize: '',
  },
  familyHistory: {
    parentalChronicIllness: '',
    affectingChild: '',
    siblingWithSEN: '',
    mothersEducationYear: '',
    lackingAdequate: '',
    povertyLevel: '',
    protectiveServiceInvestigation: '',
    homelessness: '',
    homelessnessInPast: '',
    birthMotherParity: '',
    abuseAffectingChild: '',
    socialSupport: '',
    resourceDto: {
      id: '',
      uiIdentifier: '',
      name: '',
      userId: '',
      uploadedOn: '',
      resourceUploadId: '',
      resourceUrl: '',
      uploadedBy: '',
      type: '',
    },
  },
  signature: [
    {
      name: '',
      relationship: '',
      email: '',
      signature: '',
      rowKey: '',
      partitionKey: '',
      verbal: false,
      id: '',
      date: '',
      license: '',
    },
  ],
  isSigned: false,
  reviewdBy: '',
  reviewdDate: '',
  canSign: false,
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const validationSchema = yup.object().shape({
  demographics: yup.object().shape({
    race: yup.string().required('Clientpage.Race.validation').nullable(),
    ethnicity: yup
      .string()
      .required('Clientpage.Ethnicity.validation')
      .nullable(),
    primarySpokenLanguage: yup
      .string()
      .required('IntakeFamilyHistory.primarySpokenLanguage.required')
      .nullable(),
    primarySpokenLanguageOther: yup.string().when('primarySpokenLanguage', {
      is: 'OTHER',
      then: yup
        .string()
        .required('IntakeFamilyHistory.primarySpokenLanguageOther.required')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    primaryWrittenLanguage: yup
      .string()
      .required('IntakeFamilyHistory.primaryWrittenLanguage.required')
      .nullable(),
    primaryWrittenLanguageOther: yup.string().when('primaryWrittenLanguage', {
      is: 'OTHER',
      then: yup
        .string()
        .required('IntakeFamilyHistory.primaryWrittenLanguageOther.required')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    multipleBirth: yup
      .string()
      .required('Intake.EICSField.required')
      .nullable(),
    childBorn: yup.string().required('Intake.EICSField.required').nullable(),
    childAdopted: yup.string().required('Intake.EICSField.required').nullable(),
  }),
  pregnancy: yup.object().shape({
    gestationalAge: yup.string().required('Required'),
    gestationalAgeCheck: yup.boolean().nullable(),
    diagnosisOfSubstanceExposedNewborn: yup
      .string()
      .required('Intake.EICSField.required'),
    birthWeight: yup.string().required('Required').nullable(),
    weightInLbs: yup.string().when('birthWeight', {
      is: 'In Pounds',
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable(),
    }),
    weightInOz: yup.string().when('birthWeight', {
      is: 'In Pounds',
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable(),
    }),
    weightInGrams: yup.string().when('birthWeight', {
      is: 'In Grams',
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable(),
    }),
    specialCareAfterBirth: yup.string().required('Intake.EICSField.required'),
    childInNICU: yup.string().required('Intake.EICSField.required'),
    daysInNICUCheck: yup.boolean().nullable(),
    daysInNICU: yup.string().when('childInNICU', {
      is: 'Yes',
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
  currentHealth: yup.object().shape({
    childHospitalized: yup.string().required('Intake.EICSField.required'),
    childBloodLevel: yup.string().required('Intake.EICSField.required'),
    childInsecureDifficulties: yup
      .string()
      .required('Intake.EICSField.required'),
    childNervousSystem: yup.string().required('Intake.EICSField.required'),
    childMultipleTrauma: yup.string().required('Intake.EICSField.required'),
    childDiagnosedAtBirth: yup.string().required('Intake.EICSField.required'),
    childChronicFeeding: yup.string().required('Intake.EICSField.required'),
    smallSize: yup.string().required('Intake.EICSField.required'),
  }),
  familyHistory: yup.object().shape({
    parentalChronicIllness: yup.string().required('Intake.EICSField.required'),
    affectingChild: yup.string().required('Intake.EICSField.required'),
    siblingWithSEN: yup.string().required('Intake.EICSField.required'),
    mothersEducationYear: yup
      .string()
      .required('IntakeFamilyHistory.yearsOfEducation.required'),
    mothersEducationYearCheck: yup.boolean().nullable(),
    lackingAdequate: yup.string().required('Intake.EICSField.required'),
    povertyLevel: yup.string().required('Intake.EICSField.required'),
    protectiveServiceInvestigation: yup
      .string()
      .required('Intake.EICSField.required'),
    homelessness: yup.string().required('Intake.EICSField.required'),
    homelessnessInPast: yup.string().required('Intake.EICSField.required'),
    birthMotherParity: yup.string().required('Intake.EICSField.required'),
    abuseAffectingChild: yup.string().required('Intake.EICSField.required'),
    socialSupport: yup.string().required('Intake.EICSField.required'),
  }),
});

const IntakeReview: FC<ModalProps> = ({ open, handleClose }) => {
  const clientId = localStorage.getItem('ClientId');
  const [successOrError, setSuccessOrError] = useState<AlertColor>('success');

  const formikRef = useRef<FormikProps<IntakeReviewResponseType>>(null);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState<string>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [signaturePartitionKey, setSignaturePartitionKey] =
    useState<string>('');

  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(open);

  const [reviewInitialValues, setReviewInitialValues] =
    useState<IntakeReviewResponseType>(initialValues);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [resourceDto, setResourceData] = useState<ResourceRepoDTO>();
  const [selectedFile, setSelectedFile] = useState<ResourceRepoDTO>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSignaturePartitionKey(signaturePartitionKey);
  }, [signaturePartitionKey]);

  useEffect(() => {
    const handleIntakeGet = async () => {
      handleGetIntakeReviewDetails();
    };
    handleIntakeGet();
  }, []);

  const handlePreviewClick = (fileItem: ResourceRepoDTO | undefined) => {
    setShowPreview(true);
    setSelectedFile(fileItem);
  };

  const handleGetIntakeReviewDetails = () => {
    if (clientId) {
      toggleLoader(true);
      getIntakeReviewDetails(clientId)
        .then((data) => {
          setResourceData(data.familyHistory.resourceDto);
          if (data.isSigned) {
            setIsScreenLocked(true);
          }

          setReviewInitialValues({
            ...data,
            pregnancy: {
              ...data.pregnancy,
              gestationalAgeCheck:
                data.pregnancy.gestationalAge !== 'Unknown' ? false : true,
              daysInNICUCheck:
                data.pregnancy.daysInNICU !== 'Unknown' ? false : true,
            },
            familyHistory: {
              ...data.familyHistory,
              mothersEducationYearCheck:
                data.familyHistory.mothersEducationYear !== 'Unknown'
                  ? false
                  : true,
            },
            signature: data.signature,
          });

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const saveDebounce = useCallback(
    debounce((data: IntakeReviewResponseType) => {
      if (clientId)
        putIntakeReviewDetails(clientId, data)
          .then(async (response) => {
            toggleLoader(false);
            setSuccessOrError('success');
            setToastrId(response.message);
            setToastrDefaultMessage(response.message);
            handleClose();
          })
          .catch((error) => {
            toggleLoader(false);
            setSuccessOrError('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId(error);
              setToastrDefaultMessage(error);
            }
          });
    }, 500),
    [],
  );

  const handleFormSubmit = async (values: IntakeReviewResponseType) => {
    const params: IntakeReviewResponseType = {
      demographics: {
        ...values.demographics,
      },
      pregnancy: {
        diagnosisOfSubstanceExposedNewborn:
          values.pregnancy.diagnosisOfSubstanceExposedNewborn,
        gestationalAge: values.pregnancy.gestationalAge,
        birthWeight: values.pregnancy.birthWeight,
        weightInLbs: values.pregnancy.weightInLbs,
        weightInOz: values.pregnancy.weightInOz,
        weightInGrams: values.pregnancy.weightInGrams,
        specialCareAfterBirth: values.pregnancy.specialCareAfterBirth,
        childInNICU: values.pregnancy.childInNICU,
        daysInNICU: values.pregnancy.daysInNICU,
      },
      currentHealth: {
        ...values.currentHealth,
      },
      familyHistory: {
        parentalChronicIllness: values.familyHistory.parentalChronicIllness,
        affectingChild: values.familyHistory.affectingChild,
        siblingWithSEN: values.familyHistory.siblingWithSEN,
        mothersEducationYear: values.familyHistory.mothersEducationYear,
        lackingAdequate: values.familyHistory.lackingAdequate,
        povertyLevel: values.familyHistory.povertyLevel,
        protectiveServiceInvestigation:
          values.familyHistory.protectiveServiceInvestigation,
        homelessness: values.familyHistory.homelessness,
        homelessnessInPast: values.familyHistory.homelessnessInPast,
        birthMotherParity: values.familyHistory.birthMotherParity,
        abuseAffectingChild: values.familyHistory.abuseAffectingChild,
        socialSupport: values.familyHistory.socialSupport,
        resourceDto: values.familyHistory.resourceDto,
      },
      signature: values.signature,
      isSigned: values.isSigned,
      reviewdBy: values.reviewdBy,
      reviewdDate: values.reviewdDate,
      canSign: values.canSign,
    };
    toggleLoader(true);
    saveDebounce(params);
  };

  const handleValidation = async () => {
    // Very very important
    await formikRef.current?.validateForm();
    // Very very important

    if (formikRef.current && formikRef.current.isValid) {
      setApproveModal(true);
    }
  };

  const [raceValues, setRaceValues] = React.useState<OptionType[]>([]);
  const [ethnicityValues, setEthnicityValues] = React.useState<OptionType[]>(
    [],
  );
  const [languageValues, setLanguageValues] = React.useState<OptionType[]>([]);
  const [signClicked, setSignClicked] = useState<boolean>(false);

  const { lookups } = React.useContext(LookUpContext);

  React.useEffect(() => {
    if (lookups === null) {
      return;
    }
    setRaceValues(() => {
      return lookups.race.map((r) => {
        return {
          label: r.description,
          id: r.code,
        };
      });
    });
    setEthnicityValues(() => {
      return lookups.ethnicity.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
    setLanguageValues(() => {
      return lookups.language.map((lang) => {
        return {
          label: lang.description,
          id: lang.code,
        };
      });
    });
  }, [lookups]);

  const clearForm = () => {
    handleClose();
    setModalOpen(false);
  };

  const onSignatureSave = (sign: string) => {
    setSignClicked(!signClicked);

    const updatedSignature = reviewInitialValues.signature;
    updatedSignature[0] = {
      ...updatedSignature[0],
      partitionKey: '',
      rowKey: '',
      signature: sign,
    };

    formikRef.current?.setFieldValue('isSigned', true);
    formikRef.current?.setFieldValue('signature', updatedSignature);
  };

  const signatureCallBack = (response: SignatureStateType[]) => {
    setReviewInitialValues((prevIntakeDetails) => {
      const updatedSignature = prevIntakeDetails.signature.map(
        (item, index) => {
          if (index === 0) {
            return {
              ...item,
              partitionKey: response[0].partitionkey,
              rowKey: response[0].rowkey,
              signature: response[0].signaturedata,
            };
          }
          return item;
        },
      );

      return {
        ...prevIntakeDetails,
        isSigned: false,
        signature: updatedSignature,
      };
    });
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setSuccessOrError(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const onCancelClick = () => {
    setSignClicked(!signClicked);
  };

  const clearSignature = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('signature', [
        {
          partitionKey: '',
          rowKey: '',
          signature: '',
        },
      ]);
      formikRef.current?.setFieldValue('isSigned', false);
    }

    setIsScreenLocked(false);
    setSuccessOrError('success');
    setToastrId('ProgressNote.signatureResetedsuccess');
    setToastrDefaultMessage('Signature Reset Successfully');
    setSignClicked(!signClicked);
  };

  return (
    <>
      {modalOpen && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'lg'}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="pb-md">
            {selectedFile && showPreview && (
              <FilePreviewDialog
                url={selectedFile.resourceUrl}
                fileName={selectedFile.name.split('/').pop()}
                onClose={() => {
                  setShowPreview(false);
                  setSelectedFile(undefined);
                }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', // Align items vertically in the center
              }}
            >
              <MediumTypography
                labelid="IntakeReview.Titletext"
                defaultlabel="Intake Review"
                sxProps={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginRight: '2px',
                }}
              />
              {reviewInitialValues.reviewdDate && (
                <MediumTypography
                  label={`- Reviewed by  ${
                    reviewInitialValues.reviewdBy
                  } on ${dayjs(reviewInitialValues.reviewdDate).format(
                    'MM/DD/YYYY',
                  )}`}
                  sxProps={{
                    paddingTop: '5px',
                  }}
                />
              )}
              {reviewInitialValues.reviewdBy === 'TBR' && (
                <MediumTypography
                  label={`- Reviewed by TBR`}
                  sxProps={{
                    paddingTop: '5px',
                  }}
                />
              )}
              <Close
                style={{
                  marginLeft: 'auto', // Move the Close button to the right side
                  marginTop: '12px',
                  marginBottom: '12px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (
                    !_.isEqual(reviewInitialValues, formikRef.current?.values)
                  ) {
                    setCancelModal(true);
                  } else {
                    clearForm();
                  }
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            {toastrId && (
              <SnackBarComponent
                open={toastrId !== undefined}
                handleClose={() => {
                  setToastrId(undefined);
                }}
                labelId={toastrId}
                defaultMessageId={toastrDefaultMessage}
                successOrError={successOrError}
              />
            )}

            <Box>
              <Formik
                initialValues={reviewInitialValues}
                innerRef={formikRef}
                validateOnMount={true}
                validateOnChange={true}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={() => {
                  handleValidation();
                }}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <>
                    <div
                      ref={parentRef}
                      style={{
                        pointerEvents: isScreenLocked ? 'none' : 'auto',
                        opacity: isScreenLocked ? '0.8' : '1 ',
                      }}
                      onKeyDownCapture={(e) => {
                        if (isScreenLocked) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onFocus={() => {
                        if (isScreenLocked) {
                          if (parentRef.current) parentRef.current.focus();
                        }
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          className="gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box className="p-sm">
                            <MediumTypography
                              labelid={'Demographic.SidebarText'}
                              defaultlabel="Demographics"
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2a4241"
                            />
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '12px' }}
                            />
                            <Box className="rowContainer">
                              <Grid
                                container
                                direction={'row'}
                                rowSpacing={'20px'}
                                columnSpacing={'40px'}
                              >
                                <Grid item xs={5} lg={5}>
                                  <MultipleAutocompleteSearch
                                    labelId="IntakeReview.Race.required"
                                    defaultId="Race *"
                                    data={raceValues}
                                    type="string"
                                    onSelectItem={(
                                      value: OptionType[] | null,
                                    ) => {
                                      if (value) {
                                        const selectedRaceIds = value.map(
                                          (r) => r.id,
                                        );
                                        setFieldValue(
                                          'demographics.race',
                                          selectedRaceIds.toString(),
                                        );
                                      }
                                    }}
                                    disableLabel={true}
                                    optionHintId={'No Race found'}
                                    sxProps={{
                                      [`& .${autocompleteClasses.popupIndicator}`]:
                                        {
                                          transform: 'none',
                                          marginTop: '2px',
                                        },
                                      backgroundColor: '#fff',
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                    value={values.demographics.race
                                      .split(',') // Split the string by comma
                                      .map((r) => ({
                                        id: r,
                                        label:
                                          raceValues.find(
                                            (raceValue) => raceValue.id === r,
                                          )?.label || '', // Set label to empty string if not found
                                      }))
                                      .filter(
                                        (
                                          option,
                                        ): option is {
                                          id: string;
                                          label: string;
                                        } => option.label !== '', // Filter out options with empty labels
                                      )}
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.race &&
                                    touched.demographics.race && (
                                      <MediumTypography
                                        labelid={errors.demographics.race}
                                        textColor="red"
                                      />
                                    )}
                                </Grid>

                                <Grid item xs={5} lg={5}>
                                  <DropdownComponent
                                    names={ethnicityValues}
                                    labelid={'IntakeReview.Ethnicity.required'}
                                    value={values.demographics.ethnicity}
                                    defaultlabelid="Ethnicity *"
                                    handleChange={(e) => {
                                      setFieldValue(
                                        'demographics.ethnicity',
                                        e,
                                      );
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.ethnicity &&
                                    touched.demographics.ethnicity && (
                                      <MediumTypography
                                        labelid={errors.demographics.ethnicity}
                                        textColor="red"
                                      />
                                    )}
                                </Grid>

                                <Grid item xs={5} lg={5}>
                                  <DropdownComponent
                                    names={languageValues}
                                    labelid={'IntakeReview.Primary.required'}
                                    value={
                                      values.demographics.primarySpokenLanguage
                                    }
                                    defaultlabelid="Primary Spoken Language *"
                                    handleChange={(e) => {
                                      if (e !== 'Other') {
                                        setFieldValue(
                                          'demographics.primarySpokenLanguageOther',
                                          '',
                                        );
                                      }
                                      setFieldValue(
                                        'demographics.primarySpokenLanguage',
                                        e,
                                      );
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.primarySpokenLanguage &&
                                    touched.demographics
                                      .primarySpokenLanguage && (
                                      <MediumTypography
                                        className="errorText-md"
                                        labelid={
                                          errors.demographics
                                            .primarySpokenLanguage
                                        }
                                      />
                                    )}
                                </Grid>

                                <Grid item xs={5} lg={5}>
                                  <DropdownComponent
                                    names={languageValues}
                                    labelid={
                                      'IntakeReview.PrimaryWritten.required'
                                    }
                                    value={
                                      values.demographics.primaryWrittenLanguage
                                    }
                                    defaultlabelid="Primary Written Language *"
                                    handleChange={(e) => {
                                      if (e !== 'Other') {
                                        setFieldValue(
                                          'demographics.primaryWrittenLanguageOther',
                                          '',
                                        );
                                      }
                                      setFieldValue(
                                        'demographics.primaryWrittenLanguage',
                                        e,
                                      );
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics
                                      .primaryWrittenLanguage &&
                                    touched.demographics
                                      .primaryWrittenLanguage && (
                                      <MediumTypography
                                        labelid={
                                          errors.demographics
                                            .primaryWrittenLanguage
                                        }
                                        className="errorText-md"
                                      />
                                    )}
                                </Grid>

                                {values.demographics.primarySpokenLanguage.match(
                                  'OTHER',
                                ) && (
                                  <>
                                    <Grid item xs={5} lg={5}>
                                      <Textinput
                                        name="primarySpokenLanguageOther"
                                        labelid="IntakeReview.PrimarySpokenOthers.required"
                                        defaultlabelid="Other Spoken Language *"
                                        Value={
                                          values.demographics
                                            .primarySpokenLanguageOther
                                        }
                                        handlechange={(text: string) => {
                                          setFieldValue(
                                            'demographics.primarySpokenLanguageOther',
                                            text,
                                          );
                                        }}
                                        inputProps={{
                                          maxLength: 100,
                                        }}
                                        sxProps={{
                                          backgroundColor: 'white',
                                        }}
                                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                      />
                                      {errors.demographics &&
                                        touched.demographics &&
                                        errors.demographics
                                          .primarySpokenLanguageOther &&
                                        touched.demographics
                                          .primarySpokenLanguageOther && (
                                          <MediumTypography
                                            labelid={
                                              errors.demographics
                                                .primarySpokenLanguageOther
                                            }
                                            defaultlabel="Is Required"
                                            className="errorText-md"
                                          />
                                        )}
                                    </Grid>
                                    {!values.demographics.primarySpokenLanguage.match(
                                      'OTHER',
                                    ) && <Grid item xs={5} lg={5}></Grid>}
                                  </>
                                )}

                                {values.demographics.primaryWrittenLanguage.match(
                                  'OTHER',
                                ) && (
                                  <>
                                    {!values.demographics.primarySpokenLanguage.match(
                                      'OTHER',
                                    ) && <Grid item xs={5} lg={5}></Grid>}
                                    <Grid item xs={5} lg={5}>
                                      <Textinput
                                        name="primaryWrittenLanguageOther"
                                        labelid="IntakeReview.PrimaryWrittenOthers.required"
                                        defaultlabelid="Other Written Language *"
                                        Value={
                                          values.demographics
                                            .primaryWrittenLanguageOther
                                        }
                                        handlechange={(text: string) => {
                                          setFieldValue(
                                            'demographics.primaryWrittenLanguageOther',
                                            text,
                                          );
                                        }}
                                        inputProps={{
                                          maxLength: 100,
                                        }}
                                        sxProps={{
                                          backgroundColor: 'white',
                                        }}
                                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                      />
                                      {errors.demographics &&
                                        touched.demographics &&
                                        errors.demographics
                                          .primaryWrittenLanguageOther &&
                                        touched.demographics
                                          .primaryWrittenLanguageOther && (
                                          <MediumTypography
                                            labelid={
                                              errors.demographics
                                                .primaryWrittenLanguageOther
                                            }
                                            defaultlabel="Is Required"
                                            className="errorText-md"
                                          />
                                        )}
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Box>
                            <Box>
                              <Grid
                                container
                                direction={'row'}
                                rowSpacing={'20px'}
                                columnSpacing={'40px'}
                              >
                                <Grid item xs={3} lg={3}>
                                  <MediumTypography
                                    labelid="IntakeReview.MultipleBirth.required"
                                    defaultlabel="Multiple Birth? *"
                                  />
                                  <CustomRadioButton
                                    data={IntakeRadioData}
                                    value={values.demographics.multipleBirth}
                                    onSelectItem={(value) => {
                                      if (value) {
                                        setFieldValue(
                                          'demographics.multipleBirth',
                                          value,
                                        );
                                      }
                                    }}
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.multipleBirth &&
                                    touched.demographics.multipleBirth && (
                                      <MediumTypography
                                        labelid={
                                          errors.demographics.multipleBirth
                                        }
                                        textColor="red"
                                      />
                                    )}
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MediumTypography
                                    labelid="IntakeReview.ChildBornMA.required"
                                    defaultlabel="Was this child born in MA ?*"
                                  />
                                  <CustomRadioButton
                                    data={EICSRadioData}
                                    value={values.demographics.childBorn}
                                    onSelectItem={(value) => {
                                      if (value) {
                                        setFieldValue(
                                          'demographics.childBorn',
                                          value,
                                        );
                                      }
                                    }}
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.childBorn &&
                                    touched.demographics.childBorn && (
                                      <MediumTypography
                                        labelid={errors.demographics.childBorn}
                                        className="errorText-md"
                                      />
                                    )}
                                </Grid>
                                <Grid item xs={4} lg={4}>
                                  <MediumTypography
                                    labelid="IntakeReview.ChildAdopted.required"
                                    defaultlabel="Is this child adopted ?*"
                                  />
                                  <CustomRadioButton
                                    data={EICSRadioData}
                                    value={values.demographics.childAdopted}
                                    onSelectItem={(value) => {
                                      if (value) {
                                        setFieldValue(
                                          'demographics.childAdopted',
                                          value,
                                        );
                                      }
                                    }}
                                  />
                                  {errors.demographics &&
                                    touched.demographics &&
                                    errors.demographics.childAdopted &&
                                    touched.demographics.childAdopted && (
                                      <MediumTypography
                                        labelid={
                                          errors.demographics.childAdopted
                                        }
                                        className="errorText-md"
                                      />
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container className="mt-xlg">
                        <Grid
                          item
                          xs={12}
                          xl={12}
                          className="gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box className="p-sm">
                            <MediumTypography
                              labelid={'Intake.Titletext'}
                              defaultlabel="Intake"
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2a4241"
                            />
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '12px' }}
                            />

                            <Grid
                              container
                              direction={'row'}
                              rowSpacing={'20px'}
                              columnSpacing={'40px'}
                            >
                              <Grid item xs={5} lg={5}>
                                <Textinput
                                  labelid="IntakeReview.GestationalAge.required"
                                  defaultlabelid="Gestational Age (in weeks) *"
                                  Value={values.pregnancy.gestationalAge}
                                  inputProps={{ maxLength: 2 }}
                                  handlechange={(text: string) => {
                                    setFieldValue(
                                      'pregnancy.gestationalAge',
                                      text.replace(/[^0-9]/g, ''),
                                    );
                                  }}
                                  sxProps={{
                                    backgroundColor: 'white',
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  disabled={
                                    values.pregnancy.gestationalAgeCheck
                                  }
                                />
                                {errors.pregnancy &&
                                  touched.pregnancy &&
                                  errors.pregnancy.gestationalAge &&
                                  touched.pregnancy.gestationalAge &&
                                  values.pregnancy.gestationalAge !==
                                    'Unknown' && (
                                    <MediumTypography
                                      labelid={errors.pregnancy.gestationalAge}
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                lg={5}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CheckBoxComponent
                                  ischecked={
                                    values.pregnancy.gestationalAgeCheck ||
                                    false
                                  }
                                  CheckHandleChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'pregnancy.gestationalAge',
                                        'Unknown',
                                      );
                                    } else {
                                      setFieldValue(
                                        'pregnancy.gestationalAge',
                                        '',
                                      );
                                    }
                                    setFieldValue(
                                      'pregnancy.gestationalAgeCheck',
                                      e.target.checked,
                                    );
                                  }}
                                  labelid="Intake.RadioLabelC"
                                  defaultlabelid="Unknown"
                                />
                                <MediumTypography />
                              </Grid>
                            </Grid>

                            <Box
                              display="flex"
                              flexDirection="row"
                              className="mt-lg"
                            >
                              <MediumTypography
                                className="mr-md"
                                labelid="IntakeReview.BirthWeight.required"
                                defaultlabel="Child’s birth weight ? *"
                              />
                              <CustomRadioButton
                                data={BirthRadioData}
                                value={values.pregnancy.birthWeight}
                                onSelectItem={(value) => {
                                  if (value) {
                                    if (value === 'In Pounds') {
                                      setFieldValue(
                                        'pregnancy.weightInGrams',
                                        '',
                                      );
                                    } else {
                                      setFieldValue(
                                        'pregnancy.weightInLbs',
                                        '',
                                      );
                                      setFieldValue('pregnancy.weightInOz', '');
                                    }
                                    setFieldValue(
                                      'pregnancy.birthWeight',
                                      value,
                                    );
                                  }
                                }}
                              />
                            </Box>
                            {errors.pregnancy &&
                              touched.pregnancy &&
                              errors.pregnancy.birthWeight &&
                              touched.pregnancy.birthWeight && (
                                <MediumTypography
                                  labelid={errors.pregnancy.birthWeight}
                                  className="errorText-md"
                                />
                              )}

                            {values.pregnancy.birthWeight === 'In Pounds' && (
                              <Box
                                component="div"
                                display="flex"
                                flexDirection="row"
                                className="mt-md"
                              >
                                <Box className="mr-md">
                                  <Textinput
                                    name="weightInLbs"
                                    labelid="weightInLbs"
                                    defaultlabelid="Lbs *"
                                    Value={values.pregnancy.weightInLbs}
                                    inputProps={{ maxLength: 2 }}
                                    handlechange={(text: string) => {
                                      setFieldValue(
                                        'pregnancy.weightInLbs',
                                        text.replace(/[^0-9 ]/g, ''),
                                      );
                                    }}
                                    sxProps={{
                                      backgroundColor: 'white',
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.pregnancy &&
                                    touched.pregnancy &&
                                    errors.pregnancy.weightInLbs &&
                                    touched.pregnancy.weightInLbs && (
                                      <MediumTypography
                                        labelid={errors.pregnancy.weightInLbs}
                                        defaultlabel="Weight In Lbs is Required"
                                        className="errorText-md"
                                      />
                                    )}
                                </Box>

                                <Box>
                                  <Textinput
                                    name="weightInOz"
                                    labelid="weightInOz"
                                    defaultlabelid="Oz *"
                                    Value={values.pregnancy.weightInOz}
                                    inputProps={{ maxLength: 2 }}
                                    handlechange={(text: string) => {
                                      setFieldValue(
                                        'pregnancy.weightInOz',
                                        text.replace(/[^0-9 ]/g, ''),
                                      );
                                    }}
                                    sxProps={{
                                      backgroundColor: 'white',
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.pregnancy &&
                                    touched.pregnancy &&
                                    errors.pregnancy.weightInOz &&
                                    touched.pregnancy.weightInOz && (
                                      <MediumTypography
                                        labelid={errors.pregnancy.weightInOz}
                                        defaultlabel="Weight In Oz is Required"
                                        className="errorText-md"
                                      />
                                    )}
                                </Box>
                              </Box>
                            )}

                            {values.pregnancy.birthWeight === 'In Grams' && (
                              <Box
                                component="div"
                                display="flex"
                                flexDirection="row"
                                className="mt-md"
                              >
                                <Box>
                                  <Textinput
                                    name="weightInGrams"
                                    labelid="weightInGrams"
                                    defaultlabelid="Grams *"
                                    Value={values.pregnancy.weightInGrams}
                                    inputProps={{ maxLength: 5 }}
                                    handlechange={(text: string) => {
                                      setFieldValue(
                                        'pregnancy.weightInGrams',
                                        text.replace(/[^0-9 ]/g, ''),
                                      );
                                    }}
                                    sxProps={{
                                      backgroundColor: 'white',
                                    }}
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                  {errors.pregnancy &&
                                    touched.pregnancy &&
                                    errors.pregnancy.weightInGrams &&
                                    touched.pregnancy.weightInGrams && (
                                      <MediumTypography
                                        labelid={errors.pregnancy.weightInGrams}
                                        defaultlabel="Weight In Grams is Required"
                                        className="errorText-md"
                                      />
                                    )}
                                </Box>
                              </Box>
                            )}

                            <Grid
                              container
                              direction={'row'}
                              rowSpacing={'20px'}
                              columnSpacing={'40px'}
                            >
                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  className="mr-md"
                                  labelid="IntakeReview.DiagnosisOfSubstanceExposedNewborn.required"
                                  defaultlabel="Diagnosis of Substance Exposed Newborn (SEN) ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.pregnancy
                                      .diagnosisOfSubstanceExposedNewborn
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'pregnancy.diagnosisOfSubstanceExposedNewborn',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.pregnancy &&
                                  touched.pregnancy &&
                                  errors.pregnancy
                                    .diagnosisOfSubstanceExposedNewborn &&
                                  touched.pregnancy
                                    .diagnosisOfSubstanceExposedNewborn && (
                                    <MediumTypography
                                      labelid={
                                        errors.pregnancy
                                          .diagnosisOfSubstanceExposedNewborn
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  className="mr-md"
                                  labelid="IntakeReview.SpecialCareAfterBirth.required"
                                  defaultlabel="Did your child receive special care after birth ? *"
                                />
                                <CustomRadioButton
                                  data={IntakeRadioData}
                                  value={values.pregnancy.specialCareAfterBirth}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'pregnancy.specialCareAfterBirth',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.pregnancy &&
                                  touched.pregnancy &&
                                  errors.pregnancy.specialCareAfterBirth &&
                                  touched.pregnancy.specialCareAfterBirth && (
                                    <MediumTypography
                                      labelid={
                                        errors.pregnancy.specialCareAfterBirth
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              direction={'row'}
                              rowSpacing={'20px'}
                              columnSpacing={'40px'}
                            >
                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childInNICU.required"
                                  defaultlabel="Was this child in NICU ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.pregnancy.childInNICU}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      if (
                                        values.pregnancy.daysInNICU &&
                                        values.pregnancy.daysInNICU !== ''
                                      ) {
                                        setFieldValue(
                                          'daysInNICU',
                                          values.pregnancy.daysInNICU,
                                        );
                                      } else {
                                        setFieldValue(
                                          'pregnancy.daysInNICU',
                                          '',
                                        );
                                      }
                                      if (value !== 'Yes') {
                                        setFieldValue(
                                          'pregnancy.daysInNICUCheck',
                                          false,
                                        );
                                      }
                                      setFieldValue(
                                        'pregnancy.childInNICU',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.pregnancy &&
                                  touched.pregnancy &&
                                  errors.pregnancy.childInNICU &&
                                  touched.pregnancy.childInNICU && (
                                    <MediumTypography
                                      labelid={errors.pregnancy.childInNICU}
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childHospitalized.required"
                                  defaultlabel="Was the child hospitalized for more than 25 days during a 6-month period, excluding the initial birth admission to a NICU ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.currentHealth.childHospitalized}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childHospitalized',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childHospitalized &&
                                  touched.currentHealth.childHospitalized && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth.childHospitalized
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              {values.pregnancy.childInNICU === 'Yes' && (
                                <Grid
                                  container
                                  direction={'row'}
                                  columnSpacing={'20px'}
                                  className="mt-md ml-lg"
                                >
                                  <Grid item xs={5} lg={5}>
                                    <Textinput
                                      name="daysInNICU"
                                      labelid="daysInNICU"
                                      defaultlabelid="NICU days ? *"
                                      Value={values.pregnancy.daysInNICU}
                                      inputProps={{ maxLength: 3 }}
                                      handlechange={(text: string) => {
                                        setFieldValue(
                                          'pregnancy.daysInNICU',
                                          text.replace(/[^0-9]/g, ''),
                                        );
                                      }}
                                      sxProps={{
                                        backgroundColor: 'white',
                                      }}
                                      placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                      disabled={
                                        values.pregnancy.daysInNICUCheck
                                      }
                                    />
                                    {errors.pregnancy &&
                                      touched.pregnancy &&
                                      errors.pregnancy.daysInNICU &&
                                      touched.pregnancy.daysInNICU &&
                                      values.pregnancy.daysInNICU !==
                                        'Unknown' && (
                                        <MediumTypography
                                          labelid={errors.pregnancy.daysInNICU}
                                          className="errorText-md"
                                        />
                                      )}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={5}
                                    lg={5}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <CheckBoxComponent
                                      ischecked={
                                        values.pregnancy.daysInNICUCheck ||
                                        false
                                      }
                                      CheckHandleChange={(e) => {
                                        if (e.target.checked) {
                                          setFieldValue(
                                            'pregnancy.daysInNICU',
                                            'Unknown',
                                          );
                                        } else {
                                          setFieldValue(
                                            'pregnancy.daysInNICU',
                                            '',
                                          );
                                        }
                                        setFieldValue(
                                          'pregnancy.daysInNICUCheck',
                                          e.target.checked,
                                        );
                                      }}
                                      labelid="Intake.RadioLabelC"
                                      defaultlabelid="Unknown"
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>

                            <Grid
                              container
                              direction={'row'}
                              columnSpacing={'40px'}
                              className="mt-md"
                            >
                              <Grid item xs={5} lg={5}>
                                <MediumTypography
                                  labelid="IntakeReview.childBloodLevel.required"
                                  defaultlabel="Blood lead levels measured 5 micrograms/dl ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.currentHealth.childBloodLevel}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childBloodLevel',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childBloodLevel &&
                                  touched.currentHealth.childBloodLevel && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth.childBloodLevel
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5} lg={5}>
                                <MediumTypography
                                  labelid="IntakeReview.childNervousSystem.required"
                                  defaultlabel="Suspected central nervous system abnormality ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.currentHealth.childNervousSystem
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childNervousSystem',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childNervousSystem &&
                                  touched.currentHealth.childNervousSystem && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth.childNervousSystem
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childInsecureDifficulties.required"
                                  defaultlabel="Difficulty with insecure attachment/interactional difficulties ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.currentHealth
                                      .childInsecureDifficulties
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childInsecureDifficulties',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth
                                    .childInsecureDifficulties &&
                                  touched.currentHealth
                                    .childInsecureDifficulties && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth
                                          .childInsecureDifficulties
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childMultipleTrauma.required"
                                  defaultlabel="Multiple trauma/losses experienced by child ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.currentHealth.childMultipleTrauma
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childMultipleTrauma',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childMultipleTrauma &&
                                  touched.currentHealth.childMultipleTrauma && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth.childMultipleTrauma
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={12} lg={12} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childDiagnosedAtBirth.required"
                                  defaultlabel="Was child diagnosed at birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA) ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.currentHealth.childDiagnosedAtBirth
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childDiagnosedAtBirth',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childDiagnosedAtBirth &&
                                  touched.currentHealth
                                    .childDiagnosedAtBirth && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth
                                          .childDiagnosedAtBirth
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={12} lg={12} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.smallSize.required"
                                  defaultlabel="Is child's weight, weight for height, or height for age less than the 5th percentile (Small size) ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.currentHealth.smallSize}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.smallSize',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.smallSize &&
                                  touched.currentHealth.smallSize && (
                                    <MediumTypography
                                      labelid={errors.currentHealth.smallSize}
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={12} lg={12} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.childChronicFeeding.required"
                                  defaultlabel="Chronic feeding difficulties ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.currentHealth.childChronicFeeding
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'currentHealth.childChronicFeeding',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.currentHealth &&
                                  touched.currentHealth &&
                                  errors.currentHealth.childChronicFeeding &&
                                  touched.currentHealth.childChronicFeeding && (
                                    <MediumTypography
                                      labelid={
                                        errors.currentHealth.childChronicFeeding
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              direction={'row'}
                              columnSpacing={'40px'}
                              className="mt-md mb-lg"
                            >
                              <Grid item xs={5} lg={5}>
                                <MediumTypography
                                  labelid="IntakeReview.parentalChronicIllness.required"
                                  defaultlabel="Parental chronic illness or disability that affects caregiving ability ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.familyHistory.parentalChronicIllness
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.parentalChronicIllness',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.parentalChronicIllness &&
                                  touched.familyHistory
                                    .parentalChronicIllness && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory
                                          .parentalChronicIllness
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5}>
                                <MediumTypography
                                  labelid="IntakeReview.affectingChild.required"
                                  defaultlabel="Substance abuse affecting child ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.affectingChild}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.affectingChild',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.affectingChild &&
                                  touched.familyHistory.affectingChild && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.affectingChild
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.siblingWithSEN.required"
                                  defaultlabel="Sibling with a diagnosis of Substance Exposed Newborn (SEN) ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.siblingWithSEN}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.siblingWithSEN',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.siblingWithSEN &&
                                  touched.familyHistory.siblingWithSEN && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.siblingWithSEN
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.mothersEducationYear.required"
                                  defaultlabel="Biological mother’s years of formal education ? *"
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Textinput
                                    name="mothersEducationYear"
                                    labelid=""
                                    defaultlabelid=""
                                    Value={
                                      values.familyHistory.mothersEducationYear
                                    }
                                    handlechange={(text: string) => {
                                      const regex = /^\d+$/;
                                      if (regex.test(text) || text === '') {
                                        setFieldValue(
                                          'familyHistory.mothersEducationYear',
                                          text,
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      maxLength: 2,
                                    }}
                                    sxProps={{
                                      backgroundColor: 'white',
                                      width: '30%',
                                    }}
                                    className="mr-md"
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                    disabled={
                                      values.familyHistory
                                        .mothersEducationYearCheck
                                    }
                                  />

                                  <CheckBoxComponent
                                    ischecked={
                                      values.familyHistory
                                        .mothersEducationYearCheck || false
                                    }
                                    CheckHandleChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'familyHistory.mothersEducationYear',
                                          'Unknown',
                                        );
                                      } else {
                                        setFieldValue(
                                          'familyHistory.mothersEducationYear',
                                          '',
                                        );
                                      }
                                      setFieldValue(
                                        'familyHistory.mothersEducationYearCheck',
                                        e.target.checked,
                                      );
                                    }}
                                    labelid="Intake.RadioLabelC"
                                    defaultlabelid="Unknown"
                                  />
                                </Box>
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.mothersEducationYear &&
                                  touched.familyHistory.mothersEducationYear &&
                                  values.familyHistory.mothersEducationYear !==
                                    'Unknown' && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory
                                          .mothersEducationYear
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.lackingAdequate.required"
                                  defaultlabel="Lacking adequate food or clothing ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.lackingAdequate}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.lackingAdequate',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.lackingAdequate &&
                                  touched.familyHistory.lackingAdequate && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.lackingAdequate
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.protectiveService.required"
                                  defaultlabel="Open/Confirmed protective service investigation ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.familyHistory
                                      .protectiveServiceInvestigation
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.protectiveServiceInvestigation',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory
                                    .protectiveServiceInvestigation &&
                                  touched.familyHistory
                                    .protectiveServiceInvestigation && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory
                                          .protectiveServiceInvestigation
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.homelessness.required"
                                  defaultlabel="Homelessness ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.homelessness}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.homelessness',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.homelessness &&
                                  touched.familyHistory.homelessness && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.homelessness
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.homelessnessInPast.required"
                                  defaultlabel="Homeless in the past 12 months ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.familyHistory.homelessnessInPast
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.homelessnessInPast',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.homelessnessInPast &&
                                  touched.familyHistory.homelessnessInPast && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.homelessnessInPast
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.abuse.required"
                                  defaultlabel="Abuse (physical or emotional) affecting child ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={
                                    values.familyHistory.abuseAffectingChild
                                  }
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.abuseAffectingChild',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.abuseAffectingChild &&
                                  touched.familyHistory.abuseAffectingChild && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.abuseAffectingChild
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={5} lg={5} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.socialSupport.required"
                                  defaultlabel="Family lacking social supports ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.socialSupport}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.socialSupport',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.socialSupport &&
                                  touched.familyHistory.socialSupport && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.socialSupport
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={12} lg={12} className="mt-md">
                                <MediumTypography
                                  labelid="IntakeReview.birthMotherParity.required"
                                  defaultlabel="Mother less than 17 years of age and/or with 3 or more children before 20 (Birth Mother Parity) ? *"
                                />
                                <CustomRadioButton
                                  data={EICSRadioData}
                                  value={values.familyHistory.birthMotherParity}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.birthMotherParity',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.birthMotherParity &&
                                  touched.familyHistory.birthMotherParity && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.birthMotherParity
                                      }
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={9} lg={9} className="mt-md">
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <MediumTypography
                                    className="mb-sm"
                                    labelid="IntakeReview.povertyLevel.required"
                                    defaultlabel="Poverty level ? *"
                                  />
                                  {resourceDto?.resourceUrl && (
                                    <Document
                                      style={{
                                        width: '15px',
                                        marginBottom: 'auto',
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        const extension =
                                          resourceDto?.resourceUrl
                                            ? extractFileExtension(
                                                resourceDto.resourceUrl,
                                              ).toLowerCase()
                                            : '';
                                        if (
                                          PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                            extension,
                                          )
                                        ) {
                                          handlePreviewClick(resourceDto);
                                        }
                                      }}
                                    />
                                  )}
                                </Box>
                                <CustomRadioButton
                                  data={PovertyJsonData}
                                  value={values.familyHistory.povertyLevel}
                                  onSelectItem={(value) => {
                                    if (value) {
                                      setFieldValue(
                                        'familyHistory.povertyLevel',
                                        value,
                                      );
                                    }
                                  }}
                                />
                                {errors.familyHistory &&
                                  touched.familyHistory &&
                                  errors.familyHistory.povertyLevel &&
                                  touched.familyHistory.povertyLevel && (
                                    <MediumTypography
                                      labelid={
                                        errors.familyHistory.povertyLevel
                                      }
                                      defaultlabel="Poverty Level is Required"
                                      className="errorText-md"
                                    />
                                  )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>

                    {values.canSign && (
                      <Grid container className="mt-xlg">
                        <Grid
                          item
                          xs={12}
                          xl={12}
                          className="gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box className="p-sm">
                            <MediumTypography
                              labelid={'ConsentForms.staffSignatureTitle'}
                              defaultlabel={'Staff Initials'}
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2a4241"
                            />
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '12px' }}
                            />

                            <Box
                              sx={{
                                paddingBottom:
                                  reviewInitialValues.reviewdBy === 'TBR'
                                    ? '0px'
                                    : '24px',
                              }}
                            >
                              {values.signature.length > 0 &&
                                values.signature[0].rowKey === '' &&
                                values.signature[0].signature === '' && (
                                  <ButtonComponent
                                    className="btn-primary btn-cancel signature_add_Box"
                                    labelId="Insurance.Addsignature"
                                    defaultLabelId="Add Signature"
                                    variantType="outlined"
                                    onClick={() => setSignClicked(true)}
                                  />
                                )}
                              {values.signature.length > 0 &&
                                (values.signature[0].rowKey !== '' ||
                                  values.signature[0].signature) && (
                                  <ButtonComponent
                                    className="btn-primary btn-cancel signature_view_Box"
                                    labelId={'ProgressNote.ViewUpdateSignature'}
                                    defaultLabelId={'View/update signature'}
                                    variantType="outlined"
                                    LeftIcon={<Editsignature />}
                                    onClick={() => setSignClicked(true)}
                                  />
                                )}
                            </Box>
                            {/* Signature pad */}
                            {signClicked && (
                              <SignatureComponent
                                signClicked={signClicked}
                                labelId="signatureText"
                                defaultLabelId="Signature"
                                onCancelClick={onCancelClick}
                                signature={
                                  formikRef.current?.values.signature[0]
                                    .signature
                                }
                                signaturePartitionKey={
                                  formikRef.current?.values.signature[0]
                                    .partitionKey ?? ''
                                }
                                signatureRowKey={
                                  formikRef.current?.values.signature[0]
                                    .rowKey ?? ''
                                }
                                signatureSaveOrUPdate={onSignatureSave}
                                resetCallBackForTypeEmployee={clearSignature}
                                signatureCallBack={signatureCallBack}
                                handleSuccess={handleSuccess}
                                type="Employee"
                                // hideRemoveButton={false} // commenting to revert the changes of Intake remove signature functionality
                                hideRemoveButton={true}
                              />
                            )}

                            {reviewInitialValues.reviewdDate && (
                              <Box className="flex__">
                                <MediumTypography
                                  label={`Reviewed by ${
                                    reviewInitialValues.reviewdBy
                                  } on ${dayjs(
                                    reviewInitialValues.reviewdDate,
                                  ).format('MM/DD/YYYY')}`}
                                />
                              </Box>
                            )}

                            {reviewInitialValues.reviewdBy === 'TBR' && (
                              <Box className="flex__">
                                <MediumTypography
                                  label={`Intake is Reviewed in TBR`}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Formik>
            </Box>

            <div
              ref={parentRef}
              style={{
                pointerEvents: isScreenLocked ? 'none' : 'auto',
                opacity: isScreenLocked ? '0.8' : '1 ',
              }}
              onKeyDownCapture={(e) => {
                if (isScreenLocked) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onFocus={() => {
                if (isScreenLocked) {
                  if (parentRef.current) parentRef.current.focus();
                }
              }}
            >
              <Box>
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel mr-md"
                      variantType="contained"
                      defaultLabelId="Cancel"
                      labelId="Contacts.cancelbtn"
                      onClick={() => {
                        if (
                          !_.isEqual(
                            reviewInitialValues,
                            formikRef.current?.values,
                          )
                        ) {
                          setCancelModal(true);
                        } else {
                          clearForm();
                        }
                      }}
                    />

                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Insurance.save"
                      defaultLabelId="Save"
                      onClick={() => {
                        if (
                          formikRef.current?.values.signature[0].signature !==
                            '' ||
                          formikRef.current?.values.signature[0].rowKey !== ''
                        ) {
                          if (formikRef.current?.isValid) {
                            setApproveModal(true);
                          } else if (!formikRef.current?.isValid) {
                            formikRef.current?.handleSubmit();
                          }
                        } else {
                          handleFormSubmit(formikRef.current.values);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <ModalPopup
                open={cancelModal}
                maxWidth={'xs'}
                description="formUnsavedChangesMessage"
                onCancel={() => setCancelModal(false)}
                onOk={() => clearForm()}
                labelId1="Clientpage.Nobtn"
                negativeActionLabel="No"
                labelId2="Clientpage.Yesbtn"
                positiveActionLabel="Yes"
              />
              {approveModal && (
                <ModalPopup
                  open={approveModal}
                  onCancel={() => {
                    setApproveModal(false);
                  }}
                  description="Intake.ReviewConfirmation"
                  onOk={() => {
                    if (formikRef.current) {
                      handleFormSubmit(formikRef.current.values);
                      setApproveModal(false);
                    }
                  }}
                  labelId1="Clientpage.Nobtn"
                  negativeActionLabel="No"
                  labelId2="Clientpage.Yesbtn"
                  positiveActionLabel="Yes"
                />
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default IntakeReview;

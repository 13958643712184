import { FC, useEffect, useState } from 'react';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Menu, MenuItem } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import SearchBox from '../../components/formlib/SearchBox';
import { v4 as uuidv4 } from 'uuid';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { getCaseloadListEmployees } from '../../services/configApi/codelist/caseloadlistService';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

export interface ServiceCoordinator {
  id: string;
  label: string;
  type: 'all' | 'myTeam' | 'others';
}
interface ServiceCoordinatorDropdownProps {
  onItemSelect: (item: ServiceCoordinator) => void;
  onItemsSelect: (items: ServiceCoordinator[]) => void;
  filterUpdate: string | null;
}

const CaseloadEmployeesDropdown: FC<ServiceCoordinatorDropdownProps> = ({
  onItemSelect,
  onItemsSelect,
  filterUpdate,
}) => {
  const [originalEmployees, setOriginalEmployees] = useState<
    ServiceCoordinator[]
  >([]);
  const [employees, setEmployees] = useState<ServiceCoordinator[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<
    ServiceCoordinator[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (filterUpdate === null) {
      return;
    }
    if (filterUpdate === '') {
      setSelectedEmployees([]);
      setEmployees(JSON.parse(JSON.stringify(originalEmployees)));
    } else {
      const ids = filterUpdate.split(',');
      const eps = selectedEmployees.filter((item) => {
        return !ids.includes(item.id);
      });
      if (eps.length === 0) {
        setSelectedEmployees([]);
        setEmployees(JSON.parse(JSON.stringify(originalEmployees)));
      } else {
        setSelectedEmployees(eps);
      }
    }
  }, [filterUpdate]);

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (originalEmployees.length > 0) {
      const filteredEmployees = originalEmployees.filter((employee) => {
        return employee.label.toLowerCase().includes(value.toLowerCase());
      });
      setEmployees(filteredEmployees);
    }
  };

  useEffect(() => {
    getCaseloadListEmployees()
      .then((res) => {
        employees.push({
          id: uuidv4(),
          label: 'All',
          type: 'all',
        });
        // employees.push({
        //   id: uuidv4(),
        //   label: 'My Team',
        //   type: 'myTeam',
        // });
        const userId = localStorage.getItem('userId');
        res.forEach((item) => {
          if (userId !== null && userId !== item.id) {
            employees.push({
              label: `${item.firstName !== null ? item.firstName : ''} ${
                item.lastName !== null ? item.lastName : ''
              }`,
              id: item.id,
              type: 'others',
            });
          }
        });
        setOriginalEmployees(employees);
        setEmployees(JSON.parse(JSON.stringify(employees)));
      })
      .catch(() => {
        employees.push({
          id: uuidv4(),
          label: 'All',
          type: 'all',
        });
        employees.push({
          id: uuidv4(),
          label: 'My Team',
          type: 'myTeam',
        });
        setEmployees(employees);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF',
          border: '1px solid #008C82',
          borderRadius: '4px',
          marginRight: '16px',
        }}
        onClick={handleFilterClick}
      >
        <TextWithIcon
          labelId="selectStaffText"
          defaultLabel="Select Staff"
          RightIcon={<KeyboardArrowDownIcon />}
          sxProps={{
            color: '#008C82',
            height: '32px',
            padding: '0px 16px 0px 16px',
          }}
        />
      </Box>
      <Menu
        id="menu-filter"
        className="pt-md"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleFilterClose}
      >
        <Box sx={{ padding: '8px' }}>
          <SearchBox
            value={searchQuery}
            labelId="searchEmployeeText"
            defaultlabel="Search Employee"
            onChange={(value) => handleSearch(value)}
            sxProps={{
              backgroundColor: '#E7F0F0',
            }}
          />
          <Box className="mt-md">
            {employees.map((employee) => {
              return (
                <MenuItem
                  key={employee.id}
                  onClick={() => {
                    if (employee.type === 'all' || employee.type === 'myTeam') {
                      onItemSelect(employee);
                      setSelectedEmployees([]);
                      handleFilterClose();
                    } else {
                      const found = selectedEmployees.find((item) => {
                        return item.id === employee.id;
                      });
                      if (found) {
                        setSelectedEmployees(
                          selectedEmployees.filter(
                            (item) => item.id !== employee.id,
                          ),
                        );
                      } else {
                        setSelectedEmployees([...selectedEmployees, employee]);
                      }
                    }
                  }}
                  sx={{
                    height: '32px',
                    width: '100%',
                    whiteSpace: 'normal',
                  }}
                >
                  {employee.type === 'others' ? (
                    <>
                      <CheckBoxComponent
                        ischecked={selectedEmployees.some(
                          (item) => item.id === employee.id,
                        )}
                        label={employee.label}
                      />
                    </>
                  ) : (
                    <>
                      <Box className="pl-xlg" />
                      <MediumTypography label={employee.label} />
                    </>
                  )}
                </MenuItem>
              );
            })}
            <Box display={'flex'} justifyContent={'end'}>
              <ButtonComponent
                className="btn-primary btn-submit ml-md mt-md"
                labelId="Dashboard.filtersubmit"
                defaultLabelId="Apply"
                onClick={() => {
                  if (selectedEmployees.length > 0) {
                    setSearchQuery('');
                    onItemsSelect(selectedEmployees);
                    handleFilterClose();
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default CaseloadEmployeesDropdown;

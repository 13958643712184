import { Badge, Box, TableCell, TableRow, styled } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import moment from 'moment';
import { ProgressNotesListResponseType } from '../../services/progressNotes/ProgressNotesApi';
import { formatName, getFullNameWithMiddleName } from '../../utils/nameUtils';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { convertTimeDuration } from '../../utils/dateUtil';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

interface ProgressNotesListProps {
  data: ProgressNotesListResponseType;
  routePath: string;
  onView: (rowData: ProgressNotesListResponseType) => void;
}

const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color || '#37D183',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
    marginRight: '10px',
  },
}));

const ProgressNotesTableRow: FC<ProgressNotesListProps> = (props) => {
  const {
    firstName,
    middleName,
    lastName,
    enrolmentId,
    appointmentType,
    appointmentStartTime,
    attendees,
    duration,
    status,
    suffix,
  } = props.data;

  const renderCell = (
    content: string | null | undefined,
    format?: string,
    textColor?: string,
  ) => {
    if (!content || content === '') {
      return <MediumTypography label="-" sxProps={{ textAlign: 'left' }} />;
    } else if (content === 'N/A') {
      return <MediumTypography label="N/A" sxProps={{ textAlign: 'left' }} />;
    }
    return (
      <MediumTypography
        label={format ? moment(content).local().format(format) : content}
        textColor={textColor}
      />
    );
  };

  const badgePicker = (statusVal: string) => {
    switch (statusVal) {
      case 'Completed':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#37D183',
              },
            }}
          />
        );
      case 'In Progress':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#F9B803',
              },
            }}
          />
        );
      case 'Canceled':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#EB4C60',
              },
            }}
          />
        );
      case 'Pending':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#F9B803',
              },
            }}
          />
        );
      default:
        return <CustomBadge variant="dot" />;
    }
  };
  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
      }}
    >
      <TableCell padding="checkbox">
        <CheckBoxComponent ischecked={false} />
      </TableCell>
      {props.routePath === 'Clinician' && (
        <TooltipTableCell
          value={formatName(firstName, middleName, lastName, suffix)}
          visible={true}
          textSxProps={{ maxWidth: '150px' }}
        />
      )}
      {props.routePath === 'Clinician' && (
        <TableCell>{renderCell(enrolmentId)}</TableCell>
      )}

      <TableCell>
        {appointmentType ? (
          <MediumTypography
            labelid={appointmentType}
            sxProps={{ textAlign: 'left' }}
          />
        ) : (
          <MediumTypography label="-" sxProps={{ textAlign: 'left' }} />
        )}
      </TableCell>
      <TableCell>{renderCell(appointmentStartTime, 'MM/DD/YYYY')}</TableCell>
      {props.routePath === 'Clinician' && (
        <TooltipTableCell
          value={
            (attendees &&
              attendees
                .map((attendee) =>
                  getFullNameWithMiddleName(
                    attendee.firstName,
                    attendee.middleName,
                    attendee.lastName,
                  ),
                )
                .join(', ')) ||
            '-'
          }
          visible={true}
          textSxProps={{ maxWidth: '150px' }}
        />
      )}
      {props.routePath === 'Client' && (
        <TooltipTableCell
          value={getFullNameWithMiddleName(firstName, middleName, lastName)}
          visible={true}
          textSxProps={{ maxWidth: '150px' }}
        />
      )}
      <TableCell>{renderCell(appointmentStartTime, 'hh:mm A')}</TableCell>
      <TableCell>{renderCell(convertTimeDuration(duration, true))}</TableCell>
      <TableCell>
        <Box sx={{ display: 'flex' }} alignItems={'center'}>
          {status && badgePicker(status)}
          {renderCell(status)}
        </Box>
      </TableCell>
      {checkPermissionForFeature('backend.progress_note', 'viewPermission') && (
        <TableCell>
          <Box className="flex__">
            <Box
              className={
                appointmentStartTime === '' || !appointmentStartTime
                  ? 'disablePopupIcon'
                  : 'cursorPointer'
              }
              onClick={() => {
                if (appointmentStartTime === '' || !appointmentStartTime) {
                  return;
                } else {
                  props.onView(props.data);
                }
              }}
            >
              <ToolTipIcon
                labelId="ConsentForms.listActionViewToolTipLabel"
                defaultLabel="View"
                cursor="pointer"
                Icon={<ViewEye />}
              />
            </Box>
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProgressNotesTableRow;
